import React, { FC, PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const TitleTooltip: FC<PropsWithChildren<Props>> = ({ titleTooltip, title, titleClassName, link }) => {
  const Base: React.FC<PropsWithChildren> = link
    ? ({ children }) => (
        <Link to={link} className="base-link">
          {children}
        </Link>
      )
    : React.Fragment;
  return (
    <Base>
      <OverlayTrigger
        overlay={
          <Tooltip className="size-caption tooltip-title" data-test-id="titleTooltip-tooltip">
            {titleTooltip}
          </Tooltip>
        }
      >
        <div className={titleClassName} data-test-id="titleTooltip-title">
          {title}
        </div>
      </OverlayTrigger>
    </Base>
  );
};
interface Props {
  titleTooltip: string | null;
  title: string | null;
  titleClassName: string;
  link?: string;
}
export default TitleTooltip;
