import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import CampaignList from '../../../../components/CampaignList/CampaignList';

const CampaignsTab: FC = () => {
  const { id: productId } = useParams<'id'>();

  return (
    <div>
      <CampaignList productId={productId!} />
    </div>
  );
};

export default CampaignsTab;
