import axios from 'axios';
import InvalidCredentialsError from '../errors/InvalidCredentialsError';
import InvalidOtpCodeError from '../errors/InvalidOtpCodeError';
import ChangeMfaResult from '../models/Account/ChangeMfaResult';
import AuthToken from '../models/AuthToken';
import request from './requestService';
import UserAccountDetails from '../models/Account/UserAccountDetails';
import UserInfo from '../models/Account/UserInfo';
import { isBusinessError } from '../models/BusinessError';
import InvalidPhoneNumberError from '../errors/InvalidPhoneNumberError';
import InvalidTwoFactorStatusError from '../errors/InvalidTwoFactorStatusError';

class AccountService {
  private readonly BaseUrl = `/account`;

  public async getUserInfo(): Promise<UserInfo> {
    const userResponse = await request.get<UserInfo>(`${this.BaseUrl}/userInfo`);
    return userResponse.data;
  }

  public async getUserDetails(): Promise<UserAccountDetails> {
    const response = await request.get(`${this.BaseUrl}/UserDetails`);
    return response.data;
  }

  public async updateUserDetails(fullName: string, phoneNumber?: string): Promise<UserAccountDetails> {
    try {
      const response = await request.put(`${this.BaseUrl}/UpdateUserDetails`, {
        fullName,
        phoneNumber,
      });
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidPhoneNumber') throw new InvalidPhoneNumberError();
      }
      throw error;
    }
  }

  public async regenerateRecoveryCode(): Promise<string> {
    try {
      const response = await request.post<{ recoveryCode: string }>(`${this.BaseUrl}/regenerateRecoveryCode`);
      return response.data.recoveryCode;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidTwoFactorStatus') throw new InvalidTwoFactorStatusError();
      }
      throw error;
    }
  }

  public async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return request.post(`${this.BaseUrl}/ChangePassword`, {
      oldPassword,
      newPassword,
    });
  }

  public async changeMfa(password: string): Promise<ChangeMfaResult> {
    try {
      const response = await request.post(`${this.BaseUrl}/ChangeMfa`, {
        password,
      });
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidCredentials') throw new InvalidCredentialsError();
      }
      throw error;
    }
  }

  public async verifyOtpCode(twoFaToken: string, code: string): Promise<AuthToken> {
    try {
      const response = await request.post<AuthToken>(`${this.BaseUrl}/verifyOtpCode`, { twoFaToken, code });
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidOtpCode') throw new InvalidOtpCodeError();
      }
      throw error;
    }
  }
}

const accountService = new AccountService();

export default accountService;
