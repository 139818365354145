import React, { FC, PropsWithChildren, useContext } from 'react';
import TabsContext from './TabsContext';

const TabItem: FC<PropsWithChildren<Props>> = ({ children, id }) => {
  const { activeTab, setActiveTab } = useContext(TabsContext);
  return (
    <li className="nav-item">
      <button
        className={`nav-link ${activeTab === id ? 'active' : ''}`}
        onClick={() => {
          setActiveTab(id);
        }}
        aria-current="page"
        id={id}
        type="button"
        role="tab"
      >
        {children}
      </button>
    </li>
  );
};
interface Props {
  id: string;
}
export default TabItem;
