import React, { FormEventHandler, FormEvent, PropsWithChildren, FC } from 'react';

import { Button, Form } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

import CodeInput from './CodeInput/CodeInput';
import useCodeFormReducer, { ITwoFaFormState } from './useCodeFormReducer';

const CodeForm: FC<PropsWithChildren<ICodeFormProps>> = ({
  codeLength = 6,
  onSubmit,
  children,
  onRecoveryCodeRequested,
  onSupportRequested,
  buttonClassName,
}) => {
  const initialState: ITwoFaFormState = {
    inputValues: Array(codeLength).fill(''),
    focusedIndex: 0,
  };

  const {
    state: { inputValues, focusedIndex },
    dispatch,
  } = useCodeFormReducer(initialState);

  const onChangeHandler = (index: number, value: string): void => {
    dispatch({ type: 'INPUT', payload: { index, value } });
  };

  const onBackspaceHandler = () => {
    dispatch({ type: 'BACKSPACE' });
  };

  const onPasteHandler = (value: string) => {
    dispatch({ type: 'PASTE', payload: { value } });
  };

  const onFocusHandler = (index: number): void => {
    dispatch({ type: 'FOCUS', payload: { index } });
  };

  const onBackHandler = () => {
    dispatch({ type: 'BACK' });
  };

  const onForwardHandler = () => {
    dispatch({ type: 'FORWARD' });
  };

  const onSubmitFormHandler: FormEventHandler<HTMLFormElement> = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit(inputValues.join(''));
  };

  return (
    <Form className="mt-4" onSubmit={onSubmitFormHandler}>
      <div className="d-flex justify-content-center">
        <div className={`justify-content-between pb-3 d-flex gap-2 ${children ? 'is-invalid' : null}`}>
          {inputValues.map((value: string, index: number) => {
            const key = `${index}`;
            return (
              <CodeInput
                key={key}
                index={index}
                value={value}
                isFocused={index === focusedIndex}
                isDisabled={false}
                onChange={onChangeHandler}
                onBackspace={onBackspaceHandler}
                onPaste={onPasteHandler}
                onFocus={onFocusHandler}
                onLeft={onBackHandler}
                onRight={onForwardHandler}
              />
            );
          })}
        </div>
      </div>
      {children}
      <div className="mt-4 mb-2">
        <Button
          variant="primary"
          type="submit"
          className={`text-uppercase w-100 ${buttonClassName}`}
          data-test-id="codeForm-verifyCode"
        >
          <FormattedMessage id="2fa.code.form.btn.label" />
        </Button>
      </div>
      <div
        className={`d-flex ineedhelp-bottom ${
          onRecoveryCodeRequested ? 'justify-content-between' : 'justify-content-end'
        } `}
      >
        {onRecoveryCodeRequested && (
          <Button
            variant="ghost"
            onClick={onRecoveryCodeRequested}
            data-test-id="codeForm-useRecoveryCode"
            className="btn-rounded px-2 py-1"
          >
            <FormattedMessage id="2fa.code.useRecovery.btn.label" />
          </Button>
        )}
        {onSupportRequested && (
          <Button variant="ghost" onClick={onSupportRequested} data-test-id="codeForm-needHelp" className="btn-rounded">
            <FormattedMessage id="2fa.code.help.btn.label" />
          </Button>
        )}
      </div>
    </Form>
  );
};

interface ICodeFormProps {
  codeLength?: number;
  onSubmit: (code: string) => void;
  onSupportRequested?: () => void;
  onRecoveryCodeRequested?: () => void;
  buttonClassName?: string;
}

export default CodeForm;
