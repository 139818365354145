import AlertItem from '../models/Alert/AlertItem';
import productsHelper from './productsHelper';

const alertsBaseUrl = '/alerts';
const adminAlertsUrl = alertsBaseUrl;
const getUserAlertsUrl = (companyId: string) => `/companies/${companyId}${alertsBaseUrl}`;

const getItemUrl = (alertItem: AlertItem) => {
  return `${productsHelper.getBatchesUrl({
    productId: alertItem.productId,
    synthesisStepId: alertItem.synthesisStepId,
    campaignId: alertItem.campaignId,
    viewState: {
      idsFilter: [alertItem.batchId],
    },
  })}`;
};
const getAdminClientAlertsUrl = (companyId: string) => `${adminAlertsUrl}/?companyId=${companyId}`;

export default {
  adminAlertsUrl,
  getUserAlertsUrl,
  getItemUrl,
  getAdminClientAlertsUrl,
};
