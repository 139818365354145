import qs from 'qs';
import CampaignDetails from '../models/Campaign/CampaignDetails';
import CampaignList from '../models/Campaign/CampaignList';
import CampaignPhase from '../models/Campaign/CampaignPhase';
import CampaignTrackers from '../models/Campaign/CampaignTrackers';
import PagingInfo from '../models/PagingInfo';
import request from './requestService';

class CampaignService {
  private readonly BaseUrl = `/Campaign`;

  private readonly ManufacturerBaseUrl = `/Manufacturer/Campaign`;

  public async list(params: {
    companyId?: string;
    productId: string;
    paging?: PagingInfo;
    problemTrackers?: string[];
    synthesisStepId?: string;
    phase?: CampaignPhase;
  }): Promise<CampaignList> {
    const response = await request.get(`${this.BaseUrl}/List`, {
      params: {
        campaignPhase: params.phase,
        companyId: params.companyId,
        productId: params.productId,
        ...params.paging,
        problemTrackers: params.problemTrackers,
        synthesisStepId: params.synthesisStepId,
      },
      paramsSerializer: {
        serialize: (p) => {
          return qs.stringify(p);
        },
      },
    });
    return response.data;
  }

  public async get(campaignId: string): Promise<CampaignDetails> {
    const response = await request.get(`${this.BaseUrl}/GetDetails`, {
      params: {
        campaignId,
      },
    });
    return response.data;
  }

  public async trackers(campaignId: string): Promise<CampaignTrackers> {
    const response = await request.get(`${this.BaseUrl}/Trackers`, {
      params: {
        campaignId,
      },
    });
    return response.data;
  }

  public async manufacturerList(
    productId?: string,
    campaignNameTerm?: string,
    paging?: PagingInfo,
  ): Promise<CampaignList> {
    const response = await request.get(`${this.ManufacturerBaseUrl}/List`, {
      params: {
        productId,
        campaignNameTerm,
        ...paging,
      },
    });
    return response.data;
  }
}

export default new CampaignService();
