import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Spinner from '../../../../components/Spinner/Spinner';
import dataPlaceholders from '../../../../constants/dataPlaceholders';
import useNotifications from '../../../../hooks/useNotifications';
import CompanyDetails from '../../../../models/Company/CompanyDetails';
import companyService from '../../../../services/companyService';
import AddEditCompany from '../../Companies/AddEditCompany/AddEditCompany';
import productsHelper from '../../../../helpers/productsHelper';
import companiesHelper from '../../../../helpers/companiesHelper';
import Alert from '../../../../components/Alert/Alert';
import Confirm from '../../../../components/Confirm/Confirm';
import AddEditUser from '../../Users/AddEditUser/AddEditUser';
import GenericFormattedDate from '../../../../components/GenericFormattedDate/GenericFormattedDate';

const CompanyDetailTab: React.FC = () => {
  const { id: companyId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['companytatus', companyId],
    () => companyService.getStatus(companyId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.unableLoadStatus' }),
        ),
    },
  );
  const { data: company, isLoading: isLoadingItem } = useQuery(
    ['company', companyId],
    () => companyService.get(companyId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companies.company.notification.title' }),
          intl.formatMessage({ id: 'admin.companies.company.notification.unableLoadItem' }),
        ),
    },
  );

  const getCityString = (companyData?: CompanyDetails) => {
    if (!companyData?.city && !companyData?.zipCode) return dataPlaceholders.missingData;
    if (!companyData.city) return companyData.zipCode;
    if (!companyData.zipCode) return companyData.city;

    return `${companyData.zipCode} ${companyData.city}`;
  };

  const getHasManufacturingFacilities = (value?: boolean) => {
    if (value === undefined) {
      return undefined;
    }

    return intl.formatMessage({
      id: `admin.companyOverview.tab.detail.card.hasManufacturingFacilities.value.${value ? 'yes' : 'no'}`,
    });
  };

  const [isAddUser, setIsAddUser] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  const { mutate: deleteCompany, isLoading: isDeleting } = useMutation(
    async (id: string) => {
      await companyService.delete(id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['companies']);
        queryClient.invalidateQueries(['companyStatus', companyId]);
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.unableDeleteItem' }),
        ),
    },
  );

  const onConfirmDelete = () => {
    deleteCompany(status!.id);
    navigate(companiesHelper.companiesBaseUrl);
  };

  return (
    <>
      <div className="row gx-4 py-2 mb-4">
        <div className="col size-label text-color-gray-l6">
          {status?.relatedDataUpdatedAt && (
            <>
              <FormattedMessage id="admin.companyOverview.tab.status.lastUpdate" />{' '}
              <GenericFormattedDate value={status?.relatedDataUpdatedAt} />
            </>
          )}
        </div>
        <div className="col-auto">
          <Link
            to={productsHelper.getProductAddUrl(companyId)}
            className="btn btn-link btn-link-underline btn-link-underline-create me-3"
          >
            <FormattedMessage id="admin.companyOverview.tab.status.action.addProduct" />
          </Link>
          <Button
            variant="link"
            className="btn btn-link btn-link-underline btn-link-underline-create me-3"
            onClick={() => setIsAddUser(true)}
          >
            <FormattedMessage id="admin.companyOverview.tab.status.action.addUser" />
          </Button>
          <Button variant="link" className="btn-link-underline me-3" onClick={() => setIsEdit(true)}>
            <FormattedMessage id="admin.companyOverview.tab.status.action.edit" />
          </Button>
          <Button variant="link" className="btn-link-underline" onClick={() => setIsDelete(true)}>
            <FormattedMessage id="admin.companyOverview.tab.status.action.delete" />
          </Button>
        </div>
      </div>
      <div className="card">
        <div className="row">
          <div className="card-divider d-flex p-3 align-items-center">
            <div className="font-bold text-uppercase">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.title" />
            </div>
          </div>
        </div>
        <div className="form-4-col px-3 py-4">
          <div className="mb-3">
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.name" />
            </div>
            <div className="size-label">{company?.name || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.contactPerson" />
            </div>
            <div className="size-label">{company?.contactPerson || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.email" />
            </div>
            <div className="size-label">{company?.email || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.phoneNumber" />
            </div>
            <div className="size-label">{company?.phoneNumber || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.street" />
            </div>
            <div className="size-label">{company?.street || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.zipAndCity" />
            </div>
            <div className="size-label">{getCityString(company)}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.country" />
            </div>
            <div className="size-label">{company?.country || dataPlaceholders.missingData}</div>
          </div>
          <div>
            <div className="size-caption text-color-gray-l6 mb-1">
              <FormattedMessage id="admin.companyOverview.tab.detail.card.hasManufacturingFacilities.title" />
            </div>
            <div className="size-label">
              {getHasManufacturingFacilities(company?.hasManufacturingFacilities) || dataPlaceholders.missingData}
            </div>
          </div>
        </div>
      </div>
      {isAddUser && <AddEditUser userId={null} defaultCompanyId={companyId} onClose={() => setIsAddUser(false)} />}
      {isEdit && <AddEditCompany companyId={companyId!} onClose={() => setIsEdit(false)} />}
      {isDelete && status?.totalUsers === 0 && status?.totalProducts === 0 && (
        <Confirm
          title={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.title' })}
          targetName={status.name}
          confirmTitle={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.confirmMessage' })}
          message={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.message' })}
          onClose={() => setIsDelete(false)}
          onConfirm={() => onConfirmDelete()}
        />
      )}
      {isDelete && (status?.totalUsers || status?.totalProducts) && (
        <Alert
          title={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.title' })}
          targetName={status.name}
          message={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.message' })}
          hint={intl.formatMessage({ id: 'admin.companyOverview.tab.status.delete.alertHint' })}
          onClose={() => setIsDelete(false)}
        />
      )}
      {(isLoadingItem || isDeleting || isLoadingStatus) && <Spinner />}
    </>
  );
};

export default CompanyDetailTab;
