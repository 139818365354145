import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import productsHelper from '../../helpers/productsHelper';
import useNotifications from '../../hooks/useNotifications';
import useQueryStringState from '../../hooks/useQueryStringState';
import BatchItem from '../../models/Batch/BatchItem';
import BatchListViewState from '../../models/Batch/BatchListViewState';
import SelectOption from '../../models/SelectOption';
import TimePeriod from '../../models/TimePeriod';
import batchService from '../../services/batchService';
import campaignService from '../../services/campaignService';
import synthesisStepService from '../../services/synthesisStepService';
import BatchSearch from '../../models/Batch/BatchSearch';

const pageSize = 20;

const getDateFromTimePeriod = (period?: TimePeriod): Date | undefined => {
  switch (period) {
    case TimePeriod.day:
      return dayjs().add(-1, 'day').toDate();
    case TimePeriod.week:
      return dayjs().add(-1, 'week').toDate();
    case TimePeriod.month:
      return dayjs().add(-1, 'month').toDate();
    default:
      return undefined;
  }
};

const useBatchList = (
  productId: string,
  companyId?: string,
  problemTrackers?: SelectOption[],
  hasAccessToProduct?: boolean,
) => {
  const [viewState, , setViewStateFunc] = useQueryStringState<BatchListViewState>();

  const queryClient = useQueryClient();
  const intl = useIntl();
  const { notify } = useNotifications();

  const {
    data: batchesData,
    isLoading: isLoadingBatches,
    isRefetching: isRefetchingBatches,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      'batches',
      companyId,
      productId,
      viewState.search.idsFilter,
      problemTrackers,
      viewState.search.synthesisStepId,
      viewState.search.campaignId,
      viewState.search.timePeriod,
    ],
    async ({ pageParam = 1 }) => {
      const data = await batchService.list({
        companyId,
        productId,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
        ids: viewState.search.idsFilter,
        problemTrackers: problemTrackers?.map((x) => x.value),
        synthesisStepId: viewState.search.synthesisStepId,
        campaignId: viewState.search.campaignId,
        fromDate: getDateFromTimePeriod(viewState.search.timePeriod),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.notification.title' }),
          intl.formatMessage({ id: 'batchList.notification.unableToLoadBatches' }),
        ),
    },
  );

  const searchBatches = (term: string) => {
    return queryClient.fetchQuery(['searchBatches', productId, term], () => {
      return batchService.search({ companyId, productId, nameTerm: term });
    });
  };

  const { data: steps, isLoading: isLoadingSteps } = useQuery(
    ['synthesisSteps', productId, companyId],
    () => synthesisStepService.list(productId, companyId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.notification.title' }),
          intl.formatMessage({ id: 'batchList.notification.unableLoadSteps' }),
        ),
    },
  );

  const { data: campaigns, isLoading: isLoadingCampaigns } = useQuery(
    ['campaigns', viewState.search.synthesisStepId],
    () => campaignService.list({ companyId, productId, synthesisStepId: viewState.search.synthesisStepId }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.notification.title' }),
          intl.formatMessage({ id: 'batchList.notification.unableToLoadCampaigns' }),
        ),
      enabled: !!viewState.search.synthesisStepId,
    },
  );

  const { data: selectedBatchesData } = useQuery(
    ['selectedBatches', companyId, productId, viewState.search.idsFilter],
    () =>
      batchService.list({
        companyId,
        productId,
        ids: viewState.search.idsFilter,
      }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.notification.title' }),
          intl.formatMessage({ id: 'batchList.notification.unableToLoadBatches' }),
        ),
      enabled: viewState.search.idsFilter && viewState.search.idsFilter.length > 0,
    },
  );

  const selectedBatches = useMemo(() => {
    if (viewState.search.idsFilter && viewState.search.idsFilter.length > 0) {
      return selectedBatchesData?.batches.map((x) => ({
        id: x.id,
        name: x.name,
        campaignName: x.campaignName,
        synthesisStepName: x.synthesisStepName,
      }));
    }
    return undefined;
  }, [viewState.search.idsFilter, selectedBatchesData]);

  const groupedBatches: {
    campaign: { id: string; name: string; synthesisStepName: string; stepLink: string; campaignLink: string };
    batches: BatchItem[];
  }[] = useMemo(() => {
    return [
      ...(batchesData?.pages
        .flatMap((x) => x.batches)
        .reduce((r, o) => {
          const key = o.campaignId;
          const item = r.get(key) || {
            campaign: {
              id: o.campaignId,
              name: o.campaignName,
              synthesisStepName: o.synthesisStepName,
              stepLink: hasAccessToProduct
                ? productsHelper.getSynthesisStepUrl(o.productId, o.synthesisStepId)
                : productsHelper.getSynthesisStepOverviewUrl(o.productId, o.synthesisStepId),
              campaignLink: productsHelper.getCampaignsUrl(
                {
                  productId: o.productId,
                  stepId: o.synthesisStepId,
                  campaignId: o.campaignId,
                },
                hasAccessToProduct
                  ? undefined
                  : productsHelper.getSynthesisStepOverviewCampaingsUrl(o.productId, o.synthesisStepId),
              ),
            },
            batches: [],
          };
          item.batches = [...item.batches, o];
          return r.set(key, item);
        }, new Map())
        .values() || []),
    ];
  }, [batchesData]);

  const stepOptions = useMemo(
    () => [
      { value: '', label: intl.formatMessage({ id: 'batchList.filter.synthesisStep.placeholder' }) },
      ...(steps?.steps.map((x) => ({ value: x.id, label: x.name })) || []),
    ],
    [steps],
  );
  const campaignOptions = useMemo(
    () => [
      { value: '', label: intl.formatMessage({ id: 'batchList.filter.campaign.placeholder' }) },
      ...(campaigns?.campaigns.map((x) => ({ value: x.id, label: x.name })) || []),
    ],
    [campaigns],
  );

  useEffect(() => {
    if (
      viewState.search.batchId === undefined &&
      groupedBatches.length === 1 &&
      groupedBatches[0].batches.length === 1
    ) {
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, batchId: groupedBatches[0].batches[0].id },
      }));
    }
  }, [groupedBatches]);

  useEffect(() => {
    if (
      stepOptions &&
      stepOptions.length > 0 &&
      !isLoadingCampaigns &&
      !campaignOptions?.find((x) => x.value === viewState.search.campaignId)
    ) {
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, campaignId: undefined, campaignName: undefined },
      }));
    }
  }, [campaignOptions]);

  return {
    hash: viewState.hash,
    searchBatches,
    selectBatches: (batches?: BatchSearch[]) =>
      setViewStateFunc((state) => ({ ...state, search: { ...state.search, idsFilter: batches?.map((x) => x.id) } })),
    selectedBatches,
    isLoadingBatches: (isLoadingBatches || isRefetchingBatches) && !isFetchingNextPage,
    groupedBatches,
    totalPages: batchesData?.pages.length || 0,
    totalBatches: batchesData?.pages?.[0]?.total || 0,
    totalCampaings: batchesData?.pages?.[0]?.totalCampaigns || 0,
    totalSynthesisSteps: batchesData?.pages?.[0]?.totalSynthesisSteps || 0,
    fetchNextPage,
    hasNextPage: hasNextPage || false,
    batchId: viewState.search.batchId,
    handleBatchToggle: (batchId?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, batchId: state.search.batchId === batchId ? undefined : batchId },
      })),
    oorBatchId: viewState.search.oorBatchId,
    handleShowOorDetails: (batchId?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          oorBatchId: batchId || undefined,
        },
      })),
    oosBatchId: viewState.search.oosBatchId,
    handleShowOosDetails: (batchId?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          oosBatchId: batchId || undefined,
        },
      })),
    handleSelectPart: (batchId?: string, partId?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          oorBatchId: batchId || undefined,
          parts: partId ? { selectedId: partId } : undefined,
        },
      })),
    steps: stepOptions || [],
    isLoadingSteps,
    selectedSynthesisStep: viewState.search.synthesisStepId,
    handleSelectSynthesisStep: (synthesisStepId?: string, synthesisStepName?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          synthesisStepId: synthesisStepId !== '' ? synthesisStepId : undefined,
          synthesisStepName: synthesisStepId !== '' ? synthesisStepName : undefined,
        },
      })),
    campaings: campaignOptions || [],
    isLoadingCampaigns,
    selectedCampaign: viewState.search.campaignId,
    handleSelectCampaign: (campaignId?: string, campaignName?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          campaignId: campaignId !== '' ? campaignId : undefined,
          campaignName: campaignId !== '' ? campaignName : undefined,
        },
      })),
    timePeriod: viewState.search.timePeriod || TimePeriod.all,
    setTimePeriod: (timePeriod: TimePeriod) =>
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, timePeriod: timePeriod === TimePeriod.all ? undefined : timePeriod },
      })),
  };
};

export default useBatchList;
