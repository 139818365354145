import React from 'react';

import de from 'date-fns/locale/de';
import enGB from 'date-fns/locale/en-GB';
import { registerLocale } from 'react-datepicker';
import { useAppContext } from './context/useAppContext';
import NotificationItem from './components/NotificationItem/NotificationItem';
import config from './appConfig';
import AppRoutes from './AppRoutes';

const AppTemplate = () => {
  registerLocale('de', de);
  registerLocale('en-GB', enGB);

  const {
    state: { notification },
    dispatch,
  } = useAppContext();

  const onHideNotificationHandler = () => {
    dispatch({ type: 'CLEAR_NOTIFICATION' });
    if (notification?.callback) {
      notification.callback();
    }
  };

  return (
    <main className="d-flex">
      {config.environmentMarker && (
        <div className="position-absolute text-color-warn size-caption text-uppercase">{config.environmentMarker}</div>
      )}
      <AppRoutes />
      {notification && (
        <NotificationItem
          title={notification.title}
          exclamation={notification.exclamation}
          success={notification.type === 'success'}
          message={notification.message}
          hint={notification.hint}
          callback={onHideNotificationHandler}
        />
      )}
    </main>
  );
};

export default AppTemplate;
