import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import TabsContextProvider from './TabsContextProvider';

interface Props {
  defaultTab: string;
  className?: string;
}

const TabsContainer: FC<PropsWithChildren<Props>> = ({ children, defaultTab, className }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab]);

  return (
    <TabsContextProvider activeTab={activeTab} setActiveTab={setActiveTab}>
      <div className={className}>{children}</div>
    </TabsContextProvider>
  );
};

export default TabsContainer;
