const companiesBaseUrl = '/companies';
const getCompanyUrl = (companyId: string) => `${companiesBaseUrl}/${companyId}`;
const getDashboardUrl = (companyId: string) => `${getCompanyUrl(companyId)}/dashboard`;
const getUsersUrl = (companyId: string) => `${getCompanyUrl(companyId)}/users`;
const getProductsUrl = (companyId: string) => `${getCompanyUrl(companyId)}/products`;
const getAlertsUrl = (companyId: string) => `${getCompanyUrl(companyId)}/alerts`;
const getUpdatesUrl = (companyId: string) => `${getCompanyUrl(companyId)}/updates`;

export default {
  companiesBaseUrl,
  getDashboardUrl,
  getCompanyUrl,
  getUsersUrl,
  getProductsUrl,
  getAlertsUrl,
  getUpdatesUrl,
};
