import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import './OutOfRange.scss';

import numberHelper from '../../../helpers/numberHelper';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import BatchOutOfRangeItem from '../../../models/Batch/BatchOutOfRangeItem';

const numberOfColumns = 10;

const OutOfRange: FC<Props> = ({ value, columns, tooltip, getBatchUrl, link }) => {
  const intl = useIntl();
  const { hasData, delta, chartData, trackerState, scaleStep, minColumnValue } = useMemo(() => {
    const hasOOR = columns && columns.length > 0;
    const columnsWithData = columns?.filter((x) => x.value !== null).map((x) => x.value as number);
    const lastColumnDelta =
      columnsWithData && columnsWithData.length > 1
        ? columnsWithData[columnsWithData.length - 1] - columnsWithData[columnsWithData.length - 2] || 0
        : 0;

    const columnsToPrepend: (BatchOutOfRangeItem | null)[] =
      columns && columns.length > 0
        ? Array(numberOfColumns - columns.length < 0 ? 0 : numberOfColumns - columns.length).fill(null)
        : Array(numberOfColumns).fill(null);

    const columnsData =
      columns && columns.length > 0 ? [...columnsToPrepend, ...columns.slice(-numberOfColumns)] : columnsToPrepend;

    let classByData = 'no-range size-caption';
    if (hasOOR) {
      classByData = lastColumnDelta > 0 ? 'out-off-range' : 'in-range';
    }

    let min = columnsWithData && columnsWithData.length > 0 ? Math.min(...columnsWithData) : 0;
    const max = columnsWithData && columnsWithData.length > 0 ? Math.max(...columnsWithData) : 0;
    if (max === min) {
      min = 0;
    }

    return {
      hasData: columns && columns.length > 0,
      delta: lastColumnDelta,
      chartData: columnsData,
      trackerState: classByData,
      scaleStep: 90 / (max - min),
      minColumnValue: min,
    };
  }, [columns]);

  const formatTooltip = (item: BatchOutOfRangeItem) => {
    const valueString = `${numberHelper.formatNumber(item.value)}%`;
    if (item.value !== null) {
      return (
        <>
          {item.batchName}
          <br />
          {valueString}
        </>
      );
    }

    return valueString;
  };

  return (
    <div className="out-of-range-component bg-color-gray-l1 p-2" data-test-id="outOfRange-component">
      <div className="size-label d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <TitleTooltip
            title={intl.formatMessage({ id: 'tracker.outOfRange.shortTitle' })}
            titleTooltip={intl.formatMessage({ id: 'tracker.outOfRange.title' })}
            titleClassName="size-label"
          />
          {tooltip && (
            <div className="ms-1 d-flex">
              <IconTooltip tooltip={tooltip} className="d-inline-block" />
            </div>
          )}
          {value && value > 0 ? (
            <Link to={link} className="ms-3 link link--secondary text-without-transformation size-caption">
              <FormattedMessage id="tracker.showDetails" />
            </Link>
          ) : (
            <span className="ms-3 size-caption text-color-gray-l5">
              <FormattedMessage id="tracker.showDetails" />
            </span>
          )}
        </div>
        <div className={trackerState} data-test-id="outOfRange-delta">
          {hasData ? (
            <>
              {delta > 0 ? '+' : ''}
              {numberHelper.formatNumber(delta)}%
            </>
          ) : (
            <FormattedMessage id="tracker.noData" />
          )}
        </div>
      </div>
      <h3 className="font-bold" data-test-id="outOfRange-value">
        {value && value > 0 ? (
          <Link to={link} className="base-link">
            {numberHelper.formatNumber(value)}%
          </Link>
        ) : (
          <span>{numberHelper.formatNumber(value)}%</span>
        )}
      </h3>
      <div className="columns-box mt-auto position-relative">
        {chartData.map((column, index) => {
          const columnHeight =
            column && column.value && column.value > 0 ? scaleStep * (column.value - minColumnValue) + 10 : 10;
          const key = `${index}-${column?.batchName}`;

          return (
            <OverlayTrigger
              key={key}
              placement="top"
              delay={{ show: 250, hide: 250 }}
              overlay={
                <Tooltip className="tooltip-title">
                  {column !== null ? formatTooltip(column) : intl.formatMessage({ id: 'tracker.noData' })}
                </Tooltip>
              }
            >
              {column ? (
                <Link
                  className={`column rounded-top ${trackerState}`}
                  style={{ height: `${columnHeight}%` }}
                  data-test-id={`outOfRange-column-${index}`}
                  to={getBatchUrl(column.batchId, column.batchName)}
                />
              ) : (
                <div
                  className="column rounded-top no-data"
                  style={{ height: `${columnHeight}%` }}
                  data-test-id={`outOfRange-column-${index}`}
                />
              )}
            </OverlayTrigger>
          );
        })}
      </div>
    </div>
  );
};

type Props = {
  value?: number | null;
  columns?: BatchOutOfRangeItem[];
  tooltip?: string;
  getBatchUrl: (batchId: string, batchName: string) => string;
  link: string;
};

export default OutOfRange;
