import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import ProblemTrackerFilter from '../models/ProblemTrackerFilter';
import ProblemTrackerViewState from '../models/ProblemTrackerViewState';
import SelectOption from '../models/SelectOption';
import useQueryStringState from './useQueryStringState';

const useProblemTrackerFilter = () => {
  const [viewState, setViewState] = useQueryStringState<ProblemTrackerViewState>();
  const { formatMessage: fm } = useIntl();
  const options = useMemo<SelectOption[]>(
    () => [
      { label: fm({ id: 'hooks.problemTracker.filter.OutOfRange' }), value: ProblemTrackerFilter.OutOfRange },
      {
        label: fm({ id: 'hooks.problemTracker.filter.OutOfSpecification' }),
        value: ProblemTrackerFilter.OutOfSpecification,
      },
      { label: fm({ id: 'hooks.problemTracker.filter.OutOfTime' }), value: ProblemTrackerFilter.OutOfTime },
    ],
    [],
  );

  const selectTrackerOption = (value: SelectOption[]) => {
    setViewState({
      ...viewState,
      search: { ...viewState.search, problemTrackers: value?.map((x) => x.value) },
      hash: '',
    });
  };

  const selectedTrackerOptions = useMemo(() => {
    return viewState.search?.problemTrackers?.map((x) => options.find((o) => o.value === x)!) || [];
  }, [viewState.search?.problemTrackers, options]);

  return {
    selectedTrackers: selectedTrackerOptions,
    selectTrackers: selectTrackerOption,
    options,
  };
};

export default useProblemTrackerFilter;
