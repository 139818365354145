import React, { FC, useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../../components/Header/Header';
import Select from '../../../components/Select/Select';
import UsersTable from '../../../components/UsersTable/UsersTable';
import UsersTableSkeleton from '../../../components/UsersTable/UsersTableSkeleton';
import useCompanies from '../../../hooks/admin/useCompanies';
import usePaginatedUsers from '../../../hooks/admin/users/usePaginatedUsers';
import useNotifications from '../../../hooks/useNotifications';
import ListUsersSortColumn from '../../../models/User/ListUsersSortColumn';
import AddEditUser from './AddEditUser/AddEditUser';
import useUsersFiltersViewState from '../../../hooks/admin/users/useUsersFiltersViewState';
import useSortingViewState from '../../../hooks/useSortingViewState';
import useUserRoleOptions from '../../../hooks/useUserRoleOptions';
import NoData from '../../../components/NoData/NoData';

const pageSize = 20;

const Users: FC = () => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const [isAdd, setIsAdd] = useState(false);
  const { handleFilterChange, viewState } = useUsersFiltersViewState();

  const {
    data: usersData,
    isLoading: isUsersLoading,
    fetchNextPage,
    hasNextPage,
  } = usePaginatedUsers(
    pageSize,
    viewState.search.companyId,
    getSortOrder<ListUsersSortColumn>('fullName', 'Users'),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'admin.users.notification.title' }),
          fm({ id: 'admin.users.notification.unableToLoadUsers' }),
        ),
    },
    viewState.search.roleId,
  );

  const { isLoading: isCompaniesLoading, options: companyOptions } = useCompanies(
    () =>
      notify(
        'error',
        fm({ id: 'admin.users.notification.title' }),
        fm({ id: 'admin.users.notification.unableToLoadCompanies' }),
      ),
    fm({ id: 'admin.users.filter.company.empty' }),
  );

  const { roleOptions } = useUserRoleOptions();

  const handleCompanyFilterChange = (companyIdValue: string) =>
    handleFilterChange({ companyId: companyIdValue || undefined, roleId: viewState.search.roleId });
  const handleRoleFilterChange = (roleValue: string) =>
    handleFilterChange({ companyId: viewState.search.companyId, roleId: roleValue || undefined });

  return (
    <>
      <Header title={fm({ id: 'header.admin.usersTitle' })} showSearch />
      <div className="page-content px-5">
        <div className="filters-container pb-4">
          <Form.Group className="d-flex justify-content-between">
            <span className="me-3">
              <Select
                value={viewState.search.companyId || ''}
                onChange={handleCompanyFilterChange}
                options={companyOptions}
                size="lg"
                dataTestId="users-companyFilter"
              />
            </span>
            <span>
              <Select
                value={viewState.search.roleId || ''}
                onChange={handleRoleFilterChange}
                options={roleOptions}
                size="lg"
                dataTestId="users-rolesFilter"
              />
            </span>
          </Form.Group>

          <Button
            className="btn-link-underline btn-link-underline-create"
            variant="link"
            onClick={() => setIsAdd(true)}
            data-test-id="users-add"
          >
            <FormattedMessage id="admin.users.btn.add.label" />
          </Button>
        </div>
        {usersData?.pages?.[0]?.users?.length === 0 && <NoData />}

        <div className="mb-4 shadow-2">
          {(isUsersLoading || isCompaniesLoading) && <UsersTableSkeleton cards={9} showPhoneNumber={false} />}
          {!!usersData?.pages?.[0]?.users?.length && (
            <InfiniteScroll
              className="rounded-bottom"
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={null}
              dataLength={usersData.pages.length}
              scrollableTarget="scrollContainer"
            >
              <UsersTable users={usersData.pages.flatMap((x) => x.users)} totalUsers={usersData.pages[0].total} />
            </InfiniteScroll>
          )}
        </div>

        {isAdd && <AddEditUser userId={null} onClose={() => setIsAdd(false)} />}
      </div>
    </>
  );
};

export default Users;
