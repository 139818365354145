import { faSort, faSortAsc, faSortDesc } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import useSortingViewState from '../../../hooks/useSortingViewState';
import SortOrder from '../../../models/SortOrder';

const TableHeader: <TColumn extends string>(props: Props<TColumn>) => ReactElement = <TColumn extends string>({
  columns,
  tableName,
}: Props<TColumn>) => {
  const { viewSortingState, isDescending, handleSortingChange } = useSortingViewState();
  const getIcon = (column: TColumn) => {
    if (viewSortingState.search[tableName]?.sortingBy?.includes(column)) {
      return isDescending(tableName) ? faSortDesc : faSortAsc;
    }
    return faSort;
  };

  const onViewSortingStateChange = handleSortingChange(tableName);

  const onSort = (column: TColumn) => {
    if (!column) return;
    onViewSortingStateChange({
      sortingBy:
        new SortOrder<TColumn>(
          column,
          viewSortingState.search[tableName]?.sortingBy !== column || isDescending(tableName),
        ).toString() || undefined,
    });
  };

  return (
    <thead className="text-color-gray-l6" data-test-id="tableHeader-container">
      <tr className="size-caption">
        {columns.map((x) => (
          <th key={x.key} data-test-id={`tableHeader-item-${x.name}`} className="font-medium">
            <Button onClick={() => onSort(x.name as TColumn)} variant="link" className="text-color-gray-l6 btn-sort">
              {x.key && <FormattedMessage id={x.key} />}{' '}
              {x.name && <FontAwesomeIcon icon={getIcon(x.name)} size="xs" className="ps-2" />}
            </Button>
          </th>
        ))}
      </tr>
    </thead>
  );
};

interface Props<TColumn extends string> {
  columns: {
    name: TColumn | '';
    key: string;
  }[];
  tableName: string;
}

export default TableHeader;
