import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import NoData from '../../../../components/NoData/NoData';
import MultiSelect from '../../../../components/Select/MultiSelect/MultiSelect';
import Select from '../../../../components/Select/Select';
import ProductRowSkeleton from '../../../../components/Skeletons/ProductOverview/ProductRowSkeleton';
import StatusSelect from '../../../../components/StatusSelect/StatusSelect';
import productsHelper from '../../../../helpers/productsHelper';
import useNotifications from '../../../../hooks/useNotifications';
import useProblemTrackerFilter from '../../../../hooks/useProblemTrackerFilter';
import useProductsViewState from '../../../../hooks/useProductsViewState';
import productService from '../../../../services/productService';
import ProductRow from '../../../../components/ProductRow/ProductRow';

const pageSize = 20;

const CompanyProductsTab: React.FC = () => {
  const { id: companyId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { phaseId, isActive, setPhaseId, setIsActive } = useProductsViewState();
  const { selectedTrackers, selectTrackers, options: problemTrackerOptions } = useProblemTrackerFilter();

  const {
    data: productsModel,
    isLoading: isProductsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['products', companyId, isActive, phaseId, selectedTrackers],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        isActive,
        phaseId,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
        problemTrackers: selectedTrackers?.map((x) => x.value),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.products.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.products.notification.unableLoadProducts' }),
        ),
    },
  );

  const { isLoading: isPhasesLoading, data: phasesData } = useQuery(
    ['productPhases'],
    () => productService.productPhases(),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.products.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.products.notification.unableLoadPhases' }),
        ),
    },
  );

  const phaseOptions = useMemo(() => {
    const phases = !phasesData
      ? []
      : phasesData.phases.map((phase) => ({ label: phase.name, value: phase.id.toString() }));
    return [
      { label: intl.formatMessage({ id: 'admin.companyOverview.tab.products.filter.phase.all' }), value: '' },
      ...phases,
    ];
  }, [phasesData]);

  return (
    <>
      <div className="filters-container pb-4">
        <Form.Group className="d-flex gap-3">
          <StatusSelect
            onChange={(value) => setIsActive(value)}
            value={isActive}
            labels={{
              empty: intl.formatMessage({ id: 'admin.companyOverview.tab.products.filter.status.empty' }),
              active: intl.formatMessage({ id: 'admin.companyOverview.tab.products.filter.status.onlyActive' }),
              inactive: intl.formatMessage({
                id: 'admin.companyOverview.tab.products.filter.status.onlyInactive',
              }),
            }}
            dataTestId="products-statusFilter"
          />
          <Select
            value={phaseId?.toString() || ''}
            onChange={(value) => setPhaseId(value !== '' ? (value as unknown as number) : undefined)}
            options={phaseOptions}
            size="lg"
            dataTestId="products-phaseFilter"
          />
          <MultiSelect
            value={selectedTrackers}
            options={problemTrackerOptions}
            onChange={selectTrackers}
            size="lg"
            placeholder={intl.formatMessage({ id: 'admin.companyOverview.tab.products.filter.problemTrackers.empty' })}
          />
          <div className="col d-flex align-items-center">
            {isActive !== undefined || phaseId !== undefined || (selectedTrackers && selectedTrackers.length > 0)
              ? intl.formatMessage(
                  { id: 'admin.companyOverview.tab.products.filter.total.label' },
                  { value: productsModel?.pages[0].total || 0 },
                )
              : null}
          </div>
        </Form.Group>
        <Link
          className="btn-link-underline btn-link btn-link-underline-create"
          to={productsHelper.getProductAddUrl(companyId)}
          data-test-id="products-add"
        >
          <FormattedMessage id="admin.companyOverview.tab.products.action.add" />
        </Link>
      </div>
      {productsModel?.pages[0].products.length === 0 && <NoData />}
      {!!productsModel?.pages?.[0]?.products?.length && (
        <InfiniteScroll
          className="overflow-visible"
          next={fetchNextPage}
          hasMore={hasNextPage || false}
          loader={null}
          dataLength={productsModel?.pages.length || 0}
          scrollableTarget="scrollContainer"
        >
          <div>
            {productsModel?.pages
              .flatMap((x) => x.products)
              .map((product) => (
                <ProductRow key={product.id} product={product} showActions />
              ))}
          </div>
        </InfiniteScroll>
      )}
      {(isProductsLoading || isPhasesLoading) && <ProductRowSkeleton cards={2} />}
    </>
  );
};

export default CompanyProductsTab;
