import React, { FC, useMemo, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import useUserType from '../../hooks/useUserType';
import UserType from '../../models/Account/UserType';
import ReportData from '../../models/Report/ReportData';
import ReportModal from './ReportModal';

const ReportButton: FC<Props> = ({ reportData, className, fullSize = false, disabled }) => {
  const [isGetReport, setGetReport] = useState<boolean>(false);
  const userType = useUserType();
  const intl = useIntl();

  const onOpenReportModalHandler = () => {
    setGetReport(true);
  };

  const button = useMemo(
    () => (
      <Button
        className={className}
        size={fullSize ? 'lg' : 'sm'}
        variant="outline"
        onClick={onOpenReportModalHandler}
        data-test-id="getReport-btn"
        disabled={disabled || userType === UserType.Admin}
      >
        <FormattedMessage id="report.action.getReport" />
      </Button>
    ),
    [className, fullSize, onOpenReportModalHandler, disabled, userType, intl.locale],
  );

  return (
    <>
      {disabled && userType !== UserType.Admin ? (
        <OverlayTrigger
          overlay={
            <Tooltip className="size-caption">
              <FormattedMessage id="report.getFullAccessHint" />
            </Tooltip>
          }
        >
          <div className="d-inline-block">{button}</div>
        </OverlayTrigger>
      ) : (
        button
      )}
      {isGetReport && <ReportModal reportData={reportData} onCancel={() => setGetReport(false)} />}
    </>
  );
};

interface Props {
  reportData: ReportData;
  className?: string;
  fullSize?: boolean;
  disabled?: boolean;
}

export default ReportButton;
