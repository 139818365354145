import React, { useContext } from 'react';

import { AccordionContext, Button, useAccordionButton } from 'react-bootstrap';

const BatchToggleButton: React.FC<Props> = ({ eventKey, callback, dataTestId, className }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <Button data-test-id={dataTestId} onClick={decoratedOnClick} className={className}>
      {isCurrentEventKey ? 'Hide KPI' : 'Show KPI '}
    </Button>
  );
};

interface Props {
  eventKey: string;
  dataTestId?: string;
  className?: string;
  callback?(eventKey: string): void;
}

export default BatchToggleButton;
