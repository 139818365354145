import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import useCategoryList from '../../../hooks/appGallery/useCategoryList';
import useProcessList from '../../../hooks/appGallery/useProcessList';
import AppDetails from '../../../models/AppGallery/Application/AppDetails';
import Spinner from '../../../components/Spinner/Spinner';
import useNotifications from '../../../hooks/useNotifications';
import ApplicationStatus from '../../../models/AppGallery/Application/ApplicationStatus';
import applicationRequestService from '../../../services/AppGallery/applicationRequestService';
import applicationService from '../../../services/AppGallery/applicationService';
import imageFileService from '../../../services/AppGallery/imageFileService';
import './AppDetailsModal.scss';

const AppDetailsModal: FC<Props> = ({ appId, onClose }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();

  const onLoadingError = () => {
    notify(
      'error',
      fm({ id: 'appGallery.appDetailsModal.notification.title' }),
      fm({ id: 'appGallery.appDetailsModal.notification.unableLoad' }),
    );
    onClose();
  };

  const { data: processList, isLoading: isLoadingProcesses } = useProcessList({
    onError: onLoadingError,
  });

  const { data: categoryList, isLoading: isLoadingCategories } = useCategoryList({
    onError: onLoadingError,
  });

  const { data: appDetails, isLoading: isLoadingItem } = useQuery<AppDetails>(
    ['applicationDetails', appId],
    () => applicationService.details(appId),
    {
      onError: onLoadingError,
    },
  );

  const queryClient = useQueryClient();
  const { mutate: request, isLoading: isRequesting } = useMutation(
    () => applicationRequestService.requestAccess(appId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['applicationDetails', appId]);
        queryClient.invalidateQueries(['applicationList']);
        notify(
          'success',
          fm({ id: 'appGallery.appDetailsModal.notification.title' }),
          fm({ id: 'appGallery.appDetailsModal.notification.applicationRequested' }),
        );
        onClose();
      },
      onError: () => {
        notify(
          'error',
          fm({ id: 'appGallery.appDetailsModal.notification.title' }),
          fm({ id: 'appGallery.appDetailsModal.notification.unableRequestApplication' }),
        );
      },
    },
  );
  const { mutate: remove, isLoading: isRemoving } = useMutation(() => applicationService.removeApplication(appId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['applicationDetails', appId]);
      queryClient.invalidateQueries(['applicationList']);
      notify(
        'success',
        fm({ id: 'appGallery.appDetailsModal.notification.title' }),
        fm({ id: 'appGallery.appDetailsModal.notification.applicationRemoved' }),
      );
      onClose();
    },
    onError: () => {
      notify(
        'error',
        fm({ id: 'appGallery.appDetailsModal.notification.title' }),
        fm({ id: 'appGallery.appDetailsModal.notification.unableRemoveApplication' }),
      );
    },
  });
  const getProcess = (processId: string) => processList?.find((x) => x.id === processId)?.name;

  const isLoading = isLoadingProcesses || isLoadingCategories || isLoadingItem || isRequesting || isRemoving;

  return (
    <>
      {processList && categoryList && appDetails && (
        <Modal show centered className="app-details-modal-component modal-2-col modal-size-medium">
          <div className="d-flex justify-content-between align-items-center w-100 px-4 pt-4">
            <div className="text-uppercase text-color-primary-green size-label font-normal">
              {fm({ id: 'appGallery.appDetailsModal.title' })}
            </div>
            <div>
              <Button className="btn-toggle" size="sm" variant="ghost" onClick={onClose}>
                <FontAwesomeIcon icon={faXmark} size="lg" />
              </Button>
            </div>
          </div>
          <Modal.Header className="px-4 py-0 text-center">
            <div>
              <img
                src={imageFileService.getImageUrl(appDetails.iconImageId)}
                alt={`${appDetails.name} icon`}
                className="app-details-modal-component-img mb-2"
              />
              <h3 className="app-details-modal-component-title font-bold text-uppercase w-100">{appDetails.name}</h3>
            </div>
          </Modal.Header>
          <Modal.Body className="px-4 pb-4">
            <div className="d-flex justify-content-center w-100 p-2">
              <div className="me-3">{fm({ id: 'appGallery.appDetailsModal.process' })}:</div>
              <div className="d-flex justify-content-between">
                {appDetails.processIds.map((processId) => (
                  <div
                    key={processId}
                    className={`me-2 app-details-modal-component-process app-details-modal-component-process-${getProcess(
                      processId,
                    )} size-caption`}
                  >
                    <FormattedMessage id={`appGallery.processTab.process.${getProcess(processId)}`} />
                  </div>
                ))}
              </div>
            </div>
            {appDetails.categoryIds.length > 0 && (
              <div className="d-flex justify-content-center">
                <div className="me-3 p-2">{fm({ id: 'appGallery.appDetailsModal.category' })}: </div>
                {appDetails.categoryIds.map((categoryId) => (
                  <div key={categoryId} className="text-color-gray-light1 size-body me-3 p-2">
                    {categoryList.find((x) => x.id === categoryId)!.name}
                  </div>
                ))}
              </div>
            )}
            <div className="app-details-modal-component-description text-center text-color-gray-light1 bg-color-gray-l2 p-3 my-2 mb-2">
              {appDetails.description}
            </div>
            <div className="d-flex justify-content-center">
              {appDetails.status === ApplicationStatus.Available && (
                <Button variant="primary" className="mt-4" disabled={isLoading} onClick={() => request()}>
                  {fm({ id: 'appGallery.appDetailsModal.action.request' })}
                </Button>
              )}
              {appDetails.status === ApplicationStatus.Installed && (
                <div className="installed size-body mt-4 me-4 font-bold">
                  <FormattedMessage id="appGallery.appDetailsModal.status.installed" />
                </div>
              )}
              {appDetails.status === ApplicationStatus.Installed && (
                <Button
                  variant="secondary"
                  type="button"
                  className="mt-4"
                  onClick={() => remove()}
                  disabled={isLoading}
                  data-test-id="addEditClient-uninstall"
                >
                  {fm({ id: 'appGallery.appDetailsModal.action.uninstall' })}
                </Button>
              )}
            </div>
          </Modal.Body>
        </Modal>
      )}
      {isLoading && <Spinner />}
    </>
  );
};

interface Props {
  appId: string;
  onClose: () => void;
}

export default AppDetailsModal;
