import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import Spinner from '../../components/Spinner/Spinner';
import ProductRow from '../../components/ProductRow/ProductRow';
import Header from '../../components/Header/Header';
import BackButton from '../../components/BackButton/BackButton';
import TabsContainer from '../../components/TabbedComponent/TabsContainer';
import Tabs from '../../components/TabbedComponent/Tabs';
import TabItem from '../../components/TabbedComponent/TabItem';
import TabsContent from '../../components/TabbedComponent/TabsContent';
import TabsContentItem from '../../components/TabbedComponent/TabsContentItem';
import UsersTable from '../../components/UsersTable/UsersTable';
import ManufacturerStepTable from '../../components/ManufacturerStepTable/ManufacturerStepTable';
import useSearch, { tabs } from '../../hooks/userSearch';
import NoData from '../../components/NoData/NoData';

interface Props {
  companyId: string;
  isUsersAvailable?: boolean;
  isManufacturerFacilitiesAvailable?: boolean;
}

const Search: React.FC<Props> = ({ companyId, isUsersAvailable, isManufacturerFacilitiesAvailable }) => {
  const intl = useIntl();
  const { searchTerm } = useParams<'searchTerm'>();

  const {
    usersResult,
    isLoadingUsers,
    stepsResult,
    isLoadingSteps,
    productsResult,
    isLoadingProducts,
    selectedTab,
    fetchProductsNextPage,
    hasProductsNextPage,
    fetchStepsNextPage,
    hasStepsNextPage,
    fetchUsersNextPage,
    hasUsersNextPage,
  } = useSearch(searchTerm!, companyId, { steps: isManufacturerFacilitiesAvailable, users: isUsersAvailable });

  const noResultBlock = useMemo(
    () => <NoData message={intl.formatMessage({ id: 'search.noResults' })} />,
    [intl.locale],
  );

  return (
    <>
      <Header
        title={intl.formatMessage({ id: 'search.title' })}
        subtitle={
          <span className="d-inline-block text-color-gray-l6 size-body mt-2">
            {intl.formatMessage({ id: 'search.term' })} <span className="text-color-off-white">{searchTerm}</span>
          </span>
        }
        showSearch
      >
        <div className="col-auto">
          <BackButton />
        </div>
      </Header>
      <div className="page-content px-5">
        <TabsContainer defaultTab={selectedTab}>
          <Tabs className="mb-4">
            {productsResult?.pages[0]?.products && (
              <TabItem id={tabs.products}>
                <FormattedMessage id="search.tab.product" values={{ value: productsResult?.pages[0]?.total ?? 0 }} />
              </TabItem>
            )}
            {usersResult?.pages[0]?.users && (
              <TabItem id={tabs.users}>
                <FormattedMessage id="search.tab.user" values={{ value: usersResult?.pages[0]?.total ?? 0 }} />
              </TabItem>
            )}
            {stepsResult?.pages[0]?.steps && (
              <TabItem id={tabs.steps}>
                <FormattedMessage id="search.tab.step" values={{ value: stepsResult?.pages[0]?.total ?? 0 }} />
              </TabItem>
            )}
          </Tabs>
          <TabsContent>
            <TabsContentItem id={tabs.products}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchProductsNextPage}
                hasMore={hasProductsNextPage || false}
                loader={null}
                dataLength={productsResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {productsResult?.pages
                  .flatMap((x) => x.products)
                  .map((product) => (
                    <ProductRow key={product.id} product={product} />
                  ))}
                {(!productsResult?.pages[0]?.products || productsResult.pages[0].products.length === 0) &&
                  !isLoadingProducts &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
            <TabsContentItem id={tabs.users}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchUsersNextPage}
                hasMore={hasUsersNextPage || false}
                loader={null}
                dataLength={usersResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {usersResult?.pages[0]?.users && usersResult.pages[0].users.length > 0 && (
                  <div className="mb-4 shadow-2">
                    <div className="rounded-bottom overflow-auto">
                      <UsersTable
                        users={usersResult.pages.flatMap((x) => x.users)}
                        totalUsers={usersResult.pages[0].total}
                        companyId={companyId}
                      />
                    </div>
                  </div>
                )}
                {(!usersResult?.pages[0]?.users || usersResult.pages[0].users.length === 0) &&
                  !isLoadingUsers &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
            <TabsContentItem id={tabs.steps}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchStepsNextPage}
                hasMore={hasStepsNextPage || false}
                loader={null}
                dataLength={stepsResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {stepsResult?.pages[0]?.steps && stepsResult.pages[0].steps.length > 0 && (
                  <ManufacturerStepTable steps={stepsResult.pages.flatMap((x) => x.steps)} />
                )}
                {(!stepsResult?.pages[0]?.steps || stepsResult.pages[0].steps.length === 0) &&
                  !isLoadingSteps &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
          </TabsContent>
        </TabsContainer>
        {(isLoadingProducts || isLoadingSteps || isLoadingUsers) && <Spinner />}
      </div>
    </>
  );
};

export default Search;
