import axios, { AxiosError } from 'axios';
import appConfig from '../appConfig';
import events from '../constants/events';
import AuthToken from '../models/AuthToken';
import authStorageService from './authStorageService';

const request = axios.create({
  baseURL: appConfig.backendUrl,
});

const internalRequest = axios.create({
  baseURL: appConfig.backendUrl,
});

const refreshAccessToken = async (): Promise<boolean> => {
  const refreshToken = authStorageService.getState()?.refreshToken;
  try {
    const response = await internalRequest.post<AuthToken>('account/refreshToken', { refreshToken });
    if (response.data) {
      authStorageService.setState(response.data);
      return true;
    }
    return false;
  } catch {
    return false;
  }
};

/* add auth token to requests */
request.interceptors.request.use((config) => {
  const protectedAuthUrls = [
    'userInfo',
    'regenerateRecoveryCode',
    'UserDetails',
    'UpdateUserDetails',
    'ChangePassword',
    'ChangeMfa',
  ];
  const { url } = config;

  if (!url?.startsWith('/account') || protectedAuthUrls.some((authUrl) => url?.endsWith(authUrl))) {
    const token = authStorageService.getState()?.accessToken;

    config.headers.set('Authorization', `Bearer ${token}`);
  }

  return config;
});

/* error handling */
request.interceptors.response.use(undefined, async (error: AxiosError) => {
  switch (error.response?.status) {
    case 401:
      if (await refreshAccessToken()) {
        try {
          return await request({ ...error.config });
        } catch {
          window.dispatchEvent(new Event(events.logouted));
        }
      } else {
        window.dispatchEvent(new Event(events.logouted));
      }
      break;
    case 404:
      window.dispatchEvent(new Event(events.notFound));
      break;
    case 403:
      window.dispatchEvent(new Event(events.forbidden));
      break;
    default:
      break;
  }

  return Promise.reject(error);
});

export default request;
