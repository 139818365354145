import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';

import { useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner/Spinner';
import TabsContainer from '../../../components/TabbedComponent/TabsContainer';
import TabItem from '../../../components/TabbedComponent/TabItem';
import Tabs from '../../../components/TabbedComponent/Tabs';
import TabsContent from '../../../components/TabbedComponent/TabsContent';
import TabsContentItem from '../../../components/TabbedComponent/TabsContentItem';
import ProductRow from '../../../components/ProductRow/ProductRow';
import CompanyItem from '../Companies/CompanyItem/CompanyItem';
import UsersTable from '../../../components/UsersTable/UsersTable';
import Header from '../../../components/Header/Header';
import BackButton from '../../../components/BackButton/BackButton';
import NoData from '../../../components/NoData/NoData';
import useSearch, { tabs } from '../../../hooks/userSearch';

const Search = () => {
  const { searchTerm } = useParams<'searchTerm'>();
  const intl = useIntl();

  const {
    usersResult,
    isLoadingUsers,
    productsResult,
    isLoadingProducts,
    companiesResult,
    isLoadingCompanies,
    selectedTab,
    fetchProductsNextPage,
    hasProductsNextPage,
    fetchUsersNextPage,
    hasUsersNextPage,
    fetchCompaniesNextPage,
    hasCompaniesNextPage,
  } = useSearch(searchTerm!, undefined, { companies: true, users: true });

  const noResultBlock = useMemo(
    () => <NoData message={intl.formatMessage({ id: 'admin.search.noResults' })} />,
    [intl.locale],
  );

  return (
    <>
      <Header
        title={intl.formatMessage({ id: 'admin.search.title' })}
        subtitle={
          <span className="d-inline-block text-color-gray-l6 size-body mt-2">
            {intl.formatMessage({ id: 'admin.search.term' })} <span className="text-color-off-white">{searchTerm}</span>
          </span>
        }
        showSearch
      >
        <div className="col-auto">
          <BackButton />
        </div>
      </Header>
      <div className="page-content px-5">
        <TabsContainer defaultTab={selectedTab}>
          <Tabs className="mb-4">
            <TabItem id={tabs.products}>
              <FormattedMessage
                id="admin.search.tab.product"
                values={{ value: productsResult?.pages[0]?.total ?? 0 }}
              />
            </TabItem>
            <TabItem id={tabs.companies}>
              <FormattedMessage
                id="admin.search.tab.company"
                values={{ value: companiesResult?.pages[0]?.total ?? 0 }}
              />
            </TabItem>
            <TabItem id={tabs.users}>
              <FormattedMessage id="admin.search.tab.user" values={{ value: usersResult?.pages[0]?.total ?? 0 }} />
            </TabItem>
          </Tabs>
          <TabsContent>
            <TabsContentItem id={tabs.products}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchProductsNextPage}
                hasMore={hasProductsNextPage || false}
                loader={null}
                dataLength={productsResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {productsResult?.pages
                  .flatMap((x) => x.products)
                  .map((product) => (
                    <ProductRow key={product.id} product={product} />
                  ))}
                {(!productsResult?.pages[0]?.products || productsResult.pages[0].products.length === 0) &&
                  !isLoadingProducts &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
            <TabsContentItem id={tabs.companies}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchCompaniesNextPage}
                hasMore={hasCompaniesNextPage || false}
                loader={null}
                dataLength={companiesResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {companiesResult?.pages[0]?.companies && companiesResult.pages[0].companies.length > 0 && (
                  <div className="mb-4">
                    <div className="form-2-col gap-4">
                      {companiesResult?.pages
                        .flatMap((x) => x.companies)
                        .map((company) => (
                          <CompanyItem key={company.id} company={company} />
                        ))}
                    </div>
                  </div>
                )}
                {(!companiesResult?.pages[0].companies || companiesResult.pages[0].companies.length === 0) &&
                  !isLoadingCompanies &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
            <TabsContentItem id={tabs.users}>
              <InfiniteScroll
                className="overflow-visible"
                next={fetchUsersNextPage}
                hasMore={hasUsersNextPage || false}
                loader={null}
                dataLength={usersResult?.pages.length || 0}
                scrollableTarget="scrollContainer"
              >
                {usersResult?.pages[0]?.users && usersResult.pages[0].users.length > 0 && (
                  <div className="mb-4 shadow-2">
                    <div className="rounded-bottom overflow-auto">
                      <UsersTable
                        users={usersResult.pages.flatMap((x) => x.users)}
                        totalUsers={usersResult.pages[0].total}
                      />
                    </div>
                  </div>
                )}
                {(!usersResult?.pages[0]?.users || usersResult.pages[0].users.length === 0) &&
                  !isLoadingUsers &&
                  noResultBlock}
              </InfiniteScroll>
            </TabsContentItem>
          </TabsContent>
        </TabsContainer>
      </div>
      {(isLoadingProducts || isLoadingCompanies || isLoadingUsers) && <Spinner />}
    </>
  );
};

export default Search;
