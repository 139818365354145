import React, { FC, useEffect, useMemo, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DOMPurify from 'dompurify';

import useNotifications from '../../../hooks/useNotifications';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';
import Spinner from '../../../components/Spinner/Spinner';
import messageService from '../../../services/InternalMessaging/messageService';
import MessageDetails from '../../../models/Messages/MessageDetails';
import MessageStatus from '../../../models/Messages/MessageStatus';
import Confirm from '../../../components/Confirm/Confirm';
import MessageDetailsSkeleton from './MessageDetailsSkeleton';
import './MessageDetails.scss';

interface Props {
  messageId: string;
  onClose: () => void;
}

const MessageDetailsModal: FC<Props> = ({ messageId, onClose }) => {
  const { formatMessage: fm } = useIntl();
  const queryClient = useQueryClient();
  const { notifyError, notifySuccess } = useNotifications();

  const { data: messageDetails, isLoading: isLoadingItem } = useQuery<MessageDetails>(
    ['messages', 'details', messageId],
    () => messageService.details(messageId),
    {
      onError: () =>
        notifyError(
          fm({ id: 'messages.messageDetails.title' }),
          fm({ id: 'messages.messageDetails.notification.unableToLoadMessage' }),
        ),
    },
  );

  const [isDelete, setIsDelete] = useState<boolean>(false);
  const { mutate: deleteMessage, isLoading: isDeleting } = useMutation(
    () => messageService.remove({ defaultChecked: false, oppositeIds: new Set([messageId]) }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        notifySuccess(
          fm({ id: 'messages.messageDetails.notification.title' }),
          fm({ id: 'messages.messageDetails.notification.deleteMessage' }),
        );
        onClose();
      },
      onError: () =>
        notifyError(
          fm({ id: 'messages.messageDetails.notification.title' }),
          fm({ id: 'messages.messageDetails.notification.unableToDeleteMessage' }),
        ),
    },
  );

  const { mutate: changeStatus, isLoading: isChangingStatus } = useMutation(
    (data: { status: MessageStatus; onSuccess?: () => void }) =>
      messageService.changeStatus({ defaultChecked: false, oppositeIds: new Set([messageId]) }, data.status),
    {
      onSuccess: async (_data, variables) => {
        queryClient.invalidateQueries('messages');
        variables.onSuccess?.();
      },
      onError: () =>
        notifyError(
          fm({ id: 'messages.messageDetails.notification.title' }),
          fm({ id: 'messages.messageDetails.notification.unableChangeStatus' }),
        ),
    },
  );

  const markAsUnread = () => {
    return changeStatus({
      status: MessageStatus.unread,
      onSuccess: () => {
        notifySuccess(
          fm({ id: 'messages.messageDetails.notification.title' }),
          fm({ id: 'messages.messageDetails.notification.changedStatus' }),
        );
        onClose();
      },
    });
  };

  useEffect(() => {
    changeStatus({ status: MessageStatus.read });
  }, []);

  const contentHTML = useMemo(
    () =>
      messageDetails?.content
        ? DOMPurify.sanitize(messageDetails.content, {
            USE_PROFILES: { html: true },
          })
        : '',
    [messageDetails],
  );

  return (
    <div className="message-details-component animated-component-item">
      <Modal show centered className="app-details-modal-component modal-2-col modal-size-medium">
        <div className="d-flex justify-content-between align-items-center w-100 px-4 pt-4">
          <div className="text-uppercase text-color-primary-green size-label font-normal w-100">
            <FormattedMessage id="messages.messageDetails.title" />
          </div>
          <Button className="btn-toggle" size="sm" variant="link" onClick={onClose}>
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </Button>
        </div>
        <Modal.Header className="px-4 text-center">
          {isLoadingItem || isChangingStatus ? (
            <MessageDetailsSkeleton.MessageDetailsHeaderSkeleton />
          ) : (
            <h4 className="animated-component-item font-bold w-100 mb-0">{messageDetails?.title}</h4>
          )}
        </Modal.Header>
        <Modal.Body className="d-flex flex-column p-4 pt-3">
          {isLoadingItem || isChangingStatus ? (
            <MessageDetailsSkeleton.MessageDetailsBodySkeleton />
          ) : (
            <>
              <div className="animated-component-item d-flex justify-content-between w-100 px-3 py-2">
                <div className="d-flex justify-content-between">
                  <div className="size-caption d-flex me-4">
                    <span className="text-color-gray-light1 me-2">
                      <FormattedMessage id="messages.messageDetails.createdAt" />:
                    </span>
                    <GenericFormattedDate withTime value={messageDetails?.createdAt} />
                  </div>
                  <div className="size-caption d-flex">
                    <span className="text-color-gray-light1 me-2">
                      <FormattedMessage id="messages.messageDetails.author" />:
                    </span>
                    {messageDetails?.systemName ||
                      (messageDetails?.user ? `${messageDetails.user?.fullName} (${messageDetails.user?.email})` : '')}
                  </div>
                </div>
              </div>
              <div
                className="message-details-component-description text-start text-color-gray-light1 bg-color-gray-l2 pt-3 my-2 mb-2 px-3"
                dangerouslySetInnerHTML={{ __html: contentHTML }}
              />{' '}
            </>
          )}
          <div className="d-flex justify-content-between">
            <Button
              size="sm"
              variant="danger"
              type="button"
              className="mt-4 "
              onClick={() => setIsDelete(true)}
              disabled={isLoadingItem}
              data-test-id="delete-message-btn"
            >
              <FormattedMessage id="messages.messageDetails.action.delete" />
            </Button>
            <Button size="sm" variant="secondary" className="mt-4" disabled={isLoadingItem} onClick={markAsUnread}>
              <FormattedMessage id="messages.messageDetails.action.markAsUnread" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {isDelete && (
        <Confirm
          title={fm({ id: 'messages.messageDetails.confirm.delete.title' })}
          confirmTitle={fm({ id: 'messages.messageDetails.confirm.delete.confirmMsg' })}
          message={fm({ id: 'messages.messageDetails.confirm.delete.message' })}
          onClose={() => setIsDelete(false)}
          onConfirm={() => deleteMessage()}
        />
      )}
      {isDeleting && <Spinner />}
    </div>
  );
};

export default MessageDetailsModal;
