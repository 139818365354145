import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useNotifications from '../../../hooks/useNotifications';
import Header from '../../../components/Header/Header';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';
import BatchList from '../../../components/BatchList/BatchList';
import BackButton from '../../../components/BackButton/BackButton';
import productService from '../../../services/productService';
import productsHelper from '../../../helpers/productsHelper';
import useQueryStringState from '../../../hooks/useQueryStringState';
import BatchListViewState from '../../../models/Batch/BatchListViewState';
import companiesHelper from '../../../helpers/companiesHelper';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const BatchesOverview: FC = () => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status } = useQuery(['productStatus', productId], () => productService.getStatus(productId!), {
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'admin.batchesOverview.notification.title' }),
        intl.formatMessage({ id: 'admin.batchesOverview.notification.unableLoadProductStatus' }),
      ),
  });
  const [viewState] = useQueryStringState<BatchListViewState>();

  return (
    <>
      <Header
        title={
          <>
            <FormattedMessage id="batchesOverview.title" />
            <div className="py-2">
              <Breadcrumbs
                values={[
                  {
                    url: status?.companyId ? companiesHelper.getCompanyUrl(status?.companyId) : undefined,
                    label: status?.companyName || '',
                  },
                  {
                    url: productsHelper.getProductUrl(productId!),
                    label: status?.name || '',
                  },
                  ...(viewState.search.synthesisStepName
                    ? [
                        {
                          url: productsHelper.getSynthesisStepUrl(productId!, viewState.search.synthesisStepId!),
                          label: viewState.search.synthesisStepName || '',
                        },
                        ...(viewState.search.campaignName
                          ? [
                              {
                                url: productsHelper.getCampaignsUrl({
                                  productId: productId!,
                                  stepId: viewState.search.synthesisStepId!,
                                  campaignId: viewState.search.campaignId!,
                                }),
                                label: viewState.search.campaignName || '',
                              },
                            ]
                          : []),
                      ]
                    : []),
                ]}
              />
            </div>
          </>
        }
      >
        <div className="d-flex gap-4 align-items-baseline">
          <span className="size-caption">
            <FormattedMessage id="admin.batchesOverview.header.updatedAt" />{' '}
            <GenericFormattedDate value={status?.updatedAt} />
          </span>
          <BackButton />
        </div>
      </Header>
      <div className="page-content pb-4 px-5">
        <BatchList productId={productId!} hasAccessToProduct />
      </div>
    </>
  );
};

export default BatchesOverview;
