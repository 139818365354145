import React from 'react';
import { useIntl } from 'react-intl';

import Header from '../../components/Header/Header';
import ProductAlerts from '../../components/ProductAlerts/ProductAlerts';

interface Props {
  companyId: string;
}

const AlertsPage: React.FC<Props> = ({ companyId }) => {
  const { formatMessage: fm } = useIntl();

  return (
    <>
      <Header title={fm({ id: 'alerts.title' })} showSearch />
      <div className="page-content px-5">
        <ProductAlerts companyId={companyId} />
      </div>
    </>
  );
};

export default AlertsPage;
