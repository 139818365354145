import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ProductUpdates from '../../../components/ProductUpdates/ProductUpdates';

interface Props {
  companyId: string;
}

const UpdatesTab: FC<Props> = ({ companyId }) => {
  const { id: productId } = useParams<'id'>();
  return <ProductUpdates productId={productId} companyId={companyId} />;
};

export default UpdatesTab;
