import React, { FC, useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Button, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import InvalidEmailAddressError from '../../../../errors/InvalidEmailAddressError';
import userService from '../../../../services/userService';
import DuplicateEmailError from '../../../../errors/DuplicateEmailError';
import { transformString } from '../../../../helpers/yupExtentions';
import Shape from '../../../../models/Shape';
import InvalidPhoneNumberError from '../../../../errors/InvalidPhoneNumberError';
import useNotifications from '../../../../hooks/useNotifications';
import Regex from '../../../../constants/regex';
import Spinner from '../../../../components/Spinner/Spinner';
import Select from '../../../../components/Select/Select';
import useCompanies from '../../../../hooks/admin/useCompanies';
import useUserRoleOptions from '../../../../hooks/useUserRoleOptions';

const AddEditUser: FC<Props> = ({ onClose, userId, defaultCompanyId }) => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();
  const { roleOptions } = useUserRoleOptions();

  const validationSchema = useMemo(() => {
    const requiredMessage = fm({ id: 'validation.required' });

    return object<Shape<FormData>>({
      fullName: string()
        .transform(transformString)
        .required(requiredMessage)
        .min(2, fm({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, fm({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.Name, fm({ id: 'validation.nameRegex' })),
      email: string()
        .transform(transformString)
        .required(requiredMessage)
        .email(fm({ id: 'validation.email' })),
      department: string()
        .transform(transformString)
        .required(requiredMessage)
        .min(2, fm({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, fm({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.NameExtended, fm({ id: 'validation.nameExtendedRegex' })),
      companyId: string().transform(transformString).required(requiredMessage),
      roleId: string().transform(transformString).required(requiredMessage),
    });
  }, [locale]);
  const title = fm({ id: userId ? 'admin.users.form.title.edit' : 'admin.users.form.title.add' });
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    setError,
    control,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient();
  const { isLoading: isCompaniesLoading, options: companyOptions } = useCompanies(
    () =>
      notify(
        'error',
        fm({ id: 'admin.users.notification.title' }),
        fm({ id: 'admin.users.notification.unableToLoadCompanies' }),
      ),
    fm({ id: 'admin.users.filter.company.empty' }),
    !defaultCompanyId,
  );

  const { data: userData, isLoading: isLoadingItem } = useQuery(['user', userId], () => userService.get(userId!), {
    onError: () => notify('error', title, fm({ id: 'admin.users.notification.unableToLoadUser' })),
    enabled: !!userId,
  });

  useEffect(() => {
    if (defaultCompanyId) {
      setValue('companyId', defaultCompanyId);
    }
  }, []);

  useEffect(() => {
    if (userData) {
      setValue('fullName', userData?.fullName);
      setValue('email', userData?.email);
      setValue('phoneNumber', userData?.phoneNumber || undefined);
      setValue('department', userData?.department);
      setValue('companyId', userData?.companyId || '');
      setValue('roleId', userData?.roleId || '');
    }
  }, [userData]);

  const { mutate: saveUser, isLoading: isSaving } = useMutation(
    async (formData: FormData) => {
      if (userId) {
        await userService.update({ id: userId, ...formData });
        return null;
      }

      await userService.create(formData);
      return formData;
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries('users');
        queryClient.invalidateQueries('search');
        if (data) {
          queryClient.invalidateQueries(['companyStatus', data.companyId]);
          queryClient.invalidateQueries('companies');
        }

        notify(
          'success',
          title,
          fm({
            id: userId ? 'admin.users.notification.updatedItem' : 'admin.users.notification.addedItem',
          }),
        );
        onClose(true);
      },
      onError: (error: unknown) => {
        if (error instanceof InvalidEmailAddressError) {
          setError('email', { message: fm({ id: 'validation.email' }) }, { shouldFocus: true });
        } else if (error instanceof DuplicateEmailError) {
          setError('email', { message: fm({ id: 'validation.emailUsed' }) }, { shouldFocus: true });
        } else if (error instanceof InvalidPhoneNumberError) {
          setError('phoneNumber', { message: fm({ id: 'validation.phoneNumber' }) }, { shouldFocus: true });
        } else {
          notify('error', title, fm({ id: 'admin.users.notification.unableToSaveUser' }));
        }
      },
    },
  );

  const isLoading = isSubmitting || isLoadingItem || isCompaniesLoading || isSaving;

  return (
    <>
      <Modal show centered className="AddEditUserForm">
        <span className="text-uppercase text-color-primary-green size-label px-4 pt-4">
          <FormattedMessage id="admin.users.form.extraTitle" data-test-id="addEditUser-extraTitle" />
        </span>
        <Modal.Header className="px-4">
          <h3 data-test-id="addEditUser-title">{title}</h3>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form className="d-flex flex-column" noValidate onSubmit={handleSubmit((data) => saveUser(data))}>
            {!defaultCompanyId && (
              <Form.Group className="mb-3">
                <Form.Label>
                  <FormattedMessage id="admin.users.form.selectCompany.label" />
                </Form.Label>
                <Controller
                  control={control}
                  name="companyId"
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Select
                      value={value}
                      onChange={onChange}
                      options={companyOptions}
                      dataTestId="addEditUser-company"
                      isError={!!error}
                      placeholder={fm({ id: 'admin.users.form.selectCompany.placeholder' })}
                    />
                  )}
                />
                {!!errors?.companyId && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditUser-company-error">
                    {errors.companyId.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            )}
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.users.form.selectRole.label" />
              </Form.Label>
              <Controller
                control={control}
                name="roleId"
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <Select
                    value={value}
                    onChange={onChange}
                    options={roleOptions}
                    dataTestId="addEditUser-role"
                    isError={!!error}
                    placeholder={fm({ id: 'admin.users.form.selectRole.placeholder' })}
                  />
                )}
              />
              {!!errors?.roleId && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditUser-role-error">
                  {errors.roleId?.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.users.form.userName.label" />
              </Form.Label>
              <Form.Control
                autoFocus
                type="text"
                placeholder={fm({ id: 'admin.users.form.userName.placeholder' })}
                {...register('fullName')}
                isInvalid={!!errors?.fullName}
                data-test-id="addEditUser-fullName"
              />
              {!!errors?.fullName && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditUser-fullName-error">
                  {errors.fullName.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.users.form.companyEmail.label" />
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={fm({ id: 'admin.users.form.companyEmail.placeholder' })}
                {...register('email')}
                isInvalid={!!errors?.email}
                disabled={userData?.emailVerified}
                data-test-id="addEditUser-companyEmail"
              />
              {!!errors?.email && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditUser-companyEmail-error">
                  {errors.email.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.users.form.phoneNumber.label" />
              </Form.Label>
              <Form.Control
                type="tel"
                placeholder={fm({ id: 'admin.users.form.phoneNumber.placeholder' })}
                {...register('phoneNumber')}
                isInvalid={!!errors?.phoneNumber}
                data-test-id="addEditUser-phoneNumber"
              />
              {!!errors?.phoneNumber && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditUser-phoneNumber-error">
                  {errors.phoneNumber.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.users.form.department.label" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={fm({ id: 'admin.users.form.department.placeholder' })}
                {...register('department')}
                isInvalid={!!errors?.department}
                data-test-id="addEditUser-department"
              />
              {!!errors?.department && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditUser-department-error">
                  {errors.department.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              className="btn btn-primary mb-3 mt-4"
              disabled={isLoading}
              data-test-id="addEditUser-submit"
            >
              {userId ? (
                <FormattedMessage id="admin.users.form.editBtn.label" />
              ) : (
                <FormattedMessage id="admin.users.form.addBtn.label" />
              )}
            </Button>
            <Button
              variant="ghost"
              type="button"
              className="btn"
              onClick={() => onClose(false)}
              disabled={isLoading}
              data-test-id="addEditUser-cancel"
            >
              <FormattedMessage id="admin.users.form.cancelBtn.label" />
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

interface Props {
  userId: string | null;
  defaultCompanyId?: string;
  onClose: (update: boolean) => void;
}

type FormData = {
  fullName: string;
  email: string;
  phoneNumber?: string;
  department: string;
  companyId: string;
  roleId: string;
};

export default AddEditUser;
