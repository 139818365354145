import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const ShortInfoSkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton chart-row" data-test-id="loader">
      <ContentLoader
        height={133}
        speed={SkeletonSpeed}
        backgroundColor={SkeletonColors.darkGrey1}
        foregroundColor={SkeletonColors.mediumGrey}
        viewBox="0 0 480 133"
      >
        <rect x="0" y="0" rx="5" ry="4" width="480" height="39" />
        <rect x="0" y="47" rx="5" ry="4" width="480" height="39" />
        <rect x="0" y="94" rx="5" ry="4" width="480" height="39" />
      </ContentLoader>
    </div>
  );
};
export default ShortInfoSkeleton;
