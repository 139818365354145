import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { object, ref, string } from 'yup';
import Spinner from '../../../../components/Spinner/Spinner';
import { transformString } from '../../../../helpers/yupExtentions';
import Shape from '../../../../models/Shape';
import accountService from '../../../../services/accountService';
import useNotifications from '../../../../hooks/useNotifications';
import passwordValidation from '../../../../constants/passwordValidation';

const ChangePassword: FC<Props> = ({ onClose }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      oldPassword: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' })),
      newPassword: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .min(
          passwordValidation.minLength,
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.minLength' }),
        )
        .matches(
          passwordValidation.lowercaseChar,
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.lowercaseChar' }),
        )
        .matches(
          passwordValidation.uppercaseChar,
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.uppercaseChar' }),
        )
        .matches(
          passwordValidation.numberChar,
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.numberChar' }),
        )
        .matches(
          passwordValidation.specialChar,
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.specialChar' }),
        )
        .notOneOf(
          [ref('oldPassword'), null],
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.newPassword.validation.notOldPassword' }),
        ),
      confirmNewPassword: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .oneOf(
          [ref('newPassword')],
          intl.formatMessage({ id: 'settings.tab.security.changePassword.form.confirmNewPassword.validation.noMatch' }),
        ),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const notificationTitle = intl.formatMessage({
    id: 'settings.tab.security.notification.title',
  });
  const { mutate: changePassword, isLoading: isChangingPassword } = useMutation<unknown, unknown, FormData>(
    ({ oldPassword, newPassword }) => accountService.changePassword(oldPassword, newPassword),
    {
      onSuccess: () => {
        notify(
          'success',
          notificationTitle,
          intl.formatMessage({
            id: 'settings.tab.security.notification.changedPassword',
          }),
        );
        onClose();
      },
      onError: () => {
        notify(
          'error',
          notificationTitle,
          intl.formatMessage({ id: 'settings.tab.security.notification.unableToChangePassword' }),
        );
      },
    },
  );

  const onSubmitFormHandler = (data: FormData) => changePassword(data);

  const isLoading = isChangingPassword;

  return (
    <>
      <Modal show centered>
        <span className="text-uppercase text-color-primary-green size-label px-4 pt-4 pb-2">
          <FormattedMessage id="settings.tab.security.title" />
        </span>
        <Modal.Header className="px-4">
          <h3 className="mb-0">
            <FormattedMessage id="settings.tab.security.changePassword.title" />
          </h3>
        </Modal.Header>
        <Modal.Body className="p-4">
          <Form className="d-flex flex-column" onSubmit={handleSubmit(onSubmitFormHandler)}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="settings.tab.security.changePassword.form.oldPassword.label" />
              </Form.Label>
              <Form.Control
                type="password"
                size="lg"
                placeholder={intl.formatMessage({
                  id: 'settings.tab.security.changePassword.form.oldPassword.placeholder',
                })}
                {...register('oldPassword')}
                isInvalid={!!errors?.oldPassword}
              />
              {!!errors?.oldPassword && (
                <Form.Control.Feedback type="invalid">{errors.oldPassword.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="settings.tab.security.changePassword.form.newPassword.label" />
              </Form.Label>
              <Form.Control
                type="password"
                size="lg"
                placeholder={intl.formatMessage({
                  id: 'settings.tab.security.changePassword.form.newPassword.placeholder',
                })}
                {...register('newPassword')}
                isInvalid={!!errors?.newPassword}
              />
              {!!errors?.newPassword && (
                <Form.Control.Feedback type="invalid">{errors.newPassword.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="settings.tab.security.changePassword.form.confirmNewPassword.label" />
              </Form.Label>
              <Form.Control
                type="password"
                size="lg"
                placeholder={intl.formatMessage({
                  id: 'settings.tab.security.changePassword.form.confirmNewPassword.placeholder',
                })}
                {...register('confirmNewPassword')}
                isInvalid={!!errors?.confirmNewPassword}
              />
              {!!errors?.confirmNewPassword && (
                <Form.Control.Feedback type="invalid">{errors.confirmNewPassword.message}</Form.Control.Feedback>
              )}
            </Form.Group>

            <Button variant="primary" type="submit" className="btn btn-primary mt-4 mb-3">
              <FormattedMessage id="settings.tab.security.changePassword.action.submit" />
            </Button>
            <Button variant="ghost" type="button" onClick={onClose}>
              <FormattedMessage id="settings.tab.security.changePassword.action.cancel" />
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

type FormData = { oldPassword: string; newPassword: string; confirmNewPassword: string };

interface Props {
  onClose(): void;
}

export default ChangePassword;
