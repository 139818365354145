import React from 'react';
import { useIntl } from 'react-intl';
import Header from '../../components/Header/Header';
import ProductUpdates from '../../components/ProductUpdates/ProductUpdates';

interface Props {
  companyId: string;
}

const Updates: React.FC<Props> = ({ companyId }) => {
  const { formatMessage: fm } = useIntl();

  return (
    <>
      <Header title={fm({ id: 'updates.title' })} showSearch />
      <div className="page-content px-5" id="updatesPageContainer">
        <ProductUpdates companyId={companyId} />
      </div>
    </>
  );
};

export default Updates;
