import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, NavLink, Link } from 'react-router-dom';
import Header from '../../components/Header/Header';

const AppGallery: React.FC = () => {
  const intl = useIntl();
  return (
    <>
      <Header title={intl.formatMessage({ id: 'appGallery.title' })} className="height-auto">
        <Link to="/settings/apps" type="button" className="btn-link-underline me-3 btn btn-link">
          <FormattedMessage id="appGallery.link.myApp" />
        </Link>
      </Header>
      <div className="page-content px-5">
        <Nav variant="tabs" className="mb-4">
          <Nav.Item>
            <Nav.Link as={NavLink} to="process">
              <FormattedMessage id="appGallery.processTab.title" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="category">
              <FormattedMessage id="appGallery.categoryTab.title" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="list">
              <FormattedMessage id="appGallery.listTab.title" />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Outlet />
      </div>
    </>
  );
};

export default AppGallery;
