import React, { useEffect, FC } from 'react';
import './Logout.scss';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

const delay = 3000;

const Logout: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimer = setTimeout(async () => {
      navigate('/login');
    }, delay);
    return () => {
      clearTimeout(redirectTimer);
    };
  }, []);

  return (
    <div className="logout-component w-100">
      <div>
        <div className="row justify-content-end">
          <div className="col-4 justify-content-center">
            <div className="d-flex flex-column my-auto login-container">
              <div className="d-flex flex-column m-auto login-container p-5 text-center">
                <div className="m-auto">
                  <h3 className="text-center font-bold">
                    <FormattedMessage id="logout.exclamation" />
                    <br />
                    <FormattedMessage id="logout.title" />
                  </h3>
                </div>
                <p className="text-color-gray-l6">
                  <FormattedMessage id="logout.hint" />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logout;
