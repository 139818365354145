import React, { useEffect, useState, FC } from 'react';

import './ResetPassword.scss';
import { useIntl } from 'react-intl';

import SetPassword from '../../components/SetPassword/SetPassword';
import ActionDescriptor from '../../components/ActionDescriptor/ActionDescriptor';
import useAuth from '../../hooks/useAuth';
import Spinner from '../../components/Spinner/Spinner';
import ErrorMessage from '../../components/ErrorMessage/ErrorMessage';
import { useAppContext } from '../../context/useAppContext';
import useQueryParams from '../../hooks/useQueryParams';
import Logo from '../../components/Logo/Logo';
import useNavigateToLogin from '../../hooks/useNavigateToLogin';

const ResetPassword: FC = () => {
  const intl = useIntl();
  const { dispatch } = useAppContext();
  const navigateToLogin = useNavigateToLogin();

  const {
    isLoading,
    api: { verifyResetPasswordLink, confirmResetPassword },
  } = useAuth();
  const { token } = useQueryParams(['token']);
  const [isValidToken, setIsValidToken] = useState(token !== null);

  useEffect(() => {
    const verify = async () => {
      try {
        await verifyResetPasswordLink(token!);
        setIsValidToken(true);
      } catch (err) {
        setIsValidToken(false);
        dispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            notification: {
              type: 'error',
              title: 'Reset Password',
              message: 'Reset password link is expired or invalid',
              callback: navigateToLogin,
            },
          },
        });
      }
    };

    verify();
  }, [token]);

  const onSubmitHandler = async (password: string) => {
    if (!token) return;

    try {
      await confirmResetPassword(token, password);
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'success',
            title: intl.formatMessage({ id: 'resetPassword.title' }),
            message: intl.formatMessage({ id: 'resetPassword.savedPassword' }),
            callback: navigateToLogin,
          },
        },
      });
    } catch (err) {
      dispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'resetPassword.title' }),
            message: intl.formatMessage({ id: 'resetPassword.unableChangePassword' }),
            callback: navigateToLogin,
          },
        },
      });
    }
  };
  return (
    <>
      <div className="reset-password-component d-flex flex-column w-100">
        <div className="w-100 text-center logo-wrapper">
          <Logo />
        </div>

        <ActionDescriptor
          className="bg-color-gray-l3 m-auto align-items-center shadow-2"
          title={intl.formatMessage({ id: 'resetPassword.changePassword.title' })}
          header={intl.formatMessage({ id: 'resetPassword.changePassword.header' })}
          subHeader={intl.formatMessage({ id: 'resetPassword.changePassword.subHeader' })}
        >
          {isValidToken ? (
            <SetPassword onSubmit={onSubmitHandler} btnTitle={intl.formatMessage({ id: 'resetPassword.btn.label' })} />
          ) : (
            <ErrorMessage message={intl.formatMessage({ id: 'resetPassword.errorMessage' })} />
          )}
        </ActionDescriptor>
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

export default ResetPassword;
