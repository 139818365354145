import request from './requestService';
import PlatformStatistics from '../models/Platform/PlatformStatistics';

class PlatformService {
  private readonly BaseUrl = `/platform`;

  public async getStatistics(): Promise<PlatformStatistics> {
    const response = await request.get(`${this.BaseUrl}/GetStatistics`);
    return response.data;
  }
}

export default new PlatformService();
