import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ListApplicationSortColumn from '../../../models/AppGallery/Application/ListApplicationSortColumn';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';
import './ListTab.scss';

const ListTabSkeleton: FC<Props> = ({ cards, columns }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <div className="list-tab-component mb-4 rounded-4 narrow-row-table shadow-2" data-test-id="loader">
      <table className="w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 170 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="150" height="25" />
                </ContentLoader>
              </td>

              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 450 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="440" height="25" />
                </ContentLoader>
              </td>

              <td>
                <ContentLoader
                  height={32}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.green}
                  viewBox="0 0 100 34"
                >
                  <rect x="0" y="1" rx="4" ry="4" width="98" height="30" />
                </ContentLoader>
              </td>

              <td>
                <ContentLoader
                  height={25}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.white}
                  viewBox="0 0 100 30"
                >
                  <rect x="23" y="3" rx="4" ry="4" width="68" height="25" />
                </ContentLoader>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface Props {
  cards: number;
  columns: { name: ListApplicationSortColumn | ''; key: string }[];
}
export default ListTabSkeleton;
