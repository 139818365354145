import { useReducer } from 'react';

interface ILoginState {
  mode:
    | 'LOGIN'
    | 'SETUP_2FA'
    | 'VERIFY_2FA'
    | 'BACKUP_RECOVERY_CODE'
    | 'RECOVERY_CODE'
    | 'RECOVER_PASSWORD'
    | 'SUPPORT';
  data: {
    twoFaToken?: string;
    barcodeUri?: string;
    recoveryCode?: string;
    email?: string;
  } | null;
}

const initialState: ILoginState = {
  mode: 'LOGIN',
  data: null,
};

type ShowLoginAction = {
  type: 'SHOW_LOGIN';
};

type ShowRecoverPassword = {
  type: 'RECOVER_PASSWORD';
};

type ShowTwoFaAction = {
  type: 'SHOW_TWO_FA';
  payload: { twoFaToken: string; barcodeUri?: string; email?: string };
};

type ShowBackupRecoveryCodeAction = {
  type: 'BACKUP_RECOVERY_CODE';
  payload: { recoveryCode: string };
};

type ShowRecoveryCode = {
  type: 'RECOVERY_CODE';
};

type ShowSupport = {
  type: 'SHOW_SUPPORT';
};

type Actions =
  | ShowLoginAction
  | ShowTwoFaAction
  | ShowRecoverPassword
  | ShowRecoveryCode
  | ShowBackupRecoveryCodeAction
  | ShowSupport;

const reducer = (state: ILoginState, action: Actions): ILoginState => {
  const { type } = action;

  switch (type) {
    case 'SHOW_LOGIN':
      return {
        ...state,
        mode: 'LOGIN',
      };
    case 'RECOVER_PASSWORD':
      return {
        ...state,
        mode: 'RECOVER_PASSWORD',
      };
    case 'SHOW_TWO_FA':
      return {
        ...state,
        mode: action.payload?.barcodeUri ? 'SETUP_2FA' : 'VERIFY_2FA',
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case 'RECOVERY_CODE':
      return {
        ...state,
        mode: 'RECOVERY_CODE',
      };
    case 'SHOW_SUPPORT': {
      return {
        ...state,
        mode: 'SUPPORT',
      };
    }
    case 'BACKUP_RECOVERY_CODE':
      return {
        ...state,
        mode: 'BACKUP_RECOVERY_CODE',
        data: {
          ...state.data,
          recoveryCode: action.payload.recoveryCode,
        },
      };

    default:
      throw new Error('unknown Login action');
  }
};

const useLoginReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default useLoginReducer;
