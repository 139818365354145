import { useMemo, useState } from 'react';
import SelectionState from '../models/SelectionState';

const useTableSelection = () => {
  const [selectionState, setSelectionState] = useState<SelectionState>({
    defaultChecked: false,
    oppositeIds: new Set(),
  });

  const isMasterChecked = useMemo(
    () => selectionState.defaultChecked || (!selectionState.defaultChecked && selectionState.oppositeIds.size > 0),
    [selectionState],
  );

  const resetState = () => {
    setSelectionState({
      defaultChecked: false,
      oppositeIds: new Set(),
    });
  };

  const toggleItem = (id: string, isChecked: boolean) => {
    if ((!selectionState.defaultChecked && isChecked) || (selectionState.defaultChecked && !isChecked)) {
      setSelectionState((state) => ({
        defaultChecked: selectionState.defaultChecked,
        oppositeIds: state.oppositeIds.add(id),
      }));
    } else {
      setSelectionState((state) => {
        state.oppositeIds.delete(id);
        return {
          defaultChecked: selectionState.defaultChecked,
          oppositeIds: state.oppositeIds,
        };
      });
    }
  };

  const toggleMaster = () => {
    if (!selectionState.defaultChecked && selectionState.oppositeIds?.size === 0) {
      setSelectionState({
        defaultChecked: true,
        oppositeIds: new Set(),
      });
    } else {
      resetState();
    }
  };

  const isItemChecked = (id: string): boolean => {
    return (
      (!selectionState.defaultChecked && selectionState.oppositeIds?.has(id)) ||
      (selectionState.defaultChecked && !selectionState.oppositeIds?.has(id))
    );
  };

  return {
    selectionState,
    isMasterChecked,
    toggleMaster,
    isItemChecked,
    toggleItem,
    resetState,
  };
};
export default useTableSelection;
