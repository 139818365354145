import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import StatusBadge from '../../../../components/StatusBadge/StatusBadge';
import GenericFormattedDate from '../../../../components/GenericFormattedDate/GenericFormattedDate';
import companiesHelper from '../../../../helpers/companiesHelper';
import AddEditCompany from '../AddEditCompany/AddEditCompany';
import CompanyItemModel from '../../../../models/Company/CompanyItem';

const CompanyItem: React.FC<Props> = ({ company }) => {
  const [isEdit, selectIsEdit] = useState<boolean>(false);

  const getClassName = () => {
    if (company.isActive) {
      if (company.numberOfActiveUsers > 0) {
        return 'numb text-color-primary-green';
      }
      return 'numb text-color-graph-negative';
    }
    return 'numb text-color-gray-light1';
  };

  return (
    <>
      <div className="card p-3" data-test-id="companies-companyCard">
        <div className="form-2-col mb-7">
          <div>
            <div className="d-flex align-items-center mb-3">
              <StatusBadge isActive={company.isActive} bg="gray-l4" className="size-caption" />
              <span className="ms-3 size-caption text-color-gray-l6 ">
                <FormattedMessage id="admin.companies.card.created" />
                <span data-test-id="companies-companyCard-createdAt">
                  <GenericFormattedDate value={company.createdDate} />
                </span>
              </span>
            </div>
            <h3 className="font-bold text-uppercase" data-test-id="companies-companyCard-name">
              {company.name}
            </h3>
          </div>
          <div>
            <div className="bg-color-gray-l2 p-3 rounded d-flex justify-content-between size-label mb-3">
              <span>
                <FormattedMessage id="admin.companies.card.totalUsers" />
              </span>
              <span className={getClassName()} data-test-id="companies-companyCard-activeUsers">
                {company.numberOfActiveUsers}
              </span>
            </div>
            <div className="bg-color-gray-l2 p-3 rounded d-flex justify-content-between size-label">
              <span>
                <FormattedMessage id="admin.companies.card.products" />
              </span>
              <span className="numb" data-test-id="companies-companyCard-productCount">
                {company.numberOfProducts}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 justify-content-between">
          <button
            type="button"
            className="link link--underlined link--secondary font-medium"
            onClick={() => selectIsEdit(true)}
            data-test-id="companies-companyCard-edit"
          >
            <FormattedMessage id="admin.companies.action.edit" />
          </button>
          <Link
            to={companiesHelper.getProductsUrl(company.id)}
            className="link link--underlined link--secondary font-medium"
          >
            <FormattedMessage id="admin.companies.action.allProducts" />
          </Link>
          <Link
            to={companiesHelper.getUsersUrl(company.id)}
            className="link link--underlined link--secondary font-medium"
          >
            <FormattedMessage id="admin.companies.action.manageUsers" />
          </Link>
          <Link
            to={companiesHelper.getCompanyUrl(company.id)}
            className="link link--underlined link--secondary font-medium"
          >
            <FormattedMessage id="admin.companies.action.showDetails" />
          </Link>
        </div>
      </div>
      {isEdit && <AddEditCompany companyId={company.id} onClose={() => selectIsEdit(false)} />}
    </>
  );
};

interface Props {
  company: CompanyItemModel;
}

export default CompanyItem;
