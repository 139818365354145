import React, { useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import Header from '../../../components/Header/Header';
import Select from '../../../components/Select/Select';
import Spinner from '../../../components/Spinner/Spinner';
import useCompanies from '../../../hooks/admin/companies/useCompanies';
import useNotifications from '../../../hooks/useNotifications';
import ProductUpdates from '../../../components/ProductUpdates/ProductUpdates';

const Updates: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const onCompanyIdChange = (newCompanyId: string | undefined) => {
    if (!newCompanyId) {
      searchParams.delete('companyId');
    } else {
      searchParams.set('companyId', newCompanyId);
    }
    setSearchParams(searchParams);
  };

  const { isLoading: isCompaniesLoading, data: companyList } = useCompanies({
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'admin.updates.notification.title' }),
        intl.formatMessage({ id: 'admin.updates.notification.unableToLoadCompanies' }),
      ),
  });

  const companyOptions = useMemo(() => {
    return [
      { label: intl.formatMessage({ id: 'admin.updates.filter.company.empty' }), value: '' },
      ...(companyList ? companyList.companies.map((company) => ({ label: company.name, value: company.id })) : []),
    ];
  }, [companyList?.companies, intl.locale]);

  return (
    <>
      <Header title={intl.formatMessage({ id: 'admin.updates.title' })} showSearch />
      <div className="page-content px-5" id="updatesPageContainer">
        <div className="filters-container mb-4">
          <Form.Group>
            <Select
              value={companyId || ''}
              onChange={onCompanyIdChange}
              options={companyOptions}
              size="lg"
              dataTestId="updates-companyFilter"
            />
          </Form.Group>
        </div>
        <ProductUpdates companyId={companyId ?? undefined} />
        {isCompaniesLoading && <Spinner />}
      </div>
    </>
  );
};

export default Updates;
