import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import userService from '../services/userService';
import useNotifications from './useNotifications';

const useUserRoleOptions = () => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();
  const { data: userListRoles } = useQuery(['userListRoles'], () => userService.listRoles(), {
    onError: () =>
      notify(
        'error',
        fm({ id: 'hooks.roles.notification.usersRoleListTitle' }),
        fm({ id: 'hooks.roles.notification.unableToLoadUsersRoleList' }),
      ),
  });

  const roleOptions = useMemo(() => {
    return [
      { label: fm({ id: 'hooks.roles.filter.empty' }), value: '' },
      ...(userListRoles
        ? userListRoles.roles.map((role) => ({
            label: fm({ id: `hooks.roles.filter.${role.roleType}` }),
            value: role.roleId,
          }))
        : []),
    ];
  }, [locale, userListRoles]);

  return { roleOptions };
};

export default useUserRoleOptions;
