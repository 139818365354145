import { useMemo } from 'react';
import { useQuery } from 'react-query';
import companyService from '../../services/companyService';

const useCompanies = (onError: () => void, placeholder: string, enabled?: boolean) => {
  const { isLoading, data: companyList } = useQuery(['companies'], () => companyService.list(), {
    onError,
    enabled,
  });

  const options = useMemo(() => {
    return [
      { label: placeholder, value: '' },
      ...(companyList ? companyList.companies.map((company) => ({ label: company.name, value: company.id })) : []),
    ];
  }, [companyList?.companies, placeholder]);

  return {
    isLoading,
    options,
  };
};

export default useCompanies;
