import AdminProductsViewState from '../../../models/Product/AdminProductsViewState';
import useProductsViewState from '../../useProductsViewState';

const useAdminProductsViewState = () => {
  const productsViewState = useProductsViewState<AdminProductsViewState>();
  const { viewState, setViewState } = productsViewState;

  const setCompanyId = (companyId?: string | null) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        companyId: companyId === '' || !companyId ? undefined : companyId,
      },
    });
  };

  return {
    ...productsViewState,
    companyId: viewState.search.companyId,
    setCompanyId,
  };
};

export default useAdminProductsViewState;
