import React, { FC, useRef } from 'react';
import { faCircleInfo as InfoRegular } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import './InlineTracker.scss';

import TrackerVariant from '../../../models/TrackerState';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import eventHelper from '../../../helpers/eventHelper';

const InlineTracker: FC<Props> = ({
  label,
  tooltip,
  labelTooltip,
  value,
  variant,
  unitLabel,
  spaceBeforeUnit: separateUnit,
  type,
  dataTestId,
  onClick,
  link,
}) => {
  const ref = useRef(null);

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      onClick?.();
    }
  };

  const props = onClick
    ? {
        onClick,
        role: 'button',
        tabIndex: 0,
        onKeyDown: onKeyDownHandler,
      }
    : undefined;

  const valueComponent = (
    <>
      {value}
      {separateUnit && ' '}
      {unitLabel}
    </>
  );

  const valueWithLink = link ? (
    <Link to={link} className={classNames('base-link', variant.toString())}>
      {valueComponent}
    </Link>
  ) : (
    valueComponent
  );

  return (
    <div
      className={classNames(`row inline-tracker-component inline-tracker-component-type-${type}`, variant.toString(), {
        'with-click': !!onClick,
      })}
      data-test-id={dataTestId}
      {...props}
    >
      <div ref={ref} className="col font-bold d-inline-flex" data-test-id="inline-tracker-label">
        {label && labelTooltip ? (
          <TitleTooltip title={label} titleTooltip={labelTooltip} titleClassName="align-middle" />
        ) : (
          <span className="align-middle">{label}</span>
        )}
        {tooltip &&
          (type === 'background' ? (
            <IconTooltip tooltip={tooltip} icon={InfoRegular} className="ms-1 align-middle" />
          ) : (
            <IconTooltip tooltip={tooltip} className="ms-1 align-middle" />
          ))}
      </div>
      <div className="col-auto">
        <span className="inline-tracker-component-value align-middle" data-test-id="inline-tracker-value">
          {variant !== TrackerVariant.noData ? valueWithLink : <FormattedMessage id="tracker.noData" />}
        </span>
      </div>
    </div>
  );
};

type Props = {
  label: string;
  labelTooltip?: string;
  tooltip?: string;
  value?: string;
  unitLabel?: string;
  variant: TrackerVariant;
  spaceBeforeUnit?: boolean;
  type: 'value' | 'background';
  dataTestId?: string;
  onClick?: () => void;
  link?: string;
};

export default InlineTracker;
