import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';

const ProductStepSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="animated-component-skeleton col-6 gx-4" data-test-id="loader">
          <div className="card mb-4 p-3">
            <div className="d-flex justify-content-between">
              <ContentLoader
                height={29}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey2}
                foregroundColor={SkeletonColors.lightGrey1}
                viewBox="0 0 285 29"
              >
                <rect x="0" y="0" rx="4" ry="4" width="285" height="24" />
              </ContentLoader>
              <ContentLoader
                height={29}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.blue}
                foregroundColor={SkeletonColors.darkGrey1}
                viewBox="0 0 181 29"
              >
                <rect x="0" y="0" rx="4" ry="4" width="115" height="24" />
              </ContentLoader>
            </div>
            <ContentLoader
              height={43}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 748 43"
            >
              <rect x="0" y="12" rx="4" ry="4" width="180" height="33" />
              <rect x="188" y="12" rx="4" ry="4" width="180" height="33" />
              <rect x="378" y="12" rx="4" ry="4" width="180" height="33" />
              <rect x="568" y="12" rx="4" ry="4" width="180" height="33" />
            </ContentLoader>
          </div>
        </div>
      ))}
    </>
  );
};

interface Props {
  cards: number;
}

export default ProductStepSkeleton;
