import { useNavigate } from 'react-router-dom';

const useNavigateToLogin = () => {
  const navigate = useNavigate();

  return () => {
    navigate('/login');
  };
};

export default useNavigateToLogin;
