import appConfig from '../../appConfig';

class ImageFileService {
  private readonly BaseUrl = `/ImageFile`;

  public getImageUrl(imageId?: string | null): string | undefined {
    return imageId ? `${appConfig.appGalleryBackendUrl}${this.BaseUrl}/Get/${imageId}` : undefined;
  }
}

export default new ImageFileService();
