import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import numberHelper from '../../helpers/numberHelper';
import './Progress.scss';
import ProgressBar from './ProgressBar/ProgressBar';

const Progress: FC<Props> = ({ value }) => (
  <div
    className="progress-component p-2 gx-4 d-flex flex-column h-100 justify-content-between size-label"
    data-test-id="progress-component"
  >
    <div className="row">
      <div className="col">
        <FormattedMessage id="tracker.progress.title" />
      </div>
      <div className="col-auto" data-test-id="progress-component-value">
        {value === null || value === undefined ? (
          <span className="text-color-gray-l6">
            <FormattedMessage id="tracker.noData" />
          </span>
        ) : (
          `${numberHelper.formatNumber(value)}%`
        )}
      </div>
    </div>
    <ProgressBar value={value} />
  </div>
);

interface Props {
  value?: number | null;
}

export default Progress;
