import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import './ProcessTab.scss';
import { useSearchParams } from 'react-router-dom';
import useProcessList from '../../../hooks/appGallery/useProcessList';

import useNotifications from '../../../hooks/useNotifications';
import AppDetailsModal from '../AppDetailsModal/AppDetailsModal';
import ProcessColumn from './ProcessColumn/ProcessColumn';
import ProcessTabSkeleton from './ProcessTabSkeleton';

const ProcessTab: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: processList, isLoading: isLoadingProcesses } = useProcessList({
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'appGallery.processTab.notification.title' }),
        intl.formatMessage({ id: 'appGallery.processTab.notification.unableToLoadProcessList' }),
      ),
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const handleCLoseModal = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  return (
    <>
      {isLoadingProcesses && !processList ? (
        <ProcessTabSkeleton cards={5} />
      ) : (
        <div className="process-tab-component animated-component-item pb-3">
          <div className="process-tab-component-title row gap-3">
            {processList?.map((processItem) => (
              <div className="col py-3 px-4 pb-0 bg-color-gray-l4 text-center" key={processItem.id}>
                <div className={`process-tab-component-type process-tab-component-type-${processItem.name}`}>
                  <FormattedMessage id={`appGallery.processTab.process.${processItem.name}`} />
                </div>
              </div>
            ))}
          </div>
          <div className="process-tab-component-body row gap-3">
            {processList?.map((processItem) => (
              <div className=" col p-3 bg-color-gray-l4" key={processItem.id}>
                <div className="process-tab-component-body-item">
                  <ProcessColumn processId={processItem.id} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      {id && <AppDetailsModal appId={id} onClose={handleCLoseModal} />}
    </>
  );
};

export default ProcessTab;
