import React, { FC, PropsWithChildren, useContext } from 'react';
import { AccordionContext } from 'react-bootstrap';
import { TransitionGroup } from 'react-transition-group';

const AccordionLazyContent: FC<PropsWithChildren<Props>> = ({ eventKey, children }) => {
  const { activeEventKey } = useContext(AccordionContext);

  return <TransitionGroup>{activeEventKey === eventKey ? (children as React.ReactElement) : null}</TransitionGroup>;
};

interface Props {
  eventKey: string;
}

export default AccordionLazyContent;
