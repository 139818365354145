import React, { FC } from 'react';
import './Privacy.scss';
import { FormattedMessage } from 'react-intl';

const Privacy: FC = () => {
  return (
    <div className="smart-scroll">
      <div className="privacy-page my-5 d-flex flex-column">
        <div className="row">
          <div className="divider">
            <h1 className="px-5 py-4 mb-0 text-center">
              <FormattedMessage id="activation.privacy.title" />
            </h1>
          </div>
        </div>
        <div className="row">
          <p className="text px-5 py-4">
            <FormattedMessage id="activation.privacy.body" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
