import qs from 'qs';
import BatchListViewState from '../models/Batch/BatchListViewState';
import CampaignListViewState from '../models/Campaign/CampaignListViewState';
import ProblemTrackerFilter from '../models/ProblemTrackerFilter';
import ProblemTrackerViewState from '../models/ProblemTrackerViewState';

const productsBaseUrl = '/products';
const getUserUrlPrefix = (companyId: string) => `/companies/${companyId}`;

const adminProductsUrl = productsBaseUrl;
const getUserProductsUrl = (companyId: string, problemTracker?: ProblemTrackerFilter) => {
  let params = '';
  if (problemTracker) {
    const problemTrackerState: ProblemTrackerViewState = {
      problemTrackers: [problemTracker],
    };
    params += qs.stringify(problemTrackerState, { indices: true });
  }

  return `${getUserUrlPrefix(companyId)}${productsBaseUrl}${params ? `?${params}` : ''}`;
};

const getProductUrl = (productId: string) => `${productsBaseUrl}/${productId}`;

const getProductAlertsUrl = (productId: string) => `${getProductUrl(productId)}/alerts`;
const getProductUpdatesUrl = (productId: string) => `${getProductUrl(productId)}/updates`;

const getSynthesisStepUrl = (productId: string, stepId: string) => {
  return `${getProductUrl(productId)}/steps/#${stepId}`;
};

const getSynthesisStepOverviewUrl = (productId: string, stepId: string) => {
  return `${getProductUrl(productId)}/step/${stepId}/details`;
};

const getSynthesisStepOverviewCampaingsUrl = (productId: string, stepId: string, campaignId?: string) => {
  return `${getProductUrl(productId)}/step/${stepId}/campaigns${campaignId ? `#campaign-${campaignId}` : ''}`;
};

const getCampaignsUrl = (
  funcParams: {
    productId: string;
    stepId?: string;
    campaignId?: string;
    problemTracker?: ProblemTrackerFilter;
  },
  baseUrl?: string,
) => {
  const params: CampaignListViewState & ProblemTrackerViewState = {
    synthesisStepId: funcParams.stepId,
    problemTrackers: funcParams.problemTracker && [funcParams.problemTracker],
  };
  return `${baseUrl ?? `${getProductUrl(funcParams.productId)}/campaigns/`}?${qs.stringify(params, { indices: true })}${
    funcParams.campaignId ? `#campaign-${funcParams.campaignId}` : ''
  }`;
};
const getBatchesUrl = (funcParams: {
  productId: string;
  synthesisStepId?: string;
  synthesisStepName?: string;
  campaignId?: string;
  campaignName?: string;
  viewState?: BatchListViewState;
  problemTracker?: ProblemTrackerFilter;
}) => {
  const params: BatchListViewState & ProblemTrackerViewState = {
    ...funcParams.viewState,
    campaignId: funcParams.campaignId,
    campaignName: funcParams.campaignName,
    synthesisStepId: funcParams.synthesisStepId,
    synthesisStepName: funcParams.synthesisStepName,
    problemTrackers: funcParams.problemTracker && [funcParams.problemTracker],
  };
  return `${getProductUrl(funcParams.productId)}/batchesOverview/?${qs.stringify(params, { indices: true })}`;
};

const getProductAddUrl = (defaultCompanyId: string | undefined = undefined) =>
  `${productsBaseUrl}/add${defaultCompanyId ? `?defaultCompanyId=${defaultCompanyId}` : ''}`;
const getProductEditUrl = (productId: string) => `${getProductUrl(productId)}/edit`;

export default {
  adminProductsUrl,
  getUserProductsUrl,

  getProductUrl,

  getProductAlertsUrl,
  getProductUpdatesUrl,
  getSynthesisStepUrl,
  getSynthesisStepOverviewUrl,
  getSynthesisStepOverviewCampaingsUrl,
  getCampaignsUrl,
  getBatchesUrl,

  getProductAddUrl,
  getProductEditUrl,
};
