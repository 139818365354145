import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import DetailsAdminNoteSkeleton from './DetailsAdminNoteSkeleton';
import DetailsDescriptionSkeleton from '../../../../components/Skeletons/ProductOverview/Details/DetailsDescriptionSkeleton';
import DetailsImageSkeleton from '../../../../components/Skeletons/ProductOverview/Details/DetailsImageSkeleton';
import DetailsSkeleton from '../../../../components/Skeletons/ProductOverview/Details/DetailsSkeleton';
import dataPlaceholders from '../../../../constants/dataPlaceholders';
import useNotifications from '../../../../hooks/useNotifications';
import productImageService from '../../../../services/productImageService';
import productService from '../../../../services/productService';

import './DetailTab.scss';

const DetailTab: FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const title = intl.formatMessage({ id: 'admin.productOverview.detailTab.productDetails.title' });
  const { id: productDetailsId } = useParams<'id'>();
  const { data: details, isLoading: isLoadingDetails } = useQuery(
    ['productDetails', productDetailsId],
    () => productService.get(productDetailsId!),
    {
      onError: () =>
        notify(
          'error',
          title,
          intl.formatMessage({ id: 'admin.productOverview.detailTab.notification.unableLoadProductDetails' }),
        ),
    },
  );

  return (
    <div className="admin-details-tab-component pb-2">
      <div className="row gx-4 mb-4">
        <div className="col-9">
          {isLoadingDetails ? (
            <DetailsSkeleton />
          ) : (
            <div className="animated-component-item row mb-4">
              <div className="card">
                <div className="card-divider font-bold text-uppercase p-3">
                  <FormattedMessage id="admin.productOverview.detailTab.productDetails.title" />
                </div>
                <div className="px-3 py-4">
                  <div className="row">
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.tradeName" />
                      </div>
                      <div className="size-label">{details?.name || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.commercialName" />
                      </div>
                      <div className="size-label">{details?.commercialName || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.casNumber" />
                      </div>
                      <div className="size-label">{details?.casNumber || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.productType" />
                      </div>
                      <div className="size-label">{details?.productType || dataPlaceholders.missingData}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.molecularWeight" />
                      </div>
                      <div className="size-label">{details?.molecularWeight || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.originator" />
                      </div>
                      <div className="size-label">{details?.originator || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.approvedFDA" />
                      </div>
                      <div className="size-label">{details?.yearApprovedFda || dataPlaceholders.missingData}</div>
                    </div>
                    <div className="col-3 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.approvedEMA" />
                      </div>
                      <div className="size-label">{details?.yearApprovedEma || dataPlaceholders.missingData}</div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-8 pb-3">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.iupacName" />
                      </div>
                      <div className="size-label">{details?.iupacName || dataPlaceholders.missingData}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-8">
                      <div className="size-caption text-color-gray-l6 pb-2">
                        <FormattedMessage id="admin.productOverview.detailTab.productDetails.smiles" />
                      </div>
                      <div className="size-label">{details?.smiles || dataPlaceholders.missingData}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="card">
              <div className="card-divider font-bold text-uppercase p-3">
                <FormattedMessage id="admin.productOverview.detailTab.description.title" />
              </div>
              <div className="col px-3 py-4">
                {isLoadingDetails && <DetailsDescriptionSkeleton />}
                <div className="description animated-component-item">
                  {details?.description || dataPlaceholders.missingData}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-3">
          {isLoadingDetails ? (
            <DetailsImageSkeleton />
          ) : (
            <div className="animated-component-item card p-3 mb-4">
              <div className="font-bold text-uppercase pb-3">
                <FormattedMessage id="admin.productOverview.detailTab.chemicalStructure.title" />
              </div>
              <div className="text-center">
                <img src={productImageService.getImageUrl(details?.imageId)} alt={details?.name} />
              </div>
            </div>
          )}
          <div className="card">
            <div className="card-divider p-3 font-bold text-uppercase pb-3">
              <FormattedMessage id="admin.productOverview.detailTab.adminNote.title" />
            </div>
            <div className="col px-3 py-4">
              {isLoadingDetails && <DetailsAdminNoteSkeleton />}
              <div className="description animated-component-item">{details?.note || dataPlaceholders.missingData}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailTab;
