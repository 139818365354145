const usersBaseUrl = '/users';
const getUserUrlPrefix = (companyId: string) => `/companies/${companyId}`;

const getManufacturerUsers = (manufacturerId: string) => `${usersBaseUrl}/?manufacturerId=${manufacturerId}`;
const getSeniorManagerUsersUrl = (companyId: string) => `${getUserUrlPrefix(companyId)}${usersBaseUrl}`;

export default {
  usersBaseUrl,
  getManufacturerUsers,
  getSeniorManagerUsersUrl,
};
