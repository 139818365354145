import appConfig from '../../appConfig';
import ApplicationRequestList from '../../models/AppGallery/ApplicationRequest/ApplicationRequestList';
import PagingInfo from '../../models/PagingInfo';
import request from '../requestService';

class ApplicationRequestService {
  private readonly BaseUrl = `/ApplicationRequest`;

  public async list(params: {
    orderBy?: string;
    paging?: PagingInfo;
    applicationId?: string;
    status?: string;
    companyId?: string;
  }): Promise<ApplicationRequestList> {
    const response = await request.get<ApplicationRequestList>(`${this.BaseUrl}/List`, {
      params: {
        orderBy: params.orderBy,
        ...params.paging,
        applicationId: params.applicationId,
        status: params.status,
        companyId: params.companyId,
      },
      baseURL: appConfig.appGalleryBackendUrl,
    });

    return response.data;
  }

  public requestAccess(applicationId: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/RequestAccess`,
      {
        applicationId,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }

  public approveAccess(applicationRequestId: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/ApproveAccess`,
      {
        applicationRequestId,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }

  public rejectAccess(applicationRequestId: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/RejectAccess`,
      {
        applicationRequestId,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }
}
export default new ApplicationRequestService();
