import React, { useMemo } from 'react';

import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import InfiniteScroll from 'react-infinite-scroll-component';
import Header from '../../../components/Header/Header';
import useCompanies from '../../../hooks/admin/companies/useCompanies';
import useAdminProductsViewState from '../../../hooks/admin/products/useAdminProductsViewState';
import useNotifications from '../../../hooks/useNotifications';
import productService from '../../../services/productService';
import PhasesTabs from '../../../components/PhasesFilter/PhasesTabs';
import Select from '../../../components/Select/Select';
import StatusSelect from '../../../components/StatusSelect/StatusSelect';
import ProductRowSkeleton from '../../../components/Skeletons/ProductOverview/ProductRowSkeleton';
import useProblemTrackerFilter from '../../../hooks/useProblemTrackerFilter';
import MultiSelect from '../../../components/Select/MultiSelect/MultiSelect';
import NoData from '../../../components/NoData/NoData';
import ProductRow from '../../../components/ProductRow/ProductRow';

const pageSize = 20;

const Products = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { phaseId, isActive, companyId, setPhaseId, setIsActive, setCompanyId } = useAdminProductsViewState();
  const { selectedTrackers, selectTrackers, options: problemTrackerOptions } = useProblemTrackerFilter();

  const { isLoading: isCompaniesLoading, data: companyList } = useCompanies({
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'admin.products.title' }),
        intl.formatMessage({ id: 'admin.products.notification.unableToLoadCompanies' }),
      ),
  });

  const {
    data: productsModel,
    isLoading: isProductsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['products', companyId, isActive, phaseId, selectedTrackers],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        isActive,
        phaseId,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
        problemTrackers: selectedTrackers?.map((x) => x.value),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.products.title' }),
          intl.formatMessage({ id: 'admin.products.notification.unableLoadProducts' }),
        ),
    },
  );

  const { isLoading: isPhasesLoading, data: phasesData } = useQuery(
    ['productPhases'],
    () => productService.productPhases(),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.products.title' }),
          intl.formatMessage({ id: 'admin.products.notification.unableLoadPhases' }),
        ),
    },
  );

  const companyOptions = useMemo(() => {
    return [
      { label: intl.formatMessage({ id: 'admin.products.filter.company.empty' }), value: '' },
      ...(companyList ? companyList.companies.map((company) => ({ label: company.name, value: company.id })) : []),
    ];
  }, [companyList?.companies, intl.locale]);

  return (
    <>
      <Header title={intl.formatMessage({ id: 'header.admin.productsTitle' })} showSearch />
      <div className="page-content px-5">
        <PhasesTabs
          selectedPhase={phaseId}
          onPhaseSelect={setPhaseId}
          allCount={phasesData?.allProductCount}
          phases={phasesData?.phases}
          className="mb-4"
          isLoading={isPhasesLoading}
        />
        <div className="filters-container pb-4">
          <Form.Group className="d-flex">
            <div className="me-3">
              <Select
                value={companyId || ''}
                onChange={setCompanyId}
                options={companyOptions}
                size="lg"
                dataTestId="products-companyFilter"
              />
            </div>
            <div className="me-3">
              <StatusSelect
                onChange={(value) => setIsActive(value)}
                value={isActive}
                labels={{
                  empty: intl.formatMessage({ id: 'admin.products.filter.status.empty' }),
                  active: intl.formatMessage({ id: 'admin.products.filter.status.onlyActive' }),
                  inactive: intl.formatMessage({ id: 'admin.products.filter.status.onlyInactive' }),
                }}
                dataTestId="products-statusFilter"
              />
            </div>
            <div className="me-3">
              <MultiSelect
                size="lg"
                value={selectedTrackers}
                options={problemTrackerOptions}
                onChange={selectTrackers}
                placeholder={intl.formatMessage({ id: 'admin.products.filter.problemTrackers.empty' })}
              />
            </div>
            <div className="col d-flex align-items-center">
              {isActive !== undefined || companyId !== undefined || (selectedTrackers && selectedTrackers.length > 0)
                ? intl.formatMessage(
                    { id: 'admin.products.filter.total.label' },
                    { value: productsModel?.pages[0].total || 0 },
                  )
                : null}
            </div>
          </Form.Group>
          <Link className="btn-link-underline btn-link btn-link-underline-create" to="add" data-test-id="products-add">
            <FormattedMessage id="admin.products.action.add" />
          </Link>
        </div>
        {productsModel?.pages[0].products?.length === 0 && <NoData />}
        {!!productsModel?.pages?.[0]?.products?.length && (
          <InfiniteScroll
            className="overflow-visible"
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            dataLength={productsModel?.pages.length || 0}
            scrollableTarget="scrollContainer"
          >
            <div>
              {productsModel?.pages
                .flatMap((x) => x.products)
                .map((product) => (
                  <ProductRow key={product.id} product={product} showCompany showActions />
                ))}
            </div>
          </InfiniteScroll>
        )}
        {(isProductsLoading || isCompaniesLoading) && <ProductRowSkeleton cards={2} />}
      </div>
    </>
  );
};

export default Products;
