import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const ProductTabsSkeleton: FC = () => {
  return (
    <div data-test-id="loader">
      <ContentLoader
        height={26}
        speed={SkeletonSpeed}
        backgroundColor={SkeletonColors.lightGrey2}
        foregroundColor={SkeletonColors.lightGrey1}
        viewBox="0 0 700 26"
      >
        <rect x="0" y="4" rx="4" ry="4" width="66" height="17" />
        <rect x="81" y="4" rx="4" ry="4" width="149" height="17" />
        <rect x="245" y="4" rx="4" ry="4" width="263" height="17" />
        <rect x="523" y="4" rx="4" ry="4" width="105" height="17" />
      </ContentLoader>
    </div>
  );
};
export default ProductTabsSkeleton;
