import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import numberHelper from '../../../helpers/numberHelper';

import trackerHelper from '../../../helpers/trackerHelper';
import ValueTrackerWithDetails from '../ValueTrackerWithDetails/ValueTrackerWithDetails';

const OutOfRangeWithDetails: FC<Props> = ({ value, tooltip, link }) => {
  const intl = useIntl();

  return (
    <ValueTrackerWithDetails
      title={intl.formatMessage({ id: 'tracker.outOfRange.shortTitle' })}
      titleTooltip={intl.formatMessage({ id: 'tracker.outOfRange.title' })}
      value={numberHelper.formatNumber(value).toString()}
      variant={trackerHelper.getTypeForValue(value)}
      tooltip={tooltip}
      units="%"
      dataTestId="outOfRange-component"
      link={value && value > 0 ? link : undefined}
    />
  );
};

type Props = {
  value?: number | null;
  tooltip?: string;
  link?: string;
};

export default OutOfRangeWithDetails;
