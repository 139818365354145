import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useQuery } from 'react-query';
import { Button, Modal } from 'react-bootstrap';

import useNotifications from '../../../hooks/useNotifications';
import batchService from '../../../services/batchService';
import Spinner from '../../Spinner/Spinner';
import TableHeader from '../../Table/TableHeader/TableHeader';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';
import GenericFormattedDate from '../../GenericFormattedDate/GenericFormattedDate';

import './BatchTests.scss';
import NoData from '../../NoData/NoData';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';

interface Props {
  batchId: string;

  onClose(): void;
}

const columns = [
  { name: '', key: 'batchList.batchTests.table.header.description' },
  { name: '', key: 'batchList.batchTests.table.header.method' },
  { name: '', key: 'batchList.batchTests.table.header.value' },
  { name: '', key: 'batchList.batchTests.table.header.specification' },
  { name: '', key: 'batchList.batchTests.table.header.testedAt' },
];

const BatchTests: FC<Props> = ({ batchId, onClose }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: batchTests, isLoading: isLoadingTests } = useQuery(
    ['batchTests', batchId],
    () => batchService.listQualityTests(batchId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.batchTests.notification.title' }),
          intl.formatMessage({ id: 'batchList.batchTests.notification.unableLoadParts' }),
        ),
    },
  );

  return (
    <>
      <Modal show centered onHide={onClose} scrollable className="scroll-outside" size="xl">
        <Modal.Header>
          <div className="d-flex gap-5 align-items-center">
            <span className="text-uppercase font-bold d-flex">
              <FormattedMessage id="batchList.batchTests.title" />
            </span>
            <span className="text-color-gray-l6 size-label">
              <FormattedMessage
                id="batchList.batchTests.total"
                values={{ events: batchTests?.total || 0, total: batchTests?.totalTests || 0 }}
              />
            </span>
          </div>
          <div className="d-flex gap-5 align-items-center">
            <Button onClick={onClose} size="sm">
              <FormattedMessage id="batchList.batchTests.action.close" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0 batch-tests-component">
          <div className="modal-body-content bg-color-gray-l2 h-100">
            {batchTests?.total && batchTests.total > 0 ? (
              <table className="w-100 crop-table">
                <TableHeader columns={columns} tableName="BatchTests" />
                <tbody className="animated-component-item">
                  {batchTests?.items.map((batchTest) => (
                    <tr key={batchTest.id}>
                      <td className="font-semi-bold">
                        <TitleTooltip
                          titleTooltip={batchTest.description}
                          title={batchTest.description}
                          titleClassName="inline-crop"
                        />
                      </td>
                      <td className="font-normal">
                        <TitleTooltip
                          titleTooltip={batchTest.method}
                          title={batchTest.method}
                          titleClassName="inline-crop"
                        />
                        <IconTooltip tooltip={batchTest.methodDescription} className="ms-1" />
                      </td>
                      <td className="font-semi-bold text-color-error">
                        {batchTest.value} {batchTest.unit}
                      </td>
                      <td className="font-normal">
                        <TitleTooltip
                          titleTooltip={batchTest.specification}
                          title={batchTest.specification}
                          titleClassName="inline-crop"
                        />
                      </td>
                      <td className="font-normal">
                        <GenericFormattedDate withTime value={batchTest.testedAt} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}
            {!isLoadingTests && !batchTests?.total && <NoData />}
          </div>
        </Modal.Body>
        <Modal.Footer className="p-2" />
      </Modal>
      {isLoadingTests && <Spinner />}
    </>
  );
};

export default BatchTests;
