const flattenMessages = (
  nestedMessages: Record<string, string | Record<string, string>>,
  prefix = '',
): Record<string, string> => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      return {
        ...messages,
        [prefixedKey]: value,
      };
    }

    return {
      ...messages,
      ...flattenMessages(value, prefixedKey),
    };
  }, {});
};

class LocaleService {
  constructor(private readonly defaultLocale: string, private readonly availableLocales: string[]) {}

  public async getMessages(lang: string) {
    const locale = this.availableLocales.includes(lang) ? lang : this.defaultLocale;
    let messages = {};
    try {
      const res: { default: Record<string, string> } = await import(`../locales/${locale}.ts`);

      messages = flattenMessages(res.default);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    return messages;
  }
}

export default new LocaleService('en', ['en']);
