import { OrderBy } from '../models/SortOrder';
import request from './requestService';
import PagingInfo from '../models/PagingInfo';
import ListUpdateSortColumn from '../models/Update/ListUpdateSortColumn';
import UpdateList from '../models/Update/UpdateList';

class UpdateService {
  private readonly BaseUrl = `/updates`;

  public async list(
    companyId?: string,
    productId?: string,
    paging?: PagingInfo,
    orderBy?: OrderBy<ListUpdateSortColumn>,
  ): Promise<UpdateList> {
    const params = {
      companyId,
      productId,
      ...paging,
      orderBy,
    };

    const response = await request.get(`${this.BaseUrl}/list`, {
      params,
    });
    return response.data;
  }
}

const updateService = new UpdateService();

export default updateService;
