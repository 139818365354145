import React, { useEffect, FC } from 'react';
import { Modal } from 'react-bootstrap';
import './NotificationItem.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/pro-regular-svg-icons';

const DEFAULT_TIMEOUT = 5; // in seconds

const NotificationItem: FC<INotification> = ({
  exclamation,
  title,
  message,
  success,
  delay = DEFAULT_TIMEOUT,
  hint,
  callback,
}) => {
  const runCallback = () => {
    if (callback) {
      callback();
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      runCallback();
    }, delay * 1000);
    return () => clearTimeout(timer);
  }, []);

  const onHideHandler = () => {
    runCallback();
  };

  return (
    <Modal
      className={`notification-item-component ${success ? 'success' : 'fault'}`}
      show
      centered
      animation={false}
      onHide={onHideHandler}
      data-test-id="notification"
    >
      <Modal.Body className="text-center p-3">
        <div className="text-color-gray-l6 text-uppercase" data-test-id="notification-title">
          {title}
        </div>
        <FontAwesomeIcon
          icon={success ? faCircleCheck : faCircleExclamation}
          size="4x"
          className={`py-4 ${success ? 'text-color-primary-green' : 'text-color-error'}`}
          data-test-id="notification-icon"
        />
        {exclamation && (
          <div className="message" data-test-id="notification-exclamation">
            {exclamation}
          </div>
        )}
        <div className="message" data-test-id="notification-message">
          {message}
        </div>
        {hint && (
          <div className="hint" data-test-id="notification-hint">
            {hint}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

interface INotification {
  success: boolean;
  title: string;
  exclamation?: string;
  message: string;
  hint?: string;
  delay?: number;
  callback?: () => void;
}

export default NotificationItem;
