import request from './requestService';
import SynthesisStepList from '../models/SynthesisStep/SynthesisStepList';
import SynthesisStepTrackers from '../models/SynthesisStep/SynthesisStepTrackers';
import SynthesisStepDetails from '../models/SynthesisStep/SynthesisStepDetails';
import PagingInfo from '../models/PagingInfo';
import ManufacturerSynthesisStepListSortColumn from '../models/SynthesisStep/ManufacturerSynthesisStepSortColumn';
import { OrderBy } from '../models/SortOrder';
import ManufacturerSynthesisStepList from '../models/SynthesisStep/ManufacturerSynthesisStepList';

class SynthesisStepService {
  private readonly BaseUrl = `/SynthesisStep`;

  private readonly ManufacturerBaseUrl = `/Manufacturer/SynthesisStep`;

  public async list(productId: string, companyId?: string): Promise<SynthesisStepList> {
    const params = {
      productId,
      companyId,
    };

    const response = await request.get(`${this.BaseUrl}/List`, {
      params,
    });
    return response.data;
  }

  public async trackers(id: string): Promise<SynthesisStepTrackers> {
    const response = await request.get(`${this.BaseUrl}/Trackers/?synthesisStepId=${id}`);
    return response.data;
  }

  public async getDetails(synthesisStepId: string): Promise<SynthesisStepDetails> {
    const params = {
      synthesisStepId,
    };
    const response = await request.get(`${this.BaseUrl}/GetDetails`, { params });
    return response.data;
  }

  public async manufacturerList(params: {
    productId?: string;
    paging?: PagingInfo;
    orderBy?: OrderBy<ManufacturerSynthesisStepListSortColumn>;
    searchTerm?: string;
  }): Promise<ManufacturerSynthesisStepList> {
    const response = await request.get(`${this.ManufacturerBaseUrl}/List`, {
      params: {
        ...params.paging,
        productId: params.productId,
        orderBy: params.orderBy,
        searchTerm: params.searchTerm,
      },
    });
    return response.data;
  }
}

export default new SynthesisStepService();
