import React, { useState } from 'react';

import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, NavLink } from 'react-router-dom';
import { faUsers, faIndustryWindows, faFolder, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import companiesHelper from '../../../helpers/companiesHelper';
import productsHelper from '../../../helpers/productsHelper';
import usersHelper from '../../../helpers/usersHelper';

import AddEditUser from '../Users/AddEditUser/AddEditUser';
import AddEditCompany from '../Companies/AddEditCompany/AddEditCompany';
import DashboardHeader from '../../../components/DashboardHeader/DashboardHeader';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';
import ValueTracker from '../../../components/trackers/ValueTracker/ValueTracker';
import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import platformService from '../../../services/platformService';
import useNotifications from '../../../hooks/useNotifications';
import ProductUpdatesMini from '../../../components/ProductUpdates/ProductUpdatesMini/ProductUpdatesMini';
import ProductAlertsMini from '../../../components/ProductAlerts/ProductAlertsMini/ProductAlertsMini';
import AlertsStatistics from '../../../components/AlertsStatistics/AlertsStatistics';
import AdminDashboardTrackersSkeleton from './DashboardTrackersSkeleton';
import UpdatedAtSkeleton from '../../../components/Skeletons/UpdatedAtSkeleton';

const Dashboard = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const [isAddUser, setIsAddUser] = useState<boolean>(false);
  const [isAddCompany, setIsAddCompany] = useState<boolean>(false);

  const { data: statistics, isLoading: isLoadingStatistics } = useQuery(
    ['platformStatistics'],
    () => platformService.getStatistics(),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.dashboard.notification.title' }),
          intl.formatMessage({ id: 'admin.dashboard.notification.unableLoadStatistics' }),
        ),
    },
  );

  return (
    <>
      <DashboardHeader>
        <Dropdown className="create">
          <Dropdown.Toggle variant="primary" className="no-drop btn">
            <FontAwesomeIcon icon={faPlus} className="pe-3" />
            <FormattedMessage id="admin.dashboard.action.create" />
          </Dropdown.Toggle>
          <Dropdown.Menu className="bg-color-gray-l3">
            <Dropdown.Item className="text-uppercase" onClick={() => setIsAddCompany(true)} as="button">
              <FontAwesomeIcon icon={faIndustryWindows} className="pe-3" />
              <FormattedMessage id="admin.dashboard.action.addCompany" />
            </Dropdown.Item>
            <Dropdown.Item className="text-uppercase" onClick={() => setIsAddUser(true)} as="button">
              <FontAwesomeIcon icon={faUsers} className="pe-3" />
              <FormattedMessage id="admin.dashboard.action.addUser" />
            </Dropdown.Item>
            <Dropdown.Item className="text-uppercase" to={productsHelper.getProductAddUrl()} as={NavLink}>
              <FontAwesomeIcon icon={faFolder} className="pe-3" />
              <FormattedMessage id="admin.dashboard.action.addProduct" />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </DashboardHeader>

      <div className="page-content px-5">
        <div className="card mb-4 p-3 overflow-visible" data-test-id="platform-statistic">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="font-bold text-uppercase" data-test-id="platform-statistic-title">
              <FormattedMessage id="admin.dashboard.platformStatistics.title" />
            </div>
            {isLoadingStatistics && <UpdatedAtSkeleton />}
            {statistics?.updatedAt && (
              <div className="size-caption text-color-gray-l6" data-test-id="platform-statistic-updatedAt">
                <FormattedMessage id="admin.dashboard.platformStatistics.updatedAt" />{' '}
                <GenericFormattedDate value={statistics?.updatedAt} />
              </div>
            )}
          </div>
          {isLoadingStatistics ? (
            <AdminDashboardTrackersSkeleton />
          ) : (
            <div className="animated-component-item d-flex gap-3 chart-row">
              <div className="flex-1">
                <ValueTracker
                  title={intl.formatMessage({ id: 'admin.dashboard.platformStatistics.trackers.totalProducts' })}
                  titleClassName="text-uppercase"
                  value={numberHelper.formatNumber(statistics?.totalProducts).toString()}
                  variant={trackerHelper.getTypeForPositive(statistics?.totalProducts)}
                  hint={
                    <Link to={productsHelper.adminProductsUrl} className="link link--secondary size-caption">
                      <FormattedMessage id="admin.dashboard.platformStatistics.action.showMore" />
                    </Link>
                  }
                />
              </div>
              <div className="flex-1">
                <ValueTracker
                  title={intl.formatMessage({ id: 'admin.dashboard.platformStatistics.trackers.totalCompanies' })}
                  titleClassName="text-uppercase"
                  value={numberHelper.formatNumber(statistics?.totalCompanies).toString()}
                  variant={trackerHelper.getTypeForPositive(statistics?.totalCompanies)}
                  hint={
                    <Link to={companiesHelper.companiesBaseUrl} className="link link--secondary size-caption">
                      <FormattedMessage id="admin.dashboard.platformStatistics.action.showMore" />
                    </Link>
                  }
                />
              </div>
              <div className="flex-1">
                <ValueTracker
                  title={intl.formatMessage({ id: 'admin.dashboard.platformStatistics.trackers.activeUsers' })}
                  titleClassName="text-uppercase"
                  value={numberHelper.formatNumber(statistics?.activeUsers).toString()}
                  variant={trackerHelper.getTypeForPositive(statistics?.activeUsers)}
                  hint={
                    <Link to={usersHelper.usersBaseUrl} className="link link--secondary size-caption">
                      <FormattedMessage id="admin.dashboard.platformStatistics.action.showMore" />
                    </Link>
                  }
                />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex gap-4 mb-4">
          <div className="flex-2">
            <ProductAlertsMini />
          </div>
          <div className="flex-1 card">
            <AlertsStatistics size={7} />
          </div>
        </div>
        <div className="d-flex">
          <ProductUpdatesMini />
        </div>
      </div>
      {isAddUser && <AddEditUser userId={null} onClose={() => setIsAddUser(false)} />}
      {isAddCompany && <AddEditCompany companyId={null} onClose={() => setIsAddCompany(false)} />}
    </>
  );
};

export default Dashboard;
