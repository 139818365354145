import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import cn from 'classnames';
import numberHelper from '../../helpers/numberHelper';
import trackerHelper from '../../helpers/trackerHelper';
import SynthesisStep from '../../models/SynthesisStep/SynthesisStep';
import TrackerVariant from '../../models/TrackerState';
import ProgressMini from '../Progress/ProgressMini/ProgressMini';
import InlineTracker from '../trackers/InlineTracker/InlineTracker';
import productsHelper from '../../helpers/productsHelper';
import ProblemTrackerFilter from '../../models/ProblemTrackerFilter';
import TrackerStatus from '../../models/TrackerStatus';

import './ProductStep.scss';

interface Props {
  step: SynthesisStep;
  highlighted?: boolean;
}

const ProductStep: FC<Props> = ({ step, highlighted }) => {
  const intl = useIntl();
  return (
    <div key={step.id} className="product-step-component col-6 gx-4" data-test-id="productsOverview-status-step">
      <div className={cn('card mb-4 p-3', { highlighted })}>
        <div className="row pb-3">
          <div className="col">
            <span
              className="font-bold text-color-white text-uppercase"
              data-test-id="productsOverview-status-step-name"
            >
              {step.name}
            </span>
            <span className="ms-3 size-label text-color-gray-l6">
              <FormattedMessage id="productStep.phaseName" />{' '}
              <span data-test-id="productsOverview-status-step-phaseName">{step.phaseName}</span>
            </span>
          </div>
          <div className="col-auto">
            <ProgressMini value={step.progress} />
          </div>
        </div>
        <div className="gap-2 d-flex">
          <div className="col">
            <InlineTracker
              type="value"
              variant={trackerHelper.getTypeForValue(step.outOfRange)}
              label={intl.formatMessage({ id: 'tracker.outOfRange.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfRange.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.synthesisStep' })}
              value={numberHelper.formatNumber(step.outOfRange).toString()}
              unitLabel="%"
              dataTestId="productsOverview-status-step-oor"
              link={
                step.outOfRange && step.outOfRange > 0
                  ? productsHelper.getBatchesUrl({
                      productId: step.productId,
                      synthesisStepId: step.id,
                      problemTracker: ProblemTrackerFilter.OutOfRange,
                    })
                  : undefined
              }
            />
          </div>
          <div className="col">
            <InlineTracker
              type="value"
              variant={trackerHelper.getTypeForValue(step.outOfSpecificationNumerator)}
              label={intl.formatMessage({ id: 'tracker.outOfSpecification.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.synthesisStep' })}
              value={`${step.outOfSpecificationNumerator || 0}/${step.outOfSpecificationDenominator || 0}`}
              dataTestId="productsOverview-status-step-oos"
              link={
                step.outOfSpecificationNumerator && step.outOfSpecificationNumerator > 0
                  ? productsHelper.getBatchesUrl({
                      productId: step.productId,
                      synthesisStepId: step.id,
                      problemTracker: ProblemTrackerFilter.OutOfSpecification,
                    })
                  : undefined
              }
            />
          </div>
          <div className="col">
            <InlineTracker
              type="value"
              variant={trackerHelper.getTypeForTrackerStatus(step.outOfTimeStatus, TrackerVariant.positive)}
              label={intl.formatMessage({ id: 'tracker.outOfTime.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfTime.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.synthesisStep' })}
              value={numberHelper.formatNumber(step.outOfTime).toString()}
              unitLabel={intl.formatMessage({ id: 'tracker.outOfTime.unitDay' }, { days: step.outOfTime })}
              spaceBeforeUnit
              dataTestId="productsOverview-status-step-oot"
              link={
                step.outOfTimeStatus && step.outOfTimeStatus !== TrackerStatus.Normal
                  ? productsHelper.getBatchesUrl({
                      productId: step.productId,
                      synthesisStepId: step.id,
                      problemTracker: ProblemTrackerFilter.OutOfTime,
                    })
                  : undefined
              }
            />
          </div>
          <div className="col">
            <InlineTracker
              type="value"
              variant={trackerHelper.getTypeForValue(step.emittedGreenHouseGases)}
              label={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.title' })}
              value={numberHelper.formatNumber(step.emittedGreenHouseGases)?.toString()}
              unitLabel={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.unit' })}
              spaceBeforeUnit
              dataTestId="productsOverview-status-step-emission"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductStep;
