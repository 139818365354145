import React, { useRef, ChangeEventHandler, ChangeEvent, useState, FC, useMemo } from 'react';

import './SetPassword.scss';

import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { Button, Form, InputGroup } from 'react-bootstrap';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import passwordValidation from '../../constants/passwordValidation';

const claimKeys = ['lowercaseChar', 'uppercaseChar', 'numberChar', 'specialChar', 'minLength'] as const;

type Claims = (typeof claimKeys)[number];
type PassClaimsRecord<T> = Record<Claims, T>;
type PassClaimsState = PassClaimsRecord<boolean>;
type PasswordDescription = PassClaimsRecord<string>;
type PasswordValidators = PassClaimsRecord<(str: string) => boolean>;
type FormData = { password: string; confirm: string };

const passValidators: PasswordValidators = {
  lowercaseChar: (str: string) => str.match(passwordValidation.lowercaseChar) !== null,
  uppercaseChar: (str: string) => str.match(passwordValidation.uppercaseChar) !== null,
  numberChar: (str: string) => str.match(passwordValidation.numberChar) !== null,
  specialChar: (str: string) => str.match(passwordValidation.specialChar) !== null,
  minLength: (str: string) => str.length >= passwordValidation.minLength,
};

const initialState: PassClaimsState = {
  lowercaseChar: false,
  uppercaseChar: false,
  numberChar: false,
  specialChar: false,
  minLength: false,
};

const SetPassword: FC<ISetPasswordProps> = ({ onSubmit, btnTitle }) => {
  const intl = useIntl();

  const passClaimsLabel: Readonly<PasswordDescription> = useMemo(() => {
    return {
      lowercaseChar: intl.formatMessage({ id: 'setPassword.rule.lowercaseChar' }),
      uppercaseChar: intl.formatMessage({ id: 'setPassword.rule.uppercaseChar' }),
      numberChar: intl.formatMessage({ id: 'setPassword.rule.numberChar' }),
      specialChar: intl.formatMessage({ id: 'setPassword.rule.specialChar' }),
      minLength: intl.formatMessage({ id: 'setPassword.rule.minLength' }),
    };
  }, [intl.locale]);

  const [isValidClaim, setIsValidClaim] = useState<PassClaimsState>(initialState);
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FormData>({});
  const password = useRef({});
  password.current = watch('password', '');

  const onInputChangeHandler: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>): void => {
    const field = e.currentTarget;

    const validState: PassClaimsState = claimKeys.reduce((state, claim: Claims) => {
      return {
        ...state,
        [claim]: passValidators[claim](field.value),
      };
    }, {} as PassClaimsState);

    setIsValidClaim(validState);
  };

  const onSubmitHandler = (data: FormData) => {
    onSubmit(data.password);
  };

  const matchPasswordRequirements = (): boolean => {
    return !claimKeys.some((claim: Claims) => !isValidClaim[claim]);
  };

  return (
    <form
      className="set-password-component d-flex flex-column m-auto pt-5"
      noValidate
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      <div className="form-container">
        <div data-test-id="setPassword-errors">
          {errors.password?.message && <ErrorMessage message={errors.password.message} />}
          {errors.password && errors.password.type === 'validate' && (
            <ErrorMessage message={intl.formatMessage({ id: 'validation.password' })} />
          )}
          {errors.confirm?.message && <ErrorMessage message={errors.confirm.message} />}
        </div>
        <div className="d-flex flex-row align-self-center">
          <Form.Group className="mb-3 me-4 w-50" controlId="password">
            <Form.Label>
              <FormattedMessage id="setPassword.form.password.label" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="password"
                size="lg"
                {...register('password', {
                  onChange: onInputChangeHandler,
                  required: intl.formatMessage({ id: 'validation.required' }),
                  validate: matchPasswordRequirements,
                })}
                data-test-id="setPassword-password"
              />
              <InputGroup.Text>
                <FontAwesomeIcon icon={faLockKeyhole} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3 w-50" controlId="confirm">
            <Form.Label>
              <FormattedMessage id="setPassword.form.confirmPassword.label" />
            </Form.Label>
            <InputGroup>
              <Form.Control
                type="password"
                size="lg"
                {...register('confirm', {
                  validate: (value) =>
                    value === password.current || intl.formatMessage({ id: 'validation.confirmPassword' }),
                })}
                data-test-id="setPassword-confirmPassword"
              />
              <InputGroup.Text>
                <FontAwesomeIcon icon={faLockKeyhole} />
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </div>
        <div className="d-flex flex-wrap password-hints">
          {claimKeys.map((claim: Claims) => (
            <div
              key={claim}
              className="claim-hint-box size-label d-flex align-items-center"
              data-test-id={`setPassword-claim-${claim}`}
            >
              <div className={`claim-hint me-2 ${isValidClaim[claim] ? 'match' : ''}`} />
              <span>{passClaimsLabel[claim]}</span>
            </div>
          ))}
        </div>
      </div>

      <Button
        variant="primary"
        type="submit"
        className="m-auto mt-5 btn btn-primary wide-btn btn-rounded"
        data-test-id="setPassword-submit"
      >
        {btnTitle}
      </Button>
    </form>
  );
};

interface ISetPasswordProps {
  btnTitle: string;

  onSubmit(password: string): void;
}

export default SetPassword;
