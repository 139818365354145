import React, { FC } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useIntl } from 'react-intl';

interface Props {
  onChange: (startDate: Date | null, endDate: Date | null) => void;
  dateFrom: Date | null;
  dateTo: Date | null;
}

const DateRange: FC<Props> = ({ onChange, dateFrom, dateTo }) => {
  const { formatMessage: fm, locale } = useIntl();

  return (
    <div className="d-flex">
      <div className="d-flex justify-content-between me-3">
        <div className="size-body d-flex align-items-center me-3">{fm({ id: 'dateRange.title.from' })}:</div>
        <DatePicker
          isClearable
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          locale={locale}
          timeFormat="HH:mm"
          filterDate={(d) => new Date() > d}
          placeholderText={fm({ id: 'dateRange.placeHolder.start' })}
          dateFormat="dd.MM.yyyy HH:mm"
          selected={dateFrom}
          onChange={(date: Date) => onChange(date, dateTo)}
          selectsStart
          startDate={dateFrom}
          endDate={dateTo}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div className="size-body d-flex align-items-center me-3">{fm({ id: 'dateRange.title.to' })}:</div>
        <DatePicker
          isClearable
          showMonthDropdown
          showYearDropdown
          showTimeSelect
          locale={locale}
          timeFormat="HH:mm"
          filterDate={(d) => new Date() > d}
          placeholderText={fm({ id: 'dateRange.placeHolder.end' })}
          dateFormat="dd.MM.yyyy HH:mm"
          selected={dateTo}
          onChange={(date: Date) => onChange(dateFrom, date)}
          selectsEnd
          startDate={dateFrom}
          endDate={dateTo}
          minDate={dateFrom}
        />
      </div>
    </div>
  );
};
export default DateRange;
