import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import UsersTableSkeleton from '../../../../components/UsersTable/UsersTableSkeleton';
import UsersTable from '../../../../components/UsersTable/UsersTable';
import usePaginatedUsers from '../../../../hooks/admin/users/usePaginatedUsers';
import useNotifications from '../../../../hooks/useNotifications';
import ListUsersSortColumn from '../../../../models/User/ListUsersSortColumn';
import AddEditUser from '../../Users/AddEditUser/AddEditUser';
import useSortingViewState from '../../../../hooks/useSortingViewState';
import Select from '../../../../components/Select/Select';
import useUserRoleOptions from '../../../../hooks/useUserRoleOptions';
import useQueryStringState from '../../../../hooks/useQueryStringState';
import NoData from '../../../../components/NoData/NoData';

const pageSize = 20;

const CompanyUsersTab: React.FC = () => {
  const { id: companyId } = useParams<'id'>();
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const [isAdd, setIsAdd] = useState(false);
  const [viewState, setViewState] = useQueryStringState<{ roleId?: string } | undefined>();

  const {
    data: usersData,
    isLoading: isUsersLoading,
    fetchNextPage,
    hasNextPage,
  } = usePaginatedUsers(
    pageSize,
    companyId,
    getSortOrder<ListUsersSortColumn>('fullName', 'Users'),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'admin.companyOverview.tab.users.notification.title' }),
          fm({ id: 'admin.companyOverview.tab.users.notification.unableToLoadUsers' }),
        ),
    },
    viewState?.search?.roleId,
  );

  const { roleOptions } = useUserRoleOptions();
  const setRoleId = (id: string) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        roleId: id === '' ? undefined : id,
      },
    });
  };

  return (
    <>
      <div className="filters-container pb-4">
        <Form.Group className="d-flex justify-content-between">
          <span>
            <Select
              value={viewState?.search?.roleId || ''}
              onChange={setRoleId}
              options={roleOptions}
              size="lg"
              dataTestId="users-rolesFilter"
            />
          </span>
        </Form.Group>
        <Button
          className="btn-link-underline btn-link-underline-create"
          variant="link"
          onClick={() => setIsAdd(true)}
          data-test-id="users-add"
        >
          <FormattedMessage id="admin.companyOverview.tab.users.action.add" />
        </Button>
      </div>
      {usersData?.pages?.[0]?.users?.length === 0 && <NoData />}
      {isUsersLoading && <UsersTableSkeleton cards={8} showCompanyName={false} />}
      {!!usersData?.pages?.[0]?.users?.length && (
        <div className="mb-4 shadow-2">
          <InfiniteScroll
            className="rounded-bottom"
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            dataLength={usersData.pages.length}
            scrollableTarget="scrollContainer"
          >
            <UsersTable
              users={usersData.pages.flatMap((x) => x.users)}
              totalUsers={usersData.pages[0].total}
              companyId={companyId}
            />
          </InfiniteScroll>
        </div>
      )}
      {isAdd && <AddEditUser userId={null} defaultCompanyId={companyId} onClose={() => setIsAdd(false)} />}
    </>
  );
};

export default CompanyUsersTab;
