import React, { FC, useEffect, useMemo, useRef } from 'react';
import { Accordion } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import useQueryStringState from '../../../hooks/useQueryStringState';
import BatchItem from '../../../models/Batch/BatchItem';
import EntityTypes from '../../../models/Report/EntityTypes';
import ReportData from '../../../models/Report/ReportData';
import TrackersTabs from '../../../models/Tabs/TrackersTabs';
import TrackerVariant from '../../../models/TrackerState';
import AccordionItem from '../../Accordion/AccordionToggle/AccordionItem/AccordionItem';
import AccordionLazyContent from '../../Accordion/AccordionToggle/AccordionLazy/AccordionLazyContent';
import BatchTrackers from '../../BatchTrackers/BatchTrackers';
import ProgressMini from '../../Progress/ProgressMini/ProgressMini';
import ReportButton from '../../Report/ReportButton';
import InlineTracker from '../../trackers/InlineTracker/InlineTracker';
import BatchParts from '../BatchParts/BatchParts';
import BatchToggleButton from './BatchToggleButton';

import './BatchRow.scss';

interface Props {
  batch: BatchItem;
  onToggle: (batchId: string) => void;
  onSelectPart: (batchId: string, partId: string | undefined) => void;
  showOor: (batchId: string) => void;
  showOos: (batchId: string) => void;
  isScrollIntoView?: boolean;
}

const BatchRow: FC<Props> = ({ batch, onToggle, onSelectPart, isScrollIntoView, showOor, showOos }) => {
  const intl = useIntl();
  const reportData: ReportData = useMemo(() => {
    return {
      productName: batch.productName,
      synthesisStepName: batch.synthesisStepName,
      campaignName: batch.campaignName,
      batchName: batch.name,
      entityType: EntityTypes.batch,
      entityId: batch.id,
    };
  }, [batch]);

  const [viewState, setViewState] = useQueryStringState<{
    batches?: { [batchId: string]: { tab?: string } | undefined };
  }>();
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isScrollIntoView) {
      ref.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, []);

  const handleTabChange = (tab: TrackersTabs | undefined) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        batches: {
          ...viewState.search?.batches,
          [batch.id]: { ...viewState.search?.batches?.[batch.id], tab: tab?.toString() },
        },
      },
    });
  };

  return (
    <AccordionItem eventKey={batch.id}>
      <div
        ref={ref}
        className="batch-row-component animated-component-item d-flex justify-content-between align-items-center gap-4 p-2 px-3 header card-divider gx-3 m-0 bg-color-gray-l4"
      >
        <div className="d-flex size-label font-semi-bold flex-1">{batch.name}</div>
        <div className="d-flex flex-1">
          <ProgressMini value={batch.progress} />
        </div>
        <div className="d-flex gap-3 flex-4">
          <div className="w-100">
            <InlineTracker
              type="background"
              variant={trackerHelper.getTypeForValue(batch.outOfRangeNumerator)}
              label={intl.formatMessage({ id: 'tracker.outOfRange.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfRange.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.batch' })}
              value={`${batch.outOfRangeNumerator || 0}/${batch.outOfRangeDenominator || 0}`}
              onClick={batch.outOfRangeNumerator && batch.outOfRangeNumerator > 0 ? () => showOor(batch.id) : undefined}
            />
          </div>
          <div className="w-100">
            <InlineTracker
              type="background"
              variant={trackerHelper.getTypeForFlag(batch.outOfSpecification)}
              label={intl.formatMessage({ id: 'tracker.outOfSpecification.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.batch' })}
              value={
                batch.outOfSpecification
                  ? intl.formatMessage({ id: 'tracker.flag.true' })
                  : intl.formatMessage({ id: 'tracker.flag.false' })
              }
              onClick={batch.outOfSpecification ? () => showOos(batch.id) : undefined}
            />
          </div>
          <div className="w-100">
            <InlineTracker
              type="background"
              variant={trackerHelper.getTypeForTrackerStatus(batch.outOfTimeStatus, TrackerVariant.positive)}
              label={intl.formatMessage({ id: 'tracker.outOfTime.shortTitle' })}
              labelTooltip={intl.formatMessage({ id: 'tracker.outOfTime.title' })}
              tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.batch' })}
              value={numberHelper.formatNumber(batch.outOfTime)?.toString()}
              unitLabel={intl.formatMessage({ id: 'tracker.outOfTime.unitHour' }, { hours: batch.outOfTime })}
              spaceBeforeUnit
            />
          </div>
        </div>
        <div className="d-flex size-label font-normal flex-1">{batch.masterBatchRecordRootName}</div>
        <div className="d-flex justify-content-between">
          <ReportButton reportData={reportData} className="me-3 action-button" />
          <BatchToggleButton eventKey={batch.id} callback={onToggle} className="action-button" />
        </div>
      </div>
      <Accordion.Collapse eventKey={batch.id}>
        <AccordionLazyContent eventKey={batch.id}>
          <BatchParts batchId={batch.id} onSelectPart={onSelectPart} />
          <BatchTrackers
            batchId={batch.id}
            activeTab={viewState?.search?.batches?.[batch.id]?.tab}
            onTabChange={handleTabChange}
          />
        </AccordionLazyContent>
      </Accordion.Collapse>
    </AccordionItem>
  );
};

export default BatchRow;
