import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import CircleValueTracker from '../../../trackers/CircleValueTracker/CircleValueTracker';
import ValueTracker from '../../../trackers/ValueTracker/ValueTracker';
import numberHelper from '../../../../helpers/numberHelper';
import trackerHelper from '../../../../helpers/trackerHelper';
import CampaignTrackers from '../../../../models/Campaign/CampaignTrackers';
import YieldRange from '../../../trackers/YieldRange/YieldRange';

const PerformanceTab: FC<Props> = ({ trackers }) => {
  const intl = useIntl();
  return (
    <div className="row g-3">
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.weight)}
          title={intl.formatMessage({ id: 'tracker.weight.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.weight.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.weight)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.weight.unit' })}
        />
      </div>
      <div className="col-6 chart-row">
        <YieldRange
          value={trackers?.yieldRange}
          goldenBatch={trackers?.percentageGoldenBatchYield}
          yieldValue={trackers?.yield}
          targetYield={trackers?.percentageTargetYield}
          tooltip={intl.formatMessage({ id: 'tracker.yieldDistribution.tooltips.campaign' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.percentageGoldenBatchYield)}
          title={intl.formatMessage({ id: 'tracker.percentageGoldenBatchYield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.percentageGoldenBatchYield.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.percentageGoldenBatchYield)?.toString()}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.productionRate)}
          title={intl.formatMessage({ id: 'tracker.productionRate.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.productionRate.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.productionRate)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.productionRate.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.spaceTimeYield)}
          title={intl.formatMessage({ id: 'tracker.spaceTimeYield.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.spaceTimeYield.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.spaceTimeYield)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.spaceTimeYield.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.plantHourConsumption)}
          title={intl.formatMessage({ id: 'tracker.plantHourConsumption.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.plantHourConsumption.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.plantHourConsumption)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.plantHourConsumption.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <CircleValueTracker
          variant={trackerHelper.getTypeForProgress(trackers?.utilizedCapacity)}
          title={intl.formatMessage({ id: 'tracker.utilizedCapacity.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.utilizedCapacity.tooltips.campaign' })}
          value={trackers?.utilizedCapacity}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.cycleTime)}
          title={intl.formatMessage({ id: 'tracker.cycleTime.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.cycleTime.tooltips.campaign' })}
          value={numberHelper.formatNumber(trackers?.cycleTime)?.toString()}
          hint={intl.formatMessage(
            { id: 'tracker.cycleTime.unit' },
            { hours: numberHelper.formatNumber(trackers?.cycleTime) },
          )}
        />
      </div>
    </div>
  );
};

interface Props {
  trackers?: CampaignTrackers;
}

export default PerformanceTab;
