import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import trackerHelper from '../../../helpers/trackerHelper';
import ValueTrackerWithDetails from '../ValueTrackerWithDetails/ValueTrackerWithDetails';

const OutOfSpecification: FC<Props> = ({ numerator, denominator, tooltip, link }) => {
  const intl = useIntl();

  return (
    <ValueTrackerWithDetails
      title={intl.formatMessage({ id: 'tracker.outOfSpecification.shortTitle' })}
      titleTooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.title' })}
      value={`${numerator || 0}/${denominator || 0}`}
      variant={trackerHelper.getTypeForValue(numerator)}
      tooltip={tooltip}
      dataTestId="outOfSpecification-component"
      link={(numerator || 0) > 0 ? link : undefined}
    />
  );
};

type Props = {
  numerator?: number | null;
  denominator?: number | null;
  tooltip?: string;
  link: string;
};

export default OutOfSpecification;
