import { useInfiniteQuery } from 'react-query';
import SortOrder from '../../../models/SortOrder';
import ListUsersSortColumn from '../../../models/User/ListUsersSortColumn';
import userService from '../../../services/userService';

const queryKey = 'users';

const usePaginatedUsers = (
  pageSize: number,
  companyId?: string,
  orderBy?: SortOrder<ListUsersSortColumn>,
  options?: { onError: () => void },
  roleId?: string,
) => {
  return useInfiniteQuery(
    [queryKey, companyId, orderBy, roleId],
    async ({ pageParam = 1 }) => {
      const data = await userService.list({
        companyId,
        paging: { pageSize, pageNumber: pageParam },
        orderBy: orderBy?.toString(),
        roleId,
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: options?.onError,
    },
  );
};

export default usePaginatedUsers;
