import { useAppContext } from '../context/useAppContext';

const useNotifications = () => {
  const { dispatch: appDispatch } = useAppContext();

  const notify = (
    type: 'error' | 'success' | 'info',
    title: string,
    message: string,
    hint?: string,
    callback?: () => void,
  ) => {
    appDispatch({
      type: 'SHOW_NOTIFICATION',
      payload: {
        notification: {
          type,
          title,
          message,
          hint,
          callback,
        },
      },
    });
  };

  const notifySuccess = (title: string, message: string, hint?: string, callback?: () => void) =>
    notify('success', title, message, hint, callback);

  const notifyError = (title: string, message: string, hint?: string, callback?: () => void) =>
    notify('error', title, message, hint, callback);

  const notifyInfo = (title: string, message: string, hint?: string, callback?: () => void) =>
    notify('info', title, message, hint, callback);

  return { notify, notifySuccess, notifyError, notifyInfo };
};

export default useNotifications;
