import qs from 'qs';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import productsHelper from '../../helpers/productsHelper';
import useAdminProductsViewState from '../../hooks/admin/products/useAdminProductsViewState';
import useNotifications from '../../hooks/useNotifications';
import ProductsViewState from '../../models/Product/ProductsViewState';

interface Props {
  companyId: string;
  fallback: string;
}

const AdminToUserProductsRoute: FC<Props> = ({ companyId, fallback }) => {
  const productsViewState = useAdminProductsViewState();
  const navigate = useNavigate();
  const { notify } = useNotifications();
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    if (productsViewState.companyId !== companyId) {
      notify(
        'error',
        fm({ id: 'adaptRoutes.notification.notFound.title' }),
        fm({ id: 'adaptRoutes.notification.notFound.description' }),
      );
      navigate(fallback, { replace: true });
      return;
    }

    const newState: ProductsViewState = {
      phaseId: productsViewState.phaseId,
      isActive: productsViewState.isActive?.toString() as 'true' | 'false',
    };

    navigate({
      pathname: productsHelper.getUserProductsUrl(companyId),
      search: qs.stringify(newState),
    });
  }, []);

  return null;
};

export default AdminToUserProductsRoute;
