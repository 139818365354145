import React, { FC } from 'react';

import './Alert.scss';

import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';

const Alert: FC<Props> = ({ title, targetName, message, hint, onClose }) => {
  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Modal className="alert-component" show centered backdrop="static" keyboard={false} data-test-id="confirm">
      <Modal.Body className="text-center">
        <div className="text-color-gray-l6 size-label text-uppercase mb-3" data-test-id="confirm-title">
          {title}
        </div>
        <div className="message pb-4" data-test-id="confirm-message">
          {message}
        </div>
        <h3 className="my-3">{targetName}</h3>
        <p className="hint pt-4">{hint}</p>
      </Modal.Body>
      <Button variant="primary" onClick={onCloseHandler}>
        <FormattedMessage id="alert.closeBtn.label" />
      </Button>
    </Modal>
  );
};

type Props = {
  title: string;
  targetName: string;
  message: string;
  hint: string;
  onClose: () => void;
};

export default Alert;
