import { useAppContext } from '../context/useAppContext';
import UserInfo from '../models/Account/UserInfo';

// use this hook under ProtectedRoute only, so the userInfo is never null
const useUserInfo = (): UserInfo => {
  const {
    state: { user },
  } = useAppContext();

  if (!user) {
    throw new Error('User info is not available, you may have used useUserInfo hook outside ProtectedRoute.');
  }

  return user!;
};

export default useUserInfo;
