import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import CampaignListSkeleton from './CampaignListSkeleton';
import useCampaignListViewState from './useCampaignListViewState';
import useProblemTrackerFilter from '../../hooks/useProblemTrackerFilter';
import Select from '../Select/Select';
import MultiSelect from '../Select/MultiSelect/MultiSelect';
import numberHelper from '../../helpers/numberHelper';
import CampaignItem from './CampaignItem';
import NoData from '../NoData/NoData';

interface Props {
  productId: string;
  companyId?: string;
  stepId?: string;
}

const CampaignList: FC<Props> = ({ companyId, productId, stepId }) => {
  const intl = useIntl();
  const { selectedTrackers, selectTrackers, options: problemTrackerOptions } = useProblemTrackerFilter();
  const {
    hash,
    isLoadingCampaigns,
    selectedSynthesisStep,
    handleSelectSynthesisStep,
    steps,
    selectedPhase,
    handleSelectPhase,
    phases,
    totalCampaings,
    totalSynthesisSteps,
    groupedCampaings,
  } = useCampaignListViewState(productId, selectedTrackers, companyId, stepId);

  return (
    <div className="campaign-list">
      <div className="d-flex gap-3 pb-4">
        {!stepId && (
          <Select
            value={selectedSynthesisStep}
            size="lg"
            onChange={handleSelectSynthesisStep}
            options={steps}
            placeholder={intl.formatMessage({ id: 'campaigns.filter.synthesisStep.placeholder' })}
          />
        )}
        <Select
          value={selectedPhase}
          size="lg"
          onChange={handleSelectPhase}
          options={phases}
          placeholder={intl.formatMessage({ id: 'campaigns.filter.phase.placeholder' })}
        />
        <MultiSelect
          value={selectedTrackers}
          options={problemTrackerOptions}
          onChange={selectTrackers}
          size="lg"
          placeholder={intl.formatMessage({ id: 'campaigns.filter.problemTrackers.empty' })}
        />
        <div className="ms-auto d-flex flex-column justify-content-between align-items-end">
          <div className="text-color-gray-l6 size-caption">
            <FormattedMessage id="campaigns.filter.total.synthesisSteps" values={{ value: totalSynthesisSteps }} />
          </div>
          <FormattedMessage id="campaigns.filter.total.campaigns" values={{ value: totalCampaings }} />
        </div>
      </div>
      {!isLoadingCampaigns &&
        groupedCampaings?.map((groupedCampaign) => (
          <div key={groupedCampaign.step.id} className="card mb-4">
            <div className="px-4 py-3 card-divider d-flex gap-3 align-items-center">
              <h3 className="mb-0 me-5" data-test-id="synthesisStep-item-name">
                {groupedCampaign.step.name}
              </h3>
              <div className="py-1 px-2 d-flex gap-2 bg-color-gray-l2 rounded-1 align-items-center">
                <div className="size-caption text-color-gray-l6">
                  <FormattedMessage id="campaigns.step.totalCampaigns" />
                </div>
                <div className="size-label">{numberHelper.formatNumber(groupedCampaign.step.totalCampaigns)}</div>
              </div>
              <div className="py-1 px-2 d-flex gap-2 bg-color-gray-l2 rounded-1 align-items-center">
                <div className="size-caption text-color-gray-l6">
                  <FormattedMessage id="campaigns.step.totalBatches" />
                </div>
                <div className="size-label">{numberHelper.formatNumber(groupedCampaign.step.totalBatches)}</div>
              </div>
            </div>
            <div className="px-4 py-3 d-flex flex-column gap-3 ">
              {groupedCampaign.campaigns.map((campaign) => (
                <CampaignItem
                  key={campaign.id}
                  campaign={campaign}
                  productId={productId}
                  isScrollIntoView={hash === `campaign-${campaign.id}`}
                />
              ))}
            </div>
          </div>
        ))}
      {isLoadingCampaigns && <CampaignListSkeleton />}
      {groupedCampaings?.length === 0 && !isLoadingCampaigns && <NoData />}
    </div>
  );
};

export default CampaignList;
