import React, { FC, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import productsHelper from '../../helpers/productsHelper';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import Progress from '../Progress/Progress';
import OutOfRange from '../trackers/OutOfRange/OutOfRange';
import ValueTracker from '../trackers/ValueTracker/ValueTracker';
import TrackerVariant from '../../models/TrackerState';
import ReportButton from '../Report/ReportButton';
import EntityTypes from '../../models/Report/EntityTypes';
import CampaignTrackers from './CampaignTrackers/CampaignTrackers';
import CampaignItemModel from '../../models/Campaign/CampaignItem';
import OutOfSpecification from '../trackers/OutOfSpecification/OutOfSpecification';
import ProblemTrackerFilter from '../../models/ProblemTrackerFilter';
import OutOfTime from '../trackers/OutOfTime/OutOfTime';

interface Props {
  productId: string;
  campaign: CampaignItemModel;
  isScrollIntoView?: boolean;
}

const CampaignItem: FC<Props> = ({ productId, campaign, isScrollIntoView }) => {
  const intl = useIntl();

  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (!isScrollIntoView) {
      return;
    }
    console.log(isScrollIntoView, ref?.current);

    ref?.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
  }, []);

  return (
    <div key={campaign.id} className="card bg-color-gray-l4" ref={ref}>
      <div className="d-flex gap-3 p-3 align-items-center card-divider">
        <div className="me-auto">
          <h3 className="mb-0 font-bold text-uppercase">{campaign.name}</h3>
        </div>
        <div className="size-caption">
          <FormattedMessage id="campaigns.phase" /> <FormattedMessage id={`campaigns.phases.${campaign.phase}`} />
        </div>
        <div className="size-caption">
          <FormattedMessage id="campaigns.updatedAt" /> <GenericFormattedDate value={campaign.updatedAt} />
        </div>
        <div className="text-color-gray-l6 size-caption">
          <FormattedMessage id="campaigns.start" /> <GenericFormattedDate value={campaign.start} />
        </div>
        <div className="text-color-gray-l6 size-caption">
          <FormattedMessage id="campaigns.finish" /> <GenericFormattedDate value={campaign.finish} />
        </div>
        <ReportButton
          reportData={{
            productName: campaign.productName,
            synthesisStepName: campaign.synthesisStepName,
            campaignName: campaign.name,
            entityType: EntityTypes.campaign,
            entityId: campaign.id,
          }}
        />
        <Link
          to={productsHelper.getBatchesUrl({
            productId,
            synthesisStepId: campaign.synthesisStepId,
            synthesisStepName: campaign.synthesisStepName,
            campaignId: campaign.id,
            campaignName: campaign.name,
          })}
          className="btn btn-primary btn-sm"
        >
          <FormattedMessage id="campaigns.action.showDetails" />
        </Link>
      </div>
      <div className="d-flex gap-3 p-3 card-divider">
        <div className="flex-2 chart-row">
          <Progress value={campaign.progress} />
        </div>
        <div className="flex-2">
          <OutOfRange
            value={campaign.outOfRange}
            columns={campaign.outOfRangeList}
            tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.campaign' })}
            link={productsHelper.getBatchesUrl({
              productId,
              synthesisStepId: campaign.synthesisStepId,
              synthesisStepName: campaign.synthesisStepName,
              campaignId: campaign.id,
              campaignName: campaign.name,
              problemTracker: ProblemTrackerFilter.OutOfRange,
            })}
            getBatchUrl={(batchId: string) =>
              productsHelper.getBatchesUrl({
                productId: productId!,
                synthesisStepId: campaign.synthesisStepId,
                campaignId: campaign.id,
                viewState: {
                  idsFilter: [batchId],
                },
              })
            }
          />
        </div>
        <div className="flex-1">
          <OutOfSpecification
            numerator={campaign?.outOfSpecificationNumerator}
            denominator={campaign?.outOfSpecificationDenominator}
            tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.campaign' })}
            link={productsHelper.getBatchesUrl({
              productId,
              synthesisStepId: campaign.synthesisStepId,
              synthesisStepName: campaign.synthesisStepName,
              campaignId: campaign.id,
              campaignName: campaign.name,
              problemTracker: ProblemTrackerFilter.OutOfSpecification,
            })}
          />
        </div>
        <div className="flex-1">
          <OutOfTime
            delay={campaign.outOfTime}
            status={campaign.outOfTimeStatus}
            tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.campaign' })}
            link={productsHelper.getBatchesUrl({
              productId,
              synthesisStepId: campaign.synthesisStepId,
              synthesisStepName: campaign.synthesisStepName,
              campaignId: campaign.id,
              campaignName: campaign.name,
              problemTracker: ProblemTrackerFilter.OutOfTime,
            })}
          />
        </div>
        <div className="flex-1">
          <ValueTracker
            title={intl.formatMessage({ id: 'tracker.batches.title' })}
            value={`${campaign.completedBatches}/${campaign.totalBatches}`}
            hint={intl.formatMessage({ id: 'tracker.batches.hint' })}
            variant={TrackerVariant.progress}
            tooltip={intl.formatMessage({ id: 'tracker.batches.tooltip' })}
          />
        </div>
      </div>
      <CampaignTrackers campaignId={campaign.id} />
    </div>
  );
};

export default CampaignItem;
