import React, { FC, useState, useEffect } from 'react';

import { IntlProvider } from 'react-intl';

import { PropsWithChildrenOnly } from '../../types/propsWithChildrenOnly';
import useAppReducer from '../../context/useAppReducer';
import { AppContext } from '../../context/useAppContext';
import localeService from '../../services/localeService';
import usePersistState from '../../hooks/usePersistState';

const AppProvider: FC<PropsWithChildrenOnly> = ({ children }) => {
  const [state, dispatch] = useAppReducer();
  const [locale] = usePersistState<string>('locale', navigator.language);
  const [{ messages, isLocaleLoaded }, setIntlState] = useState({
    messages: {},
    isLocaleLoaded: false,
  });

  const contextMemo = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state],
  );

  useEffect(() => {
    const getLocaleMessages = async () => {
      const localeMessages = await localeService.getMessages(locale);

      setIntlState({ messages: localeMessages, isLocaleLoaded: true });
    };

    getLocaleMessages();
  }, [locale]);

  return (
    <AppContext.Provider value={contextMemo}>
      <IntlProvider messages={messages} locale={locale} defaultLocale="en">
        {isLocaleLoaded && children}
      </IntlProvider>
    </AppContext.Provider>
  );
};

export default AppProvider;
