import { useState } from 'react';
import axios, { AxiosResponse, AxiosError } from 'axios';
import request from '../services/requestService';
import { TwoFaSetupRequiredError, InvalidVerificationTokenError, ErrorCode } from '../errors/AuthErrors';
import AuthToken from '../models/AuthToken';
import authStorageService from '../services/authStorageService';
import accountService from '../services/accountService';
import supportService from '../services/supportService';

const useAuth = () => {
  const baseUrl = '/account';
  const [isLoading, setIsLoading] = useState(false);

  const post = async <T>(url: string, data: Record<string, string> | undefined = undefined): Promise<T> => {
    setIsLoading(true);
    try {
      const res: AxiosResponse<T> = await request({
        url,
        method: 'post',
        data,
      });

      return res.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ code: ErrorCode; data: unknown }>;

        if (axiosError.response) {
          switch (axiosError.response.data.code) {
            case 'InvalidVerificationToken':
              throw new InvalidVerificationTokenError();
            case 'TwoFaSetupRequired': {
              const errorData = axiosError.response.data.data as ITwoFaToken;
              throw new TwoFaSetupRequiredError(errorData);
            }

            default:
              throw new Error('Unhandled Axios Exception');
          }
        }
      }
      throw new Error('Unknown Exception');
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async (
    url: 'setPassword' | 'confirmResetPassword',
    token: string,
    password: string,
  ): Promise<string> => {
    const data = await post<ITwoFaToken>(`${baseUrl}/${url}`, { token, password });
    return data.twoFaToken;
  };

  const verifyActivationLink = async (token: string) => {
    return post<{ fullName: string; email: string }>(`${baseUrl}/verifyActivationLink`, { token });
  };

  const setPassword = async (token: string, password: string): Promise<string> => {
    try {
      return await updatePassword('setPassword', token, password);
    } catch (err) {
      if (err instanceof TwoFaSetupRequiredError) {
        return Promise.resolve(err.data.twoFaToken);
      }

      throw err;
    }
  };

  const addOtpDevice = async (twoFaToken: string): Promise<string> => {
    const data = await post<IOtpDevicePath>(`${baseUrl}/addOtpDevice`, { twoFaToken });
    return data.barcodeUri;
  };

  const login = async (
    userName: string,
    password: string,
  ): Promise<ITwoFaToken | (ITwoFaToken & IOtpDevicePath) | null> => {
    try {
      return await post<ITwoFaToken>(`${baseUrl}/login`, { userName, password });
    } catch (err) {
      if (err instanceof TwoFaSetupRequiredError) {
        const { twoFaToken } = err.data;
        const barcodeUri = await addOtpDevice(twoFaToken);
        return {
          twoFaToken,
          barcodeUri,
        };
      }
    }

    return null;
  };

  const resetPassword = async (email: string) => {
    await post(`${baseUrl}/resetPassword`, { email });
  };

  const verifyResetPasswordLink = async (token: string) => {
    return post(`${baseUrl}/verifyResetPasswordLink`, { token });
  };

  const confirmResetPassword = async (token: string, password: string): Promise<void> => {
    await updatePassword('confirmResetPassword', token, password);
  };

  const verifyOtpCode = async (twoFaToken: string, code: string): Promise<void> => {
    const data = await post<AuthToken>(`${baseUrl}/verifyOtpCode`, { twoFaToken, code });
    authStorageService.setState(data);
  };

  const verifyRecoveryCode = async (twoFaToken: string, code: string): Promise<{ recoveryCode: string }> => {
    const data = await post<AuthToken & { recoveryCode: string }>(`${baseUrl}/verifyRecoveryCode`, {
      twoFaToken,
      code,
    });
    authStorageService.setState(data);
    return { recoveryCode: data.recoveryCode };
  };

  const generateRecoveryCode = async (): Promise<string> => {
    setIsLoading(true);
    try {
      return await accountService.regenerateRecoveryCode();
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    const authState = authStorageService.getState();

    if (authState) {
      await post(`${baseUrl}/logout`, { refreshToken: authState.refreshToken });
    }
  };

  const sendHelpMessage = async (email: string, message: string, twoFaToken: string): Promise<void> => {
    setIsLoading(true);
    try {
      await supportService.sendEmail(email, message, twoFaToken);
    } finally {
      setIsLoading(false);
    }
  };

  const api = {
    verifyActivationLink,
    setPassword,
    addOtpDevice,
    login,
    verifyOtpCode,
    verifyRecoveryCode,
    resetPassword,
    verifyResetPasswordLink,
    confirmResetPassword,
    generateRecoveryCode,
    logout,
    sendHelpMessage,
  };

  return { isLoading, api };
};

interface ITwoFaToken {
  twoFaToken: string;
}

interface IOtpDevicePath {
  barcodeUri: string;
}

export default useAuth;
