import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import CampaignList from '../../../components/CampaignList/CampaignList';
import useUserInfo from '../../../hooks/useUserInfo';

const CampaignsTab: FC = () => {
  const { id: productId } = useParams<'id'>();
  const { companyId } = useUserInfo();

  return (
    <div>
      <CampaignList productId={productId!} companyId={companyId!} />
    </div>
  );
};

export default CampaignsTab;
