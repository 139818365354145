import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';

const MessageDetailsHeaderSkeleton: FC = () => {
  return (
    <ContentLoader
      height={29}
      speed={SkeletonSpeed}
      backgroundColor={SkeletonColors.white}
      foregroundColor={SkeletonColors.lightGrey1}
      viewBox="0 0 550 29"
    >
      <rect x="0" y="0" rx="4" ry="4" width="550" height="29" />
    </ContentLoader>
  );
};

const MessageDetailsBodySkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton">
      <div className="d-flex justify-content-between w-100 px-3 py-2">
        <div className="d-flex justify-content-between">
          <div className="size-caption d-flex me-4">
            <span className="text-color-gray-light1 me-2">
              <FormattedMessage id="messages.messageDetails.createdAt" />:
            </span>
            <ContentLoader
              height={20}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 203 25"
            >
              <rect x="0" y="0" rx="4" ry="4" width="203" height="20" />
            </ContentLoader>
          </div>
          <div className="size-caption d-flex">
            <span className="text-color-gray-light1 me-2">
              <FormattedMessage id="messages.messageDetails.author" />:
            </span>
            <ContentLoader
              height={20}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 150 25"
            >
              <rect x="0" y="0" rx="4" ry="4" width="150" height="20" />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div>
        <ContentLoader
          height={56}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.lightGrey2}
          foregroundColor={SkeletonColors.darkGrey2}
          viewBox="0 0 760 56"
        >
          <rect x="0" y="0" rx="4" ry="4" width="760" height="56" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default { MessageDetailsHeaderSkeleton, MessageDetailsBodySkeleton };
