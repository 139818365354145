import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropsWithChildrenOnly } from '../../../types/propsWithChildrenOnly';

const LoginLayout: FC<PropsWithChildrenOnly> = ({ children }) => {
  return (
    <div className="bg-login w-100">
      <div>
        <div className="row justify-content-end">
          <div className="col-4 justify-content-center">
            <div className="d-flex flex-column my-auto login-container smart-scroll">
              <span className="px-6 text-uppercase text-color-primary-green pt-4 text-center">
                <FormattedMessage id="login.title" />
              </span>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
