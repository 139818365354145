import React, { FC, useState, useMemo, HTMLAttributes } from 'react';

import { useQuery } from 'react-query';
import { Button } from 'react-bootstrap';

import { useIntl } from 'react-intl';
import useNotifications from '../../hooks/useNotifications';
import AdminAlertStatGroupBy from '../../models/Alert/AdminAlertStatGroupBy';
import alertService from '../../services/alertService';
import AlertsChart from '../AlertsChart/AlertsChart';
import AlertsStatisticsSkeleton from './AlertsStatisticsSkeleton';

const alertChartGroupBy: { name: string; value: AdminAlertStatGroupBy }[] = [
  { name: 'alertsStatistics.statGroupBy.day', value: 'Day' },
  { name: 'alertsStatistics.statGroupBy.week', value: 'Week' },
  { name: 'alertsStatistics.statGroupBy.month', value: 'Month' },
];

const AlertsStatistics: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
  companyId,
  size,
  className: parentClasses,
}) => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();
  const [chartGroupBy, setChartGroupBy] = useState<AdminAlertStatGroupBy>('Day');

  const { data: alertsStat, isLoading: isAlertsLoading } = useQuery(
    ['alertStatistics', chartGroupBy, companyId],
    () => alertService.getStatistics(chartGroupBy, companyId),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'alertsStatistics.notification.title' }),
          fm({ id: 'alertsStatistics.notification.unableToLoadAlertsStat' }),
        ),
    },
  );

  const alertStatGroupings = useMemo(() => {
    return alertChartGroupBy.map((item) => {
      return {
        ...item,
        name: fm({ id: item.name }),
      };
    });
  }, [locale]);

  return (
    <>
      {isAlertsLoading && <AlertsStatisticsSkeleton size={size} />}
      {!isAlertsLoading && (
        <div className={`${parentClasses || ''} bg-color-gray-l3 p-3 rounded d-flex flex-column`}>
          <div className="animated-component-item d-flex justify-content-end mb-2 gap-2">
            {alertStatGroupings.map((radio) => (
              <Button
                key={radio.value}
                variant="link"
                name="radio"
                value={radio.value}
                className={`px-1 btn-link-underline ${chartGroupBy === radio.value ? 'active' : ''}`}
                onClick={() => setChartGroupBy(radio.value)}
              >
                {radio.name}
              </Button>
            ))}
          </div>
          <AlertsChart
            data={alertsStat}
            size={size}
            groupBy={chartGroupBy}
            noDataLabel={fm({ id: `alertsStatistics.noData.${chartGroupBy}` })}
          />
        </div>
      )}
    </>
  );
};

interface Props {
  companyId?: string;
  size: number;
}

export default AlertsStatistics;
