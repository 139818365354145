import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import GenericFormattedDate from '../../../../components/GenericFormattedDate/GenericFormattedDate';
import ProductAlertsMini from '../../../../components/ProductAlerts/ProductAlertsMini/ProductAlertsMini';
import ProductUpdatesMini from '../../../../components/ProductUpdates/ProductUpdatesMini/ProductUpdatesMini';
import Spinner from '../../../../components/Spinner/Spinner';
import numberHelper from '../../../../helpers/numberHelper';
import productsHelper from '../../../../helpers/productsHelper';
import useNotifications from '../../../../hooks/useNotifications';
import companyService from '../../../../services/companyService';
import productService from '../../../../services/productService';
import OutOfRangeWithDetails from '../../../../components/trackers/OutOfRangeWithDetails/OutOfRangeWithDetails';
import ProblemTrackerFilter from '../../../../models/ProblemTrackerFilter';
import OutOfTime from '../../../../components/trackers/OutOfTime/OutOfTime';
import OutOfSpecification from '../../../../components/trackers/OutOfSpecification/OutOfSpecification';
import ProductRow from '../../../../components/ProductRow/ProductRow';

const pageSize = 20;

const CompanyStatusTab: FC = () => {
  const { id: companyId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['companytatus', companyId],
    () => companyService.getStatus(companyId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.unableLoadStatus' }),
        ),
    },
  );

  const {
    data: productsModel,
    isLoading: isLoadingProducts,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['activeProducts', companyId],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        isActive: true,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.tab.status.notification.unableLoadProducts' }),
        ),
    },
  );

  return (
    <div>
      <div className="row gx-4 mb-4">
        <div className="col-8">
          <div className="card p-3">
            <div className="row pb-3 align-items-center">
              <div className="col font-bold text-uppercase">
                <FormattedMessage id="admin.companyOverview.tab.status.statusBlock.title" />
              </div>
              <div className="col-auto size-caption text-color-gray-l6">
                <FormattedMessage id="admin.companyOverview.tab.status.statusBlock.updatedAt" />{' '}
                <GenericFormattedDate value={status?.lastEventAt} />
              </div>
            </div>
            <div className="d-flex gap-3 chart-row">
              <div className="flex-1">
                <OutOfRangeWithDetails
                  value={status?.outOfRange}
                  tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.global' })}
                  link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfRange)}
                />
              </div>
              <div className="flex-1">
                <OutOfSpecification
                  numerator={status?.outOfSpecificationNumerator}
                  denominator={status?.outOfSpecificationDenominator}
                  tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.global' })}
                  link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfSpecification)}
                />
              </div>
              <div className="flex-1">
                <OutOfTime
                  delay={status?.outOfTime}
                  status={status?.outOfTimeStatus}
                  tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.global' })}
                  link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfTime)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="card p-3">
            <div className="row font-bold text-uppercase pb-3 align-items-center">
              <div className="col">
                <FormattedMessage id="admin.companyOverview.tab.status.productsTotal.title" />
              </div>
              <div className="col-auto">
                <Link to="../products" className="link link--secondary font-medium size-caption">
                  <FormattedMessage id="admin.companyOverview.tab.status.productsTotal.action.showAll" />
                </Link>
              </div>
            </div>
            <div className="chart-row">
              <div className="row card-line px-3 card-line-padding mb-2">
                <div className="col">
                  <FormattedMessage id="admin.companyOverview.tab.status.productsTotal.total" />
                </div>
                <div className="col-auto">{numberHelper.formatNumber(status?.totalProducts)}</div>
              </div>
              <div className="row card-line px-3 card-line-padding mb-2">
                <div className="col">
                  <FormattedMessage id="admin.companyOverview.tab.status.productsTotal.active" />
                </div>

                <div
                  className={`col-auto ${
                    status?.activeProducts && status?.activeProducts > 0 ? 'text-color-graph-positive' : ''
                  }`}
                >
                  {numberHelper.formatNumber(status?.activeProducts)}
                </div>
              </div>
              <div className="row card-line px-3 card-line-padding">
                <div className="col">
                  <FormattedMessage id="admin.companyOverview.tab.status.productsTotal.inactive" />
                </div>
                <div className="col-auto">{numberHelper.formatNumber(status?.inactiveProducts)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-4">
        <div className="flex-1">
          <ProductAlertsMini companyId={companyId} />
        </div>
        <div className="flex-1">
          <ProductUpdatesMini companyId={companyId} />
        </div>
      </div>
      {!!productsModel?.pages?.[0]?.products?.length && (
        <InfiniteScroll
          className="overflow-visible"
          next={fetchNextPage}
          hasMore={hasNextPage || false}
          loader={null}
          dataLength={productsModel?.pages.length || 0}
          scrollableTarget="scrollContainer"
        >
          <div>
            {productsModel?.pages
              .flatMap((x) => x.products)
              .map((product) => (
                <ProductRow key={product.id} product={product} />
              ))}
          </div>
        </InfiniteScroll>
      )}
      {(isLoadingStatus || isLoadingProducts) && <Spinner />}
    </div>
  );
};

export default CompanyStatusTab;
