import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useMemo } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { object, string } from 'yup';
import Spinner from '../../../../components/Spinner/Spinner';
import InvalidCredentialsError from '../../../../errors/InvalidCredentialsError';
import { transformString } from '../../../../helpers/yupExtentions';
import useNotifications from '../../../../hooks/useNotifications';
import ChangeMfaResult from '../../../../models/Account/ChangeMfaResult';
import Shape from '../../../../models/Shape';
import accountService from '../../../../services/accountService';

const BaseKey = 'settings.tab.security.changeMfa.confirmationModal';

const ConfirmationModal: FC<Props> = ({ onConfirmed, onClose }) => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      password: string()
        .transform(transformString)
        .required(fm({ id: 'validation.required' })),
    });
  }, [locale]);

  const {
    register,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const notificationTitle = fm({ id: 'settings.tab.security.notification.title' });
  const { mutate: changeMfa, isLoading } = useMutation<ChangeMfaResult, unknown, FormData>(
    ({ password }) => accountService.changeMfa(password),
    {
      onSuccess: (data) => {
        onConfirmed(data);
      },
      onError: (error) => {
        if (error instanceof InvalidCredentialsError) {
          setError(
            'password',
            {
              message: fm({ id: `${BaseKey}.form.password.validation.invalid` }),
            },
            { shouldFocus: true },
          );
        } else {
          notify('error', notificationTitle, fm({ id: `${BaseKey}.notification.unableToChangeMfa` }));
          onClose();
        }
      },
    },
  );

  const onSubmitFormHandler = (data: FormData) => changeMfa(data);

  return (
    <>
      <Modal show centered>
        <span className="text-uppercase text-color-primary-green size-label px-4 pt-4 font-normal">
          <FormattedMessage id="settings.tab.security.title" />
        </span>
        <Modal.Header className="px-4">
          <h3 className="mb-0 font-bold">
            <FormattedMessage id={`${BaseKey}.title`} />
          </h3>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0 size-label font-medium text-color-gray-light1">
          <p className="mb-5">
            <FormattedMessage id={`${BaseKey}.description`} />
          </p>
          <Form className="d-flex flex-column" onSubmit={handleSubmit(onSubmitFormHandler)}>
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id={`${BaseKey}.form.password.label`} />
              </Form.Label>
              <Form.Control
                type="password"
                size="lg"
                placeholder={fm({
                  id: `${BaseKey}.form.password.placeholder`,
                })}
                {...register('password')}
                isInvalid={!!errors?.password}
              />
              {!!errors?.password && (
                <Form.Control.Feedback type="invalid">{errors.password.message}</Form.Control.Feedback>
              )}
            </Form.Group>
            <Button variant="primary" type="submit" className="btn mt-4 mb-3">
              <FormattedMessage id={`${BaseKey}.action.submit`} />
            </Button>
            <Button variant="ghost" type="button" onClick={onClose}>
              <FormattedMessage id={`${BaseKey}.action.cancel`} />
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

type FormData = { password: string };

interface Props {
  onConfirmed(changeMfaResult: ChangeMfaResult): void;
  onClose(): void;
}

export default ConfirmationModal;
