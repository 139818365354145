import { faSearch, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, FormEvent, PropsWithChildren, useState } from 'react';

import { Form, InputGroup } from 'react-bootstrap';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import dashboardHelper from '../../helpers/dashboardHelper';
import useUserInfo from '../../hooks/useUserInfo';
import UserType from '../../models/Account/UserType';

import './Search.scss';

const Search: FC<PropsWithChildren<Props>> = ({ placeholder }) => {
  const [searchTerm, setSearch] = useState<string>('');
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { companyId, type: userType } = useUserInfo();

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    queryClient.invalidateQueries('search');

    if (userType === UserType.Admin) {
      navigate(dashboardHelper.getAdminDashboardSearchUrl(searchTerm));
    } else {
      navigate(dashboardHelper.getUserDashboardSearchUrl(companyId!, searchTerm));
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <InputGroup className="search-component">
        <Form.Control
          type="text"
          className="form-control-small"
          placeholder={placeholder}
          value={searchTerm}
          onChange={(event) => setSearch(event.target.value)}
        />
        {searchTerm ? (
          <button type="button" className="btn btn-outline" onClick={() => setSearch('')}>
            <FontAwesomeIcon icon={faXmark} />
          </button>
        ) : (
          <InputGroup.Text>
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>
        )}
      </InputGroup>
    </form>
  );
};

type Props = {
  placeholder?: string;
};

export default Search;
