import appConfig from '../appConfig';
import request from './requestService';

class ProductImageService {
  private readonly BaseUrl = `/product/image`;

  public async upload(image: File): Promise<string> {
    const formData = new FormData();
    formData.append('Image', image, 'test.jpeg');
    const options = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const response = await request.post<{ productImageId: string }>(`${this.BaseUrl}`, formData, options);
    return response.data.productImageId;
  }

  public getImageUrl(imageId?: string): string | undefined {
    return imageId ? `${appConfig.backendUrl}${this.BaseUrl}/${imageId}` : undefined;
  }
}

export default new ProductImageService();
