import React, { FC, useState } from 'react';

import { Button, Form } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import Header from '../../../components/Header/Header';
import NoData from '../../../components/NoData/NoData';
import Select from '../../../components/Select/Select';
import UsersTable from '../../../components/UsersTable/UsersTable';
import UsersTableSkeleton from '../../../components/UsersTable/UsersTableSkeleton';
import usePaginatedUsers from '../../../hooks/admin/users/usePaginatedUsers';
import useUsersFiltersViewState from '../../../hooks/admin/users/useUsersFiltersViewState';
import useNotifications from '../../../hooks/useNotifications';
import useSortingViewState from '../../../hooks/useSortingViewState';
import useUserRoleOptions from '../../../hooks/useUserRoleOptions';
import ListUsersSortColumn from '../../../models/User/ListUsersSortColumn';
import AddEditUser from '../../Admin/Users/AddEditUser/AddEditUser';

const pageSize = 20;

interface Props {
  companyId: string;
}

const Users: FC<Props> = ({ companyId }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const [isAdd, setIsAdd] = useState(false);
  const { handleFilterChange, viewState } = useUsersFiltersViewState();

  const {
    data: usersData,
    isLoading: isUsersLoading,
    fetchNextPage,
    hasNextPage,
  } = usePaginatedUsers(
    pageSize,
    companyId,
    getSortOrder<ListUsersSortColumn>('fullName', 'Users'),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'organizationAdmin.users.notification.title' }),
          fm({ id: 'organizationAdmin.users.notification.unableToLoadUsers' }),
        ),
    },
    viewState.search?.roleId,
  );

  const handleRoleFilterChange = (roleValue: string) => handleFilterChange({ roleId: roleValue || undefined });

  const { roleOptions } = useUserRoleOptions();

  return (
    <>
      <Header title={fm({ id: 'organizationAdmin.users.header.title' })} showSearch />
      <div className="page-content px-5">
        <div className="filters-container pb-4">
          <Form.Group className="d-flex justify-content-between">
            <span>
              <Select
                value={viewState.search.roleId || ''}
                onChange={handleRoleFilterChange}
                options={roleOptions}
                size="lg"
                dataTestId="users-rolesFilter"
              />
            </span>
          </Form.Group>

          <Button
            className="btn-link-underline btn-link-underline-create"
            variant="link"
            onClick={() => setIsAdd(true)}
            data-test-id="users-add"
          >
            <FormattedMessage id="organizationAdmin.users.button.add" />
          </Button>
        </div>
        {usersData?.pages?.[0]?.users?.length === 0 && <NoData />}

        <div className="mb-4 shadow-2">
          {isUsersLoading && <UsersTableSkeleton cards={9} showCompanyName={false} />}
          {!!usersData?.pages?.[0]?.users?.length && (
            <InfiniteScroll
              className="rounded-bottom"
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={null}
              dataLength={usersData.pages.length}
              scrollableTarget="scrollContainer"
            >
              <UsersTable
                users={usersData.pages.flatMap((x) => x.users)}
                totalUsers={usersData.pages[0].total}
                companyId={companyId}
              />
            </InfiniteScroll>
          )}
        </div>

        {isAdd && <AddEditUser userId={null} onClose={() => setIsAdd(false)} defaultCompanyId={companyId} />}
      </div>
    </>
  );
};

export default Users;
