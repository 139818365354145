import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const UpdatedAtSkeleton: FC = () => {
  return (
    <div data-test-id="loader">
      <ContentLoader
        height={18}
        speed={SkeletonSpeed}
        backgroundColor={SkeletonColors.lightGrey2}
        foregroundColor={SkeletonColors.lightGrey1}
        viewBox="0 0 210 18"
      >
        <rect x="0" y="0" rx="4" ry="4" width="210" height="17" />
      </ContentLoader>
    </div>
  );
};
export default UpdatedAtSkeleton;
