enum SkeletonColors {
  darkGrey2 = '#1a1c1e',
  darkGrey1 = '#23262b',
  mediumGrey = '#34383f',
  lightGrey2 = '#4c4e53',
  lightGrey1 = '#5f6169',
  white = '#bbbec2',
  blue = '#4187f0',
  green = '#0c6',
}

export default SkeletonColors;
