import React, { useContext, PropsWithChildren } from 'react';

import './AccordionToggle.scss';

import { AccordionContext, useAccordionButton } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import eventHelper from '../../../helpers/eventHelper';

const AccordionToggle: React.FC<PropsWithChildren<Props>> = ({ children, eventKey, callback, dataTestId }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLElement>) => {
    if (eventHelper.isSubmitKeyPressed(e)) {
      decoratedOnClick(e);
    }
  };
  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <div
      className="accordion-toggle-component d-flex align-items-center justify-content-between"
      onClick={decoratedOnClick}
      role="button"
      onKeyDown={onKeyDownHandler}
      tabIndex={0}
      data-test-id={dataTestId}
    >
      {children}

      <div className="btn-toggle bg-secondary rounded d-flex" role="button" tabIndex={0}>
        <FontAwesomeIcon icon={isCurrentEventKey ? faAngleUp : faAngleDown} className="m-auto" />
      </div>
    </div>
  );
};

interface Props {
  eventKey: string;
  dataTestId?: string;
  callback?(eventKey: string): void;
}

export default AccordionToggle;
