import React, { useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import SelectOption from '../../../models/SelectOption';
import defaultStyles from '../styles';

const createCustomContainer = (props: any, dataTestId?: string) => (
  <components.SelectContainer {...props} innerProps={{ ...props.innerProps, ...{ 'data-test-id': dataTestId } }} />
);

const MultiSelect: React.FC<Props> = ({
  options,
  onChange,
  dataTestId,
  placeholder,
  value,
  noOptionsMessage,
  size,
}) => {
  const customComponents = useMemo(
    () => ({
      SelectContainer: (props: any) => createCustomContainer(props, dataTestId),
    }),
    [dataTestId],
  );
  const styles = useMemo(() => {
    const base = size === 'lg' ? defaultStyles.lgMultiSelectStyles : defaultStyles.stylesBase;
    return base;
  }, [size]);

  return (
    <ReactSelect<SelectOption, true>
      options={options}
      components={customComponents}
      styles={styles}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      onChange={(o) => onChange(o.map((item) => item))}
      value={value}
      isSearchable={false}
      isClearable={false}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      isMulti
    />
  );
};

interface Props {
  options: SelectOption[];
  onChange(values: SelectOption[]): void;
  size?: 'sm' | 'lg';
  value?: SelectOption[];
  dataTestId?: string;
  placeholder?: string;
  noOptionsMessage?: string;
}

export default MultiSelect;
