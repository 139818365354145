import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';

const CompaniesSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div
          key={key}
          className="animated-component-skeleton card p-3"
          style={{ height: '255px' }}
          data-test-id="loader"
        >
          <div className="form-2-col mb-7">
            <div>
              <ContentLoader
                height={122}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.lightGrey2}
                viewBox="0 0 369 122"
              >
                <rect x="0" y="0" rx="4" ry="5" width="300" height="24" />
                <rect x="0" y="44" rx="4" ry="5" width="250" height="23" />
              </ContentLoader>
            </div>
            <div>
              <ContentLoader
                height={122}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.darkGrey1}
                foregroundColor={SkeletonColors.mediumGrey}
                viewBox="0 0 369 122"
              >
                <rect x="0" y="0" rx="4" ry="5" width="369" height="53" />
                <rect x="0" y="69" rx="4" ry="5" width="369" height="53" />
              </ContentLoader>
            </div>
          </div>
          <div>
            <ContentLoader
              height={20}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 779 20"
            >
              <rect x="0" y="0" rx="4" ry="4" width="109" height="20" />
              <rect x="205" y="0" rx="4" ry="4" width="109" height="20" />
              <rect x="421" y="0" rx="4" ry="4" width="109" height="20" />
              <rect x="643" y="0" rx="4" ry="4" width="109" height="20" />
            </ContentLoader>
          </div>
        </div>
      ))}
    </>
  );
};
interface Props {
  cards: number;
}
export default CompaniesSkeleton;
