import React, { FC, useMemo, useState } from 'react';
import { useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroll-component';
import './Messages.scss';
import Header from '../../components/Header/Header';
import Select from '../../components/Select/Select';
import useNotifications from '../../hooks/useNotifications';
import useTableSelection from '../../hooks/useTableSelection';
import MessagesListSortColumn from '../../models/Messages/MessagesListSortColumn';
import GenericFormattedDate from '../../components/GenericFormattedDate/GenericFormattedDate';
import TableHeaderWithSelection from '../../components/Table/TableHeader/TableHeaderWithSelection';
import useMessagesViewState from '../../hooks/useMessagesViewState';
import messageService from '../../services/InternalMessaging/messageService';
import MessageStatus from '../../models/Messages/MessageStatus';
import DateRange from '../../components/DateRange/DateRange';
import Confirm from '../../components/Confirm/Confirm';
import MessageTableSkeleton from './MessageTableSkeleton';
import Spinner from '../../components/Spinner/Spinner';
import MessageDetailsModal from './MessageDetails/MessageDetails';
import useSortingViewState from '../../hooks/useSortingViewState';

const pageSize = 20;
const columns: { name: MessagesListSortColumn | ''; key: string }[] = [
  { name: 'title', key: 'messages.table.header.title' },
  { name: 'author', key: 'messages.table.header.author' },
  { name: 'createdAt', key: 'messages.table.header.createdAt' },
  { name: 'status', key: 'messages.table.header.status' },
  { name: '', key: '' }, // actions
];

const Messages: FC = () => {
  const { formatMessage: fm, locale } = useIntl();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotifications();
  const { isMasterChecked, toggleMaster, isItemChecked, toggleItem, selectionState, resetState } = useTableSelection();
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const { mutate: deleteMessages, isLoading: isDeleting } = useMutation(() => messageService.remove(selectionState), {
    onSuccess: async () => {
      queryClient.invalidateQueries('messages');
      setIsDelete(false);
      notifySuccess(fm({ id: 'messages.notification.title' }), fm({ id: 'messages.notification.deleteMessage' }));
      resetState();
    },
    onError: () =>
      notifyError(fm({ id: 'messages.notification.title' }), fm({ id: 'messages.notification.unableToDeleteMessage' })),
  });

  const { mutate: changeStatus, isLoading: isChangingStatus } = useMutation(
    (status: MessageStatus) => messageService.changeStatus(selectionState, status),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries('messages');
        notifySuccess(fm({ id: 'messages.notification.title' }), fm({ id: 'messages.notification.changedStatus' }));
        resetState();
      },
      onError: () =>
        notifyError(fm({ id: 'messages.notification.title' }), fm({ id: 'messages.notification.unableChangeStatus' })),
    },
  );

  const { viewState, handleViewStateChange, dateFrom, dateTo } = useMessagesViewState();
  const { getSortOrder } = useSortingViewState();
  const {
    data: messagesData,
    isLoading: isMessagesLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      'messages',
      viewState.search.filter?.status,
      getSortOrder<MessagesListSortColumn>('createdAt', 'Messages', false),
      viewState.search.filter?.dateFrom,
      viewState.search.filter?.dateTo,
    ],
    async ({ pageParam = 1 }) => {
      const data = await messageService.list({
        dateFrom: viewState.search.filter?.dateFrom || undefined,
        dateTo: viewState.search.filter?.dateTo || undefined,
        status: viewState.search.filter?.status,
        paging: { pageSize, pageNumber: pageParam },
        orderBy: getSortOrder<MessagesListSortColumn>('createdAt', 'Messages', false)?.toString(),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onSuccess: async () => {
        resetState();
      },
      onError: () =>
        notifyError(
          fm({ id: 'messages.notification.title' }),
          fm({ id: 'messages.notification.unableToLoadMessages' }),
        ),
    },
  );

  const statusOptions = useMemo(() => {
    return [
      { label: fm({ id: 'messages.filter.status.all' }), value: '' },
      { label: fm({ id: 'messages.filter.status.read' }), value: MessageStatus.read },
      { label: fm({ id: 'messages.filter.status.unread' }), value: MessageStatus.unread },
    ];
  }, [locale]);

  const isBatchActionDisabled = !isMasterChecked;

  const handleDateRangeChange = (startDate: Date | null, endDate: Date | null) => {
    handleViewStateChange({
      filter: {
        dateFrom: startDate === null ? undefined : startDate.toISOString(),
        dateTo: endDate === null ? undefined : endDate.toISOString(),
      },
    });
  };

  return (
    <>
      <Header title={fm({ id: 'messages.title' })} />
      <div className="messages-component page-content px-5">
        <div className="filters-container d-flex justify-content-start align-items-end mb-3">
          <Form.Group className="me-4">
            <Select
              value={viewState.search.filter?.status || ''}
              onChange={(value) => handleViewStateChange({ filter: { status: value === '' ? undefined : value } })}
              options={statusOptions}
              size="lg"
              minWidth={167}
              dataTestId="messages-statusFilter"
            />
          </Form.Group>
          <DateRange onChange={handleDateRangeChange} dateFrom={dateFrom} dateTo={dateTo} />
        </div>

        <div className="d-flex mb-3">
          <Button
            variant="link"
            className="link link--underlined sm me-3"
            onClick={() => changeStatus(MessageStatus.unread)}
            disabled={isBatchActionDisabled}
          >
            <FormattedMessage id="messages.action.markAsUnread" />
          </Button>
          <Button
            variant="link"
            className="link link--underlined sm me-3"
            onClick={() => changeStatus(MessageStatus.read)}
            disabled={isBatchActionDisabled}
          >
            <FormattedMessage id="messages.action.markAsRead" />
          </Button>
          <Button
            variant="link"
            className="link link--underlined sm"
            onClick={() => setIsDelete(true)}
            disabled={isBatchActionDisabled}
          >
            <FormattedMessage id="messages.action.delete" />
          </Button>
        </div>

        {isMessagesLoading ? (
          <MessageTableSkeleton cards={9} />
        ) : (
          <div className="shadow-2">
            <div className="bg-color-gray-l3 p-4 rounded-top row">
              <div className="col text-uppercase font-bold">
                <FormattedMessage id="messages.table.title" values={{ count: messagesData?.pages[0].total || 0 }} />
              </div>
            </div>
            {messagesData?.pages?.[0]?.messages?.length === 0 && (
              <div className="row p-3 text-color-gray-l6">
                <div className="col d-flex justify-content-center">
                  <FormattedMessage id="messages.noData" />
                </div>
              </div>
            )}
            {!!messagesData?.pages?.[0]?.messages?.length && (
              <InfiniteScroll
                className="rounded-bottom"
                next={fetchNextPage}
                hasMore={hasNextPage || false}
                loader={null}
                dataLength={messagesData.pages.length}
                scrollableTarget="scrollContainer"
              >
                <table className="w-100 col-25-wide">
                  <TableHeaderWithSelection<MessagesListSortColumn>
                    columns={columns}
                    tableName="Messages"
                    isMasterChecked={isMasterChecked}
                    handleToggleMaster={toggleMaster}
                  />
                  <tbody className="animated-component-item">
                    {messagesData.pages
                      .flatMap((x) => x.messages)
                      .map((message) => (
                        <tr key={message.id} data-test-id="messagesList-row">
                          <td data-test-id="messagesList-row-checkbox" className="font-semi-bold">
                            <Form.Check
                              checked={isItemChecked(message.id)}
                              className="form-check"
                              inline
                              type="checkbox"
                              onChange={(e) => toggleItem(message.id, e.target.checked)}
                            />
                          </td>
                          <td data-test-id="messagesList-row-title">
                            <Button
                              variant="link"
                              className="link link--tertiary text-without-transformation"
                              onClick={() => handleViewStateChange({ id: message.id })}
                            >
                              {message.title}
                            </Button>
                          </td>
                          <td data-test-id="messagesList-row-author">
                            {message?.systemName ||
                              (message?.user ? `${message.user?.fullName} (${message.user?.email})` : '')}
                          </td>
                          <td data-test-id="messagesList-row-createdAt" className="font-semi-bold">
                            <GenericFormattedDate withTime value={message.createdAt} />
                          </td>
                          <td data-test-id="messagesList-row-status" className="font-semi-bold">
                            <FormattedMessage id={`messages.status.${message.status}`} />
                          </td>

                          <td>
                            <Button
                              variant="link"
                              className="link link--underlined"
                              onClick={() => handleViewStateChange({ id: message.id })}
                            >
                              <FormattedMessage id="messages.action.details" />
                            </Button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </InfiniteScroll>
            )}
            {viewState.search.id && (
              <MessageDetailsModal
                messageId={viewState.search.id}
                onClose={() => handleViewStateChange({ id: undefined })}
              />
            )}
            {isDelete && (
              <Confirm
                title={fm({ id: 'messages.confirm.delete.title' })}
                confirmTitle={fm({ id: 'messages.confirm.delete.confirmMsg' })}
                message={fm({ id: 'messages.confirm.delete.message' })}
                onClose={() => setIsDelete(false)}
                onConfirm={() => deleteMessages()}
              />
            )}
          </div>
        )}
      </div>
      {(isDeleting || isChangingStatus) && <Spinner />}
    </>
  );
};

export default Messages;
