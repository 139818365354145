import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import ProductPhase from '../../models/Product/ProductPhase';
import ProductTabsSkeleton from './ProductTabsSkeleton';

const PhasesTabs: FC<Props> = ({ selectedPhase, onPhaseSelect, allCount, phases, className, isLoading }) => {
  const onSetTab = (value: string | null) => {
    const phaseId = !value || value === 'all' ? undefined : +value;
    onPhaseSelect(phaseId);
  };

  return (
    <Nav
      variant="tabs"
      activeKey={selectedPhase || 'all'}
      onSelect={onSetTab}
      className={className}
      data-test-id="phasesTabs-container"
    >
      <Nav.Item>
        <Nav.Link eventKey="all" className={isLoading ? 'no-border-bottom' : ''} data-test-id="phasesTabs-all">
          {isLoading && <ProductTabsSkeleton />}
          {!isLoading && <FormattedMessage id="phasesTabs.all" values={{ value: allCount || 0 }} />}
        </Nav.Link>
      </Nav.Item>
      {phases?.map((phase) => (
        <Nav.Item key={phase.id}>
          <Nav.Link eventKey={phase.id} data-test-id={`phasesTabs-${phase.id}`}>
            {phase.name} ({phase.productCount})
          </Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  );
};

interface Props {
  selectedPhase?: number;
  onPhaseSelect: (phaseId?: number) => void;
  phases?: ProductPhase[];
  allCount?: number;
  className?: string;
  isLoading?: boolean;
}

export default PhasesTabs;
