import { useReducer } from 'react';

type ActivateSteps = 'INIT' | 'WELCOME' | 'SET_PASSWORD' | 'SETUP_TWO_FA' | 'BACKUP_RECOVERY_CODE' | 'SHOW_SUPPORT';

interface IActivateState {
  step: ActivateSteps;
  isValidToken: boolean;
  fullName: string;
  email: string;
  twoFaToken: string;
  barcodeUri: string;
  recoveryCode: string;
}

const initialState: IActivateState = {
  step: 'INIT',
  isValidToken: false,
  fullName: '',
  email: '',
  twoFaToken: '',
  barcodeUri: '',
  recoveryCode: '',
};

const START_ACTIVATION = 'START_ACTIVATION';
type StartActivationAction = {
  type: typeof START_ACTIVATION;
  payload: {
    fullName: string;
    email: string;
  };
};

const SHOW_SET_PASSWORD = 'SHOW_SET_PASSWORD';
type ShowSetPasswordAction = {
  type: typeof SHOW_SET_PASSWORD;
};

const SHOW_SETUP_TWO_FA = 'SHOW_SETUP_TWO_FA';
type ShowSetupTwoFaAction = {
  type: typeof SHOW_SETUP_TWO_FA;
  payload: { twoFaToken: string; barcodeUri: string };
};

const SHOW_BACKUP_RECOVERY_CODE = 'BACKUP_RECOVERY_CODE';
type ShowBackupRecoveryCodeAction = {
  type: typeof SHOW_BACKUP_RECOVERY_CODE;
  payload: { recoveryCode: string };
};

const SHOW_SUPPORT = 'SHOW_SUPPORT';
type ShowSupportAction = {
  type: typeof SHOW_SUPPORT;
};

type Actions =
  | StartActivationAction
  | ShowSetPasswordAction
  | ShowSetupTwoFaAction
  | ShowBackupRecoveryCodeAction
  | ShowSupportAction;

const reducer = (state: IActivateState, action: Actions): IActivateState => {
  const { type } = action;

  switch (type) {
    case START_ACTIVATION:
      return {
        ...state,
        step: 'WELCOME',
        isValidToken: true,
        fullName: action.payload.fullName,
        email: action.payload.email,
      };
    case SHOW_SET_PASSWORD:
      return {
        ...state,
        step: 'SET_PASSWORD',
      };
    case SHOW_SETUP_TWO_FA: {
      const { twoFaToken, barcodeUri } = action.payload;
      return {
        ...state,
        step: 'SETUP_TWO_FA',
        twoFaToken,
        barcodeUri,
      };
    }
    case SHOW_SUPPORT: {
      return {
        ...state,
        step: 'SHOW_SUPPORT',
      };
    }
    case SHOW_BACKUP_RECOVERY_CODE:
      return {
        ...state,
        step: 'BACKUP_RECOVERY_CODE',
        recoveryCode: action.payload.recoveryCode,
      };
    default:
      throw new Error('Unknown Activate Action');
  }
};

const useActivateReducer = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default useActivateReducer;
