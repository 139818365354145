import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import ListApplicationRequestSortColumn from '../../models/AppGallery/ApplicationRequest/ListApplicationRequestSortColumn';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import './AppManagement.scss';

const AppManagementSkeleton: FC<Props> = ({ cards, columns }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold" data-test-id="loader">
        <FormattedMessage id="appManagement.totalCount" values={{ count: null }} />
        <ContentLoader
          height={20}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.lightGrey1}
          foregroundColor={SkeletonColors.lightGrey2}
          viewBox="0 0 70 25"
        >
          <rect x="0" y="0" rx="4" ry="4" width="40" height="20" />
        </ContentLoader>
      </div>
      <table className={classNames('w-100', `request-table-${columns.length}`)}>
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 120 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="118" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 278 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="200" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 130 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="110" height="25" />
                </ContentLoader>
              </td>

              {columns.length > 5 && (
                <td>
                  <ContentLoader
                    height={30}
                    speed={SkeletonSpeed}
                    backgroundColor={SkeletonColors.lightGrey2}
                    foregroundColor={SkeletonColors.darkGrey2}
                    viewBox="0 0 170 30"
                  >
                    <rect x="1" y="3" rx="4" ry="4" width="160" height="25" />
                  </ContentLoader>
                </td>
              )}
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 120 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="110" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={35}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.green}
                  viewBox="0 0 210 30"
                >
                  <rect x="15" y="0" rx="2" ry="2" width="165" height="30" />
                </ContentLoader>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

interface Props {
  cards: number;
  columns: { name: ListApplicationRequestSortColumn | ''; key: string }[];
}
export default AppManagementSkeleton;
