import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ProductUpdates from '../../../../components/ProductUpdates/ProductUpdates';

const UpdatesTab: FC = () => {
  const { id: productId } = useParams<'id'>();
  return <ProductUpdates productId={productId} />;
};

export default UpdatesTab;
