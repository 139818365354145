import { useAppContext } from '../context/useAppContext';
import UserType from '../models/Account/UserType';

const useUserType = (): UserType => {
  const {
    state: { user },
  } = useAppContext();

  if (user?.type) return user.type;

  return UserType.BaseUser;
};

export default useUserType;
