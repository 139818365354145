import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ProductAlertsMini from '../../../../components/ProductAlerts/ProductAlertsMini/ProductAlertsMini';
import ProductStep from '../../../../components/ProductStep/ProductStep';
import ProductUpdatesMini from '../../../../components/ProductUpdates/ProductUpdatesMini/ProductUpdatesMini';
import OutOfRange from '../../../../components/trackers/OutOfRange/OutOfRange';
import useNotifications from '../../../../hooks/useNotifications';
import productService from '../../../../services/productService';
import synthesisStepService from '../../../../services/synthesisStepService';

import './StatusTab.scss';
import ShortInfoSkeleton from '../../../../components/Skeletons/ShortInfoSkeleton';
import ProductStepSkeleton from '../../../../components/Skeletons/ProductOverview/ProductStepSkeleton';
import StatusTrackersSkeleton from '../../../../components/Skeletons/ProductOverview/Status/StatusTrackersSkeleton';
import GenericFormattedDate from '../../../../components/GenericFormattedDate/GenericFormattedDate';
import OutOfSpecification from '../../../../components/trackers/OutOfSpecification/OutOfSpecification';
import productsHelper from '../../../../helpers/productsHelper';
import ProblemTrackerFilter from '../../../../models/ProblemTrackerFilter';
import OutOfTime from '../../../../components/trackers/OutOfTime/OutOfTime';

const StatusTab: FC = () => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['productStatus', productId],
    () => productService.getStatus(productId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.productOverview.statusTab.notification.title' }),
          intl.formatMessage({ id: 'admin.productOverview.statusTab.notification.unableLoadStatus' }),
        ),
    },
  );
  const { data: steps, isLoading: isLoadingSteps } = useQuery(
    ['synthesisSteps', productId],
    () => synthesisStepService.list(productId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.productOverview.statusTab.notification.title' }),
          intl.formatMessage({ id: 'admin.productOverview.statusTab.notification.unableLoadSteps' }),
        ),
    },
  );

  return (
    <div className="admin-status-tab-component">
      <div className="row gx-4 mb-4">
        <div className="col-8">
          <div className="card p-3">
            <div className="row pb-3 align-items-center">
              <div className="col font-bold text-uppercase">
                <FormattedMessage id="admin.productOverview.statusTab.statusBlock.title" />
              </div>
              <div className="col-auto size-caption text-color-gray-l6">
                <FormattedMessage id="admin.productOverview.statusTab.statusBlock.updatedAt" />{' '}
                <GenericFormattedDate value={status?.updatedAt} />
              </div>
            </div>
            {isLoadingStatus ? (
              <StatusTrackersSkeleton />
            ) : (
              <div className="d-flex gap-3 chart-row">
                <div className="overflow-auto flex-2">
                  <OutOfRange
                    value={status?.outOfRange}
                    columns={status?.outOfRangeList}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfRange,
                    })}
                    getBatchUrl={(batchId: string) =>
                      productsHelper.getBatchesUrl({
                        productId: productId!,
                        viewState: {
                          idsFilter: [batchId],
                        },
                      })
                    }
                  />
                </div>
                <div className="flex-1">
                  <OutOfSpecification
                    numerator={status?.outOfSpecificationNumerator}
                    denominator={status?.outOfSpecificationDenominator}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfSpecification,
                    })}
                  />
                </div>
                <div className="flex-1">
                  <OutOfTime
                    delay={status?.outOfTime}
                    status={status?.outOfTimeStatus}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfTime,
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="col-4">
          <div className="card p-3">
            <div className="row font-bold text-uppercase pb-3">
              <FormattedMessage id="admin.productOverview.statusTab.stepsTotal.title" />
            </div>
            {isLoadingSteps ? (
              <ShortInfoSkeleton />
            ) : (
              <div className="chart-row">
                <div className="row card-line px-3 card-line-padding mb-2">
                  <div className="col">
                    <FormattedMessage id="admin.productOverview.statusTab.stepsTotal.total" />
                  </div>
                  <div className="col-auto">{steps?.total}</div>
                </div>
                <div className="row card-line px-3 card-line-padding mb-2">
                  <div className="col">
                    <FormattedMessage id="admin.productOverview.statusTab.stepsTotal.active" />
                  </div>

                  <div className={`col-auto ${steps?.active && steps.active > 0 ? 'text-color-graph-positive' : ''}`}>
                    {steps?.active}
                  </div>
                </div>
                <div className="row card-line px-3 card-line-padding">
                  <div className="col">
                    <FormattedMessage id="admin.productOverview.statusTab.stepsTotal.finished" />
                  </div>
                  <div className="col-auto">{steps?.finished}</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row gx-4">
        {steps?.steps.map((step, idx) => (
          <ProductStep key={step.id} step={step} highlighted={idx === 0} />
        ))}
        {isLoadingSteps && <ProductStepSkeleton cards={2} />}
      </div>
      <div className="row gx-4">
        <div className="col-6">
          <ProductAlertsMini productId={productId} />
        </div>
        <div className="col-6">
          <ProductUpdatesMini productId={productId} />
        </div>
      </div>
    </div>
  );
};

export default StatusTab;
