import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import TableHeader from '../Table/TableHeader/TableHeader';
import synthesisStepHelper from '../../helpers/synthesisStepHelper';
import ReportButton from '../Report/ReportButton';
import ManufacturerSynthesisStepListSortColumn from '../../models/SynthesisStep/ManufacturerSynthesisStepSortColumn';
import EntityTypes from '../../models/Report/EntityTypes';
import ManufacturerSynthesisStepItem from '../../models/SynthesisStep/ManufacturerSynthesisStepItem';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import useAppNavigation from '../../hooks/appGallery/useAppNavigation';

const columns: { name: ManufacturerSynthesisStepListSortColumn | ''; key: string }[] = [
  { name: 'companyName', key: 'manufacturerStepTable.table.header.companyName' },
  { name: 'productName', key: 'manufacturerStepTable.table.header.productName' },
  { name: 'synthesisStepName', key: 'manufacturerStepTable.table.header.synthesisStepName' },
  { name: 'phaseName', key: 'manufacturerStepTable.table.header.phaseName' },
  { name: '', key: '' }, // actions
];

interface Props {
  steps: ManufacturerSynthesisStepItem[];
  updatedAt?: string | null;
}

const ManufacturerStepTable: React.FC<Props> = ({ steps, updatedAt }) => {
  const { isTrackersAvailable } = useAppNavigation();

  return (
    <div className="shadow-2">
      <div className="d-flex align-items-center justify-content-between bg-color-gray-l3 p-3 rounded-top">
        <div className="font-bold text-uppercase" data-test-id="manufacturerStepTable-tableTitle">
          <FormattedMessage id="manufacturerStepTable.title" />
        </div>
        {updatedAt && (
          <div className="size-caption text-color-gray-l6" data-test-id="manufacturerStepTable-updatedAt">
            <FormattedMessage id="manufacturerStepTable.updatedAt" /> <GenericFormattedDate value={updatedAt} />
          </div>
        )}
      </div>
      <table className="w-100 rounded-bottom">
        <TableHeader<ManufacturerSynthesisStepListSortColumn> columns={columns} tableName="Steps" />
        <tbody className="animated-component-item">
          {steps.map((step) => (
            <tr key={step.synthesisStepId} data-test-id="manufacturerStepTable-tableBody-items-row">
              <td data-test-id="manufacturerStepTable-tableBody-item-companyName">{step.companyName}</td>
              <td data-test-id="manufacturerStepTable-tableBody-item-name">{step.productName}</td>
              <td data-test-id="manufacturerStepTable-tableBody-item-synthesisStepName">{step.synthesisStepName}</td>
              <td data-test-id="manufacturerStepTable-tableBody-item-phaseName">{step.phaseName}</td>
              <td data-test-id="manufacturerStepTable-tableBody-item-btn" align="right">
                <ReportButton
                  reportData={{
                    productName: step.productName,
                    synthesisStepName: step.synthesisStepName,
                    entityType: EntityTypes.synthesisStep,
                    entityId: step.synthesisStepId,
                  }}
                  className="me-3"
                  disabled={!isTrackersAvailable}
                />
                <div className="d-inline-block">
                  {isTrackersAvailable ? (
                    <Link
                      role="button"
                      className="btn btn-sm btn-primary"
                      to={synthesisStepHelper.getSynthesisStepUrl(step.productId, step.synthesisStepId)}
                      data-test-id="manufacturerStepTable-btn-showDetails"
                    >
                      <FormattedMessage id="manufacturerStepTable.buttons.details" />
                    </Link>
                  ) : (
                    <OverlayTrigger
                      overlay={
                        <Tooltip className="size-caption">
                          <FormattedMessage id="report.getFullAccessHint" />
                        </Tooltip>
                      }
                    >
                      <div className="d-inline-block">
                        <Button variant="primary" size="sm" disabled>
                          <FormattedMessage id="manufacturerStepTable.buttons.details" />
                        </Button>
                      </div>
                    </OverlayTrigger>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManufacturerStepTable;
