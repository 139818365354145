import { StylesConfig } from 'react-select';
import SelectOption from '../../models/SelectOption';

const stylesBase: StylesConfig<SelectOption> = {
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#23262B' : '#F1F1F1',
    backgroundColor: state.isFocused ? '#00CC66' : undefined,
    fontSize: 14,
    border: 0,
    borderBottom: '1px solid #23262b',
    padding: '7px 16px',
    ':hover': {
      backgroundColor: 'none',
    },
    ':active': {
      color: 'inherit',
      backgroundColor: 'none',
    },
  }),
  control: (provided, state) => {
    let borderColor = '#1A1C1E';
    if (state.isFocused) {
      borderColor = '#00CC66';
    }
    if (state.isDisabled) {
      borderColor = '#4C4E53';
    }
    return {
      ...provided,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor,
      borderRadius: 4,
      backgroundColor: '#34383f',
      minWidth: 254,

      boxShadow: 'none',
      ':hover': {
        borderColor: state.isFocused ? '#00CC66' : '#f1f1f1',
      },
    };
  },
  valueContainer: (provided) => ({
    ...provided,
    display: 'flex',
    padding: '7px 16px',
    paddingRight: 8,
    minWidth: 'inherit',
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#5F6169' : '#f1f1f1',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: 'opacity 300ms',
    color: state.isDisabled ? '#5F6169' : '#f1f1f1',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#f1f1f1',
    fontSize: '16px',
    padding: 2,
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: '#4c4e53',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      backgroundColor: '#4c4e53',
      ':hover': {
        color: '#92949a',
      },
    },
  }),
  input: (provided) => ({
    ...provided,
    color: '#f1f1f1',
    fontSize: '14px',
    boxShadow: 'none',
    height: '28px',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 6,
    backgroundColor: '#5F6169',
    boxShadow: '0px 1px 8px rgba(0, 0, 0, 0.16)',
    borderRadius: 4,
    overflow: 'hidden',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    overflow: 'overlay',
    '::-webkit-scrollbar-track': {
      borderRadius: 22,
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar': {
      width: 8,
      borderRadius: 22,
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      borderRadius: 22,
      backgroundColor: '#92949a',
    },
    '::-webkit-scrollbar-button': {
      display: 'none',
    },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? '#5F6169' : '#f1f1f1',
    padding: '0 12px',
    ':hover': {
      color: 'inherit',
    },
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};

const lgStyles: StylesConfig<SelectOption> = {
  ...stylesBase,
  option: (provided, state) => ({
    ...stylesBase.option!(provided, state),
    padding: '11px 16px',
  }),
  valueContainer: (provided, state) => ({
    ...stylesBase.valueContainer!(provided, state),
    padding: '11px 16px',
  }),
};

const lgMultiSelectStyles: StylesConfig<SelectOption> = {
  ...stylesBase,
  option: (provided, state) => ({
    ...stylesBase.option!(provided, state),
    padding: '11px 16px',
  }),
  placeholder: (provided, state) => ({
    ...stylesBase.placeholder!(provided, state),
    paddingTop: 4,
    paddingBottom: 4,
  }),
};

export default { stylesBase, lgStyles, lgMultiSelectStyles };
