import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const StatusTrackersSkeleton: FC = () => {
  return (
    <div data-test-id="loader">
      <ContentLoader
        height={133}
        speed={SkeletonSpeed}
        backgroundColor={SkeletonColors.darkGrey2}
        foregroundColor={SkeletonColors.mediumGrey}
        viewBox="0 0 1015 133"
      >
        <rect x="0" y="0" rx="8" ry="8" width="492" height="133" />
        <rect x="508" y="0" rx="8" ry="8" width="246" height="133" />
        <rect x="770" y="0" rx="8" ry="8" width="246" height="133" />
      </ContentLoader>
    </div>
  );
};
export default StatusTrackersSkeleton;
