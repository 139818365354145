import React, { useMemo } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import './ProductAlertsMini.scss';

import productsHelper from '../../../helpers/productsHelper';
import useNotifications from '../../../hooks/useNotifications';
import alertService from '../../../services/alertService';
import GenericFormattedDate from '../../GenericFormattedDate/GenericFormattedDate';
import TableHeader from '../../Table/TableHeader/TableHeader';
import alertsHelper from '../../../helpers/alertsHelper';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';
import UpdatedAtSkeleton from '../../Skeletons/UpdatedAtSkeleton';
import AlertsMiniSkeleton from './ProductAlertsMiniSkeleton';
import useSortingViewState from '../../../hooks/useSortingViewState';
import ListAlertsSortColumn from '../../../models/Alert/ListAlertSortColumn';
import companiesHelper from '../../../helpers/companiesHelper';

const pageSize = 3;

const ProductAlertsMini: React.FC<Props> = ({ productId, companyId }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const columns: { name: ListAlertsSortColumn | ''; key: string }[] = useMemo(() => {
    let productColumns: { name: ListAlertsSortColumn | ''; key: string }[] =
      !productId && !companyId ? [{ name: 'companyName', key: 'productAlerts.table.header.companyName' }] : [];
    productColumns = !productId
      ? [...productColumns, { name: 'productName', key: 'productAlerts.table.header.productName' }]
      : productColumns;

    return [
      ...productColumns,
      { name: 'typeName', key: 'productAlerts.table.header.typeName' },
      { name: 'createdAt', key: 'productAlerts.table.header.createdAt' },
      { name: '', key: '' }, // action
    ];
  }, [productId, companyId]);

  const { data: alertsData, isLoading: isAlertsLoading } = useQuery(
    ['productAlertsMini', productId, companyId, getSortOrder<ListAlertsSortColumn>('createdAt', 'Alerts', false)],
    () =>
      alertService.list(
        productId,
        companyId,
        { pageSize, pageNumber: 1 },
        getSortOrder<ListAlertsSortColumn>('createdAt', 'Alerts', false).toString(),
      ),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'productAlerts.notification.title' }),
          fm({ id: 'productAlerts.notification.unableToLoadAlerts' }),
        ),
    },
  );

  const getShowAllLink = () => {
    if (productId) return productsHelper.getProductAlertsUrl(productId);
    if (companyId) return alertsHelper.getAdminClientAlertsUrl(companyId);
    return alertsHelper.adminAlertsUrl;
  };

  return (
    <div className="product-alerts-mini-component narrow-row-table shadow-2">
      <div className="bg-color-gray-l3 p-4 rounded-top row">
        <div className="col text-uppercase font-bold">
          <FormattedMessage id="productAlerts.table.title" />
        </div>
        <div className="col-6 d-flex justify-content-end text-color-gray-l6 size-caption">
          {isAlertsLoading && <UpdatedAtSkeleton />}
          {alertsData?.updatedAt && (
            <span>
              <FormattedMessage id="productAlerts.updatedAt" /> <GenericFormattedDate value={alertsData.updatedAt} />
            </span>
          )}
        </div>
      </div>
      {alertsData?.alerts?.length === 0 && (
        <div className="row p-3 text-color-gray-l6">
          <div className="col d-flex justify-content-center">
            <FormattedMessage id="productAlerts.noData" />
          </div>
        </div>
      )}
      {isAlertsLoading && <AlertsMiniSkeleton cards={3} columns={columns} />}
      {!!alertsData?.alerts?.length && (
        <table className="w-100">
          <TableHeader<ListAlertsSortColumn> columns={columns} tableName="Alerts" />
          <tbody className="animated-component-item">
            {alertsData?.alerts.map((alert) => (
              <tr key={alert.id} data-test-id="alerts-row">
                {!companyId && !productId && (
                  <td data-test-id="alerts-row-companyName" className="td-width">
                    <TitleTooltip
                      titleTooltip={alert.companyName}
                      title={alert.companyName}
                      titleClassName="inline-crop"
                      link={companiesHelper.getCompanyUrl(alert.companyId)}
                    />
                  </td>
                )}
                {!productId && (
                  <td data-test-id="alerts-row-productName" className="td-width">
                    <TitleTooltip
                      titleTooltip={alert.productName}
                      title={alert.productName}
                      titleClassName="inline-crop"
                      link={productsHelper.getProductUrl(alert.productId)}
                    />
                  </td>
                )}
                <td data-test-id="alerts-row-typeName" className="font-semi-bold">
                  <Link to={alertsHelper.getItemUrl(alert)} className="base-link">
                    <FormattedMessage id={`productAlerts.type.${alert.typeName}.title`} />
                  </Link>
                  <span className="ms-1">
                    <OverlayTrigger
                      overlay={
                        <Tooltip>
                          <FormattedMessage id={`productAlerts.type.${alert.typeName}.description`} />
                        </Tooltip>
                      }
                    >
                      <span>
                        <FontAwesomeIcon role="tooltip" icon={faCircleInfo} className="text-color-gray-l6" />
                      </span>
                    </OverlayTrigger>
                  </span>
                </td>
                <td data-test-id="alerts-row-createdAt">
                  <GenericFormattedDate value={alert.createdAt} withTime />
                </td>
                <td align="right">
                  <Button
                    variant="link"
                    className="btn-toggle d-flex align-items-center justify-content-center"
                    href={alertsHelper.getItemUrl(alert)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isAlertsLoading && (
        <div className="bg-color-gray-l3 px-4 py-2 rounded-bottom row">
          <div className="col d-flex justify-content-end">
            {pageSize < (alertsData?.total || 0) && (
              <Link to={getShowAllLink()} className="link link--secondary link--underlined font-medium size-caption">
                <FormattedMessage id="productAlerts.action.details" />
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  productId?: string;
  companyId?: string;
}

export default ProductAlertsMini;
