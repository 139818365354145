import request from './requestService';

class SupportService {
  private readonly BaseUrl = `/Support`;

  public async sendEmail(email: string, message: string, twoFaToken: string): Promise<void> {
    await request.post(`${this.BaseUrl}/sendEmail`, { email, message, twoFaToken });
  }

  public async sendClientEmail(email: string, message: string): Promise<void> {
    await request.post(`${this.BaseUrl}/sendClientEmail`, { email, message });
  }
}

const supportService = new SupportService();

export default supportService;
