import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

const BackButton: FC = () => {
  const navigate = useNavigate();
  return (
    <Button onClick={() => navigate(-1)} data-test-id="back-button">
      <FormattedMessage id="backButton.label" />
    </Button>
  );
};

export default BackButton;
