import SynthesisStepsViewState from '../models/SynthesisStep/SynthesisStepsViewState';
import SynthesisStepViewState from '../models/SynthesisStep/SynthesisStepViewState';
import useQueryStringState from './useQueryStringState';

const useSynthesisStepsViewState = () => {
  const [viewState, setViewState] = useQueryStringState<SynthesisStepsViewState>();

  const getHandleStepViewStateChange = (stepId: string) => (newStepState: SynthesisStepViewState | undefined) => {
    const { [stepId]: oleStepState, ...restStepsState } = viewState.search;
    if (newStepState) {
      restStepsState[stepId] = newStepState;
    }

    const lastTouchedStepId = Object.keys(restStepsState)[Object.keys(restStepsState).length - 1];

    setViewState({
      search: restStepsState,
      hash: lastTouchedStepId,
    });
  };

  return {
    viewState,
    getHandleStepViewStateChange,
  };
};

export default useSynthesisStepsViewState;
