import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const AlertsStatisticsSkeleton: FC<Props> = ({ size }) => {
  let width = 1551;

  let cardStyle = 'mb-4';

  if (size === 7) {
    width = 482;

    cardStyle = '';
  }
  return (
    <div className={`${cardStyle} bg-color-gray-l3 p-3 rounded d-flex flex-column`} data-test-id="loader">
      <div className="d-flex justify-content-end gap-2">
        <ContentLoader
          height={30}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.green}
          foregroundColor={SkeletonColors.white}
          viewBox="0 0 300 29"
        >
          <rect x="152" y="0" rx="4" ry="4" width="145" height="20" />
        </ContentLoader>
      </div>
      <div className="animated-component-skeleton">
        <ContentLoader
          height={245}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.darkGrey2}
          foregroundColor={SkeletonColors.mediumGrey}
          viewBox={`0 0 ${width} 245`}
        >
          <rect x="0" y="0" rx="8" ry="8" width={width} height={245} />
        </ContentLoader>
      </div>
    </div>
  );
};

interface Props {
  size: number;
}
export default AlertsStatisticsSkeleton;
