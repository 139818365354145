import React, { ChangeEvent, FC, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import useNotifications from '../../hooks/useNotifications';
import ReportData from '../../models/Report/ReportData';
import ReportTypes from '../../models/Report/ReportTypes';
import reportService from '../../services/reportService';
import Spinner from '../Spinner/Spinner';
import './Report.scss';

const ReportModal: FC<Props> = ({ reportData, onCancel }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const [selectedReport, setSelectedReport] = useState<string>('Basic');
  const reportType = selectedReport;
  const { entityType, entityId } = reportData;
  const { mutate: getReport, isLoading: isGettingReport } = useMutation(
    () => reportService.requestReport({ entityId, entityType, reportType }),
    {
      onSuccess: () => {
        notify('success', fm({ id: 'report.title' }), fm({ id: 'report.modal.notification.success' }));
        onCancel();
      },
      onError: () => {
        notify('error', fm({ id: 'report.title' }), fm({ id: 'report.modal.notification.unableSendReport' }));
        onCancel();
      },
    },
  );

  const onCancelHandler = () => {
    onCancel();
  };

  const isSelected = (value: string): boolean => value === selectedReport;
  const radioHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedReport(event.target.value);
  };

  return (
    <>
      <Modal className="report-component modal-size-small" show centered backdrop="static" data-test-id="report">
        <Modal.Header className="text-center">
          <Modal.Title className="mb-2" data-test-id="report-title">
            <FormattedMessage id="report.title" />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-start">
          <div className="data mb-3 bg-color-gray-l2 rounded-4 px-3 py-2">
            {reportData.productName && (
              <div className="text-color-gray-l6 size-label my-1" data-test-id="report-productName">
                <FormattedMessage id="report.modal.titles.productName" />{' '}
                <span className="text-color-white ms-1">{reportData.productName}</span>
              </div>
            )}
            {reportData.synthesisStepName && (
              <div className="text-color-gray-l6 size-label my-1" data-test-id="report-synthesis-step">
                <FormattedMessage id="report.modal.titles.synthesisStep" />
                <span className="text-color-white ms-1"> {reportData.synthesisStepName}</span>
              </div>
            )}
            {reportData.campaignName && (
              <div className="text-color-gray-l6 size-label my-1" data-test-id="report-campaign">
                <FormattedMessage id="report.modal.titles.campaign" />{' '}
                <span className="text-color-white ms-1">{reportData.campaignName}</span>
              </div>
            )}
            {reportData.batchName && (
              <div className="text-color-gray-l6 size-label my-1" data-test-id="report-batch">
                <FormattedMessage id="report.modal.titles.batch" />{' '}
                <span className="text-color-white ms-1">{reportData.batchName}</span>
              </div>
            )}
          </div>
          <Form>
            <div className="p-3 mb-3 text-center">
              <Form.Check
                checked={isSelected(ReportTypes.basic)}
                inline
                type="radio"
                name="reportButtons"
                value={ReportTypes.basic}
                id="basic-report-radio"
                label={fm({ id: 'report.modal.option.getBasicReport' })}
                onChange={radioHandler}
              />
              <Form.Check
                inline
                checked={isSelected(ReportTypes.premium)}
                type="radio"
                name="reportButtons"
                value={ReportTypes.premium}
                id="premium-report-radio"
                label={fm({ id: 'report.modal.option.getPremiumReport' })}
                onChange={radioHandler}
              />
            </div>
          </Form>
          <div className="d-flex flex-column">
            <Button variant="primary" className="mb-3" onClick={() => getReport()}>
              <FormattedMessage id="report.modal.buttons.getReport" />
            </Button>
            <Button variant="ghost" onClick={onCancelHandler}>
              <FormattedMessage id="report.modal.buttons.cancel" />
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {isGettingReport && <Spinner />}
    </>
  );
};

interface Props {
  reportData: ReportData;
  onCancel: () => void;
}
export default ReportModal;
