import React, { FC, HTMLAttributes, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

import { transformString } from '../../../helpers/yupExtentions';
import Shape from '../../../models/Shape';

const ForgotPassword: FC<IRecoverPasswordProps & HTMLAttributes<HTMLDivElement>> = ({
  onGetRecoveryLink,
  onCancel,
}) => {
  const intl = useIntl();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      userName: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .email(intl.formatMessage({ id: 'validation.email' })),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmitFormHandler = async (data: FormData) => {
    onGetRecoveryLink(data.userName);
    onCancel();
  };

  const onCancelHandler = () => {
    onCancel();
  };

  return (
    <Form className="d-flex px-6 flex-column my-auto" noValidate onSubmit={handleSubmit(onSubmitFormHandler)}>
      <h3 className="text-center font-bold">
        <FormattedMessage id="login.forgotPassword.title" />
      </h3>
      <p className="hint text-center">
        <FormattedMessage id="login.forgotPassword.message" />
      </p>
      <Form.Group className="mb-5 mt-4" controlId="formBasicEmail">
        <Form.Label>
          <FormattedMessage id="login.forgotPassword.form.email.label" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            autoFocus
            type="text"
            size="lg"
            {...register('userName')}
            isInvalid={!!errors?.userName}
            data-test-id="forgotPassword-email"
          />
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
        </InputGroup>
        {errors?.userName && (
          <Form.Control.Feedback type="invalid" data-test-id="forgotPassword-email-error">
            {errors?.userName.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className="btn btn-primary btn-rounded"
        data-test-id="forgotPassword-submit"
      >
        <FormattedMessage id="login.forgotPassword.form.btn.label" />
      </Button>
      <Button
        variant="ghost"
        type="button"
        className="btn mt-5 btn-rounded"
        onClick={onCancelHandler}
        data-test-id="forgotPassword-cancel"
      >
        <FormattedMessage id="login.forgotPassword.cancel.btn.label" />
      </Button>
    </Form>
  );
};

type FormData = { userName: string };

interface IRecoverPasswordProps {
  onGetRecoveryLink: (email: string) => void;
  onCancel: () => void;
}

export default ForgotPassword;
