import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, useSearchParams } from 'react-router-dom';
import './CategoryCard.scss';

import useNotifications from '../../../../hooks/useNotifications';
import CategoryItem from '../../../../models/AppGallery/Application/CategoryItem';
import applicationService from '../../../../services/AppGallery/applicationService';
import AppDetailsModal from '../../AppDetailsModal/AppDetailsModal';
import imageFileService from '../../../../services/AppGallery/imageFileService';

const CategoryCard: React.FC<Props> = ({ categoryItem }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: appList, isLoading: isLoadingAppList } = useQuery(
    ['applicationList', 'category', categoryItem.id],
    () => applicationService.list({ categoryId: categoryItem.id, orderBy: 'categoryPriority' }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.categoryTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.categoryTab.notification.unableToLoadApplicationList' }),
        ),
    },
  );

  const { data: processList, isLoading: isLoadingProcesses } = useQuery(
    ['processList'],
    () => applicationService.listProcesses(),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.processTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.processTab.notification.unableToLoadProcessList' }),
        ),
    },
  );

  const getProcess = (processId: string) => processList?.find((x) => x.id === processId)?.name;

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const handleCLoseModal = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  return (
    <>
      {(!isLoadingAppList || !isLoadingProcesses) && (
        <div className="category-app-card-component card">
          <div className="d-flex card-divider p-3">
            <div className="animated-component-item">
              <img
                src={imageFileService.getImageUrl(categoryItem.iconImageId!)}
                alt="category"
                className="category-app-card-component-img me-3"
              />
            </div>
            <div className="text-start animated-component-item">
              <h3 className="category-app-card-component-title font-bold text-uppercase mb-0 ">{categoryItem.name}</h3>
            </div>
          </div>
          <div className="p-3">
            <div className="category-app-card-component-body bg-color-gray-l2">
              {appList?.applications.map((applicationItem) => (
                <div key={applicationItem.id} className="p-1 animated-component-item">
                  <div className="d-flex justify-content-between px-3 py-1">
                    <div className="d-flex">
                      <div
                        className={
                          applicationItem.status === 'Installed'
                            ? 'installed me-2 text-uppercase'
                            : 'requestable me-2 text-uppercase'
                        }
                      >
                        {applicationItem.name}
                      </div>
                      <div className="d-flex">
                        {applicationItem.processIds.map((processId) => (
                          <div
                            key={processId}
                            className={`category-app-card-component-process category-app-card-component-process-${getProcess(
                              processId,
                            )} size-caption me-1`}
                          >
                            {getProcess(processId)}
                          </div>
                        ))}
                      </div>
                    </div>
                    <Link to={`?id=${applicationItem.id}`} className="link link--underlined sm">
                      <FormattedMessage id="appGallery.categoryTab.application.button.details" />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {id && <AppDetailsModal appId={id} onClose={handleCLoseModal} />}
    </>
  );
};
interface Props {
  categoryItem: CategoryItem;
}

export default CategoryCard;
