import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ProductAlerts from '../../../components/ProductAlerts/ProductAlerts';

interface Props {
  companyId: string;
}

const AlertsTab: FC<Props> = ({ companyId }) => {
  const { id: productId } = useParams<'id'>();
  return <ProductAlerts productId={productId} companyId={companyId} />;
};

export default AlertsTab;
