import qs from 'qs';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface QueryState<S> {
  search: S;
  hash: string;
}

const useQueryStringState = <S>(): [
  QueryState<S>,
  Dispatch<QueryState<S>>,
  Dispatch<(oldState: QueryState<S>) => QueryState<S>>,
] => {
  const { search, hash } = useLocation();
  const navigate = useNavigate();

  const state = {
    search: useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }) as unknown as S, [search]),
    hash: hash.slice(1),
  };
  const setViewState = (newState: QueryState<S>) => {
    navigate(
      {
        search: qs.stringify(newState.search, { indices: true }),
        hash: newState.hash,
      },
      {
        replace: true,
      },
    );
  };

  const [funcs, setFuncs] = useState<((oldState: QueryState<S>) => QueryState<S>)[]>([]);
  useEffect(() => {
    if (funcs.length === 0) {
      return;
    }

    const [fistFunction, ...otherFuncs] = funcs;
    setViewState(fistFunction(state));
    setFuncs(otherFuncs);
  }, [funcs]);

  const setViewStateFunc = (func: (oldState: QueryState<S>) => QueryState<S>) => {
    setFuncs([...funcs, func]);
  };

  return [state, setViewState, setViewStateFunc];
};

export default useQueryStringState;
