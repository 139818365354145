import { faChartPie } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import { Accordion } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import AccordionItem from '../../Accordion/AccordionToggle/AccordionItem/AccordionItem';
import AccordionToggle from '../../Accordion/AccordionToggle/AccordionToggle';
import Spinner from '../../Spinner/Spinner';
import TabItem from '../../TabbedComponent/TabItem';
import Tabs from '../../TabbedComponent/Tabs';
import TabsContent from '../../TabbedComponent/TabsContent';
import TabsContentItem from '../../TabbedComponent/TabsContentItem';
import useNotifications from '../../../hooks/useNotifications';
import TrackersTabs from '../../../models/Tabs/TrackersTabs';
import campaignService from '../../../services/campaignService';
import TabsContextProvider from '../../TabbedComponent/TabsContextProvider';
import PerformanceTab from './Tabs/PerformanceTab';
import QualityTab from './Tabs/QualityTab';
import SustainabilityTab from './Tabs/SustainabilityTab';
import AccordionLazyContent from '../../Accordion/AccordionToggle/AccordionLazy/AccordionLazyContent';
import useQueryStringState from '../../../hooks/useQueryStringState';

const DefaultTab = TrackersTabs.Quality;

interface Props {
  campaignId: string;
}

const CampaignTrackers: FC<Props> = ({ campaignId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const [viewState, , setViewStateFunc] = useQueryStringState<{
    campaigns?: {
      [campaignId: string]: { tab?: TrackersTabs; openedTrackers?: boolean } | undefined;
    };
  }>();

  const { data: trackers, isLoading: isLoadingTrackers } = useQuery(
    ['campaignTrackers', campaignId],
    () => campaignService.trackers(campaignId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'campaignTrackers.notification.title' }),
          intl.formatMessage({ id: 'campaignTrackers.notification.unableToLoadTrackers' }),
        ),
    },
  );

  const onAccordionToggle = () => {
    setViewStateFunc((state) => ({
      ...state,
      search: {
        ...state.search,
        campaigns: {
          ...state.search.campaigns,
          [campaignId]: {
            ...state.search.campaigns?.[campaignId],
            openedTrackers: state.search.campaigns?.[campaignId]?.openedTrackers ? undefined : true,
          },
        },
      },
      hash: state.search.campaigns?.[campaignId]?.openedTrackers ? '' : `campaign-${campaignId}`,
    }));
  };

  const handleTabChange = (tab: string) => {
    setViewStateFunc((state) => ({
      ...state,
      search: {
        ...state.search,
        campaigns: {
          ...state.search.campaigns,
          [campaignId]: {
            ...state.search.campaigns?.[campaignId],
            tab: tab === DefaultTab ? undefined : (tab as TrackersTabs),
          },
        },
      },
    }));
  };

  return (
    <Accordion activeKey={viewState.search?.campaigns?.[campaignId]?.openedTrackers ? 'trackers' : null}>
      <AccordionItem eventKey="trackers">
        <div className="p-3 bg-color-gray-l4">
          <AccordionToggle eventKey="trackers" callback={onAccordionToggle}>
            <div className="d-flex align-self-center">
              <FontAwesomeIcon icon={faChartPie} className="pe-3 d-flex align-self-center" />
              <span className="text-uppercase font-bold accordion-item-title">
                <FormattedMessage id="campaigns.trackers.title" />
              </span>
            </div>
          </AccordionToggle>
        </div>
        <Accordion.Collapse eventKey="trackers">
          <AccordionLazyContent eventKey="trackers">
            <div className="p-3">
              <TabsContextProvider
                activeTab={viewState.search?.campaigns?.[campaignId]?.tab ?? DefaultTab}
                setActiveTab={handleTabChange}
              >
                <Tabs className="mb-3">
                  <TabItem id={TrackersTabs.Quality}>
                    <FormattedMessage id="campaigns.trackers.tab.quality" />
                  </TabItem>
                  <TabItem id={TrackersTabs.Performance}>
                    <FormattedMessage id="campaigns.trackers.tab.performance" />
                  </TabItem>
                  <TabItem id={TrackersTabs.Sustainability}>
                    <FormattedMessage id="campaigns.trackers.tab.sustainability" />
                  </TabItem>
                </Tabs>
                <TabsContent>
                  <TabsContentItem id={TrackersTabs.Quality}>
                    <QualityTab trackers={trackers} />
                  </TabsContentItem>
                  <TabsContentItem id={TrackersTabs.Performance}>
                    <PerformanceTab trackers={trackers} />
                  </TabsContentItem>
                  <TabsContentItem id={TrackersTabs.Sustainability}>
                    <SustainabilityTab trackers={trackers} />
                  </TabsContentItem>
                </TabsContent>
                {isLoadingTrackers && <Spinner />}
              </TabsContextProvider>
            </div>
          </AccordionLazyContent>
        </Accordion.Collapse>
      </AccordionItem>
    </Accordion>
  );
};

export default CampaignTrackers;
