import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import './DistributionOfImpurities.scss';
import numberHelper from '../../../helpers/numberHelper';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import DistributionOfImpuritiesValue from '../../../models/DistributionOfImpuritiesValue';

const DistributionOfImpurities: FC<Props> = ({ value, tooltip, unit, method }) => {
  const ref = useRef(null);
  const getTooltip = (tooltipMethod?: string | null) => {
    return tooltipMethod ? (
      <>
        {tooltip}
        <br />
        <FormattedMessage id="tracker.method" values={{ method: tooltipMethod }} />
      </>
    ) : (
      tooltip
    );
  };
  return (
    <div className="distribution-of-impurities-component bg-color-gray-l1 p-2" ref={ref}>
      <div className="mb-2 size-label d-flex">
        <span className="align-middle">
          <FormattedMessage id="tracker.distributionOfImpurities.title" />
        </span>
        {tooltip && (
          <div className="ms-1 d-flex">
            <IconTooltip tooltip={getTooltip(method)} className="align-middle" />
          </div>
        )}
      </div>
      {!!value?.length && (
        <div className="scrollable-table">
          <table className="w-100">
            <tbody>
              {value?.map((item) => (
                <tr key={item.key}>
                  <td>
                    <div className="table-key">{item.key}</div>
                  </td>
                  <td>
                    {numberHelper.formatNumber(item.value)}
                    {unit ?? ''}
                    {item.limit && (
                      <>
                        <br />
                        <FormattedMessage id="tracker.distributionOfImpurities.limit" />{' '}
                        {numberHelper.formatNumber(item.limit)}
                        {unit ?? ''}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!value?.length && (
        <div className="row h-100 d-flex align-items-center hint">
          <FormattedMessage id="tracker.noData" />
        </div>
      )}
    </div>
  );
};

type Props = {
  tooltip?: string;
  value?: DistributionOfImpuritiesValue[];
  unit?: string;
  method?: string | null;
};

export default DistributionOfImpurities;
