import React, { useMemo } from 'react';
import Select from '../Select/Select';

const getValue = (isActive?: string) => {
  if (isActive === 'true') return true;
  if (isActive === 'false') return false;
  return undefined;
};

const StatusSelect: React.FC<Props> = ({ onChange, value, labels, dataTestId }) => {
  const statusOptions = useMemo(() => {
    return [
      { label: labels.empty, value: '' },
      { label: labels.active, value: 'true' },
      { label: labels.inactive, value: 'false' },
    ];
  }, [labels.empty, labels.inactive, labels.active]);

  const setIsActiveHandler = (isActive?: string) => {
    onChange(getValue(isActive));
  };

  return (
    <Select
      value={value === undefined ? '' : value.toString().toLowerCase()}
      onChange={setIsActiveHandler}
      options={statusOptions}
      size="lg"
      dataTestId={dataTestId}
    />
  );
};

interface Props {
  onChange(value?: boolean): void;
  value?: boolean;
  labels: { empty: string; active: string; inactive: string };
  dataTestId?: string;
}

export default StatusSelect;
