import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';

const ProductRowSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="animated-component-skeleton card mb-4" data-test-id="loader">
          <div className="row">
            <div className="card-divider d-flex justify-content-between">
              <ContentLoader
                height={88}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey2}
                foregroundColor={SkeletonColors.lightGrey1}
                viewBox="0 0 370 88"
              >
                <rect x="16" y="16" rx="4" ry="4" width="350" height="26" />
                <rect x="16" y="54" rx="3" ry="3" width="300" height="14" />
              </ContentLoader>
              <ContentLoader
                height={88}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.green}
                viewBox="0 0 496 88"
              >
                <rect x="0" y="29" rx="4" ry="4" width="480" height="26" />
              </ContentLoader>
            </div>
          </div>
          <div className="pb-3">
            <div className="card-divider d-flex h-100">
              <ContentLoader
                height={167}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.darkGrey2}
                foregroundColor={SkeletonColors.mediumGrey}
                viewBox="0 0 1734 183"
              >
                <rect x="16" y="16" rx="5" ry="5" width="410" height="150" />
                <rect x="442" y="16" rx="5" ry="5" width="410" height="150" />
                <rect x="868" y="16" rx="5" ry="5" width="850" height="150" />
              </ContentLoader>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

interface Props {
  cards: number;
}

export default ProductRowSkeleton;
