import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useQuery } from 'react-query';
import classNames from 'classnames';
import { Accordion, Button, Modal } from 'react-bootstrap';

import './BatchPartDetails.scss';
import useNotifications from '../../../../hooks/useNotifications';
import batchService from '../../../../services/batchService';
import GenericFormattedDate from '../../../GenericFormattedDate/GenericFormattedDate';
import AccordionToggle from '../../../Accordion/AccordionToggle/AccordionToggle';
import AccordionLazyContent from '../../../Accordion/AccordionToggle/AccordionLazy/AccordionLazyContent';
import Spinner from '../../../Spinner/Spinner';
import useQueryStringState from '../../../../hooks/useQueryStringState';
import BatchStages from './BatchStages/BatchStages';

interface Props {
  batchId: string;

  onClose(): void;
}

const BatchPartDetails: FC<Props> = ({ batchId, onClose }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const [viewState, , setViewStateFunc] = useQueryStringState<{ parts: { selectedId?: string } | undefined }>();

  const { data: batchParts, isLoading: isLoadingParts } = useQuery(
    ['batchParts', batchId],
    () => batchService.listParts(batchId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.title' }),
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.unableLoadParts' }),
        ),
    },
  );

  const { data: batchData, isLoading: isLoadingBatch } = useQuery(
    ['batcheDetails', batchId],
    () => batchService.get(batchId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchList.notification.title' }),
          intl.formatMessage({ id: 'batchList.notification.unableToLoadBatches' }),
        ),
    },
  );

  const selectPart = (partIdToSelect?: string) => {
    setViewStateFunc((state) => ({
      ...state,
      search: {
        ...state.search,
        parts: {
          ...state.search.parts,
          selectedId: partIdToSelect === state.search.parts?.selectedId ? undefined : partIdToSelect,
        },
      },
    }));
  };

  return (
    <>
      <Modal
        show
        centered
        onHide={onClose}
        scrollable
        className="batch-part-details-component scroll-outside"
        size="xl"
      >
        <Modal.Header>
          <div className="d-flex gap-5 align-items-center">
            <span className="text-uppercase font-bold d-flex">
              <FormattedMessage id="batchParts.batchTable.part.details.title" />
            </span>
            <span className="text-color-gray-l6 size-label">
              <FormattedMessage
                id="batchParts.batchTable.part.details.outOfRangeEvents"
                values={{ value: batchData?.totalOutOfRangeActions }}
              />
            </span>
          </div>
          <div className="d-flex gap-5 align-items-center">
            <span className="hint">
              <FormattedMessage id="batchParts.batchTable.part.details.updatedAt" />{' '}
              <GenericFormattedDate value={batchData?.updatedAt} />
            </span>
            <Button onClick={onClose} size="sm">
              <FormattedMessage id="batchParts.batchTable.part.details.action.close" />
            </Button>
          </div>
        </Modal.Header>
        <Modal.Body className="p-0">
          <div className="modal-body-content bg-color-gray-l3 h-100">
            <Accordion activeKey={viewState?.search?.parts?.selectedId ?? null}>
              {batchParts?.map((part) =>
                part.outOfRangeActions > 0 ? (
                  <Accordion.Item key={part.id} eventKey={part.id}>
                    <div className="p-3 header card-divider">
                      <AccordionToggle eventKey={part.id} callback={selectPart}>
                        <div className="d-flex align-items-center justify-content-between w-100 pe-3">
                          <div
                            className={classNames({
                              'text-color-primary-green': viewState?.search?.parts?.selectedId === part.id,
                            })}
                          >
                            <FormattedMessage id={`batchParts.batchTable.part.details.partType.${part.type}`} />
                          </div>
                          <div className="size-caption font-normal events-total-padding px-3 bg-color-gray-l2 rounded-1">
                            <FormattedMessage
                              id="batchParts.batchTable.part.details.stageActions"
                              values={{ failed: part.outOfRangeActions, total: part.totalActions }}
                            />
                          </div>
                        </div>
                      </AccordionToggle>
                    </div>
                    <Accordion.Collapse eventKey={part.id}>
                      <AccordionLazyContent eventKey={part.id}>
                        <div className="bg-color-gray-l1 p-4">
                          <BatchStages batchId={batchId} partId={part.id} />
                        </div>
                      </AccordionLazyContent>
                    </Accordion.Collapse>
                  </Accordion.Item>
                ) : null,
              )}
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer className="p-2" />
      </Modal>
      {(isLoadingParts || isLoadingBatch) && <Spinner />}
    </>
  );
};

export default BatchPartDetails;
