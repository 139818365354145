import React, { FC, PropsWithChildren } from 'react';

import './Header.scss';

import { useIntl } from 'react-intl';
import classNames from 'classnames';

import Search from '../Search/Search';

const Header: FC<PropsWithChildren<Props>> = ({ title, subtitle, showSearch = false, children, className }) => {
  const intl = useIntl();

  return (
    <header
      className={classNames(className, 'header-component px-5 pt-4 pb-7 d-flex justify-content-between bg-dark')}
      data-test-id="header"
    >
      <span className="title-wrapper d-flex flex-column">
        <h3 className="m-0" data-test-id="header-title">
          {title}
        </h3>
        <p className="m-0 size-label">{subtitle}</p>
      </span>
      <div className="d-flex gap-3">
        {showSearch && <Search placeholder={intl.formatMessage({ id: 'header.search.placeholder' })} />}
        {children}
      </div>
    </header>
  );
};

type Props = {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  showSearch?: boolean;
  className?: string;
};

export default Header;
