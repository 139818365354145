import React, { FC } from 'react';

import { Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import { useAppContext } from '../../context/useAppContext';
import useNotifications from '../../hooks/useNotifications';
import supportService from '../../services/supportService';
import Spinner from '../Spinner/Spinner';
import Support from './Support';

const ClientSupportModal: FC<Props> = ({ onClose }) => {
  const { notify } = useNotifications();
  const intl = useIntl();
  const {
    state: { user },
  } = useAppContext();

  const notificationTitle = intl.formatMessage({
    id: 'support.contact.header',
  });

  const { mutate: sendClientEmail, isLoading: isSubmitting } = useMutation<
    unknown,
    unknown,
    { email: string; message: string }
  >(({ email, message }) => supportService.sendClientEmail(email, message), {
    onSuccess: () => {
      notify(
        'success',
        notificationTitle,
        intl.formatMessage({
          id: 'support.notification.emailSent',
        }),
      );
      onClose();
    },
    onError: () => {
      notify('error', notificationTitle, intl.formatMessage({ id: 'support.notification.errorSendingEmail' }));
    },
  });

  const onSubmitHandler = async (email: string, message: string) => {
    await sendClientEmail({ email, message });
  };

  return (
    <>
      <Modal show centered backdrop="static" onHide={onClose}>
        <div className="text-uppercase text-color-primary-green font-bold size-label px-4 pt-4 pb-2">
          <FormattedMessage id="support.contact.header" />
        </div>
        <Modal.Header className="px-4 pb-0">
          <h3 className="mb-0 font-bold">
            <FormattedMessage id="support.contact.subHeader" />
          </h3>
        </Modal.Header>
        <Modal.Body className="px-4 pb-4 pt-3">
          <Support emailFrom={user?.email} onSubmit={onSubmitHandler} onCancel={onClose} />
        </Modal.Body>
      </Modal>
      {isSubmitting && <Spinner />}
    </>
  );
};

interface Props {
  onClose: () => void;
}

export default ClientSupportModal;
