import request from './requestService';
import PagingInfo from '../models/PagingInfo';
import ProductList from '../models/Product/ProductList';
import ProductDetails from '../models/Product/ProductDetails';
import CreateProduct from '../models/Product/CreateProduct';
import UpdateProduct from '../models/Product/UpdateProduct';
import ProductPhase from '../models/Product/ProductPhase';
import ProductStatus from '../models/Product/ProductStatus';
import ManufacturerProductList from '../models/Product/ManufacturerProductList';
import ProductBaseInfo from '../models/Product/ProductBaseInfo';

class ProductService {
  private readonly BaseUrl = `/product`;

  private readonly ManufacturerBaseUrl = `/manufacturer/product`;

  public async list(requestParams: {
    companyId?: string;
    isActive?: boolean;
    phaseId?: number;
    paging?: PagingInfo;
    searchTerm?: string;
    problemTrackers?: string[];
  }): Promise<ProductList> {
    const response = await request.post(`${this.BaseUrl}/List`, {
      companyId: requestParams.companyId,
      isActive: requestParams.isActive,
      phaseId: requestParams.phaseId,
      ...requestParams.paging,
      searchTerm: requestParams.searchTerm,
      problemTrackers: requestParams.problemTrackers,
    });
    return response.data;
  }

  public async get(id: string): Promise<ProductDetails> {
    const response = await request.get(`${this.BaseUrl}/Get/?id=${id}`);
    return response.data;
  }

  public async getBaseInfo(id: string): Promise<ProductBaseInfo> {
    const response = await request.get(`${this.BaseUrl}/GetBaseInfo/?id=${id}`);
    return response.data;
  }

  public async create(product: CreateProduct): Promise<void> {
    await request.post(`${this.BaseUrl}/Create`, product);
  }

  public async update(product: UpdateProduct): Promise<void> {
    await request.put(`${this.BaseUrl}/Update`, product);
  }

  public delete(productId: string): Promise<void> {
    return request.delete(`${this.BaseUrl}/Delete/?id=${productId}`);
  }

  public async productPhases(
    companyId?: string,
    isActive?: boolean,
  ): Promise<{ phases: ProductPhase[]; allProductCount: number }> {
    const response = await request.get<{ phases: ProductPhase[]; allProductCount: number }>(
      `${this.BaseUrl}/GetProductPhases`,
      {
        params: {
          companyId,
          isActive,
        },
      },
    );
    return response.data;
  }

  public async getStatus(id: string): Promise<ProductStatus> {
    const response = await request.get(`${this.BaseUrl}/GetProductStatus/?productId=${id}`);
    return response.data;
  }

  public async manufacturerList(productNameTerm?: string, paging?: PagingInfo): Promise<ManufacturerProductList> {
    const params = {
      productNameTerm,
      ...paging,
    };
    const response = await request.get(`${this.ManufacturerBaseUrl}/List`, {
      params,
    });
    return response.data;
  }
}

export default new ProductService();
