import React, { FC, useMemo } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart } from '@fortawesome/pro-solid-svg-icons';

import './YieldRange.scss';

import numberHelper from '../../../helpers/numberHelper';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import YieldRangeItem from '../../../models/YieldRangeItem';

const numberOfColumns = 20;

type Props = {
  value?: YieldRangeItem[] | null;
  goldenBatch?: number | null;
  yieldValue?: number | null;
  targetYield?: number | null;
  tooltip?: string;
  onlySelected?: boolean;
};

const YieldRange: FC<Props> = ({ value: columns, tooltip, goldenBatch, yieldValue, targetYield, onlySelected }) => {
  const intl = useIntl();
  const { chartData, scaleStep, minColumnValue } = useMemo(() => {
    const columnsWithData = columns?.filter((x) => x.count !== null).map((x) => x.count);

    const columnsToPrepend: (YieldRangeItem | null)[] =
      columns && columns.length > 0
        ? Array(numberOfColumns - columns.length < 0 ? 0 : numberOfColumns - columns.length).fill(null)
        : Array(numberOfColumns).fill(null);

    const columnsData =
      columns && columns.length > 0 ? [...columnsToPrepend, ...columns.slice(-numberOfColumns)] : columnsToPrepend;

    let min = columnsWithData && columnsWithData.length > 0 ? Math.min(...columnsWithData) : 0;
    const max = columnsWithData && columnsWithData.length > 0 ? Math.max(...columnsWithData) : 0;
    if (max === min) {
      min = 0;
    }

    return {
      hasData: columns && columns.length > 0,
      chartData: columnsData,
      scaleStep: 90 / (max - min),
      minColumnValue: min,
    };
  }, [columns]);

  const formatTooltip = (item: YieldRangeItem) => {
    return (
      <>
        {numberHelper.formatNumber(item.from)} - {numberHelper.formatNumber(item.to)}
        <br />
        {item.count}
      </>
    );
  };

  return (
    <div className="yield-range-component bg-color-gray-l1 p-2" data-test-id="yieldRange-component">
      <div className="size-label d-flex justify-content-between mb-2">
        <div className="d-flex align-items-center">
          <div className="size-label">
            <FormattedMessage id="tracker.yieldDistribution.title" />
          </div>
          {tooltip && (
            <div className="ms-1 d-flex">
              <IconTooltip tooltip={tooltip} className="d-inline-block" />
            </div>
          )}
        </div>
        <div className="d-flex align-items-center">
          <div className="size-label me-2">
            <FormattedMessage
              id="tracker.yieldDistribution.goldenBatchYield"
              values={{ value: numberHelper.formatNumber(goldenBatch) }}
            />
          </div>
          <OverlayTrigger
            overlay={
              <Tooltip data-test-id="iconTooltip-tooltip">
                <>
                  <div className="text-color-primary-green">
                    <FormattedMessage
                      id="tracker.yieldDistribution.targetYield"
                      values={{ value: numberHelper.formatNumber(targetYield) }}
                    />
                  </div>
                  <div className="text-color-gray-light1">
                    <FormattedMessage
                      id="tracker.yieldDistribution.yield"
                      values={{ value: numberHelper.formatNumber(yieldValue) }}
                    />
                  </div>
                </>
              </Tooltip>
            }
          >
            <span>
              <FontAwesomeIcon
                icon={faMagnifyingGlassChart}
                className="text-color-gray-l6"
                data-test-id="iconTooltip-icon"
              />
            </span>
          </OverlayTrigger>
        </div>
      </div>
      <div className="columns-box mt-auto position-relative">
        {chartData.map((column, index) => {
          const columnHeight =
            column && column.count && column.count > 0 ? scaleStep * (column.count - minColumnValue) + 10 : 10;
          const key = `${index}-${column?.from}`;
          return (
            <div className="column h-100">
              <div className="target-container">
                <OverlayTrigger
                  key={key}
                  placement="top"
                  delay={{ show: 250, hide: 250 }}
                  overlay={
                    <Tooltip className="tooltip-title">
                      {column !== null ? formatTooltip(column) : intl.formatMessage({ id: 'tracker.noData' })}
                    </Tooltip>
                  }
                >
                  <div
                    className={classNames('value-column rounded-top no-data', {
                      'in-range':
                        !onlySelected ||
                        (onlySelected && column && yieldValue && column?.from < yieldValue && column?.to >= yieldValue),
                    })}
                    style={{ height: `${columnHeight}%` }}
                    data-test-id={`yieldRange-column-${index}`}
                  />
                </OverlayTrigger>
                {(!onlySelected && yieldValue && column && column?.from < yieldValue && column?.to >= yieldValue && (
                  <div
                    className={`yield-value-${Math.round(
                      ((yieldValue - column.from) / (column.to - column.from)) * 100,
                    )}`}
                  />
                )) ||
                  ''}
                {(targetYield && column && column?.from < targetYield && column?.to >= targetYield && (
                  <div
                    className={`target-value-${Math.round(
                      ((targetYield - column.from) / (column.to - column.from)) * 100,
                    )}`}
                  />
                )) ||
                  ''}
              </div>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-between size-caption">
        <div>{numberHelper.formatNumber(columns?.[0]?.from)}</div>
        <div>{numberHelper.formatNumber(columns?.[columns.length - 1]?.to)}</div>
      </div>
    </div>
  );
};

export default YieldRange;
