import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'react-bootstrap';

const StatusBadge: FC<Props> = ({ isActive, bg, className }) => {
  return (
    <Badge
      bg={bg}
      className={`d-inline-flex align-items-center ${isActive ? 'active' : ''} ${className || ''}`}
      data-test-id="statusBadge-container"
    >
      {isActive ? <FormattedMessage id="statusBadge.active" /> : <FormattedMessage id="statusBadge.inactive" />}
    </Badge>
  );
};

interface Props {
  isActive: boolean;
  bg?: 'gray-l4';
  className?: string;
}

export default StatusBadge;
