import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import './AuthQrCode.scss';

import QRCode from 'react-qr-code';
import ActionDescriptor from '../ActionDescriptor/ActionDescriptor';
import Link from '../../constants/Authenticator/Link';
import Image from '../../constants/Authenticator/Image';

const AuthQrCode: FC<IAuthQrCodeProps> = ({ barcodeUri }) => {
  const intl = useIntl();
  return (
    <div className="auth-qr-code-component d-flex">
      <ActionDescriptor
        className="code-form bg-color-gray-l3 shadow-2"
        title={intl.formatMessage({ id: '2fa.qrCode.title' })}
        actionPoint={intl.formatMessage({ id: '2fa.qrCode.actionPoint' })}
        header={intl.formatMessage({ id: '2fa.qrCode.header' })}
        subHeader={intl.formatMessage({ id: '2fa.qrCode.subHeader' })}
      >
        <div className="bg-white p-2 my-4 mx-auto" data-code={barcodeUri}>
          <QRCode value={barcodeUri} size={150} />
        </div>
        <div className="d-flex align-items-baseline mx-auto">
          <a target="_blank" rel="noreferrer" className="app-store" href={Link.appStore}>
            <img src={Image.appStore} alt={intl.formatMessage({ id: '2fa.qrCode.appStore.gaImgAlt' })} />
          </a>
          <a target="_blank" rel="noreferrer" className="play-market" href={Link.playMarket}>
            <img alt={intl.formatMessage({ id: '2fa.qrCode.playMarket.gaImgAlt' })} src={Image.playMarket} />
          </a>
        </div>
      </ActionDescriptor>
    </div>
  );
};

interface IAuthQrCodeProps {
  barcodeUri: string;
}

export default AuthQrCode;
