import axios from 'axios';
import request from './requestService';
import DuplicateCompanyNameError from '../errors/DuplicateCompanyNameError';
import InvalidPhoneNumberError from '../errors/InvalidPhoneNumberError';
import CompanyDetails from '../models/Company/CompanyDetails';
import CompanyList from '../models/Company/CompanyList';
import CreateCompany from '../models/Company/CreateCompany';
import UpdateCompany from '../models/Company/UpdateCompany';
import PagingInfo from '../models/PagingInfo';
import { isBusinessError } from '../models/BusinessError';
import CompanyStatus from '../models/Company/CompanyStatus';

class CompanyService {
  private readonly BaseUrl = `/company`;

  public async list(requestParams?: {
    paging?: PagingInfo;
    isActive?: boolean;
    companyType?: string;
    searchTerm?: string;
  }): Promise<CompanyList> {
    const response = await request.post(`${this.BaseUrl}/list`, {
      ...requestParams?.paging,
      isActive: requestParams?.isActive,
      searchTerm: requestParams?.searchTerm,
      companyType: requestParams?.companyType,
    });
    return response.data;
  }

  public async get(id: string): Promise<CompanyDetails> {
    const response = await request.get(`${this.BaseUrl}/Get/?id=${id}`);
    return response.data;
  }

  public async create(company: CreateCompany): Promise<void> {
    try {
      await request.post(`${this.BaseUrl}/Create`, company);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'DuplicateCompanyName') throw new DuplicateCompanyNameError();
        if (error.response?.data.code === 'InvalidPhoneNumber') throw new InvalidPhoneNumberError();
      }
      throw error;
    }
  }

  public async update(company: UpdateCompany): Promise<void> {
    try {
      await request.put(`${this.BaseUrl}/Update`, company);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'DuplicateCompanyName') throw new DuplicateCompanyNameError();
        if (error.response?.data.code === 'InvalidPhoneNumber') throw new InvalidPhoneNumberError();
      }
      throw error;
    }
  }

  public delete(companyId: string): Promise<void> {
    return request.delete(`${this.BaseUrl}/Delete/?id=${companyId}`);
  }

  public async getStatus(companyId: string): Promise<CompanyStatus> {
    const response = await request.get(`${this.BaseUrl}/GetStatus/?companyId=${companyId}`);
    return response.data;
  }
}

export default new CompanyService();
