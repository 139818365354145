import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const SummaryInfoSkeleton: FC = () => {
  return (
    <div className="row gx-2 align-items-center" data-test-id="loader">
      <div className="col text-uppercase font-bold">
        <FormattedMessage id="admin.productOverview.stepsTab.summary.title" />
      </div>
      <div className="col">
        <ContentLoader
          height={37}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.darkGrey1}
          foregroundColor={SkeletonColors.mediumGrey}
          viewBox="0 0 244 37"
        >
          <rect x="0" y="0" rx="4" ry="5" width="244" height="37" />
        </ContentLoader>
      </div>
      <div className="col">
        <ContentLoader
          height={37}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.darkGrey1}
          foregroundColor={SkeletonColors.mediumGrey}
          viewBox="0 0 244 37"
        >
          <rect x="0" y="0" rx="4" ry="5" width="244" height="37" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default SummaryInfoSkeleton;
