import qs from 'qs';

import appConfig from '../../appConfig';
import MessageDetails from '../../models/Messages/MessageDetails';
import MessageList from '../../models/Messages/MessageList';
import MessagesListSortColumn from '../../models/Messages/MessagesListSortColumn';
import MessageStatus from '../../models/Messages/MessageStatus';
import SelectionState from '../../models/SelectionState';
import PagingInfo from '../../models/PagingInfo';
import { OrderBy } from '../../models/SortOrder';
import request from '../requestService';

class MessageService {
  private readonly BaseUrl = `/Message`;

  public async list(params: {
    dateFrom?: string;
    dateTo?: string;
    status?: string;
    paging?: PagingInfo;
    orderBy?: OrderBy<MessagesListSortColumn>;
  }): Promise<MessageList> {
    const response = await request.get(`${this.BaseUrl}/List`, {
      params: {
        ...params.paging,
        orderBy: params.orderBy,
        status: params.status,
        dateFrom: params.dateFrom,
        dateTo: params.dateTo,
      },
      baseURL: appConfig.internalMessagingBackendUrl,
    });
    return response.data;
  }

  public remove(selectionState: SelectionState): Promise<void> {
    return request.delete(`${this.BaseUrl}/RemoveBatch`, {
      params: {
        isAll: selectionState.defaultChecked,
        oppositeIds: Array.from(selectionState.oppositeIds),
      },
      paramsSerializer: {
        serialize: (p) => {
          return qs.stringify(p);
        },
      },
      baseURL: appConfig.internalMessagingBackendUrl,
    });
  }

  public changeStatus(selectionState: SelectionState, status: MessageStatus): Promise<void> {
    return request.put(
      `${this.BaseUrl}/ChangeStatusBatch`,
      {
        isAll: selectionState.defaultChecked,
        oppositeIds: Array.from(selectionState.oppositeIds),
        status,
      },
      {
        baseURL: appConfig.internalMessagingBackendUrl,
      },
    );
  }

  public async details(id: string): Promise<MessageDetails> {
    const response = await request.get<MessageDetails>(`${this.BaseUrl}/Details`, {
      params: {
        id,
      },
      baseURL: appConfig.internalMessagingBackendUrl,
    });
    return response.data;
  }
}

const messageService = new MessageService();

export default messageService;
