import React from 'react';
import { Form } from 'react-bootstrap';

import { useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';

import InfiniteScroll from 'react-infinite-scroll-component';
import Header from '../../components/Header/Header';
import StatusSelect from '../../components/StatusSelect/StatusSelect';
import useNotifications from '../../hooks/useNotifications';
import useProductsViewState from '../../hooks/useProductsViewState';
import productService from '../../services/productService';
import PhasesTabs from '../../components/PhasesFilter/PhasesTabs';
import ProductRow from '../../components/ProductRow/ProductRow';
import useProblemTrackerFilter from '../../hooks/useProblemTrackerFilter';
import MultiSelect from '../../components/Select/MultiSelect/MultiSelect';
import NoData from '../../components/NoData/NoData';
import ProductRowSkeleton from '../../components/Skeletons/ProductOverview/ProductRowSkeleton';

const pageSize = 20;

interface Props {
  companyId: string;
}

const Products: React.FC<Props> = ({ companyId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { phaseId, isActive, setPhaseId, setIsActive } = useProductsViewState();
  const { selectedTrackers, selectTrackers, options: problemTrackerOptions } = useProblemTrackerFilter();

  const {
    data: productsModel,
    isLoading: isLoadingProducts,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['products', companyId, phaseId, isActive, selectedTrackers],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        phaseId,
        isActive,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
        problemTrackers: selectedTrackers?.map((x) => x.value),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'products.title' }),
          intl.formatMessage({ id: 'products.notification.unableLoadProducts' }),
        ),
    },
  );

  const { isLoading: isPhasesLoading, data: phasesData } = useQuery(
    ['productPhases', companyId],
    () => productService.productPhases(companyId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'products.title' }),
          intl.formatMessage({ id: 'products.notification.unableLoadPhases' }),
        ),
    },
  );

  return (
    <>
      <Header title={intl.formatMessage({ id: 'products.title' })} showSearch />
      <div className="page-content px-5">
        <PhasesTabs
          selectedPhase={phaseId}
          onPhaseSelect={setPhaseId}
          allCount={phasesData?.allProductCount}
          phases={phasesData?.phases}
          className="mb-4"
        />
        <div className="filters-container pb-4">
          <Form.Group className="d-flex">
            <div className="me-3">
              <StatusSelect
                onChange={(value) => setIsActive(value)}
                value={isActive}
                labels={{
                  empty: intl.formatMessage({ id: 'products.filter.status.empty' }),
                  active: intl.formatMessage({ id: 'products.filter.status.onlyActive' }),
                  inactive: intl.formatMessage({ id: 'products.filter.status.onlyInactive' }),
                }}
                dataTestId="products-statusFilter"
              />
            </div>
            <div className="me-3">
              <MultiSelect
                size="lg"
                value={selectedTrackers}
                options={problemTrackerOptions}
                onChange={selectTrackers}
                placeholder={intl.formatMessage({ id: 'products.filter.problemTrackers.empty' })}
              />
            </div>
            <div className="d-flex align-items-center">
              {isActive !== undefined || (selectedTrackers && selectedTrackers.length > 0)
                ? intl.formatMessage(
                    { id: 'products.filter.total.label' },
                    { value: productsModel?.pages[0].total || 0 },
                  )
                : null}
            </div>
          </Form.Group>
        </div>
        {productsModel?.pages[0].products?.length === 0 && <NoData />}
        {!!productsModel?.pages?.[0]?.products?.length && (
          <InfiniteScroll
            className="overflow-visible"
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            dataLength={productsModel?.pages.length || 0}
            scrollableTarget="scrollContainer"
          >
            <div>
              {productsModel?.pages
                ?.flatMap((x) => x.products)
                .map((product) => (
                  <ProductRow key={product.id} product={product} />
                ))}
            </div>
          </InfiniteScroll>
        )}
        {(isLoadingProducts || isPhasesLoading) && <ProductRowSkeleton cards={3} />}
      </div>
    </>
  );
};

export default Products;
