import React, { ChangeEventHandler, FC, useEffect, useRef, useState } from 'react';

import './ImageUpload.scss';

import { Button, Form, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash, faFile } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

const ImageUpload: FC<Props> = ({ onChange, imageUrl, clearImageUrl, error, className }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<{ url: string; name?: string } | undefined>();

  useEffect(() => {
    if (imageUrl) {
      setImage({ url: imageUrl });
    }
  }, [imageUrl]);

  const setImageFile = (files: FileList | null) => {
    if (imageUrl && clearImageUrl) {
      clearImageUrl();
    }
    if (files?.length === 1) {
      setImage({ url: URL.createObjectURL(files[0]), name: files[0].name });
    } else {
      setImage(undefined);
    }
    onChange(files);
  };
  const handleImageChange: ChangeEventHandler<HTMLInputElement> = (event) => setImageFile(event.target.files);
  const clearImage = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    setImageFile(null);
  };

  return (
    <Form.Group className={`image-upload-component ${className}`} data-test-id="imageUpload-container">
      <Form.Label className="label p-4">
        <FormattedMessage id="imageUpload.title" />
      </Form.Label>
      <div className="image-upload-body p-4">
        <Form.Control
          type="file"
          ref={inputRef}
          accept="image/jpeg,image/png,image/jpg"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          isInvalid={!!error}
        />
        {image ? (
          <div className="row">
            <div className="col-3 me-3">
              <div className="image-box bg-color-gray-l1">
                <Image className="m-auto p-3" src={image.url} data-test-id="imageUpload-image" />
              </div>
            </div>
            {image.name && (
              <div className="col-6 me-3 d-flex align-items-center">
                <FontAwesomeIcon icon={faFile} className="me-2" />
                <span data-test-id="imageUpload-imageName">{image.name}</span>
              </div>
            )}
            <div className="col image-upload-clear">
              <Button variant="outline" onClick={clearImage} data-test-id="imageUpload-clear">
                <FontAwesomeIcon icon={faTrash} className="me-2" />
                <FormattedMessage id="imageUpload.clearButton" />
              </Button>
            </div>
          </div>
        ) : (
          <Button variant="outline" onClick={() => inputRef.current?.click()} data-test-id="imageUpload-upload">
            <FontAwesomeIcon icon={faImage} className="me-2" />
            <FormattedMessage id="imageUpload.uploadButton" />
          </Button>
        )}
      </div>
      {error && (
        <Form.Control.Feedback type="invalid" data-test-id="imageUpload-error">
          {error}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

type Props = {
  onChange: (value: FileList | null) => void;
  imageUrl?: string;
  clearImageUrl?: () => void;
  error?: string;
  className?: string;
};

export default ImageUpload;
