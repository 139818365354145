import React, { useEffect, FC } from 'react';

import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

import Spinner from '../../components/Spinner/Spinner';
import useActivateReducer from './useActivateReducer';
import Welcome from './Welcome/Welcome';
import useAuth from '../../hooks/useAuth';
import useQueryParams from '../../hooks/useQueryParams';
import SetPassword from '../../components/SetPassword/SetPassword';
import ActionDescriptor from '../../components/ActionDescriptor/ActionDescriptor';
import BackupRecoveryCode from '../../components/BackupRecoveryCode/BackupRecoveryCode';
import Logo from '../../components/Logo/Logo';
import Support from '../../components/Support/Support';
import { useAppContext } from '../../context/useAppContext';
import AuthQrCode from '../../components/AuthQrCode/AuthQrCode';
import CodeForm from '../../components/CodeForm/CodeForm';

const Activate: FC = () => {
  const intl = useIntl();
  const { dispatch: appDispatch } = useAppContext();
  const navigate = useNavigate();
  const {
    isLoading,
    api: { verifyActivationLink, setPassword, addOtpDevice, generateRecoveryCode, verifyOtpCode, sendHelpMessage },
  } = useAuth();
  const { token } = useQueryParams(['token']);
  const {
    state: { step, isValidToken, fullName, email, barcodeUri, twoFaToken, recoveryCode },
    dispatch,
  } = useActivateReducer();

  const navigateToLogin = (): void => {
    navigate('/login');
  };

  useEffect(() => {
    const verify = async () => {
      try {
        const res = await verifyActivationLink(token!);
        dispatch({ type: 'START_ACTIVATION', payload: { fullName: res.fullName, email: res.email } });
      } catch (err: unknown) {
        appDispatch({
          type: 'SHOW_NOTIFICATION',
          payload: {
            notification: {
              type: 'error',
              title: intl.formatMessage({ id: 'activation.title' }),
              message: intl.formatMessage({ id: 'activation.invalidToken' }),
              callback: navigateToLogin,
            },
          },
        });
      }
    };

    verify();
  }, [token]);

  const onStartHandler = () => {
    dispatch({ type: 'SHOW_SET_PASSWORD' });
  };

  const onSubmitPasswordHandler = async (password: string) => {
    try {
      const twoFa = await setPassword(token!, password);
      const barcode = await addOtpDevice(twoFa);
      dispatch({ type: 'SHOW_SETUP_TWO_FA', payload: { twoFaToken: twoFa, barcodeUri: barcode } });
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'success',
            title: intl.formatMessage({ id: 'activation.title' }),
            exclamation: intl.formatMessage({ id: 'notification.wellDone' }),
            message: intl.formatMessage({ id: 'notification.savePassword' }),
            hint: intl.formatMessage({ id: 'notification.redirectHint' }),
          },
        },
      });
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'activation.title' }),
            message: intl.formatMessage({ id: 'notification.unableSavePassword' }),
          },
        },
      });
    }
  };

  const onSubmitTwoFaHandler = async (code: string) => {
    try {
      await verifyOtpCode(twoFaToken, code);

      const recCode = await generateRecoveryCode();
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'success',
            exclamation: intl.formatMessage({ id: 'notification.wellDone' }),
            title: intl.formatMessage({ id: 'notification.verification' }),
            message: intl.formatMessage({ id: 'notification.2faVerified' }),
            hint: intl.formatMessage({ id: 'notification.redirectHint' }),
          },
        },
      });

      dispatch({ type: 'BACKUP_RECOVERY_CODE', payload: { recoveryCode: recCode } });
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'activation.title' }),
            message: intl.formatMessage({ id: '2fa.code.response.error' }),
          },
        },
      });
    }
  };

  const onSupportRequestedHandler = () => {
    dispatch({ type: 'SHOW_SUPPORT' });
  };

  const onCancelSupportHandler = () => {
    dispatch({ type: 'SHOW_SETUP_TWO_FA', payload: { twoFaToken, barcodeUri } });
  };

  const onSubmitSupportHandler = async (fromEmail: string, message: string) => {
    try {
      await sendHelpMessage(fromEmail, message, twoFaToken);
      onCancelSupportHandler();
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'success',
            title: intl.formatMessage({ id: 'activation.title' }),
            message: intl.formatMessage({ id: 'notification.emailSent' }),
            hint: intl.formatMessage({ id: 'notification.willGetInTouch' }),
          },
        },
      });
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'notification.support' }),
            message: intl.formatMessage({ id: 'notification.unableSendEmail' }),
          },
        },
      });
    }
  };

  const onRegenerateRCHandler = async (): Promise<void> => {
    const code = await generateRecoveryCode();
    dispatch({ type: 'BACKUP_RECOVERY_CODE', payload: { recoveryCode: code } });
  };

  const onBackupDoneHandler = (): void => {
    appDispatch({
      type: 'SHOW_NOTIFICATION',
      payload: {
        notification: {
          type: 'success',
          exclamation: intl.formatMessage({ id: 'notification.wellDone' }),
          title: intl.formatMessage({ id: 'notification.emergencyVerification' }),
          message: intl.formatMessage({ id: 'notification.accountSecured' }),
          hint: intl.formatMessage({ id: 'notification.redirectHint' }),
          callback: () => {
            appDispatch({ type: 'AUTHORIZE' });
            navigateToLogin();
          },
        },
      },
    });
  };

  const renderStep = React.useCallback(() => {
    switch (step) {
      case 'WELCOME':
        return <Welcome name={fullName} onStart={onStartHandler} />;
      case 'SET_PASSWORD':
        return (
          <ActionDescriptor
            className="bg-color-gray-l3 align-items-center shadow-2"
            title={intl.formatMessage({ id: 'activation.title' })}
            header={intl.formatMessage({ id: 'activation.setPassword.header' })}
            subHeader={intl.formatMessage({ id: 'activation.setPassword.subHeader' })}
          >
            <SetPassword
              onSubmit={onSubmitPasswordHandler}
              btnTitle={intl.formatMessage({ id: 'activation.setPassword.btn.label' })}
            />
          </ActionDescriptor>
        );
      case 'SETUP_TWO_FA':
        return (
          <div className="d-flex m-auto">
            <AuthQrCode barcodeUri={barcodeUri} />
            <ActionDescriptor
              className="code-form bg-color-gray-l3 ms-4 shadow-2"
              title={intl.formatMessage({ id: 'activation.title' })}
              actionPoint={intl.formatMessage({ id: 'activation.2fa.code.actionPoint' })}
              header={intl.formatMessage({ id: 'activation.2fa.code.header' })}
              subHeader={intl.formatMessage({ id: 'activation.2fa.code.subHeader' })}
            >
              <CodeForm
                onSubmit={onSubmitTwoFaHandler}
                onSupportRequested={onSupportRequestedHandler}
                buttonClassName="btn-rounded"
              />
            </ActionDescriptor>
          </div>
        );
      case 'SHOW_SUPPORT':
        return (
          <div className="m-auto shadow-2 mt-5">
            <ActionDescriptor
              className="code-form bg-color-gray-l3"
              title={intl.formatMessage({ id: 'activation.title' })}
              header={intl.formatMessage({ id: 'support.needHelp.header' })}
              subHeader={intl.formatMessage({ id: 'support.getInTouch.subHeader' })}
            >
              <Support onSubmit={onSubmitSupportHandler} onCancel={onCancelSupportHandler} emailFrom={email} />
            </ActionDescriptor>
          </div>
        );
      case 'BACKUP_RECOVERY_CODE':
        return (
          <ActionDescriptor
            className="bg-color-gray-l3 m-auto align-items-center shadow-2"
            title={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.title' })}
            header={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.header' })}
            subHeader={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.subHeader' })}
          >
            <BackupRecoveryCode
              codes={recoveryCode}
              slimBtn
              onRegenerate={onRegenerateRCHandler}
              onBackup={onBackupDoneHandler}
            />
          </ActionDescriptor>
        );
      default:
        return null;
    }
  }, [step, fullName, recoveryCode, barcodeUri]);

  return (
    <>
      <div className="m-auto">
        <div className="d-flex flex-column w-100 smart-scroll">
          <div className="w-100 text-center pt-6 pb-2 align-items-center">
            <Logo />
          </div>
          <div className="d-flex m-auto p-5">{isValidToken && renderStep()}</div>
        </div>
      </div>
      {isLoading && <Spinner />}
    </>
  );
};

export default Activate;
