import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  values: { url?: string; label: string }[];
}

const Breadcrumbs: FC<Props> = ({ values }) => (
  <span>
    {values.map((x, i, arr) => (
      <span key={`${x.url}-${x.label}`} className="text-color-gray-l6 size-body">
        {x.url ? (
          <Link className="base-link-gray size-body" to={x.url}>
            {x.label}
          </Link>
        ) : (
          x.label
        )}
        {arr.length - 1 !== i && '  >  '}
      </span>
    ))}
  </span>
);
export default Breadcrumbs;
