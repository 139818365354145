import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import Spinner from '../../../../components/Spinner/Spinner';
import useNotifications from '../../../../hooks/useNotifications';
import AppDetails from '../../../../models/AppGallery/Application/AppDetails';
import ApplicationItem from '../../../../models/AppGallery/Application/ApplicationItem';
import UserApplicationStatus from '../../../../models/AppGallery/Application/UserApplicationStatus';
import applicationService from '../../../../services/AppGallery/applicationService';
import imageFileService from '../../../../services/AppGallery/imageFileService';
import './AppsTabCard.scss';
import AppsTabCardSkeleton from './AppsTabCardSkeleton';

const AppsTabCard: React.FC<Props> = ({ application }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const { data: appDetails, isLoading: isLoadingItem } = useQuery<AppDetails>(
    ['applicationDetails', application.id],
    () => applicationService.details(application.id),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({ id: 'settings.tab.apps.notification.unableLoadApps' }),
        ),
    },
  );

  const { mutate: activateApplication, isLoading: isActivating } = useMutation<unknown, unknown, string>(
    (id) => applicationService.activateApplication(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('navigation');
        queryClient.invalidateQueries('userApps');
        queryClient.invalidateQueries('modules');
        notify(
          'success',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({
            id: 'settings.tab.apps.notification.activatedApplication',
          }),
        );
      },
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({ id: 'settings.tab.apps.notification.unableActivateApplication' }),
        );
      },
    },
  );
  const { mutate: deactivateApplication, isLoading: isDeactivating } = useMutation<unknown, unknown, string>(
    (id) => applicationService.deactivateApplication(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('navigation');
        queryClient.invalidateQueries('userApps');
        queryClient.invalidateQueries('modules');
        notify(
          'success',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({
            id: 'settings.tab.apps.notification.deactivatedApplication',
          }),
        );
      },
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({ id: 'settings.tab.apps.notification.unableDeactivateApplication' }),
        );
      },
    },
  );

  return (
    <>
      {appDetails && application && (
        <div className="apps-tab-card-component bg-color-gray-l3 rounded-4">
          <div className="d-flex justify-content-between card-divider animated-component-item">
            <div className="text-uppercase p-3">
              <h3 className="mb-0">{application.name}</h3>
            </div>
            <div className="p-3">
              {application.userStatus === UserApplicationStatus.Available && (
                <Button size="sm" onClick={() => activateApplication(application.id)}>
                  <FormattedMessage id="settings.tab.apps.action.activate" />
                </Button>
              )}
              {application.userStatus === UserApplicationStatus.Activated && (
                <Button size="sm" onClick={() => deactivateApplication(application.id!)}>
                  <FormattedMessage id="settings.tab.apps.action.deactivate" />
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex flex-row p-4 animated-component-item">
            <div className="me-4 d-flex flex-column">
              <img
                src={imageFileService.getImageUrl(application.iconImageId)}
                alt="Application"
                className="apps-tab-card-component-img mb-3"
              />
            </div>
            <div className="d-flex justify-content-between w-100 flex-column">
              <div className="p-3 text-color-gray-light1 bg-color-gray-l2 size-label rounded-4">
                {appDetails?.description}
              </div>
            </div>
          </div>
        </div>
      )}
      {isLoadingItem && <AppsTabCardSkeleton cards={1} />}
      {isActivating || (isDeactivating && <Spinner />)}
    </>
  );
};
interface Props {
  application: ApplicationItem;
}
export default AppsTabCard;
