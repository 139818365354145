import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../models/Skeletons/SkeletonSpeed';
import './ProcessTab.scss';

const ProcessTabSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  const processItemName = ['Development', 'ScaleUp', 'Validation', 'Regulatory', 'Manufacture'];

  return (
    <div className="process-tab-component pb-3" data-test-id="loader">
      <div className="process-tab-component-title row gap-3">
        {items.map((key) => (
          <div key={key} className="animated-component-skeleton col py-3 px-4 pb-0 bg-color-gray-l4 text-center">
            <div className={`process-tab-component-type process-tab-component-type-${processItemName[key - 1]}`}>
              <FormattedMessage id={`appGallery.processTab.process.${processItemName[key - 1]}`} />
            </div>
          </div>
        ))}
      </div>
      <div className="process-tab-component-body row gap-3">
        {items.map((key) => (
          <div key={key} className="animated-component-skeleton col p-3 bg-color-gray-l4">
            <div className="process-tab-component-body-item">
              <div className="mb-2">
                <ContentLoader
                  height={122}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.darkGrey1}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 260 122"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
                </ContentLoader>
              </div>
              <div className="mb-2">
                <ContentLoader
                  height={122}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.darkGrey1}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 260 122"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
                </ContentLoader>
              </div>
              <div className="mb-2">
                <ContentLoader
                  height={122}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.darkGrey1}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 260 122"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
                </ContentLoader>
              </div>
              <div className="mb-2">
                <ContentLoader
                  height={122}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.darkGrey1}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 260 122"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
                </ContentLoader>
              </div>
              <div className="mb-2">
                <ContentLoader
                  height={122}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.darkGrey1}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 260 122"
                >
                  <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
                </ContentLoader>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
interface Props {
  cards: number;
}
export default ProcessTabSkeleton;
