import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useNotifications from '../../../hooks/useNotifications';
import productService from '../../../services/productService';
import synthesisStepService from '../../../services/synthesisStepService';
import OutOfRange from '../../../components/trackers/OutOfRange/OutOfRange';
import ProductStep from '../../../components/ProductStep/ProductStep';
import ShortInfoSkeleton from '../../../components/Skeletons/ShortInfoSkeleton';
import ProductStepSkeleton from '../../../components/Skeletons/ProductOverview/ProductStepSkeleton';
import StatusTrackersSkeleton from '../../../components/Skeletons/ProductOverview/Status/StatusTrackersSkeleton';
import OutOfSpecification from '../../../components/trackers/OutOfSpecification/OutOfSpecification';
import productsHelper from '../../../helpers/productsHelper';
import ProblemTrackerFilter from '../../../models/ProblemTrackerFilter';
import OutOfTime from '../../../components/trackers/OutOfTime/OutOfTime';
import ProductAlertsMini from '../../../components/ProductAlerts/ProductAlertsMini/ProductAlertsMini';
import ProductUpdatesMini from '../../../components/ProductUpdates/ProductUpdatesMini/ProductUpdatesMini';

interface Props {
  companyId: string;
}

const StatusTab: FC<Props> = ({ companyId }) => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['productStatus', productId],
    () => productService.getStatus(productId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'productsOverview.statusTab.notification.title' }),
          intl.formatMessage({ id: 'productsOverview.statusTab.notification.unableLoadStatus' }),
        ),
    },
  );
  const { data: steps, isLoading: isLoadingSteps } = useQuery(
    ['synthesisSteps', productId, companyId],
    () => synthesisStepService.list(productId!, companyId ?? undefined),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'productsOverview.statusTab.notification.title' }),
          intl.formatMessage({ id: 'productsOverview.statusTab.notification.unableLoadSteps' }),
        ),
    },
  );

  return (
    <div>
      <div className="row gx-4 mb-4">
        <div className="col-8">
          <div className="card p-3">
            <div className="row font-bold text-uppercase pb-3">
              <FormattedMessage id="productsOverview.statusTab.statusBlock.title" />
            </div>
            {isLoadingStatus ? (
              <StatusTrackersSkeleton />
            ) : (
              <div className="d-flex gap-3 chart-row">
                <div className="overflow-auto flex-2">
                  <OutOfRange
                    value={status?.outOfRange}
                    columns={status?.outOfRangeList}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfRange,
                    })}
                    getBatchUrl={(batchId: string) =>
                      productsHelper.getBatchesUrl({
                        productId: productId!,
                        viewState: {
                          idsFilter: [batchId],
                        },
                      })
                    }
                  />
                </div>
                <div className="flex-1">
                  <OutOfSpecification
                    numerator={status?.outOfSpecificationNumerator}
                    denominator={status?.outOfSpecificationDenominator}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfSpecification,
                    })}
                  />
                </div>
                <div className="flex-1">
                  <OutOfTime
                    delay={status?.outOfTime}
                    status={status?.outOfTimeStatus}
                    tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.masterProduct' })}
                    link={productsHelper.getBatchesUrl({
                      productId: productId!,
                      problemTracker: ProblemTrackerFilter.OutOfTime,
                    })}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-4">
          <div className="card p-3">
            <div className="row font-bold text-uppercase pb-3">
              <FormattedMessage id="productsOverview.statusTab.stepsTotal.title" />
            </div>
            {isLoadingStatus ? (
              <ShortInfoSkeleton />
            ) : (
              <div className="chart-row">
                <div className="row card-line px-3 card-line-padding mb-2">
                  <div className="col">
                    <FormattedMessage id="productsOverview.statusTab.stepsTotal.total" />
                  </div>
                  <div className="col-auto" data-test-id="productsOverview-status-steps-total">
                    {steps?.total}
                  </div>
                </div>
                <div className="row card-line px-3 card-line-padding mb-2">
                  <div className="col">
                    <FormattedMessage id="productsOverview.statusTab.stepsTotal.active" />
                  </div>
                  <div
                    className={`col-auto ${steps?.active && steps.active > 0 ? 'text-color-graph-positive' : ''}`}
                    data-test-id="productsOverview-status-steps-active"
                  >
                    {steps?.active}
                  </div>
                </div>
                <div className="row card-line px-3 card-line-padding">
                  <div className="col">
                    <FormattedMessage id="productsOverview.statusTab.stepsTotal.finished" />
                  </div>
                  <div className="col-auto" data-test-id="productsOverview-status-steps-finished">
                    {steps?.finished}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row gx-4">
        {steps?.steps.map((step, idx) => (
          <ProductStep key={step.id} step={step} highlighted={idx === 0} />
        ))}
        {isLoadingSteps && <ProductStepSkeleton cards={2} />}
      </div>
      <div className="row gx-4">
        <div className="col-6">
          <ProductAlertsMini productId={productId} companyId={companyId} />
        </div>
        <div className="col-6">
          <ProductUpdatesMini productId={productId} companyId={companyId} />
        </div>
      </div>
    </div>
  );
};

export default StatusTab;
