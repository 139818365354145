import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'react-bootstrap';

import './OutOfRangeActions.scss';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';

const OutOfRangeActions: FC<Props> = ({ outOfRangeActions, totalActions, title, tooltip, partNumber, onClick }) => {
  const isError = outOfRangeActions && outOfRangeActions > 0;
  const ref = useRef(null);

  return (
    <div className="out-of-range-actions-component bg-color-gray-l1 p-2">
      <div className="size-label row w-100">
        <div className="col text-color-gray-l6">
          <FormattedMessage id="outOfRangeActions.parts" /> {partNumber}
        </div>
        <div ref={ref} className="col-auto d-flex align-items-center">
          {title}
          <div className="ms-1 d-inline-flex">
            <IconTooltip tooltip={tooltip!} className="d-inline-flex" />
          </div>
        </div>
      </div>
      <h3 className="my-auto font-bold">
        <span className={isError ? 'text-color-error' : 'text-color-primary-green'}>
          {outOfRangeActions}/{totalActions}
        </span>
      </h3>
      <Button
        disabled={!isError}
        variant={isError ? 'danger' : 'secondary'}
        onClick={onClick}
        className="w-100"
        size="sm"
      >
        <FormattedMessage id="outOfRangeActions.showEvents" />
      </Button>
    </div>
  );
};

type Props = {
  title: string;
  tooltip?: string;
  outOfRangeActions?: number;
  totalActions?: number;
  partNumber?: number;
  onClick(): void;
};

export default OutOfRangeActions;
