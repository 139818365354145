import React, { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';

import { useAppContext } from '../../context/useAppContext';
import events from '../../constants/events';
import authStorageService from '../../services/authStorageService';
import Spinner from '../Spinner/Spinner';
import accountService from '../../services/accountService';

const ProtectedRoute: FC = () => {
  const location = useLocation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const {
    state: { authorized, user },
    dispatch,
  } = useAppContext();

  useEffect(() => {
    function logoutCleanup() {
      authStorageService.setState(null);
      queryClient.removeQueries();
      dispatch({ type: 'UNAUTHORIZE' });
    }

    function redirectToDashboard() {
      navigate('/');
    }

    window.addEventListener(events.logouted, logoutCleanup);
    window.addEventListener(events.forbidden, redirectToDashboard);
    window.addEventListener(events.notFound, redirectToDashboard);
    return function cleanup() {
      window.removeEventListener(events.logouted, logoutCleanup);
      window.removeEventListener(events.forbidden, redirectToDashboard);
      window.removeEventListener(events.notFound, redirectToDashboard);
    };
  }, [authorized]);

  const { data: userInfo } = useQuery(['userInfo'], () => accountService.getUserInfo(), {
    enabled: authorized,
  });

  useEffect(() => {
    if (userInfo) {
      dispatch({ type: 'SET_USER', payload: { user: userInfo } });
    }
  }, [userInfo]);

  if (!authorized) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return user ? <Outlet /> : <Spinner />;
};

export default ProtectedRoute;
