import TrackerVariant from '../models/TrackerState';
import TrackerStatus from '../models/TrackerStatus';

export default {
  getTypeForFlag(value: boolean | null): TrackerVariant {
    if (value === null) {
      return TrackerVariant.noData;
    }

    return value ? TrackerVariant.negative : TrackerVariant.positive;
  },
  getTypeForValue(value?: number | null): TrackerVariant {
    if (value === null || value === undefined) {
      return TrackerVariant.noData;
    }

    return this.getTypeForFlag(value > 0);
  },
  getTypeForProgress(value?: number | null) {
    if (value === null || value === undefined) {
      return TrackerVariant.noData;
    }
    return TrackerVariant.progress;
  },
  getTypeForRegular(value?: number | null) {
    if (value === null || value === undefined) {
      return TrackerVariant.noData;
    }
    return TrackerVariant.regular;
  },
  getTypeForPositive(value?: number | null) {
    if (value === null || value === undefined) {
      return TrackerVariant.noData;
    }
    return value > 0 ? TrackerVariant.positive : TrackerVariant.regular;
  },
  getTypeForTrackerStatus(status?: TrackerStatus | null, normalVariant: TrackerVariant = TrackerVariant.regular) {
    if (status === null || status === undefined) {
      return TrackerVariant.noData;
    }

    if (status === TrackerStatus.Error) return TrackerVariant.negative;
    if (status === TrackerStatus.Warning) return TrackerVariant.warning;

    return normalVariant;
  },
};
