import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RegexAdaptRoute: FC<Props> = ({ regex, replace, fallback, fallbackRegex = /^.*$/gi }) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const route = `${pathname}${search}`;

  useEffect(() => {
    const to = !regex.test(route) ? route.replace(fallbackRegex, fallback) : route.replace(regex, replace);
    navigate(to, { replace: true });
  }, []);

  return null;
};

interface Props {
  regex: RegExp;
  replace: string;
  fallback: string;
  fallbackRegex?: RegExp;
}

export default RegexAdaptRoute;
