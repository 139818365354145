import React, { FC, PropsWithChildren, useContext } from 'react';
import TabsContext from './TabsContext';

const TabsContentItem: FC<PropsWithChildren<Props>> = ({ children, id }) => {
  const { activeTab } = useContext(TabsContext);
  return (
    <div className={`tab-pane fade ${activeTab === id ? 'show active' : ''}`} id={id} role="tabpanel" tabIndex={0}>
      {children}
    </div>
  );
};

interface Props {
  id: string;
}
export default TabsContentItem;
