import React, { useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQueryClient } from 'react-query';
import { faPencil, faTrash } from '@fortawesome/pro-solid-svg-icons';

import './ProductRow.scss';

import StatusBadge from '../StatusBadge/StatusBadge';
import dataPlaceholders from '../../constants/dataPlaceholders';
import productImageService from '../../services/productImageService';
import OutOfRange from '../trackers/OutOfRange/OutOfRange';
import ProductItem from '../../models/Product/ProductItem';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import ReportButton from '../Report/ReportButton';
import ReportData from '../../models/Report/ReportData';
import EntityTypes from '../../models/Report/EntityTypes';
import OutOfSpecification from '../trackers/OutOfSpecification/OutOfSpecification';
import productsHelper from '../../helpers/productsHelper';
import ProblemTrackerFilter from '../../models/ProblemTrackerFilter';
import OutOfTime from '../trackers/OutOfTime/OutOfTime';
import companiesHelper from '../../helpers/companiesHelper';
import ActionsDropdown from '../ActionsDropdown/ActionsDropdown';
import productService from '../../services/productService';
import Spinner from '../Spinner/Spinner';
import useNotifications from '../../hooks/useNotifications';
import TitleTooltip from '../Tooltips/TitleTooltip/TitleTooltip';

const ProductRow: React.FC<Props> = ({ product, showCompany, showActions }) => {
  const intl = useIntl();
  const reportData: ReportData = useMemo(() => {
    return {
      productName: product.name,
      entityType: EntityTypes.product,
      entityId: product.id,
    };
  }, [product]);

  const navigate = useNavigate();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();

  const { mutate: deleteItem, isLoading: isDeleting } = useMutation(
    async (id: string) => {
      await productService.delete(id);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['products']);
        await queryClient.invalidateQueries(['productPhases']);
        await queryClient.invalidateQueries(['companyStatus', product.companyId]);
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'productRow.notification.title' }),
          intl.formatMessage({ id: 'productRow.notification.unableDeleteProduct' }),
        ),
    },
  );

  return (
    <div className="product-row card mb-4" key={product.id} data-test-id={`productRow-${product.name}`}>
      <div className="row">
        <div className="card-divider d-flex justify-content-between p-3 align-items-center">
          <div>
            <h3 className="font-bold text-uppercase mb-0" data-test-id="products-card-name">
              {product.name || dataPlaceholders.missingData}
            </h3>
            {showCompany && (
              <Link
                to={companiesHelper.getCompanyUrl(product.companyId)}
                data-test-id="products-card-companyName"
                className="base-link size-label text-uppercase"
              >
                {product.companyName}
              </Link>
            )}
          </div>
          <div className="d-flex align-items-center gap-4">
            <StatusBadge isActive={product.isActive} className="size-caption" />
            <span className="size-caption" data-test-id="productsRow-phase">
              <FormattedMessage id="productRow.phase" /> {product.phaseName || dataPlaceholders.missingData}
            </span>
            <span className="size-caption" data-test-id="productsRow-updatedAt">
              <FormattedMessage id="productRow.updatedAt" /> <GenericFormattedDate value={product.updatedAt} />
            </span>
            <ReportButton reportData={reportData} />
            <Link
              className="btn btn-sm btn-primary d-flex align-items-center"
              to={productsHelper.getProductUrl(product.id)}
              data-test-id="productsRow-button-details"
            >
              <FormattedMessage id="productRow.action.details" />
            </Link>
            {showActions && (
              <ActionsDropdown>
                <Dropdown.Item
                  className="text-uppercase d-flex align-items-center"
                  onClick={() => navigate(productsHelper.getProductEditUrl(product.id))}
                  data-test-id="products-card-edit"
                >
                  <FontAwesomeIcon icon={faPencil} className="pe-3" />
                  <FormattedMessage id="productRow.action.edit" />
                </Dropdown.Item>
                <Dropdown.Item
                  className="text-uppercase d-flex align-items-center"
                  variant="link"
                  onClick={() => deleteItem(product.id)}
                  data-test-id="products-card-delete"
                >
                  <FontAwesomeIcon icon={faTrash} className="pe-3" />
                  <FormattedMessage id="productRow.action.delete" />
                </Dropdown.Item>
              </ActionsDropdown>
            )}
          </div>
        </div>
      </div>
      <div className="pb-3">
        <div className="chart-row card-divider p-3 d-flex gap-3 h-100">
          <div className="flex-2">
            <OutOfRange
              value={product.outOfRange}
              columns={product.outOfRangeList}
              tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.masterProduct' })}
              link={productsHelper.getBatchesUrl({
                productId: product.id,
                problemTracker: ProblemTrackerFilter.OutOfRange,
              })}
              getBatchUrl={(batchId: string) =>
                productsHelper.getBatchesUrl({
                  productId: product.id,
                  viewState: {
                    idsFilter: [batchId],
                  },
                })
              }
            />
          </div>
          <div className="flex-1">
            <OutOfSpecification
              numerator={product?.outOfSpecificationNumerator}
              denominator={product?.outOfSpecificationDenominator}
              tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.masterProduct' })}
              link={productsHelper.getBatchesUrl({
                productId: product.id,
                problemTracker: ProblemTrackerFilter.OutOfSpecification,
              })}
            />
          </div>
          <div className="flex-1">
            <OutOfTime
              delay={product.outOfTime}
              status={product.outOfTimeStatus}
              tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.masterProduct' })}
              link={productsHelper.getBatchesUrl({
                productId: product.id,
                problemTracker: ProblemTrackerFilter.OutOfTime,
              })}
            />
          </div>
          <div className="flex-1">
            <div className="product-inner-block  bg-color-gray-l1 p-2">
              <img
                src={productImageService.getImageUrl(product.imageId)}
                alt={product.name}
                className="m-auto"
                data-test-id="productRow-image"
              />
            </div>
          </div>
          <div className="flex-2 p-3 bg-color-gray-l2 rounded-4" data-test-id="productsRow-productInfo">
            <div className="row">
              <div className="col-6 mb-2">
                <div className="text-color-gray-l6 size-caption mb-2">
                  <FormattedMessage id="productRow.commercialName" />
                </div>
                <div className="text-color-white size-label me-2" data-test-id="productRow-commercialName">
                  {product.commercialName ? (
                    <TitleTooltip
                      title={product.commercialName}
                      titleTooltip={product.commercialName}
                      titleClassName="inline-crop"
                    />
                  ) : (
                    dataPlaceholders.missingData
                  )}
                </div>
              </div>
              <div className="col-6 mb-2">
                <div className="text-color-gray-l6 size-caption mb-2">
                  <FormattedMessage id="productRow.productType" />
                </div>
                <div className="text-color-white size-label me-2" data-test-id="productRow-productType">
                  {product.productType ? (
                    <TitleTooltip
                      title={product.productType}
                      titleTooltip={product.productType}
                      titleClassName="inline-crop"
                    />
                  ) : (
                    dataPlaceholders.missingData
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="text-color-gray-l6 size-caption mb-2">
                  <FormattedMessage id="productRow.casNumber" />
                </div>
                <div className="text-color-white size-label me-2" data-test-id="productRow-casNumber">
                  {product.casNumber || dataPlaceholders.missingData}
                </div>
              </div>
              <div className="col-6">
                <div className="text-color-gray-l6 size-caption mb-2">
                  <FormattedMessage id="productRow.manufacturer" />
                </div>
                <div className="text-color-white size-label me-2" data-test-id="productRow-manufacturer">
                  {product.manufacturerCompanyName ? (
                    <TitleTooltip
                      title={product.manufacturerCompanyName}
                      titleTooltip={product.manufacturerCompanyName}
                      titleClassName="inline-crop"
                    />
                  ) : (
                    dataPlaceholders.missingData
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isDeleting && <Spinner />}
    </div>
  );
};

interface Props {
  product: ProductItem;
  showCompany?: boolean;
  showActions?: boolean;
}

export default ProductRow;
