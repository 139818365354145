import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import useNotifications from '../../hooks/useNotifications';
import useQueryStringState from '../../hooks/useQueryStringState';
import CampaignItem from '../../models/Campaign/CampaignItem';
import CampaignListViewState from '../../models/Campaign/CampaignListViewState';
import CampaignPhase from '../../models/Campaign/CampaignPhase';
import SelectOption from '../../models/SelectOption';
import campaignService from '../../services/campaignService';
import synthesisStepService from '../../services/synthesisStepService';

const pageSize = 20;

const useCampaignListViewState = (
  productId: string,
  problemTrackers?: SelectOption[],
  companyId?: string,
  stepId?: string,
) => {
  const [viewState, , setViewStateFunc] = useQueryStringState<CampaignListViewState>();

  useEffect(() => {
    if (stepId) {
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, synthesisStepId: stepId !== '' ? stepId : undefined },
      }));
    }
  }, [stepId]);

  const intl = useIntl();
  const { notify } = useNotifications();
  const {
    data: campaignsData,
    isLoading: isLoadingCampaigns,
    isRefetching: isRefetchingCampaigns,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['campaigns', companyId, productId, viewState.search.synthesisStepId, viewState.search.phase, problemTrackers],
    async ({ pageParam = 1 }) => {
      const data = await campaignService.list({
        productId,
        companyId,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
        problemTrackers: problemTrackers?.map((x) => x.value),
        synthesisStepId: viewState.search.synthesisStepId,
        phase: viewState.search.phase as CampaignPhase,
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'campaigns.notification.title' }),
          intl.formatMessage({ id: 'campaigns.notification.unableToLoadCampaigns' }),
        ),
    },
  );

  const { data: steps, isLoading: isLoadingSteps } = useQuery(
    ['synthesisSteps', productId, companyId],
    () => synthesisStepService.list(productId, companyId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'campaigns.notification.title' }),
          intl.formatMessage({ id: 'campaigns.notification.unableLoadSteps' }),
        ),
    },
  );

  const groupedCampaigns: {
    step: { id: string; name: string; totalCampaigns: number; totalBatches: number };
    campaigns: CampaignItem[];
  }[] = useMemo(() => {
    if (!steps) {
      return [];
    }

    return [
      ...(campaignsData?.pages
        .flatMap((x) => x.campaigns)
        .reduce((r, o) => {
          const key = o.synthesisStepId;
          let item = r.get(key);
          if (!item) {
            const step = steps.steps.filter((x) => x.id === key)?.[0];
            item = {
              step: {
                id: o.synthesisStepId,
                name: o.synthesisStepName,
                totalCampaigns: step?.totalCampaigns,
                totalBatches: step?.totalBatches,
              },
              campaigns: [],
            };
          }
          item.campaigns = [...item.campaigns, o];
          return r.set(key, item);
        }, new Map())
        .values() || []),
    ];
  }, [campaignsData, steps]);

  const stepOptions = useMemo(
    () => [
      { value: '', label: intl.formatMessage({ id: 'campaigns.filter.synthesisStep.placeholder' }) },
      ...(steps?.steps.map((x) => ({ value: x.id, label: x.name })) || []),
    ],
    [steps],
  );
  const phaseOptions = useMemo(
    () => [
      { value: '', label: intl.formatMessage({ id: 'campaigns.filter.phase.placeholder' }) },
      { value: CampaignPhase.InProgress, label: intl.formatMessage({ id: 'campaigns.filter.phase.inProgress' }) },
      { value: CampaignPhase.Next, label: intl.formatMessage({ id: 'campaigns.filter.phase.next' }) },
      { value: CampaignPhase.Finished, label: intl.formatMessage({ id: 'campaigns.filter.phase.finished' }) },
    ],
    [],
  );

  return {
    hash: viewState.hash,
    isLoadingCampaigns: (isLoadingCampaigns || isRefetchingCampaigns) && !isFetchingNextPage,
    groupedCampaings: groupedCampaigns,
    totalPages: campaignsData?.pages.length || 0,
    totalCampaings: campaignsData?.pages?.[0]?.total || 0,
    totalSynthesisSteps: campaignsData?.pages?.[0]?.totalSynthesisSteps || 0,
    fetchNextPage,
    hasNextPage: hasNextPage || false,
    steps: stepOptions || [],
    isLoadingSteps,
    selectedSynthesisStep: viewState.search.synthesisStepId,
    handleSelectSynthesisStep: (synthesisStepId?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, synthesisStepId: synthesisStepId !== '' ? synthesisStepId : undefined },
        hash: '',
      })),
    phases: phaseOptions,
    selectedPhase: viewState.search.phase,
    handleSelectPhase: (phase?: string) =>
      setViewStateFunc((state) => ({
        ...state,
        search: { ...state.search, phase: phase !== '' ? phase : undefined },
        hash: '',
      })),
  };
};

export default useCampaignListViewState;
