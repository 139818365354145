import React, { FC, useEffect } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useQuery } from 'react-query';
import { Accordion } from 'react-bootstrap';
import classNames from 'classnames';

import BatchActions from '../BatchActions/BatchActions';
import useNotifications from '../../../../../hooks/useNotifications';
import batchService from '../../../../../services/batchService';
import AccordionLazyContent from '../../../../Accordion/AccordionToggle/AccordionLazy/AccordionLazyContent';
import Spinner from '../../../../Spinner/Spinner';
import useQueryStringState from '../../../../../hooks/useQueryStringState';
import AccordionToggle from '../../../../Accordion/AccordionToggle/AccordionToggle';

interface Props {
  batchId: string;
  partId: string;
}

const BatchStages: FC<Props> = ({ batchId, partId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const [viewState, , setViewStateFunc] = useQueryStringState<{
    parts: { [partId: string]: { stageId?: string } | undefined } | undefined;
  }>();

  const { data: stagesData, isLoading: isLoadingList } = useQuery(
    ['stages', batchId, partId],
    () => batchService.listFailedStages(batchId, partId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.title' }),
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.unableLoadStages' }),
        ),
    },
  );

  const selectStage = (stageId?: string) => {
    setViewStateFunc((state) => ({
      ...state,
      search: {
        ...state.search,
        parts: {
          ...state.search.parts,
          [partId]: stageId === state.search.parts?.[partId]?.stageId ? undefined : { stageId },
        },
      },
    }));
  };

  useEffect(() => {
    if (stagesData?.stages.length === 1) {
      setViewStateFunc((state) => ({
        ...state,
        search: {
          ...state.search,
          parts: {
            ...state.search.parts,
            [partId]: { stageId: stagesData.stages[0].id },
          },
        },
      }));
    }
  }, [stagesData]);

  return (
    <>
      <Accordion activeKey={viewState?.search?.parts?.[partId]?.stageId ?? null}>
        {stagesData?.stages.map((stage) => (
          <Accordion.Item key={stage.id} eventKey={stage.id}>
            <div className="row p-3 header card-divider">
              <AccordionToggle eventKey={stage.id} callback={selectStage}>
                <div className="col-6 d-flex">
                  <div
                    className={classNames('col-4 d-flex align-self-center size-label', {
                      'text-color-primary-green': viewState?.search?.parts?.[partId]?.stageId === stage.id,
                    })}
                  >
                    {stage.name}
                  </div>
                  <div className="col-6 d-flex align-self-center size-label font-normal ps-3">
                    <FormattedMessage
                      id="batchParts.batchTable.part.details.stageActions"
                      values={{ failed: stage.outOfRange, total: stage.actionNumber }}
                    />
                  </div>
                </div>
              </AccordionToggle>
            </div>
            <Accordion.Collapse eventKey={stage.id}>
              <AccordionLazyContent eventKey={stage.id}>
                <BatchActions batchId={batchId} stageId={stage.id} />
              </AccordionLazyContent>
            </Accordion.Collapse>
          </Accordion.Item>
        ))}
      </Accordion>
      {isLoadingList && <Spinner />}
    </>
  );
};

export default BatchStages;
