import React, { FC, useMemo } from 'react';

import { Button, Form, InputGroup } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';

import { useForm } from 'react-hook-form';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { transformString } from '../../helpers/yupExtentions';
import Shape from '../../models/Shape';

const Support: FC<Props> = ({ onSubmit, onCancel, emailFrom, roundButtons }) => {
  const intl = useIntl();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      email: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .email(intl.formatMessage({ id: 'validation.email' })),
      message: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' })),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    defaultValues: {
      email: emailFrom,
    },
    resolver: yupResolver(validationSchema),
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const onSubmitFormHandler = async (data: FormData) => {
    setIsSubmitting(true);
    await onSubmit(data.email, data.message);
    setIsSubmitting(false);
  };

  return (
    <Form className="d-flex flex-column mt-4" noValidate onSubmit={handleSubmit(onSubmitFormHandler)}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="size-caption">
          <FormattedMessage id="support.form.email.label" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            autoFocus={!emailFrom}
            type="email"
            size="lg"
            placeholder={intl.formatMessage({ id: 'support.form.email.placeholder' })}
            {...register('email')}
            isInvalid={!!errors?.email}
            data-test-id="support-email"
          />
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
        </InputGroup>
        {!!errors?.email && (
          <Form.Control.Feedback type="invalid" data-test-id="support-email-error">
            {errors.email.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-4" controlId="formBasicEmail">
        <Form.Label className="size-caption">
          <FormattedMessage id="support.form.message.label" />
        </Form.Label>
        <Form.Control
          autoFocus={!!emailFrom}
          as="textarea"
          type="text"
          size="lg"
          placeholder={intl.formatMessage({ id: 'support.form.message.placeholder' })}
          {...register('message')}
          isInvalid={!!errors?.message}
          data-test-id="support-message"
        />
        {!!errors?.message && (
          <Form.Control.Feedback type="invalid" data-test-id="support-message-error">
            {errors.message.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Button
        variant="primary"
        type="submit"
        className={`btn btn-primary my-3 ${roundButtons ? 'btn-rounded' : ''}`}
        data-test-id="support-submit"
        disabled={isSubmitting}
      >
        <FormattedMessage id="support.action.submit" />
      </Button>
      <Button
        variant="ghost"
        type="button"
        className={`btn ${roundButtons ? 'btn-rounded' : ''}`}
        onClick={onCancel}
        data-test-id="support-cancel"
      >
        <FormattedMessage id="support.action.cancel" />
      </Button>
    </Form>
  );
};

type FormData = { email: string; message: string };

interface Props {
  emailFrom?: string;
  onSubmit: (email: string, message: string) => Promise<void>;
  onCancel: () => void;
  roundButtons?: boolean;
}

export default Support;
