import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import TrackerVariant from '../../../models/TrackerState';

import './ValueTracker.scss';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';

const ValueTracker: FC<Props> = ({
  title,
  value,
  hint,
  tooltip,
  titleTooltip,
  variant,
  units,
  titleClassName,
  method,
  dataTestId,
}) => {
  const getTooltip = (tooltipMethod: string | null | undefined) => {
    return tooltipMethod ? (
      <>
        {tooltip}
        <br />
        <FormattedMessage id="tracker.method" values={{ method: tooltipMethod }} />
      </>
    ) : (
      tooltip
    );
  };

  return (
    <div className="value-tracker-component bg-color-gray-l1 p-2" data-test-id={dataTestId}>
      <div className="d-inline-flex">
        {title && titleTooltip ? (
          <TitleTooltip title={title} titleTooltip={titleTooltip} titleClassName={`size-label ${titleClassName}`} />
        ) : (
          <div className={`size-label ${titleClassName}`}>{title}</div>
        )}
        {tooltip && <IconTooltip tooltip={getTooltip(method)} className="ms-1 d-flex align-items-center size-label" />}
      </div>
      <span className="my-auto">
        <h3 className={`font-bold ${variant.toString()}`} data-test-id="valueTracker-value-units">
          {value}
          {units}
        </h3>
      </span>
      <div className="size-caption text-color-gray-l6" data-test-id="valueTracker-noData">
        {variant === TrackerVariant.noData ? <FormattedMessage id="tracker.noData" /> : hint || <span>&nbsp;</span>}
      </div>
    </div>
  );
};

type Props = {
  title: string;
  titleClassName?: string;
  titleTooltip?: string;
  tooltip?: string;
  value?: string;
  hint?: React.ReactNode;
  variant: TrackerVariant;
  units?: string | null;
  method?: string | null;
  dataTestId?: string;
};

export default ValueTracker;
