import React, { FC, useMemo } from 'react';

import './CircleValueTracker.scss';
import TrackerVariant from '../../../models/TrackerState';
import numberHelper from '../../../helpers/numberHelper';
import dataPlaceholders from '../../../constants/dataPlaceholders';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';

const CircleValueTracker: FC<Props> = ({ title, value, tooltip, variant }) => {
  const trunkedNumber = useMemo(() => numberHelper.trunkNumber(value), [value]);

  return (
    <div className={`circle-value-tracker ${variant} bg-color-gray-l1 p-3 h-100 d-flex align-items-center`}>
      <div className="svg-container pe-2 ps-4">
        <svg>
          <circle className="bg" cx="50" cy="50" r="45" />
          <circle className={`meter meter-${!trunkedNumber ? 'min' : trunkedNumber}`} cx="50" cy="50" r="45" />
        </svg>
        <span className="value-container h-100 d-flex justify-content-center align-items-center">
          <span className="value-container-value d-inline-block">
            {variant === TrackerVariant.noData ? (
              dataPlaceholders.missingData
            ) : (
              <>{numberHelper.formatNumber(value)}%</>
            )}
          </span>
        </span>
      </div>
      <div className="size-label d-flex ps-3 w-100">
        <span className="title align-middle">{title}</span>
        {tooltip && (
          <div className="ms-2 d-flex">
            <IconTooltip tooltip={tooltip} className="align-middle" />
          </div>
        )}
      </div>
    </div>
  );
};

type Props = {
  variant: TrackerVariant;
  title: string;
  tooltip?: string;
  value?: number | null;
};

export default CircleValueTracker;
