import { useMemo } from 'react';
import MessagesViewState from '../models/Messages/MessagesViewState';
import useQueryStringState from './useQueryStringState';

const useMessagesViewState = () => {
  const [viewState, setViewState] = useQueryStringState<MessagesViewState>();

  const handleViewStateChange = (newViewState: MessagesViewState) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        ...newViewState,
        filter: {
          ...viewState.search.filter,
          ...newViewState.filter,
        },
      },
      hash: '',
    });
  };

  const getNewDate = (dateFromUrl: string | null | undefined) => {
    if (!dateFromUrl) {
      return null;
    }
    const newDate = Date.parse(dateFromUrl);
    return Number.isNaN(newDate) ? null : new Date(newDate);
  };

  const dateFrom = useMemo(() => {
    return getNewDate(viewState.search.filter?.dateFrom);
  }, [viewState.search.filter?.dateFrom]);

  const dateTo = useMemo(() => {
    return getNewDate(viewState.search.filter?.dateTo);
  }, [viewState.search.filter?.dateTo]);

  return {
    viewState,
    handleViewStateChange,
    dateFrom,
    dateTo,
  };
};

export default useMessagesViewState;
