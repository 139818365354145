import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import './ValueTrackerWithDetails.scss';

import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';
import TrackerVariant from '../../../models/TrackerState';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';

const ValueTrackerWithDetails: FC<Props> = ({
  title,
  value,
  tooltip,
  titleTooltip,
  variant,
  units,
  titleClassName,
  method,
  dataTestId,
  link,
}) => {
  const getTooltip = (tooltipMethod: string | null | undefined) => {
    return tooltipMethod ? (
      <>
        {tooltip}
        <br />
        <FormattedMessage id="tracker.method" values={{ method: tooltipMethod }} />
      </>
    ) : (
      tooltip
    );
  };

  const valueComponent = (
    <h3 className="font-bold" data-test-id="valueTracker-value-units">
      {value}
      {units}
    </h3>
  );

  const linkComponent = link ? (
    <Link to={link} className="link link--secondary size-caption">
      <FormattedMessage id="tracker.showDetails" />
    </Link>
  ) : (
    <span className="text-color-gray-l5 size-caption text-transform-uppercase">
      <FormattedMessage id="tracker.showDetails" />
    </span>
  );

  return (
    <div className="value-tracker-with-details-component bg-color-gray-l1 p-2" data-test-id={dataTestId}>
      <div className="d-inline-flex">
        {title && titleTooltip ? (
          <TitleTooltip title={title} titleTooltip={titleTooltip} titleClassName={`size-label ${titleClassName}`} />
        ) : (
          <div className={`size-label ${titleClassName}`}>{title}</div>
        )}
        {tooltip && <IconTooltip tooltip={getTooltip(method)} className="ms-1 d-flex align-items-center size-label" />}
      </div>
      <span className={classNames('my-auto', variant.toString())}>
        {link ? (
          <Link className={classNames('base-link', variant.toString())} to={link}>
            {valueComponent}
          </Link>
        ) : (
          valueComponent
        )}
      </span>
      <div className="text-color-gray-l6 size-caption" data-test-id="valueTracker-noData">
        {variant === TrackerVariant.noData ? <FormattedMessage id="tracker.noData" /> : linkComponent}
      </div>
    </div>
  );
};

type Props = {
  title: string;
  titleClassName?: string;
  titleTooltip?: string;
  tooltip?: string;
  value?: string;
  variant: TrackerVariant;
  units?: string | null;
  method?: string | null;
  dataTestId?: string;
  link?: string;
};

export default ValueTrackerWithDetails;
