import React, { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useSearchParams } from 'react-router-dom';
import './ListTab.scss';

import { Button } from 'react-bootstrap';
import applicationService from '../../../services/AppGallery/applicationService';
import useNotifications from '../../../hooks/useNotifications';
import TableHeader from '../../../components/Table/TableHeader/TableHeader';
import applicationRequestService from '../../../services/AppGallery/applicationRequestService';
import ApplicationStatus from '../../../models/AppGallery/Application/ApplicationStatus';
import AppDetailsModal from '../AppDetailsModal/AppDetailsModal';
import ListApplicationSortColumn from '../../../models/AppGallery/Application/ListApplicationSortColumn';
import ListTabSkeleton from './ListTabSkeleton';
import Spinner from '../../../components/Spinner/Spinner';
import useSortingViewState from '../../../hooks/useSortingViewState';

const ListTab: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();
  const columns: { name: ListApplicationSortColumn | ''; key: string }[] = useMemo(() => {
    return [
      { name: 'name', key: 'appGallery.listTab.table.header.name' },
      { name: 'shortDescription', key: 'appGallery.listTab.table.header.description' },
      { name: 'status', key: 'appGallery.listTab.table.header.status' },
      { name: '', key: '' },
    ];
  }, []);

  const { getSortOrder } = useSortingViewState();
  const { data: applicationsData, isLoading: isLoadingApplications } = useQuery(
    ['applicationList', 'list', getSortOrder<ListApplicationSortColumn>('name', 'AppList')],
    () => applicationService.list({ orderBy: getSortOrder<ListApplicationSortColumn>('name', 'AppList').toString() }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.listTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.listTab.notification.unableToLoadApplicationList' }),
        ),
    },
  );

  const { mutate: requestApplication, isLoading: isRequesting } = useMutation<unknown, unknown, string>(
    (id) => applicationRequestService.requestAccess(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('applicationList');
        notify(
          'success',
          intl.formatMessage({ id: 'appGallery.listTab.notification.title' }),
          intl.formatMessage({
            id: 'appGallery.listTab.notification.requestedApplication',
          }),
        );
      },
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.listTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.listTab.notification.unableRequestApplication' }),
        );
      },
    },
  );

  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const handleCLoseModal = () => {
    searchParams.delete('id');
    setSearchParams(searchParams);
  };

  return (
    <div className="list-tab-component mb-4 rounded-4 narrow-row-table shadow-2">
      {isLoadingApplications ? (
        <ListTabSkeleton cards={15} columns={columns} />
      ) : (
        <table className="w-100">
          <TableHeader<ListApplicationSortColumn> columns={columns} tableName="AppList" />
          <tbody className="animated-component-item">
            {applicationsData?.applications.map((application) => (
              <tr key={application.id}>
                <td className="font-semi-bold text-uppercase">
                  <Link to={`?id=${application.id}`} className="link sm">
                    {application.name}
                  </Link>
                </td>
                <td className="font-normal">{application.shortDescription}</td>

                <td align="center">
                  {application.status === ApplicationStatus.Available && (
                    <Button size="sm" onClick={() => requestApplication(application.id)} className="me-2">
                      <FormattedMessage id="appGallery.listTab.action.request" />
                    </Button>
                  )}
                  {application.status === ApplicationStatus.Installed && (
                    <span className="text-color-primary-green">
                      <FormattedMessage id="appGallery.listTab.status.Installed" />
                    </span>
                  )}
                  {application.status === ApplicationStatus.Requested && (
                    <FormattedMessage id="appGallery.listTab.status.Requested" />
                  )}
                </td>
                <td align="center">
                  <Link to={`?id=${application.id}`} className="link link--underlined sm ps-3">
                    <FormattedMessage id="appGallery.listTab.action.details" />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {id && <AppDetailsModal appId={id} onClose={handleCLoseModal} />}
      {isRequesting && <Spinner />}
    </div>
  );
};

export default ListTab;
