import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CopyToClipboardButton from '../../../../components/CopyToClipboardButton/CopyToClipboardButton';

const BaseKey = 'settings.tab.security.changeMfa.recoveryCodeModal';

const RecoveryCodeModal: FC<Props> = ({ recoveryCode, onClose }) => {
  const { formatMessage: fm } = useIntl();

  return (
    <Modal show centered>
      <span className="text-uppercase text-color-primary-green size-label px-4 pt-4 font-normal">
        <FormattedMessage id="settings.tab.security.title" />
      </span>
      <Modal.Header className="px-4">
        <h3 className="mb-0 font-bold">
          <FormattedMessage id={`${BaseKey}.title`} />
        </h3>
      </Modal.Header>
      <Modal.Body className="p-4 pt-0 size-label font-medium text-color-gray-light1">
        <p className="mb-3">
          <FormattedMessage id={`${BaseKey}.description`} />
        </p>
        <div className="my-4 bg-color-gray-l1 p-3 d-flex justify-content-center rounded-4 size-label">
          {recoveryCode}
        </div>
        <div className="d-flex justify-content-center">
          <CopyToClipboardButton className="me-4" targetText={recoveryCode} tooltip={fm({ id: `${BaseKey}.copied` })}>
            <FormattedMessage id={`${BaseKey}.action.copy`} />
          </CopyToClipboardButton>
          <Button variant="primary" type="button" onClick={onClose}>
            <FormattedMessage id={`${BaseKey}.action.backupDone`} />
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

interface Props {
  recoveryCode: string;
  onClose: () => void;
}

export default RecoveryCodeModal;
