import { faPaperPlane, faTrash, faUnlock, faUserPen } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { useAppContext } from '../../context/useAppContext';
import companiesHelper from '../../helpers/companiesHelper';
import useNotifications from '../../hooks/useNotifications';
import UserType from '../../models/Account/UserType';
import ListUsersSortColumn from '../../models/User/ListUsersSortColumn';
import UserItem from '../../models/User/UserItem';
import AddEditUser from '../../pages/Admin/Users/AddEditUser/AddEditUser';
import userService from '../../services/userService';
import ActionsDropdown from '../ActionsDropdown/ActionsDropdown';
import Confirm from '../Confirm/Confirm';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import Spinner from '../Spinner/Spinner';
import StatusBadge from '../StatusBadge/StatusBadge';
import TableHeader from '../Table/TableHeader/TableHeader';
import TitleTooltip from '../Tooltips/TitleTooltip/TitleTooltip';
import TitleTooltipWithMailLink from '../Tooltips/TitleTooltipWithMailLink/TitleTooltipWithMailLink';
import './UsersTable.scss';

interface Props {
  users: UserItem[];
  totalUsers: number;
  companyId?: string;
}

const UsersTable: React.FC<Props> = ({ totalUsers, users, companyId }) => {
  const { formatMessage: fm } = useIntl();
  const queryClient = useQueryClient();
  const { notifySuccess, notifyError } = useNotifications();
  const {
    state: { user: currentUser },
  } = useAppContext();

  const columns: { name: ListUsersSortColumn | ''; key: string }[] = useMemo(() => {
    const companyNameColumn: { name: ListUsersSortColumn | ''; key: string }[] = !companyId
      ? [{ name: 'companyName', key: 'usersTable.header.company' }]
      : [];
    const phoneNumberColumn: { name: ListUsersSortColumn | ''; key: string }[] = companyId
      ? [{ name: 'phoneNumber', key: 'usersTable.header.phoneNumber' }]
      : [];
    return [
      { name: 'fullName', key: 'usersTable.header.fullName' },
      { name: 'email', key: 'usersTable.header.email' },
      ...phoneNumberColumn,
      ...companyNameColumn,
      { name: 'department', key: 'usersTable.header.department' },
      { name: 'roleType', key: 'usersTable.header.roleType' },
      { name: 'activeSince', key: 'usersTable.header.activeSince' },
      { name: 'isActive', key: 'usersTable.header.isActive' },
      { name: '', key: '' }, // actions
    ];
  }, [companyId]);

  const [userToEdit, setUserToEdit] = useState<{ id: string }>();
  const [userToDelete, setUserToDelete] = useState<UserItem>();
  const { mutate: deleteUser, isLoading: isDeleting } = useMutation((id: string) => userService.delete(id), {
    onSuccess: async () => {
      await queryClient.invalidateQueries('users');
      await queryClient.invalidateQueries(['companyStatus', companyId]);
      notifySuccess(fm({ id: 'usersTable.notification.title' }), fm({ id: 'usersTable.notification.deleteUser' }));
    },
    onError: () =>
      notifyError(
        fm({ id: 'usersTable.notification.title' }),
        fm({ id: 'usersTable.notification.unableToDeleteUser' }),
      ),
  });
  const [deleteConfirmType, setDeleteConfirmType] = useState<'lastUserWithinSeniorManagerRole' | 'regular' | undefined>(
    undefined,
  );

  const clearDeleteUserData = () => {
    setUserToDelete(undefined);
    setDeleteConfirmType(undefined);
  };
  const onConfirmDelete = () => {
    if (userToDelete) {
      deleteUser(userToDelete.id);
    }
    clearDeleteUserData();
  };

  const [isQueryLoading, setIsQueryLoading] = useState(false);

  const setItemOfUserToDelete = async (user: UserItem) => {
    setUserToDelete(user);
    if (!companyId && user.roleType === UserType.SeniorCompanyManager) {
      const getUsersWithTheSameRole = async () => {
        setIsQueryLoading(true);
        try {
          const result = await userService.list({
            roleId: user?.roleId || undefined,
            companyId: user?.companyId || undefined,
            paging: { pageSize: 1, pageNumber: 1 },
          });

          if (result.total === 1) {
            setDeleteConfirmType('lastUserWithinSeniorManagerRole');
          } else {
            setDeleteConfirmType('regular');
          }
        } catch (err) {
          notifyError(
            fm({ id: 'usersTable.notification.title' }),
            fm({ id: 'usersTable.notification.unableLoadUsersWithRole' }),
          );
        } finally {
          setIsQueryLoading(false);
        }
      };

      await getUsersWithTheSameRole();
    } else {
      setDeleteConfirmType('regular');
    }
  };

  const showDeleteConfirmation = (user: UserItem) => {
    if (deleteConfirmType === 'lastUserWithinSeniorManagerRole') {
      return (
        <Confirm
          title={fm({ id: 'usersTable.confirm.deleteLastUser.title' })}
          targetName={user?.fullName}
          confirmTitle={fm({ id: 'usersTable.confirm.deleteLastUser.confirmMsg' })}
          message={fm({ id: 'usersTable.confirm.deleteLastUser.message' })}
          onClose={() => clearDeleteUserData()}
          onConfirm={() => onConfirmDelete()}
          showWarningIcon
        />
      );
    }
    if (deleteConfirmType === 'regular') {
      return (
        <Confirm
          title={fm({ id: 'usersTable.confirm.delete.title' })}
          targetName={user.fullName}
          confirmTitle={fm({ id: 'usersTable.confirm.delete.confirmMsg' })}
          message={fm({ id: 'usersTable.confirm.delete.message' })}
          onClose={() => clearDeleteUserData()}
          onConfirm={() => onConfirmDelete()}
        />
      );
    }
    return undefined;
  };

  const { mutate: resendActivationLink, isLoading: isResendingActivationLink } = useMutation(
    (userIdForResend: string) => userService.resendActivationLink(userIdForResend),
    {
      onSuccess: () =>
        notifySuccess(
          fm({ id: 'usersTable.notification.title' }),
          fm({ id: 'usersTable.notification.sentActivationLink' }),
        ),

      onError: () =>
        notifyError(
          fm({ id: 'usersTable.notification.title' }),
          fm({ id: 'usersTable.notification.unableToResendActivationLink' }),
        ),
    },
  );

  const [userToDeleteAuthenticators, setUserToDeleteAuthenticators] = useState<UserItem>();

  const { mutate: deleteAuthenticators, isLoading: isDeletingAuthenticators } = useMutation(
    (userId: string) => userService.deleteAuthenticators(userId),
    {
      onSuccess: () => {
        notifySuccess(
          fm({ id: 'usersTable.notification.title' }),
          fm({ id: 'usersTable.notification.deleteAuthenticators' }),
          fm({ id: 'usersTable.notification.deleteAuthenticatorsHint' }),
        );
        return queryClient.invalidateQueries('users');
      },

      onError: () =>
        notifyError(
          fm({ id: 'usersTable.notification.title' }),
          fm({ id: 'usersTable.notification.unableToDeleteAuthenticators' }),
        ),
    },
  );

  const onConfirmDeleteAuthenticators = async () => {
    if (!userToDeleteAuthenticators) return;

    await deleteAuthenticators(userToDeleteAuthenticators.id);
    setUserToDeleteAuthenticators(undefined);
  };

  return (
    <div className="users-table-component">
      <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold" data-test-id="users-total">
        <FormattedMessage id="usersTable.totalCount" values={{ count: totalUsers }} />
      </div>
      <table className={companyId ? 'full-table w-100' : 'crop-table w-100'}>
        <TableHeader<ListUsersSortColumn> columns={columns} tableName="Users" />
        <tbody className="animated-component-item">
          {users.map((user) => (
            <tr key={user.id} data-test-id="users-row">
              <td data-test-id="users-row-fullName" className="font-semi-bold">
                <TitleTooltip titleTooltip={user.fullName} title={user.fullName} titleClassName="inline-crop" />
              </td>
              <td data-test-id="users-row-email" className="font-normal td-width">
                <div className="td-width-email">
                  <TitleTooltipWithMailLink
                    title={user.email}
                    titleClassName="inline-crop users-table-component-email"
                  />
                </div>
              </td>
              {companyId && (
                <td data-test-id="users-row-phoneNumber" className="font-normal">
                  {user.phoneNumber}
                </td>
              )}
              {!companyId && (
                <td data-test-id="users-row-companyName" className="font-normal td-width">
                  <div className="td-width-user-name">
                    <Link to={companiesHelper.getCompanyUrl(user.companyId!)} className="link--tertiary">
                      <TitleTooltip
                        titleTooltip={user.companyName}
                        title={user.companyName}
                        titleClassName="inline-crop"
                      />
                    </Link>
                  </div>
                </td>
              )}
              <td data-test-id="users-row-department" className="font-normal td-width">
                <div className="td-width-department">
                  <TitleTooltip titleTooltip={user.department} title={user.department} titleClassName="inline-crop" />
                </div>
              </td>
              <td data-test-id="users-row-role" className="font-normal">
                <FormattedMessage id={`admin.users.filter.roles.${user.roleType}`} />
              </td>
              <td data-test-id="users-row-activeSince" className="font-normal">
                {!user.activeSince ? '' : <GenericFormattedDate value={user.activeSince} />}
              </td>
              <td data-test-id="users-row-isActive" className="font-normal">
                <StatusBadge isActive={user.isActive} />
              </td>
              <td align="center">
                <ActionsDropdown>
                  {!(
                    companyId &&
                    user.roleType === UserType.SeniorCompanyManager &&
                    currentUser?.userId !== user.id
                  ) && (
                    <Dropdown.Item
                      className="text-uppercase d-flex align-items-center"
                      onClick={() => setUserToEdit({ id: user.id })}
                      data-test-id="users-row-edit"
                    >
                      <FontAwesomeIcon icon={faUserPen} className="pe-3" />
                      <FormattedMessage id="usersTable.row.actions.edit" />
                    </Dropdown.Item>
                  )}
                  {currentUser?.userId !== user.id && (
                    <Dropdown.Item
                      className="text-uppercase d-flex align-items-center"
                      onClick={() => setItemOfUserToDelete(user)}
                      data-test-id="users-row-delete"
                    >
                      <FontAwesomeIcon icon={faTrash} className="pe-3" />
                      <FormattedMessage id="usersTable.row.actions.delete" />
                    </Dropdown.Item>
                  )}
                  {!user.emailVerified && (
                    <Dropdown.Item
                      className="text-uppercase d-flex align-items-center"
                      onClick={() => resendActivationLink(user.id)}
                      data-test-id="users-row-resendActivationLink"
                    >
                      <FontAwesomeIcon icon={faPaperPlane} className="pe-3" />
                      <FormattedMessage id="usersTable.row.actions.resendActivationLink" />
                    </Dropdown.Item>
                  )}
                  {user.hasTwoFa && currentUser?.userId !== user.id && (
                    <Dropdown.Item
                      className="text-uppercase d-flex align-items-center"
                      onClick={() => setUserToDeleteAuthenticators(user)}
                      data-test-id="users-row-delete-authenticators"
                    >
                      <FontAwesomeIcon icon={faUnlock} className="pe-3" />
                      <FormattedMessage id="usersTable.row.actions.deleteAuthenticators" />
                    </Dropdown.Item>
                  )}
                </ActionsDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {userToEdit && (
        <AddEditUser
          userId={userToEdit.id}
          onClose={() => setUserToEdit(undefined)}
          defaultCompanyId={companyId || ''}
        />
      )}
      {userToDelete && showDeleteConfirmation(userToDelete)}
      {userToDeleteAuthenticators && (
        <Confirm
          title={fm({ id: 'usersTable.confirm.deleteAuthenticators.title' })}
          targetName={userToDeleteAuthenticators.fullName}
          confirmTitle={fm({ id: 'usersTable.confirm.deleteAuthenticators.confirmMsg' })}
          message={fm({ id: 'usersTable.confirm.deleteAuthenticators.message' })}
          onClose={() => setUserToDeleteAuthenticators(undefined)}
          onConfirm={() => onConfirmDeleteAuthenticators()}
        />
      )}
      {(isDeleting || isResendingActivationLink || isDeletingAuthenticators || isQueryLoading) && <Spinner />}
    </div>
  );
};

export default UsersTable;
