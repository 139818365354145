import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const AppsTabCardSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="bg-color-gray-l3 rounded-4" data-test-id="loader">
          <div className="d-flex justify-content-between card-divider">
            <div className="p-3 animated-component-skeleton">
              <ContentLoader
                height={35}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.white}
                viewBox="0 0 150 35"
              >
                <rect x="2" y="0" rx="4" ry="4" width="147" height="32" />
              </ContentLoader>
            </div>
            <div className="p-3">
              <ContentLoader
                height={35}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey2}
                foregroundColor={SkeletonColors.green}
                viewBox="0 0 119 35"
              >
                <rect x="0" y="0" rx="4" ry="4" width="118" height="32" />
              </ContentLoader>
            </div>
          </div>
          <div className="d-flex flex-row p-4 animated-component-skeleton">
            <ContentLoader
              height={153}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.lightGrey1}
              viewBox="0 0 728 153"
            >
              <circle cx="50" cy="50" r="50" />
              <rect x="124" y="0" rx="8" ry="8" width="605" height="153" />
            </ContentLoader>
          </div>
        </div>
      ))}{' '}
    </>
  );
};
interface Props {
  cards: number;
}
export default AppsTabCardSkeleton;
