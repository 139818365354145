import React, { useMemo } from 'react';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';

import './BatchIdSelect.scss';

import defaultStyles from '../../Select/styles';
import BatchSearch from '../../../models/Batch/BatchSearch';

const createCustomContainer = (props: any, dataTestId?: string) => (
  <components.SelectContainer {...props} innerProps={{ ...props.innerProps, ...{ 'data-test-id': dataTestId } }} />
);

const BatchIdSelect: React.FC<Props> = ({
  loadOptions,
  onChange,
  dataTestId,
  placeholder,
  value,
  noOptionsMessage,
  size,
}) => {
  const customComponents = useMemo(
    () => ({
      SelectContainer: (props: any) => createCustomContainer(props, dataTestId),
    }),
    [dataTestId],
  );

  const styles = useMemo(() => {
    const base = size === 'lg' ? defaultStyles.lgMultiSelectStyles : defaultStyles.stylesBase;
    return base;
  }, [size]);

  return (
    <AsyncSelect<BatchSearch, true>
      className="batch-id-select-component"
      defaultOptions
      components={customComponents}
      styles={styles as any}
      getOptionValue={(option) => option.id}
      // eslint-disable-next-line react/no-unstable-nested-components
      formatOptionLabel={(option) => (
        <span className="d-flex align-items-center">
          {option.name}
          <span className="ms-1 label-hint size-caption">
            ({option.synthesisStepName} {option.campaignName})
          </span>
        </span>
      )}
      loadOptions={loadOptions}
      onChange={(options) => onChange(options.map((item) => item))}
      value={value}
      placeholder={placeholder}
      noOptionsMessage={noOptionsMessage ? () => noOptionsMessage : undefined}
      isMulti
    />
  );
};

interface Props {
  loadOptions(value: string): Promise<BatchSearch[]>;
  onChange(values: BatchSearch[]): void;
  size?: 'sm' | 'lg';
  value?: BatchSearch[];
  dataTestId?: string;
  placeholder?: string;
  noOptionsMessage?: string;
}

export default BatchIdSelect;
