import React, { PropsWithChildren, HTMLAttributes, FC } from 'react';
import './ActionDescriptor.scss';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';

const ActionDescriptor: FC<PropsWithChildren<IActionDescriptorProps & HTMLAttributes<HTMLDivElement>>> = ({
  title,
  actionPoint,
  header,
  subHeader,
  icon,
  iconSize,
  children,
  className,
}) => {
  return (
    <div className={`action-descriptor-component d-flex flex-column p-4 ${className}`}>
      {title && (
        <div
          className={`text-center text-uppercase text-color-primary-green size-label font-bold pb-5 ${icon || ''}`}
          data-test-id="actionDescriptor-title"
        >
          {title}
        </div>
      )}
      {icon && <FontAwesomeIcon icon={icon} size={iconSize ?? '1x'} className="pb-4 text-color-gray-l6" />}
      {actionPoint && (
        <h3 data-test-id="actionDescriptor-actionPoint" className="font-bold text-center">
          {actionPoint}
        </h3>
      )}
      <h3 className="text-center font-bold" data-test-id="actionDescriptor-header">
        {header}
      </h3>
      <span className="text-color-gray-l6 size-label d-block text-center" data-test-id="actionDescriptor-subHeader">
        {subHeader}
      </span>
      {children}
    </div>
  );
};

interface IActionDescriptorProps {
  title?: string;
  icon?: IconDefinition;
  iconSize?: SizeProp;
  actionPoint?: string;
  header: string;
  subHeader: string;
}

export default ActionDescriptor;
