import React from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Outlet, NavLink } from 'react-router-dom';
import Header from '../../components/Header/Header';
import { useAppContext } from '../../context/useAppContext';
import UserPermission from '../../models/Account/UserPermission';

const Settings: React.FC = () => {
  const intl = useIntl();
  const {
    state: { user },
  } = useAppContext();
  return (
    <>
      <Header title={intl.formatMessage({ id: 'settings.title' })} showSearch />
      <div className="page-content px-5">
        <Nav variant="tabs" className="mb-4">
          <Nav.Item>
            <Nav.Link as={NavLink} to="account">
              <FormattedMessage id="settings.tab.account.title" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="security">
              <FormattedMessage id="settings.tab.security.title" />
            </Nav.Link>
          </Nav.Item>
          {user?.permissions.some((x) => x === UserPermission.MyAppsAccess) && (
            <Nav.Item>
              <Nav.Link as={NavLink} to="apps">
                <FormattedMessage id="settings.tab.apps.title" />
              </Nav.Link>
            </Nav.Item>
          )}
          {user?.companyId && (
            <Nav.Item>
              <Nav.Link as={NavLink} to="companyDetails">
                <FormattedMessage id="settings.tab.companyDetails.title" />
              </Nav.Link>
            </Nav.Item>
          )}
        </Nav>
        <Outlet />
      </div>
    </>
  );
};

export default Settings;
