import React, { FC, useState } from 'react';

import './Welcome.scss';

import { Button } from 'react-bootstrap';
import { useIntl, FormattedMessage } from 'react-intl';

import { faShieldKeyhole } from '@fortawesome/pro-solid-svg-icons';
import ActionDescriptor from '../../../components/ActionDescriptor/ActionDescriptor';
import TermsModal from '../../Terms/Modal/TermsModal';
import PrivacyModal from '../../Privacy/Modal/PrivacyModal';

const Welcome: FC<IWelcomeProps> = ({ name, onStart }) => {
  const intl = useIntl();
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);

  return (
    <>
      <ActionDescriptor
        className="bg-color-gray-l3 align-items-center welcome-block"
        title={intl.formatMessage({ id: 'activation.welcome.title' })}
        header={intl.formatMessage({ id: 'activation.welcome.header' }, { name })}
        subHeader=""
        icon={faShieldKeyhole}
        iconSize="4x"
      >
        <Button
          className="mt-5 wide-btn btn-rounded"
          onClick={onStart}
          data-test-id="activation-welcome-startVerificationBtn"
        >
          <FormattedMessage id="activation.welcome.btn.label" />
        </Button>
        <div className="hint pt-4">
          <span>
            <FormattedMessage id="activation.welcome.signingHint" />{' '}
            <Button
              variant="link"
              className="btn-link-primary size-label"
              onClick={() => setShowTerms(true)}
              data-test-id="activation-welcome-termsBtn"
            >
              <FormattedMessage id="activation.welcome.termsBtn.label" />
            </Button>{' '}
            <FormattedMessage id="activation.welcome.and" />{' '}
            <Button
              variant="link"
              className="btn-link-primary size-label"
              onClick={() => setShowPrivacy(true)}
              data-test-id="activation-welcome-privacyBtn"
            >
              <FormattedMessage id="activation.welcome.privacyBtn.label" />
            </Button>
          </span>
        </div>
      </ActionDescriptor>
      {showTerms && <TermsModal onClose={() => setShowTerms(false)} />}
      {showPrivacy && <PrivacyModal onClose={() => setShowPrivacy(false)} />}
    </>
  );
};

interface IWelcomeProps {
  name: string;
  onStart: () => void;
}

export default Welcome;
