import React, { FC, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import MessagesListSortColumn from '../../models/Messages/MessagesListSortColumn';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import './Messages.scss';

interface Props {
  cards: number;
}

const MessageTableSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  const columns: { name: MessagesListSortColumn | ''; key: string }[] = [
    { name: 'title', key: 'messages.table.header.title' },
    { name: 'author', key: 'messages.table.header.author' },
    { name: 'createdAt', key: 'messages.table.header.createdAt' },
    { name: 'status', key: 'messages.table.header.status' },
    { name: '', key: '' }, // actions
  ];

  return (
    <>
      <div className="bg-color-gray-l3 p-4 rounded-top row">
        <div className="col text-uppercase font-bold">
          <FormattedMessage id="messages.table.title" values={{ count: null }} />
          <ContentLoader
            height={20}
            speed={SkeletonSpeed}
            backgroundColor={SkeletonColors.lightGrey1}
            foregroundColor={SkeletonColors.lightGrey2}
            viewBox="0 0 70 25"
          >
            <rect x="0" y="0" rx="4" ry="4" width="40" height="20" />
          </ContentLoader>
        </div>
      </div>
      <table className="users-table-component w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            <th data-test-id="messagesList-row-checkbox" className="font-semi-bold">
              <Form.Check className="form-check" inline type="checkbox" />
            </th>
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td data-test-id="messagesList-row-checkbox" className="font-semi-bold">
                <Form.Check className="form-check" inline type="checkbox" />
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 444 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="443" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 150 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="149" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 203 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="202" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 53 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="52" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 57 30"
                >
                  <rect x="3" y="3" rx="4" ry="4" width="54" height="25" />
                </ContentLoader>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default MessageTableSkeleton;
