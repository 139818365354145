import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const CampaignListSkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton" style={{ height: '390px' }} data-test-id="loader">
      <div className="card mb-4">
        <div className="px-4 py-3 card-divider d-flex">
          <ContentLoader
            height={32}
            speed={SkeletonSpeed}
            backgroundColor={SkeletonColors.lightGrey1}
            foregroundColor={SkeletonColors.lightGrey2}
            viewBox="0 0 500 34"
          >
            <rect x="0" y="8" rx="8" ry="8" width="500" height="26" />
          </ContentLoader>
        </div>
        <div className="px-4 py-3 d-flex flex-column gap-3 ">
          <div className="card bg-color-gray-l4">
            <div className="d-flex gap-3 p-3 align-items-center card-divider">
              <ContentLoader
                height={32}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.lightGrey2}
                viewBox="0 0 1000 34"
              >
                <rect x="0" y="8" rx="8" ry="8" width="1000" height="26" />
              </ContentLoader>
            </div>
            <div className="d-flex gap-3 p-3 card-divider">
              <ContentLoader
                height={133}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.darkGrey2}
                foregroundColor={SkeletonColors.mediumGrey}
                viewBox="0 0 1323 133"
              >
                <rect x="0" y="0" rx="8" ry="8" width="420" height="133" />
                <rect x="436" y="0" rx="8" ry="8" width="420" height="133" />
                <rect x="872" y="0" rx="8" ry="8" width="210" height="133" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CampaignListSkeleton;
