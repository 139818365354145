import React, { FC, PropsWithChildren, useContext } from 'react';
import { Accordion, AccordionContext } from 'react-bootstrap';

const AccordionItem: FC<PropsWithChildren<Props>> = ({ eventKey, children, dataTestId }) => {
  const { activeEventKey } = useContext(AccordionContext);
  const getClassName = (key: string) => `${activeEventKey === key ? 'active' : ''}`;
  return (
    <Accordion.Item className={getClassName(eventKey)} eventKey={eventKey} data-test-id={dataTestId}>
      {children}
    </Accordion.Item>
  );
};

interface Props {
  eventKey: string;
  dataTestId?: string;
}

export default AccordionItem;
