import React, { FC } from 'react';

const ErrorMessage: FC<IErrorMessageProps> = ({ message }) => {
  return <div className="invalid-feedback d-block text-center">{message}</div>;
};

interface IErrorMessageProps {
  message: string;
}

export default ErrorMessage;
