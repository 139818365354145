import { useState, useEffect } from 'react';

const usePersistState = <T>(key: string, initialValue: T): [T, (newState: T) => void] => {
  const [state, setState] = useState<T>(initialValue);

  useEffect(() => {
    const existingState = localStorage.getItem(key);
    if (existingState) {
      setState(JSON.parse(existingState));
    }
  }, [key]);

  return [
    state,
    (newState: T) => {
      setState(newState);
      localStorage.setItem(key, JSON.stringify(newState));
    },
  ];
};

export default usePersistState;
