import React, { FC, PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const TitleTooltipWithMailLink: FC<PropsWithChildren<Props>> = ({ title, titleClassName }) => {
  return (
    <OverlayTrigger
      overlay={
        <Tooltip className="size-caption tooltip-title" data-test-id="titleTooltip-tooltip">
          {title}
        </Tooltip>
      }
    >
      <a href={`mailto:${title}`} className={titleClassName} data-test-id="titleTooltip-title">
        {title}
      </a>
    </OverlayTrigger>
  );
};
interface Props {
  title: string | null;
  titleClassName: string;
}
export default TitleTooltipWithMailLink;
