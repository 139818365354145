import React, { useMemo, useState } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Form } from 'react-bootstrap';
import classNames from 'classnames';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from 'react-query';

import useNotifications from '../../hooks/useNotifications';
import Header from '../Header/Header';
import TableHeader from '../Table/TableHeader/TableHeader';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import applicationRequestService from '../../services/AppGallery/applicationRequestService';
import ApplicationRequestStatus from '../../models/AppGallery/ApplicationRequest/ApplicationRequestStatus';
import Select from '../Select/Select';
import applicationService from '../../services/AppGallery/applicationService';
import ListApplicationRequestSortColumn from '../../models/AppGallery/ApplicationRequest/ListApplicationRequestSortColumn';
import AppManagementSkeleton from './AppManagementSkeleton';
import './AppManagement.scss';
import Spinner from '../Spinner/Spinner';
import useSortingViewState from '../../hooks/useSortingViewState';

const pageSize = 20;

interface Props {
  companyId?: string;
}

const AppManagement: React.FC<Props> = ({ companyId }) => {
  const { formatMessage: fm, locale } = useIntl();
  const queryClient = useQueryClient();
  const { notify, notifySuccess, notifyError } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const [applicationIdFilter, setApplicationIdFilter] = useState<string>();
  const [statusFilter, setStatusFilter] = useState<string>();

  const columns: { name: ListApplicationRequestSortColumn | ''; key: string }[] = useMemo(() => {
    const companyNameColumn: { name: ListApplicationRequestSortColumn | ''; key: string }[] = !companyId
      ? [{ name: 'companyName', key: 'appManagement.table.header.companyName' }]
      : [];
    return [
      { name: 'applicationName', key: 'appManagement.table.header.applicationName' },
      { name: 'userEmail', key: 'appManagement.table.header.userEmail' },
      ...companyNameColumn,
      { name: 'requestedAt', key: 'appManagement.table.header.requestedAt' },
      { name: 'status', key: 'appManagement.table.header.status' },
      { name: '', key: '' }, // action
    ];
  }, [companyId]);

  const {
    data: applicationRequestsData,
    isLoading: isApplicationRequestsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    [
      'applicationRequests',
      getSortOrder<ListApplicationRequestSortColumn>('requestedAt', 'AppManagement', false),
      applicationIdFilter,
      statusFilter,
      companyId,
    ],
    async ({ pageParam = 1 }) => {
      const data = await applicationRequestService.list({
        paging: { pageSize, pageNumber: pageParam },
        orderBy: getSortOrder<ListApplicationRequestSortColumn>('requestedAt', 'AppManagement', false).toString(),
        applicationId: applicationIdFilter,
        status: statusFilter,
        companyId,
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          fm({ id: 'appManagement.notification.title' }),
          fm({ id: 'appManagement.notification.unableLoadRequests' }),
        ),
    },
  );

  const { mutate: approveAccess, isLoading: isApprovingAccess } = useMutation(
    async (id: string) => {
      await applicationRequestService.approveAccess(id);
    },
    {
      onSuccess: async () => {
        notifySuccess(
          fm({ id: 'appManagement.notification.title' }),
          fm({ id: 'appManagement.notification.approvedRequest' }),
        );
        return queryClient.invalidateQueries('applicationRequests');
      },
      onError: () =>
        notifyError(
          fm({ id: 'appManagement.notification.title' }),
          fm({ id: 'appManagement.notification.unableApproveRequest' }),
        ),
    },
  );

  const { mutate: rejectAccess, isLoading: isRejectingAccess } = useMutation(
    async (id: string) => {
      await applicationRequestService.rejectAccess(id);
    },
    {
      onSuccess: async () => {
        notifySuccess(
          fm({ id: 'appManagement.notification.title' }),
          fm({ id: 'appManagement.notification.rejectedRequest' }),
        );
        return queryClient.invalidateQueries('applicationRequests');
      },
      onError: () =>
        notifyError(
          fm({ id: 'appManagement.notification.title' }),
          fm({ id: 'appManagement.notification.unableRejectRequest' }),
        ),
    },
  );

  const { data: applicationsData, isLoading: isLoadingApplications } = useQuery(
    ['applicationList'],
    () => applicationService.list({ orderBy: 'name' }),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'appGallery.listTab.notification.title' }),
          fm({ id: 'appGallery.listTab.notification.unableToLoadApplicationList' }),
        ),
    },
  );

  const applicationOptions = useMemo(() => {
    return [
      { label: fm({ id: 'appManagement.filter.application.placeholder' }), value: '' },
      ...(applicationsData
        ? applicationsData.applications.map((application) => ({ label: application.name, value: application.id }))
        : []),
    ];
  }, [applicationsData, locale]);
  const statusOptions = useMemo(() => {
    return [
      { label: fm({ id: 'appManagement.filter.status.placeholder' }), value: '' },
      { label: fm({ id: 'appManagement.status.Requested' }), value: ApplicationRequestStatus.Requested },
      { label: fm({ id: 'appManagement.status.Approved' }), value: ApplicationRequestStatus.Approved },
      { label: fm({ id: 'appManagement.status.Rejected' }), value: ApplicationRequestStatus.Rejected },
    ];
  }, [locale]);

  return (
    <div className="app-management-component">
      <Header title={fm({ id: 'appManagement.title' })} showSearch />
      <div className="page-content px-5">
        <div className="filters-container pb-4">
          <Form.Group className="row gx-3">
            <div className="col">
              <Select
                value={applicationIdFilter || ''}
                onChange={(value) => setApplicationIdFilter(value)}
                options={applicationOptions}
                size="lg"
              />
            </div>
            <div className="col">
              <Select
                onChange={(value) => setStatusFilter(value)}
                value={statusFilter || ''}
                options={statusOptions}
                size="lg"
              />
            </div>
          </Form.Group>
        </div>
        {applicationRequestsData?.pages?.[0]?.applicationRequests?.length === 0 && (
          <div className="row p-7">
            <h3 className="col d-flex justify-content-center text-color--white">
              <FormattedMessage id="appManagement.noData" />
            </h3>
          </div>
        )}
        {(isApplicationRequestsLoading || isLoadingApplications) && (
          <AppManagementSkeleton columns={columns} cards={10} />
        )}

        {!!applicationRequestsData?.pages?.[0]?.applicationRequests?.length && (
          <div className="mb-4 shadow-2">
            <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold">
              <FormattedMessage
                id="appManagement.totalCount"
                values={{ count: applicationRequestsData?.pages[0]?.total }}
              />
            </div>
            <InfiniteScroll
              className="rounded-bottom"
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={null}
              dataLength={applicationRequestsData.pages.length}
              scrollableTarget="scrollContainer"
            >
              <table className={classNames('w-100', `request-table-${columns.length}`)}>
                <TableHeader<ListApplicationRequestSortColumn> columns={columns} tableName="AppManagement" />
                <tbody className="animated-component-item">
                  {applicationRequestsData.pages
                    .flatMap((x) => x.applicationRequests)
                    .map((applicationRequest) => (
                      <tr key={applicationRequest.id}>
                        <td>{applicationRequest.applicationName}</td>
                        <td>{applicationRequest.userEmail}</td>
                        {!companyId && <td>{applicationRequest.companyName}</td>}
                        <td>
                          <GenericFormattedDate value={applicationRequest.requestedAt} />
                        </td>
                        <td>
                          <FormattedMessage id={`appManagement.status.${applicationRequest.status}`} />
                        </td>
                        <td align="right">
                          {applicationRequest.status === ApplicationRequestStatus.Requested && (
                            <Button size="sm" onClick={() => approveAccess(applicationRequest.id)} className="me-2">
                              <FormattedMessage id="appManagement.action.approve" />
                            </Button>
                          )}
                          {applicationRequest.status !== ApplicationRequestStatus.Rejected && (
                            <Button size="sm" variant="secondary" onClick={() => rejectAccess(applicationRequest.id)}>
                              <FormattedMessage id="appManagement.action.reject" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </InfiniteScroll>
          </div>
        )}
      </div>
      {(isApprovingAccess || isRejectingAccess) && <Spinner />}
    </div>
  );
};

export default AppManagement;
