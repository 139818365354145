import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import ProductAlerts from '../../../../components/ProductAlerts/ProductAlerts';

const AlertsTab: FC = () => {
  const { id } = useParams<ParamsProp>();

  return <ProductAlerts productId={id} />;
};

type ParamsProp = {
  id: string;
};
export default AlertsTab;
