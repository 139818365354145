import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useAppContext } from '../../context/useAppContext';
import moduleService from '../../services/AppGallery/moduleService';

const useAppNavigation = () => {
  const {
    state: { authorized },
  } = useAppContext();

  const { data: navigationData, isLoading } = useQuery('navigation', () => moduleService.getNavigation(), {
    enabled: authorized,
  });
  const result = useMemo(
    () => ({
      isLoading,
      navigation: navigationData?.navigations || [],
      isTrackersAvailable: navigationData?.modules?.some((x) => x === 'Trackers') || false,
      isUsersAvailable: navigationData?.navigations?.some((x) => x.name === 'Users') || false,
      isManufacturerFacilitiesAvailable: navigationData?.navigations?.some((x) => x.name === 'Manufacturer') || false,
      isAppManagementAvailable: navigationData?.navigations.some((x) => x.name === 'AppManagement') || false,
    }),
    [navigationData, isLoading],
  );

  return result;
};

export default useAppNavigation;
