import React, {
  useRef,
  useEffect,
  ClipboardEventHandler,
  ClipboardEvent,
  KeyboardEventHandler,
  KeyboardEvent,
  FocusEventHandler,
  FocusEvent,
  ChangeEventHandler,
  ChangeEvent,
} from 'react';
import './CodeInput.scss';

const CodeInput = ({
  index,
  value,
  onChange,
  onPaste,
  onBackspace,
  isFocused,
  onFocus,
  isDisabled,
  onLeft,
  onRight,
}: ICodeInputProps) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    requestAnimationFrame(() => {
      if (ref.current !== document.activeElement && isFocused) {
        ref.current?.focus();
      }
    });
  }, [isFocused]);

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
  };

  const onPasteHandler: ClipboardEventHandler<HTMLInputElement> = (e: ClipboardEvent<HTMLInputElement>) => {
    onPaste(e.clipboardData.getData('text').replace(/[^0-9]*/g, ''));
  };

  const onKeyDownHandler: KeyboardEventHandler<HTMLInputElement> = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      onBackspace();
    }
    if (/^[0-9]$/.test(e.key)) {
      onChange(index, e.key);
    }
    if (e.key === 'ArrowLeft') {
      onLeft();
    }
    if (e.key === 'ArrowRight') {
      onRight();
    }
  };

  const onFocusHandler: FocusEventHandler<HTMLInputElement> = (e: FocusEvent<HTMLInputElement>) => {
    e.target.setSelectionRange(0, 1);
    onFocus(index);
  };

  return (
    <input
      className="form-control code-input-component"
      ref={ref}
      type="text"
      value={value}
      onChange={onChangeHandler}
      onPaste={onPasteHandler}
      onKeyDown={onKeyDownHandler}
      onFocus={onFocusHandler}
      disabled={isDisabled}
    />
  );
};

interface ICodeInputProps {
  index: number;
  value: string;
  isFocused: boolean;
  isDisabled: boolean;
  onChange: (index: number, value: string) => void;
  onPaste: (value: string) => void;
  onBackspace: VoidFunction;
  onFocus: (index: number) => void;
  onLeft: VoidFunction;
  onRight: VoidFunction;
}

export default CodeInput;
