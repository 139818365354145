import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const DetailsImageSkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton card p-3 mb-4" data-test-id="loader">
      <div className="font-bold text-uppercase pb-3">
        <FormattedMessage id="admin.productOverview.detailTab.chemicalStructure.title" />
      </div>
      <div className="text-center">
        <ContentLoader
          height={146}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.darkGrey2}
          foregroundColor={SkeletonColors.mediumGrey}
          viewBox="0 0 346 146"
        >
          <rect x="0" y="0" rx="8" ry="8" width="346" height="146" />
        </ContentLoader>
      </div>
    </div>
  );
};

export default DetailsImageSkeleton;
