import RequestData from '../models/Report/RequestData';
import request from './requestService';

class ReportService {
  private readonly BaseUrl = `/Report`;

  public async requestReport(requestData: RequestData): Promise<void> {
    await request.post(`${this.BaseUrl}`, requestData);
  }
}

const reportService = new ReportService();

export default reportService;
