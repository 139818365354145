import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Spinner from '../../../components/Spinner/Spinner';
import dataPlaceholders from '../../../constants/dataPlaceholders';
import { useAppContext } from '../../../context/useAppContext';
import useNotifications from '../../../hooks/useNotifications';
import CompanyDetails from '../../../models/Company/CompanyDetails';
import companyService from '../../../services/companyService';

const CompanyDetailsTab: FC = () => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const {
    state: { user },
  } = useAppContext();
  const { data: companyDetails, isLoading: isLoadingItem } = useQuery(
    ['companyDetails', user?.companyId],
    () => companyService.get(user?.companyId as string),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'settings.tab.companyDetails.notification.title' }),
          fm({ id: 'settings.tab.companyDetails.notification.unableLoadItem' }),
        ),
    },
  );

  const getCityString = (companyData?: CompanyDetails) => {
    if (!companyData?.city && !companyData?.zipCode) return dataPlaceholders.missingData;
    if (!companyData.city) return companyData.zipCode;
    if (!companyData.zipCode) return companyData.city;

    return `${companyData.zipCode} ${companyData.city}`;
  };

  const getHasManufacturingFacilities = (value?: boolean) => {
    if (value === undefined) {
      return undefined;
    }

    return fm({
      id: `settings.tab.companyDetails.card.hasManufacturingFacilities.value.${value ? 'yes' : 'no'}`,
    });
  };

  return (
    <div className="animated-component-item row mb-4">
      <div className="card">
        <div className="card-divider font-bold text-uppercase p-3">
          {fm({ id: 'settings.tab.companyDetails.title' })}
        </div>
        <div className="px-3 py-4">
          <div className="row">
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.companyName' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-name">
                {companyDetails?.name || dataPlaceholders.missingData}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.contactPerson' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-contactPerson">
                {companyDetails?.contactPerson || dataPlaceholders.missingData}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.emailAddress' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-email">
                {companyDetails?.email || dataPlaceholders.missingData}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.phoneNumber' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-phoneNumber">
                {companyDetails?.phoneNumber || dataPlaceholders.missingData}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.street' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-street">
                {companyDetails?.street || dataPlaceholders.missingData}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.zipAndCity' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-zipAndCity">
                {getCityString(companyDetails)}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                {fm({ id: 'settings.tab.companyDetails.card.country' })}
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-country">
                {companyDetails?.country || dataPlaceholders.missingData}
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="settings.tab.companyDetails.card.hasManufacturingFacilities.title" />
              </div>
              <div className="size-label" data-test-id="settings-companyDetails-country">
                {getHasManufacturingFacilities(companyDetails?.hasManufacturingFacilities) ||
                  dataPlaceholders.missingData}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoadingItem && <Spinner />}
    </div>
  );
};
export default CompanyDetailsTab;
