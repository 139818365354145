import { useReducer } from 'react';

interface ITwoFaFormState {
  inputValues: string[];
  focusedIndex: number;
}

type InputAction = {
  type: 'INPUT';
  payload: { value: string; index: number };
};

type BackspaceAction = {
  type: 'BACKSPACE';
};

type PasteAction = {
  type: 'PASTE';
  payload: { value: string };
};

type FocusAction = {
  type: 'FOCUS';
  payload: { index: number };
};

type BackAction = {
  type: 'BACK';
};

type ForwardAction = {
  type: 'FORWARD';
};

type TwoFaFormActions = InputAction | BackspaceAction | PasteAction | FocusAction | BackAction | ForwardAction;

const clampIndex = (index: number): number => {
  if (index > 5) {
    return 5;
  }

  if (index < 0) {
    return 0;
  }

  return index;
};

const reducer = (state: ITwoFaFormState, action: TwoFaFormActions): ITwoFaFormState => {
  switch (action.type) {
    case 'INPUT':
      return {
        ...state,
        inputValues: [
          ...state.inputValues.slice(0, action.payload.index),
          action.payload.value,
          ...state.inputValues.slice(action.payload.index + 1),
        ],
        focusedIndex: clampIndex(state.focusedIndex + 1),
      };

    case 'BACKSPACE':
      return {
        ...state,
        inputValues:
          state.focusedIndex === 0
            ? ['', ...state.inputValues.slice(state.focusedIndex + 1)]
            : [
                ...state.inputValues.slice(0, state.focusedIndex),
                '',
                ...state.inputValues.slice(state.focusedIndex + 1),
              ],
        focusedIndex: clampIndex(state.focusedIndex - 1),
      };

    case 'PASTE':
      return {
        ...state,
        inputValues: state.inputValues.map((_, index: number) => action.payload.value[index] || ''),
      };

    case 'FOCUS':
      return {
        ...state,
        focusedIndex: action.payload.index,
      };

    case 'BACK':
      return {
        ...state,
        focusedIndex: clampIndex(state.focusedIndex - 1),
      };

    case 'FORWARD':
      return {
        ...state,
        focusedIndex: clampIndex(state.focusedIndex + 1),
      };

    default:
      throw new Error('unknown CodeForm action');
  }
};

const useCodeFormReducer = (initialState: ITwoFaFormState) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return { state, dispatch };
};

export default useCodeFormReducer;
export type { ITwoFaFormState };
