import React, { useMemo } from 'react';
import ReactSelect, { components } from 'react-select';
import SelectOption from '../../models/SelectOption';
import defaultStyles from './styles';

const createCustomContainer = (props: any, dataTestId?: string) => (
  <components.SelectContainer {...props} innerProps={{ ...props.innerProps, ...{ 'data-test-id': dataTestId } }} />
);

const Select: React.FC<Props> = ({
  options,
  value,
  onChange,
  size,
  minWidth,
  isDisabled,
  isError,
  dataTestId,
  placeholder,
}) => {
  const styles = useMemo(() => {
    let base = size === 'lg' ? defaultStyles.lgStyles : defaultStyles.stylesBase;

    if (minWidth) {
      const localBase = base;
      base = {
        ...localBase,
        control: (provided, state) => ({
          ...localBase.control?.(provided, state),
          minWidth,
        }),
      };
    }

    if (isError) {
      const localBase = base;
      base = {
        ...localBase,
        control: (provided, state) => ({
          ...localBase.control?.(provided, state),
          borderColor: '#F04141',
          ':hover': {
            borderColor: '#F04141',
          },
        }),
      };
    }

    return base;
  }, [size, isError, minWidth]);

  const customComponents = useMemo(
    () => ({
      SelectContainer: (props: any) => createCustomContainer(props, dataTestId),
    }),
    [dataTestId],
  );

  return (
    <ReactSelect<SelectOption>
      components={customComponents}
      styles={styles}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      options={options}
      value={options.filter((x) => x.value === value)[0] || ''}
      onChange={(option) => onChange(!option ? undefined : option.value, option?.label)}
      isSearchable={false}
      isDisabled={isDisabled}
      placeholder={placeholder}
    />
  );
};

interface Props {
  options: SelectOption[];
  value?: string;
  onChange(value?: string, label?: string): void;
  size?: 'sm' | 'lg';
  minWidth?: number;
  isDisabled?: boolean;
  isError?: boolean;
  dataTestId?: string;
  placeholder?: string;
}
export default Select;
