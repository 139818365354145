import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Spinner from '../Spinner/Spinner';
import TabItem from '../TabbedComponent/TabItem';
import Tabs from '../TabbedComponent/Tabs';
import TabsContent from '../TabbedComponent/TabsContent';
import TabsContentItem from '../TabbedComponent/TabsContentItem';
import useNotifications from '../../hooks/useNotifications';
import TrackersTabs from '../../models/Tabs/TrackersTabs';
import synthesisStepService from '../../services/synthesisStepService';
import TabsContextProvider from '../TabbedComponent/TabsContextProvider';
import QualityTab from './Tabs/QualityTab';
import PerformanceTab from './Tabs/PerformanceTab';
import SustainabilityTab from './Tabs/SustainabilityTab';

interface Props {
  stepId: string;
  activeTab?: TrackersTabs;
  onTabChange: (tab: TrackersTabs) => void;
}

const SynthesisStepTrackers: FC<Props> = ({ stepId, activeTab, onTabChange }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: trackers, isLoading: isLoadingTrackers } = useQuery(
    ['synthesisStepTrackers', stepId],
    () => synthesisStepService.trackers(stepId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'synthesisStepTrackers.notification.title' }),
          intl.formatMessage({ id: 'synthesisStepTrackers.notification.unableToLoadTrackers' }),
        ),
    },
  );

  const setActiveTab = (tab: string) => {
    onTabChange(tab as TrackersTabs);
  };

  return (
    <div className="p-3">
      <TabsContextProvider activeTab={activeTab ?? TrackersTabs.Quality} setActiveTab={setActiveTab}>
        <Tabs className="mb-3">
          <TabItem id={TrackersTabs.Quality}>
            <FormattedMessage id="synthesisStepTrackers.tab.quality" />
          </TabItem>
          <TabItem id={TrackersTabs.Performance}>
            <FormattedMessage id="synthesisStepTrackers.tab.performance" />
          </TabItem>
          <TabItem id={TrackersTabs.Sustainability}>
            <FormattedMessage id="synthesisStepTrackers.tab.sustainability" />
          </TabItem>
        </Tabs>
        <TabsContent>
          <TabsContentItem id={TrackersTabs.Quality}>
            <QualityTab trackers={trackers} />
          </TabsContentItem>
          <TabsContentItem id={TrackersTabs.Performance}>
            <PerformanceTab trackers={trackers} />
          </TabsContentItem>
          <TabsContentItem id={TrackersTabs.Sustainability}>
            <SustainabilityTab trackers={trackers} />
          </TabsContentItem>
        </TabsContent>
        {isLoadingTrackers && <Spinner />}
      </TabsContextProvider>
    </div>
  );
};

export default SynthesisStepTrackers;
