import React, { FC } from 'react';

import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import CopyToClipboardButton from '../CopyToClipboardButton/CopyToClipboardButton';

const BackupRecoveryCode: FC<IRecoveryCodes> = ({ codes, onRegenerate, onBackup, slimBtn }) => {
  const intl = useIntl();

  return (
    <>
      <div
        className="my-3 w-100 bg-color-gray-l1 p-3 d-flex justify-content-center rounded size-label"
        data-test-id="backup-recovery-code-code"
      >
        {codes}
      </div>
      <div className="d-flex w-100 justify-content-center recovery-btns">
        <CopyToClipboardButton
          targetText={codes}
          tooltip={intl.formatMessage({ id: 'backupRecoveryCode.copyCode.tooltip' })}
          data-test-id="copyToClipboard-btn"
        >
          <FormattedMessage id="backupRecoveryCode.copyCode.btn.label" />
        </CopyToClipboardButton>
        <Button variant="secondary" className="text-uppercase" onClick={onRegenerate} data-test-id="regenerateCode-btn">
          <FormattedMessage id="backupRecoveryCode.regenerateCode.btn.label" />
        </Button>
      </div>
      <Button
        className={`text-uppercase btn-rounded mt-5 ${slimBtn ? 'mx-auto' : ''}`}
        onClick={onBackup}
        data-test-id="backupDone-btn"
      >
        <FormattedMessage id="backupRecoveryCode.done.btn.label" />
      </Button>
    </>
  );
};

interface IRecoveryCodes {
  codes: string;
  slimBtn?: boolean;
  onRegenerate: () => void;
  onBackup: () => void;
}

export default BackupRecoveryCode;
