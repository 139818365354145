import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import SynthesisStepSkeleton from '../../../components/Skeletons/ProductOverview/SynthesisSteps/SynthesisStepSkeleton';
import SynthesisStepItem from '../../../components/SynthesisStepItem/SynthesisStepItem';
import productsHelper from '../../../helpers/productsHelper';
import useNotifications from '../../../hooks/useNotifications';
import useQueryStringState from '../../../hooks/useQueryStringState';
import SynthesisStepViewState from '../../../models/SynthesisStep/SynthesisStepViewState';
import synthesisStepService from '../../../services/synthesisStepService';

const DetailsTab: FC = () => {
  const { stepId: synthesisStepId } = useParams();
  const intl = useIntl();
  const { notify } = useNotifications();

  const navigate = useNavigate();

  const { data: step, isLoading: isLoadingStep } = useQuery(
    ['synthesisStep', synthesisStepId],
    () => synthesisStepService.getDetails(synthesisStepId!),
    {
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'synthesisStepOverview.notification.title' }),
          intl.formatMessage({ id: 'synthesisStepOverview.notification.unableLoadStep' }),
        );
        navigate('/');
      },
    },
  );

  const [viewState, setViewState] = useQueryStringState<SynthesisStepViewState | undefined>();

  return (
    <>
      {isLoadingStep && <SynthesisStepSkeleton showGetReportBtn={false} />}
      {step && (
        <SynthesisStepItem
          step={step}
          showBtnGetReport={false}
          viewState={viewState.search}
          onViewStateChange={(state) => setViewState({ ...viewState, search: state })}
          linkToDetails={productsHelper.getSynthesisStepOverviewCampaingsUrl(step.productId, step.id)}
        />
      )}
    </>
  );
};

export default DetailsTab;
