import { useReducer } from 'react';
import IAppState from './AppState';
import AppAction from './AppAction';
import authStorageService from '../services/authStorageService';

const reducer = (state: IAppState, action: AppAction): IAppState => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload.user,
      };
    case 'AUTHORIZE':
      return {
        ...state,
        authorized: true,
      };
    case 'UNAUTHORIZE':
      return {
        ...state,
        user: null,
        authorized: false,
      };
    case 'SHOW_NOTIFICATION':
      return {
        ...state,
        notification: action.payload.notification,
      };
    case 'CLEAR_NOTIFICATION':
      return {
        ...state,
        notification: null,
      };

    default:
      return state;
  }
};

export const initialAppState: IAppState = {
  authorized: !!authStorageService.getState(),
  user: null,
  notification: null,
};

const useAppReducer = () => useReducer(reducer, initialAppState);

export default useAppReducer;
