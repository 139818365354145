import TableSortingViewState from '../models/TableSortingViewState';
import SortOrder from '../models/SortOrder';
import useQueryStringState from './useQueryStringState';
import SortingByViewState from '../models/SortingByViewState';

const useSortingViewState = () => {
  const [viewSortingState, setViewState] = useQueryStringState<TableSortingViewState>();

  const handleSortingChange = (tableName: string) => (newSortingState?: SortingByViewState) => {
    const { [tableName]: oldSortingState, ...restSortingState } = viewSortingState.search;
    if (newSortingState) {
      restSortingState[tableName] = newSortingState;
    }
    setViewState({
      ...viewSortingState,
      search: { ...viewSortingState.search, ...restSortingState },
      hash: '',
    });
  };

  const isDescending = (tableName: string) => viewSortingState.search[tableName]?.sortingBy?.includes('_desc');

  const getSortOrder = <TSortColumn extends string>(
    defaultColumnName: TSortColumn,
    tableName: string,
    defaultIsAscending = true,
  ) => {
    return viewSortingState.search[tableName]?.sortingBy
      ? new SortOrder<TSortColumn>(viewSortingState.search[tableName]?.sortingBy as TSortColumn)
      : new SortOrder<TSortColumn>(defaultColumnName, defaultIsAscending);
  };

  return { handleSortingChange, viewSortingState, isDescending, getSortOrder };
};
export default useSortingViewState;
