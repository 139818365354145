import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import BatchTrackers from '../../../models/Batch/BatchTrackers';
import ValueTracker from '../../trackers/ValueTracker/ValueTracker';
import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import TableTracker from '../../trackers/TableTracker/TableTracker';

const SustainabilityTab: FC<Props> = ({ trackers }) => {
  const intl = useIntl();
  return (
    <div className="row g-3">
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.eFactor)}
          title={intl.formatMessage({ id: 'tracker.eFactor.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.eFactor.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.eFactor)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.eFactor.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.processMassIntensityTotalMaterial)}
          title={intl.formatMessage({ id: 'tracker.processMassIntensityTotalMaterial.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.processMassIntensityTotalMaterial.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.processMassIntensityTotalMaterial)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.processMassIntensityTotalMaterial.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.processMassIntensityToxicMaterial)}
          title={intl.formatMessage({ id: 'tracker.processMassIntensityToxicMaterial.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.processMassIntensityToxicMaterial.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.processMassIntensityToxicMaterial)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.processMassIntensityTotalMaterial.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.criticalRawMaterials)}
          title={intl.formatMessage({ id: 'tracker.criticalRawMaterials.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.criticalRawMaterials.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.criticalRawMaterials)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.criticalRawMaterials.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.recyclingRate)}
          title={intl.formatMessage({ id: 'tracker.recyclingRate.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.recyclingRate.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.recyclingRate)?.toString()}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.toxicity)}
          title={intl.formatMessage({ id: 'tracker.toxicity.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.toxicity.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.toxicity)?.toString()}
          units="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.energyIntensity)}
          title={intl.formatMessage({ id: 'tracker.energyIntensity.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.energyIntensity.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.energyIntensity)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.energyIntensity.unit' })}
        />
      </div>
      <div className="col-3 chart-row">
        <TableTracker
          title={intl.formatMessage({ id: 'tracker.energyMix.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.energyMix.tooltips.batch' })}
          value={trackers?.energyMix || undefined}
          unit="%"
        />
      </div>
      <div className="col-3 chart-row">
        <ValueTracker
          variant={trackerHelper.getTypeForRegular(trackers?.emittedGreenHouseGases)}
          title={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.title' })}
          tooltip={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.tooltips.batch' })}
          value={numberHelper.formatNumber(trackers?.emittedGreenHouseGases)?.toString()}
          hint={intl.formatMessage({ id: 'tracker.emittedGreenHouseGases.unit' })}
        />
      </div>
    </div>
  );
};

interface Props {
  trackers?: BatchTrackers;
}

export default SustainabilityTab;
