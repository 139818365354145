import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import Spinner from '../Spinner/Spinner';
import TabItem from '../TabbedComponent/TabItem';
import Tabs from '../TabbedComponent/Tabs';
import TabsContent from '../TabbedComponent/TabsContent';
import TabsContentItem from '../TabbedComponent/TabsContentItem';
import useNotifications from '../../hooks/useNotifications';
import TrackersTabs from '../../models/Tabs/TrackersTabs';
import batchService from '../../services/batchService';
import TabsContextProvider from '../TabbedComponent/TabsContextProvider';
import PerformanceTab from './Tabs/PerformanceTab';
import QualityTab from './Tabs/QualityTab';
import SustainabilityTab from './Tabs/SustainabilityTab';

interface Props {
  batchId: string;
  activeTab: string | undefined;
  onTabChange: (tab: TrackersTabs | undefined) => void;
}

const DefaultTab = TrackersTabs.Quality;

const BatchTrackers: FC<Props> = ({ batchId, activeTab, onTabChange }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: trackers, isLoading: isLoadingTrackers } = useQuery(
    ['batchTrackers', batchId],
    () => batchService.trackers(batchId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchTrackers.notification.title' }),
          intl.formatMessage({ id: 'batchTrackers.notification.unableToLoadTrackers' }),
        ),
    },
  );

  const handleTabChange = (tab: string | undefined) => {
    onTabChange(tab === DefaultTab ? undefined : (tab as TrackersTabs));
  };

  return (
    <div className="p-3">
      <TabsContextProvider activeTab={activeTab ?? DefaultTab} setActiveTab={handleTabChange}>
        <Tabs className="mb-3">
          <TabItem id={TrackersTabs.Quality}>
            <FormattedMessage id="batchTrackers.tab.quality" />
          </TabItem>
          <TabItem id={TrackersTabs.Performance}>
            <FormattedMessage id="batchTrackers.tab.performance" />
          </TabItem>
          <TabItem id={TrackersTabs.Sustainability}>
            <FormattedMessage id="batchTrackers.tab.sustainability" />
          </TabItem>
        </Tabs>
        <TabsContent>
          <TabsContentItem id={TrackersTabs.Quality}>
            <QualityTab trackers={trackers} />
          </TabsContentItem>
          <TabsContentItem id={TrackersTabs.Performance}>
            <PerformanceTab trackers={trackers} />
          </TabsContentItem>
          <TabsContentItem id={TrackersTabs.Sustainability}>
            <SustainabilityTab trackers={trackers} />
          </TabsContentItem>
        </TabsContent>
        {isLoadingTrackers && <Spinner />}
      </TabsContextProvider>
    </div>
  );
};

export default BatchTrackers;
