import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import useNotifications from '../../../hooks/useNotifications';
import batchService from '../../../services/batchService';
import Spinner from '../../Spinner/Spinner';
import BatchPart from '../../../models/Batch/BatchPart';
import PartType from '../../../models/Batch/PartType';
import OutOfRangeActions from '../../trackers/OutOfRangeActions/OutOfRangeActions';

interface Props {
  batchId: string;
  onSelectPart: (batchId: string, partId: string) => void;
}

const BatchParts: FC<Props> = ({ batchId, onSelectPart }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: batchParts, isLoading: isLoadingParts } = useQuery(
    ['batchParts', batchId],
    () => batchService.listParts(batchId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.title' }),
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.notification.unableLoadParts' }),
        ),
    },
  );

  const [preparationPart, setPreparationPart] = useState<BatchPart>();
  const [reactionPart, setReactionPart] = useState<BatchPart>();
  const [separationPart, setSeparationPart] = useState<BatchPart>();
  const [packagingPart, setPackagingPart] = useState<BatchPart>();

  useEffect(() => {
    setPreparationPart(batchParts?.find((x) => x.type === PartType.Preparation));
    setReactionPart(batchParts?.find((x) => x.type === PartType.Reaction));
    setSeparationPart(batchParts?.find((x) => x.type === PartType.Separation));
    setPackagingPart(batchParts?.find((x) => x.type === PartType.Packaging));
  }, [batchParts]);

  return (
    <div className="p-3 header card-divider bg-color-gray-l4">
      <div className="parts row gx-3 chart-row">
        <div className="col">
          <OutOfRangeActions
            title={intl.formatMessage({ id: 'batchParts.batchTable.part.name.preparation' })}
            tooltip={intl.formatMessage({ id: 'batchParts.batchTable.part.tooltips.preparation' })}
            outOfRangeActions={preparationPart?.outOfRangeActions}
            totalActions={preparationPart?.totalActions}
            onClick={() => onSelectPart(batchId!, preparationPart!.id)}
            partNumber={1}
          />
        </div>
        <div className="col">
          <OutOfRangeActions
            title={intl.formatMessage({ id: 'batchParts.batchTable.part.name.reaction' })}
            tooltip={intl.formatMessage({ id: 'batchParts.batchTable.part.tooltips.reaction' })}
            outOfRangeActions={reactionPart?.outOfRangeActions}
            totalActions={reactionPart?.totalActions}
            onClick={() => onSelectPart(batchId!, reactionPart!.id)}
            partNumber={2}
          />
        </div>
        <div className="col">
          <OutOfRangeActions
            title={intl.formatMessage({ id: 'batchParts.batchTable.part.name.separation' })}
            tooltip={intl.formatMessage({ id: 'batchParts.batchTable.part.tooltips.separation' })}
            outOfRangeActions={separationPart?.outOfRangeActions}
            totalActions={separationPart?.totalActions}
            onClick={() => onSelectPart(batchId!, separationPart!.id)}
            partNumber={3}
          />
        </div>
        <div className="col">
          <OutOfRangeActions
            title={intl.formatMessage({ id: 'batchParts.batchTable.part.name.packaging' })}
            tooltip={intl.formatMessage({ id: 'batchParts.batchTable.part.tooltips.packaging' })}
            outOfRangeActions={packagingPart?.outOfRangeActions}
            totalActions={packagingPart?.totalActions}
            onClick={() => onSelectPart(batchId!, packagingPart!.id)}
            partNumber={4}
          />
        </div>
      </div>
      {isLoadingParts && <Spinner />}
    </div>
  );
};

export default BatchParts;
