import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import ListUsersSortColumn from '../../models/User/ListUsersSortColumn';
import './UsersTable.scss';

interface Props {
  cards: number;
  showCompanyName?: boolean;
  showPhoneNumber?: boolean;
}

const UsersTableSkeleton: FC<Props> = ({ cards, showCompanyName = true, showPhoneNumber = true }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  const columns: { name: ListUsersSortColumn | ''; key: string }[] = useMemo(() => {
    const companyNameColumn: { name: ListUsersSortColumn | ''; key: string }[] = showCompanyName
      ? [{ name: 'companyName', key: 'usersTable.header.company' }]
      : [];
    const phoneNumberColumn: { name: ListUsersSortColumn | ''; key: string }[] = showPhoneNumber
      ? [{ name: 'phoneNumber', key: 'usersTable.header.phoneNumber' }]
      : [];
    return [
      { name: 'fullName', key: 'usersTable.header.fullName' },
      { name: 'email', key: 'usersTable.header.email' },
      ...phoneNumberColumn,
      ...companyNameColumn,
      { name: 'department', key: 'usersTable.header.department' },
      { name: 'roleType', key: 'usersTable.header.roleType' },
      { name: 'activeSince', key: 'usersTable.header.activeSince' },
      { name: 'isActive', key: 'usersTable.header.isActive' },
      { name: '', key: '' }, // actions
    ];
  }, [showCompanyName]);

  return (
    <>
      <div className="bg-color-gray-l3 text-uppercase p-3 rounded-top font-bold" data-test-id="loader">
        <FormattedMessage id="usersTable.totalCount" values={{ count: null }} />
        <ContentLoader
          height={20}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.lightGrey1}
          foregroundColor={SkeletonColors.lightGrey2}
          viewBox="0 0 70 25"
        >
          <rect x="0" y="0" rx="4" ry="4" width="40" height="20" />
        </ContentLoader>
      </div>
      <table className="users-table-component w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 234 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="150" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 278 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="200" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 158 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="140" height="25" />
                </ContentLoader>
              </td>
              {showPhoneNumber && (
                <td>
                  <ContentLoader
                    height={30}
                    speed={SkeletonSpeed}
                    backgroundColor={SkeletonColors.lightGrey2}
                    foregroundColor={SkeletonColors.darkGrey2}
                    viewBox="0 0 232 30"
                  >
                    <rect x="1" y="3" rx="4" ry="4" width="120" height="25" />
                  </ContentLoader>
                </td>
              )}
              {showCompanyName && (
                <td>
                  <ContentLoader
                    height={30}
                    speed={SkeletonSpeed}
                    backgroundColor={SkeletonColors.lightGrey2}
                    foregroundColor={SkeletonColors.darkGrey2}
                    viewBox="0 0 232 30"
                  >
                    <rect x="1" y="3" rx="4" ry="4" width="120" height="25" />
                  </ContentLoader>
                </td>
              )}
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 158 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="140" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 186 30"
                >
                  <rect x="1" y="3" rx="4" ry="4" width="170" height="25" />
                </ContentLoader>
              </td>
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 92 30"
                >
                  <rect x="3" y="3" rx="4" ry="4" width="60" height="25" />
                </ContentLoader>
              </td>
              <td align="center">
                <Button className="btn-toggle btn-secondary">
                  <FontAwesomeIcon icon={faEllipsis} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default UsersTableSkeleton;
