import React, { FC, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link, NavLink, Outlet, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton/BackButton';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';
import Header from '../../../components/Header/Header';
import ReportButton from '../../../components/Report/ReportButton';
import StatusBadge from '../../../components/StatusBadge/StatusBadge';
import dataPlaceholders from '../../../constants/dataPlaceholders';
import companiesHelper from '../../../helpers/companiesHelper';
import useNotifications from '../../../hooks/useNotifications';
import EntityTypes from '../../../models/Report/EntityTypes';
import ReportData from '../../../models/Report/ReportData';
import productService from '../../../services/productService';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';

const ProductOverview: FC = () => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status } = useQuery(['productStatus', productId], () => productService.getStatus(productId!), {
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'admin.productOverview.notification.title' }),
        intl.formatMessage({ id: 'admin.productOverview.notification.unableLoadDetails' }),
      ),
  });

  const reportData: ReportData = useMemo(() => {
    if (status) {
      return {
        productName: status.name,
        entityType: EntityTypes.product,
        entityId: status.id,
      };
    }
    return {
      productName: '',
      entityType: EntityTypes.product,
      entityId: '',
    };
  }, [status]);

  return (
    <>
      <Header
        title={
          <>
            {status?.name || ''}
            <div className="py-2">
              <Breadcrumbs
                values={[
                  {
                    url: status?.companyId ? companiesHelper.getCompanyUrl(status?.companyId) : undefined,
                    label: status?.companyName || '',
                  },
                ]}
              />
            </div>
          </>
        }
      >
        <div className="d-flex gap-4 align-items-baseline">
          <StatusBadge isActive={status?.isActive || false} className="size-caption" />
          <span className="size-caption">
            <FormattedMessage id="admin.productOverview.header.phase" />{' '}
            {`${status?.phaseName || dataPlaceholders.missingData}`}
          </span>
          <span className="size-caption">
            <FormattedMessage id="admin.productOverview.header.updatedAt" />{' '}
            <GenericFormattedDate value={status?.updatedAt} />
          </span>
          <Link className="btn-link-underline btn-link" to="edit" data-test-id="products-edit">
            <FormattedMessage id="admin.productOverview.action.edit" />
          </Link>
          <ReportButton reportData={reportData} fullSize />
          <BackButton />
        </div>
      </Header>
      <div className="page-content px-5" id="productOverviewContainer">
        <Nav variant="tabs" className="mb-4">
          <Nav.Item>
            <Nav.Link as={NavLink} to="details">
              <FormattedMessage id="admin.productOverview.title.detail" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="status">
              <FormattedMessage id="admin.productOverview.title.status" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="steps">
              <FormattedMessage id="admin.productOverview.title.steps" values={{ value: status?.totalSteps }} />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="campaigns" data-test-id="tab-campaings">
              <FormattedMessage id="productsOverview.title.campaigns" values={{ value: status?.totalCampaings }} />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="alerts">
              <FormattedMessage id="admin.productOverview.title.alerts" values={{ value: status?.totalAlerts }} />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="updates">
              <FormattedMessage id="admin.productOverview.title.updates" values={{ value: status?.totalUpdates }} />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Outlet />
      </div>
    </>
  );
};

export default ProductOverview;
