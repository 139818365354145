import React, { FC } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { useQuery } from 'react-query';
import useNotifications from '../../../../../hooks/useNotifications';
import batchService from '../../../../../services/batchService';
import GenericFormattedDate from '../../../../GenericFormattedDate/GenericFormattedDate';
import Spinner from '../../../../Spinner/Spinner';

const BatchActions: FC<Props> = ({ batchId, stageId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: operations, isLoading: isLoadingList } = useQuery(
    ['stages', batchId, stageId],
    () => batchService.listFailedActions(batchId, stageId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.actions.notification.title' }),
          intl.formatMessage({ id: 'batchParts.batchTable.part.details.actions.notification.unableLoadActions' }),
        ),
    },
  );

  return (
    <>
      {operations?.map((operation) => (
        <div key={operation.id}>
          <div className="row py-3 px-4">
            <div className="col size-label">
              <FormattedMessage id="batchParts.batchTable.part.details.actions.operation.title" /> {operation.name}{' '}
              {operation.description}
            </div>
            <div className="col-auto size-caption d-flex gap-2 text-color-gray-l6">
              {operation.startedAt && (
                <>
                  <span>
                    <FormattedMessage id="batchParts.batchTable.part.details.actions.operation.startedAt" />{' '}
                    <GenericFormattedDate value={operation.startedAt} withTime />
                  </span>
                  -
                  <span>
                    <FormattedMessage id="batchParts.batchTable.part.details.actions.operation.finishedAt" />{' '}
                    <GenericFormattedDate value={operation.finishedAt} withTime />
                  </span>
                </>
              )}
            </div>
          </div>
          {operation.actions.map((action) => (
            <div key={action.id} className="row py-3 px-4">
              <div className="col-2 text-color-graph-negative size-label">{action.name}</div>
              <div className="col-2 size-label">
                {action.value} {action.unit}
              </div>
              <div className="col-8 size-label font-normal">{action.description}</div>
            </div>
          ))}
        </div>
      ))}
      {isLoadingList && <Spinner />}
    </>
  );
};

interface Props {
  batchId: string;
  stageId: string;
}

export default BatchActions;
