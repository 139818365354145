import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  message?: string;
}

const NavItem: FC<Props> = ({ message }) => {
  return (
    <div className="text-center p-7">
      <h3>{message || <FormattedMessage id="noData.label" />}</h3>
    </div>
  );
};

export default NavItem;
