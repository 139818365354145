import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import CampaignList from '../../../components/CampaignList/CampaignList';

interface Props {
  companyId?: string;
}

const CampaignsTab: FC<Props> = ({ companyId }) => {
  const { stepId: synthesisStepId, id: productId } = useParams();

  return <CampaignList productId={productId!} stepId={synthesisStepId!} companyId={companyId} />;
};

export default CampaignsTab;
