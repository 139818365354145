import React, { FC, useMemo } from 'react';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';

import './AlertsChart.scss';
import AdminAlertStatList from '../../models/Alert/AdminAlertStatList';
import IconTooltip from '../Tooltips/IconTooltip/IconTooltip';
import numberHelper from '../../helpers/numberHelper';
import AdminAlertStatGroupBy from '../../models/Alert/AdminAlertStatGroupBy';

dayjs.extend(weekOfYear);

const addEmptyColumns = (length: number) => {
  return Array(length).fill({
    value: '',
    isLast: false,
    height: 0,
  });
};

const calcTrend = (source: AdminAlertStatList) => {
  if (!source || source.length < 2) {
    return 0;
  }

  const [first, last] = source.slice(-2);

  return (last.Count * 100) / first.Count - 100;
};

const AlertsChart: FC<IProps> = ({ data, size, groupBy, noDataLabel }) => {
  const { formatMessage: fm, formatNumber: fn, locale } = useIntl();

  const statColumnLabelFormatters = useMemo<
    Record<AdminAlertStatGroupBy, (date: Date) => { label: string; tooltip: string }>
  >(() => {
    return {
      Day: (date) => {
        return {
          label: dayjs(date).format('DD.MM'),
          tooltip: dayjs(date).format('DD.MM.YYYY'),
        };
      },
      Week: (date) => {
        const day = date.getDay();

        const startDiff = date.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
        const endDiff = date.getDate() + (day === 0 ? 0 : 7 - day); // adjust when day is sunday
        const startDate = new Date(date.setDate(startDiff));
        const endDate = new Date(date.setDate(endDiff));

        return {
          label: fm({ id: 'alertsChart.axis.x.weekLabel' }, { weekNumber: dayjs(date).week() }),
          tooltip: `${dayjs(startDate).format('DD.MM.YYYY')} - ${dayjs(endDate).format('DD.MM.YYYY')}`,
        };
      },
      Month: (date) => {
        return {
          label: dayjs(date).format('MM.YY'),
          tooltip: dayjs(date).format('MM.YYYY'),
        };
      },
    };
  }, [locale]);

  const { trend, yAxis, xAxis, columns } = useMemo(() => {
    const source = data ? data.slice(-size) : [];
    let max = source.length > 0 ? Math.max(...source.map(({ Count }) => Count)) : 0;
    let delta = 0;
    if (max <= 10) {
      delta = 2;
    } else if (max < 25) {
      delta = 5;
    } else if (max < 50) {
      delta = 10;
    } else {
      delta = 20;
    }

    max = Math.ceil(max / delta) * delta;
    const yAxisLabels = Array(max / delta)
      .fill(null)
      .map((val, index) => index * delta)
      .concat([max])
      .reverse();

    const emptyColumnsCount = !source || size - source.length < 0 ? 0 : size - source.length;
    return {
      yAxis: yAxisLabels.length > 1 ? yAxisLabels : [],
      trend: calcTrend(source),
      xAxis: source.map(({ UnitDate }) => statColumnLabelFormatters[groupBy](UnitDate)),
      columns: source
        .map(({ Count }, index) => {
          return {
            value: Count,
            height: (Count * 100) / max,
            isLast: index === source.length - 1,
          };
        })
        .concat(addEmptyColumns(emptyColumnsCount)),
    };
  }, [data]);

  const renderTrend = () => {
    const trendClass = trend > 0 ? 'text-color-error' : 'text-color-primary-green';
    return trend !== 0 ? (
      <div className={trendClass}>
        {trend > 0 ? '+' : ''}
        {numberHelper.formatNumber(trend).toString()}%
      </div>
    ) : null;
  };

  return (
    <div className="alerts-chart animated-component-item rounded-4 bg-color-gray-l1 w-100 d-flex flex-column px-3 pt-3">
      <div className="size-label d-flex justify-content-between pb-3">
        <div>
          <FormattedMessage id="alertsChart.title" />
          <IconTooltip tooltip={fm({ id: 'alertsChart.titleTooltip' })} className="ms-1 d-inline-block" />
        </div>
        {renderTrend()}
      </div>
      <div className="chart-container flex-1 d-flex position-relative text-color-gray-l6">
        {yAxis.length === 0 ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <div>{noDataLabel}</div>
          </div>
        ) : (
          <div className="y-axis d-flex flex-1">
            <div className="lines d-flex flex-column flex-1 position-relative">
              {yAxis.map((value) => (
                <div key={value.toString()} className="line" />
              ))}
              <div className="columns size-label position-absolute d-flex">
                {columns.map(({ value, height, isLast }, index) => {
                  const key = `${value.toString()}+${index.toString()}`;
                  const style = { height: `${height}%` };
                  const columnClass = value > 0 ? 'column-border' : '';
                  const lastClass = isLast ? 'last-column' : '';
                  return (
                    <div key={key} className="d-flex align-items-end h-100 flex-1 position-relative">
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={
                          <Tooltip className="tooltip-title">
                            {value !== 0 ? `${fn(value)}` : fm({ id: 'alertsChart.noData' })}
                          </Tooltip>
                        }
                      >
                        <div className={`column w-100 ${columnClass} ${lastClass}`} style={style} />
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={<Tooltip className="tooltip-title">{xAxis[index]?.tooltip}</Tooltip>}
                      >
                        <div className="w-100 text-center z-axis-label position-absolute">{xAxis[index]?.label}</div>
                      </OverlayTrigger>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="labels d-flex flex-column px-3">
              {yAxis.map((value) => (
                <div key={value.toString()} className="label align-items-end">
                  <div className="position-relative h-100">
                    <div className="text position-absolute">{value}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

interface IProps {
  data: AdminAlertStatList | undefined;
  size: number;
  groupBy: AdminAlertStatGroupBy;
  noDataLabel: string;
}

export default AlertsChart;
