import React, { FC, PropsWithChildren } from 'react';

const Tabs: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <ul className={`nav ${className || ''}`}>{children}</ul>;
};

interface Props {
  className?: string;
}

export default Tabs;
