import ProductsViewState from '../models/Product/ProductsViewState';
import useQueryStringState from './useQueryStringState';

const useProductsViewState = <T extends ProductsViewState>() => {
  const [viewState, setViewState] = useQueryStringState<T>();

  const setPhaseId = (phaseId?: number) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        phaseId,
      },
    });
  };

  const setIsActive = (isActive?: boolean) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        isActive: isActive as 'true' | 'false' | undefined,
      },
    });
  };

  return {
    viewState,
    setViewState,
    phaseId: viewState.search.phaseId,
    isActive: viewState.search.isActive === undefined ? undefined : viewState.search.isActive === 'true',
    setPhaseId,
    setIsActive,
  };
};

export default useProductsViewState;
