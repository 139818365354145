import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Form } from 'react-bootstrap';

import './Manufacturer.scss';

import Select from '../../components/Select/Select';
import useProductOptions from '../../hooks/manufacturer/useProductOptions';
import useNotifications from '../../hooks/useNotifications';
import useUserInfo from '../../hooks/useUserInfo';
import ManufacturerSynthesisStepListSortColumn from '../../models/SynthesisStep/ManufacturerSynthesisStepSortColumn';
import synthesisStepService from '../../services/synthesisStepService';
import companyService from '../../services/companyService';
import Header from '../../components/Header/Header';
import useSortingViewState from '../../hooks/useSortingViewState';
import ManufacturerStepTable from '../../components/ManufacturerStepTable/ManufacturerStepTable';
import ManufacturerStepTableSkeleton from '../../components/ManufacturerStepTable/ManufacturerStepTableSkeleton';

const pageSize = 20;

const Manufacturer = () => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const user = useUserInfo();

  const { options: productOptions, isLoading: isLoadingProducts } = useProductOptions(() => {
    notify(
      'error',
      fm({ id: 'manufacturer.notification.title' }),
      fm({ id: 'manufacturer.notification.unableToLoadProducts' }),
    );
  }, fm({ id: 'manufacturer.filter.empty' }));

  const [searchParams, setSearchParams] = useSearchParams();
  const productId = searchParams.get('productId');
  const onProductFilterChange = (newProductId: string | undefined) => {
    if (!newProductId) {
      searchParams.delete('productId');
    } else {
      searchParams.set('productId', newProductId);
    }
    setSearchParams(searchParams);
  };

  const {
    data: stepsData,
    isLoading: isSynthesisStepsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['manufacturerSteps', productId, getSortOrder<ManufacturerSynthesisStepListSortColumn>('phaseName', 'Steps')],
    async ({ pageParam = 1 }) => {
      const data = await synthesisStepService.manufacturerList({
        productId: productId ?? undefined,
        paging: { pageSize, pageNumber: pageParam },
        orderBy: getSortOrder<ManufacturerSynthesisStepListSortColumn>('phaseName', 'Steps').toString(),
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          fm({ id: 'manufacturer.notification.title' }),
          fm({ id: 'manufacturer.notification.unableToLoadProducts' }),
        ),
    },
  );

  const { data: status } = useQuery(
    ['companyStatus', user.companyId],
    () => companyService.getStatus(user.companyId!),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'manufacturer.notification.title' }),
          fm({ id: 'manufacturer.notification.unableToLoadStatus' }),
        ),
    },
  );

  useEffect(() => {
    if (!isLoadingProducts && productId && !productOptions.find((x) => x.value === productId)) {
      notify(
        'error',
        fm({ id: 'manufacturer.notification.title' }),
        fm({ id: 'manufacturer.notification.unableToLoadProduct' }),
      );
      searchParams.delete('productId');
      setSearchParams(searchParams);
    }
  }, [isLoadingProducts, productId]);

  return (
    <div className="manufacturer-component pb-4">
      <Header title={fm({ id: 'manufacturer.title' })} showSearch />
      <div className="page-content px-5">
        <div className="filters-container pb-4">
          <Form.Group>
            <Select
              value={productId || ''}
              onChange={onProductFilterChange}
              options={productOptions}
              size="lg"
              dataTestId="users-companyFilter"
            />
          </Form.Group>
        </div>
        {stepsData?.pages[0].steps?.length === 0 && (
          <div className="row p-7">
            <h3 className="col d-flex justify-content-center text-color--white" data-test-id="manufacturer-noData">
              <FormattedMessage id="manufacturer.noData" />
            </h3>
          </div>
        )}
        {isSynthesisStepsLoading && <ManufacturerStepTableSkeleton cards={4} />}
        {!!stepsData?.pages[0].steps?.length && (
          <InfiniteScroll
            className="shadow-2"
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            dataLength={stepsData.pages.length}
            scrollableTarget="scrollContainer"
          >
            <ManufacturerStepTable steps={stepsData.pages.flatMap((x) => x.steps)} updatedAt={status?.updatedAt} />
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};

export default Manufacturer;
