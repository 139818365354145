import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useMemo } from 'react';
import { Button, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import Regex from '../../../constants/regex';
import { transformString } from '../../../helpers/yupExtentions';
import useNotifications from '../../../hooks/useNotifications';
import Shape from '../../../models/Shape';
import accountService from '../../../services/accountService';
import InvalidPhoneNumberError from '../../../errors/InvalidPhoneNumberError';
import Spinner from '../../../components/Spinner/Spinner';

const AccountTab: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const queryClient = useQueryClient();
  const notificationTitle = intl.formatMessage({ id: 'settings.tab.account.notification.title' });
  const { data: userDetails, isLoading: isLoadingUserDetails } = useQuery(
    ['userDetails'],
    () => accountService.getUserDetails(),
    {
      onError: () =>
        notify(
          'error',
          notificationTitle,
          intl.formatMessage({ id: 'settings.tab.account.notification.unableLoadUserDetails' }),
        ),
    },
  );

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      fullName: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .min(2, intl.formatMessage({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.Name, intl.formatMessage({ id: 'validation.nameRegex' })),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (userDetails) {
      setValue('fullName', userDetails.fullName);
      setValue('email', userDetails.email);
      setValue('phoneNumber', userDetails.phoneNumber || '');
      setValue('department', userDetails.department);
      setValue('companyName', userDetails.companyName || '');
      setValue(
        'role',
        intl.formatMessage({ id: `settings.tab.account.form.role.value.${userDetails.roleType}` }) || '',
      );
    }
  }, [userDetails]);

  const { mutate: saveDetails, isLoading: isSaving } = useMutation(
    (formData: FormData) => accountService.updateUserDetails(formData.fullName, formData.phoneNumber),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('userDetails');
        queryClient.invalidateQueries('userInfo');
        notify(
          'success',
          notificationTitle,
          intl.formatMessage({
            id: 'settings.tab.account.notification.updatedUserDetails',
          }),
        );
      },
      onError: (error: unknown) => {
        if (error instanceof InvalidPhoneNumberError) {
          setError(
            'phoneNumber',
            { message: intl.formatMessage({ id: 'validation.phoneNumber' }) },
            { shouldFocus: true },
          );
        } else {
          notify(
            'error',
            notificationTitle,
            intl.formatMessage({ id: 'settings.tab.account.notification.unableToSaveUserDetails' }),
          );
        }
      },
    },
  );

  const isLoading = isLoadingUserDetails || isSaving || isSubmitting;

  return (
    <Form noValidate onSubmit={handleSubmit((data) => saveDetails(data))}>
      <div className="row form-3-col">
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.fullName.label" />
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={intl.formatMessage({ id: 'settings.tab.account.form.fullName.placeholder' })}
            {...register('fullName')}
            isInvalid={!!errors?.fullName}
            data-test-id="accountDetails-fullName"
          />
          {!!errors?.fullName && (
            <Form.Control.Feedback type="invalid" data-test-id="accountDetails-fullName-error">
              {errors.fullName.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.email.label" />
          </Form.Label>
          <Form.Control type="email" {...register('email')} readOnly data-test-id="accountDetails-email" />
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.phoneNumber.label" />
          </Form.Label>
          <Form.Control
            type="text"
            placeholder={intl.formatMessage({ id: 'settings.tab.account.form.phoneNumber.placeholder' })}
            {...register('phoneNumber')}
            isInvalid={!!errors?.phoneNumber}
            data-test-id="accountDetails-phoneNumber"
          />
          {!!errors?.phoneNumber && (
            <Form.Control.Feedback type="invalid" data-test-id="accountDetails-phoneNumber-error">
              {errors.phoneNumber.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.companyName.label" />
          </Form.Label>
          <Form.Control type="text" {...register('companyName')} readOnly data-test-id="accountDetails-companyName" />
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.department.label" />
          </Form.Label>
          <Form.Control type="text" {...register('department')} readOnly data-test-id="accountDetails-department" />
        </Form.Group>
        <Form.Group className="mb-1">
          <Form.Label>
            <FormattedMessage id="settings.tab.account.form.role.label" />
          </Form.Label>
          <Form.Control type="text" {...register('role')} readOnly data-test-id="accountDetails-role" />
        </Form.Group>
      </div>
      <Button
        variant="secondary"
        type="submit"
        className="mt-5"
        disabled={isLoading}
        data-test-id="accountDetails-submit"
      >
        <FormattedMessage id="settings.tab.account.action.save" />
      </Button>
      {isLoading && <Spinner />}
    </Form>
  );
};

type FormData = {
  fullName: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  department: string;
  role: string;
};

export default AccountTab;
