import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardHeader from '../../components/DashboardHeader/DashboardHeader';
import numberHelper from '../../helpers/numberHelper';
import productsHelper from '../../helpers/productsHelper';
import useNotifications from '../../hooks/useNotifications';
import companyService from '../../services/companyService';
import productService from '../../services/productService';
import GenericFormattedDate from '../../components/GenericFormattedDate/GenericFormattedDate';
import ClientDashboardTrackersSkeleton from './DashboardTrackersSkeleton';
import ShortInfoSkeleton from '../../components/Skeletons/ShortInfoSkeleton';
import UpdatedAtSkeleton from '../../components/Skeletons/UpdatedAtSkeleton';
import ProblemTrackerFilter from '../../models/ProblemTrackerFilter';
import OutOfTime from '../../components/trackers/OutOfTime/OutOfTime';
import OutOfSpecification from '../../components/trackers/OutOfSpecification/OutOfSpecification';
import OutOfRangeWithDetails from '../../components/trackers/OutOfRangeWithDetails/OutOfRangeWithDetails';
import ProductAlertsMini from '../../components/ProductAlerts/ProductAlertsMini/ProductAlertsMini';
import ProductUpdatesMini from '../../components/ProductUpdates/ProductUpdatesMini/ProductUpdatesMini';
import ProductRow from '../../components/ProductRow/ProductRow';
import ProductRowSkeleton from '../../components/Skeletons/ProductOverview/ProductRowSkeleton';

const pageSize = 20;

interface Props {
  companyId: string;
}

const Dashboard: React.FC<Props> = ({ companyId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['companyStatus', companyId],
    () => companyService.getStatus(companyId),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'dashboard.notification.title' }),
          intl.formatMessage({ id: 'dashboard.notification.unableLoadStatus' }),
        ),
    },
  );

  const {
    data: productsModel,
    isLoading: isLoadingProducts,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['activeProducts', companyId],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        isActive: true,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'products.title' }),
          intl.formatMessage({ id: 'products.notification.unableLoadProducts' }),
        ),
    },
  );

  return (
    <>
      <DashboardHeader />
      <div className="page-content px-5">
        <div className="row gx-4 mb-4">
          <div className="col-8">
            <div className="card p-3" data-test-id="dashboard-statusBlock">
              <div className="d-flex pb-3 align-items-center justify-content-between">
                <div className="font-bold text-uppercase" data-test-id="dashboard-statusBlock-title">
                  <FormattedMessage id="dashboard.statusBlock.title" />
                </div>
                {isLoadingStatus && <UpdatedAtSkeleton />}
                {status?.updatedAt && (
                  <div className="text-color-gray-l6 size-caption" data-test-id="dashboard-statusBlock-updatedAt">
                    <FormattedMessage id="dashboard.statusBlock.updatedAt" />{' '}
                    <GenericFormattedDate value={status?.updatedAt} />
                  </div>
                )}
              </div>
              {isLoadingStatus ? (
                <ClientDashboardTrackersSkeleton />
              ) : (
                <div className="animated-component-item d-flex gap-3 chart-row">
                  <div className="flex-1">
                    <OutOfRangeWithDetails
                      value={status?.outOfRange}
                      tooltip={intl.formatMessage({ id: 'tracker.outOfRange.tooltips.global' })}
                      link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfRange)}
                    />
                  </div>
                  <div className="flex-1">
                    <OutOfSpecification
                      numerator={status?.outOfSpecificationNumerator}
                      denominator={status?.outOfSpecificationDenominator}
                      tooltip={intl.formatMessage({ id: 'tracker.outOfSpecification.tooltips.global' })}
                      link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfSpecification)}
                    />
                  </div>
                  <div className="flex-1">
                    <OutOfTime
                      delay={status?.outOfTime}
                      status={status?.outOfTimeStatus}
                      tooltip={intl.formatMessage({ id: 'tracker.outOfTime.tooltips.global' })}
                      link={productsHelper.getUserProductsUrl(companyId!, ProblemTrackerFilter.OutOfTime)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col-4">
            <div className="card p-3">
              <div className="row font-bold text-uppercase pb-3">
                <div className="d-flex justify-content-between" data-test-id="dashboard-productsTotal-title">
                  <FormattedMessage id="dashboard.productsTotal.title" />
                  <Link
                    to={productsHelper.getUserProductsUrl(companyId!)}
                    className="link link--underlined link--secondary font-medium size-label col-auto"
                    data-test-id="dashboard-productsTotal-showAllBtn"
                  >
                    <FormattedMessage id="dashboard.productsTotal.showAction" />
                  </Link>
                </div>
              </div>
              {isLoadingStatus ? (
                <ShortInfoSkeleton />
              ) : (
                <div className="chart-row">
                  <div
                    className="row card-line px-3 card-line-padding mb-2"
                    data-test-id="dashboard-productsTotal-total"
                  >
                    <div className="col">
                      <FormattedMessage id="dashboard.productsTotal.total" />
                    </div>
                    <div className="col-auto">{numberHelper.formatNumber(status?.totalProducts)}</div>
                  </div>
                  <div
                    className="row card-line px-3 card-line-padding mb-2"
                    data-test-id="dashboard-productsTotal-active"
                  >
                    <div className="col">
                      <FormattedMessage id="dashboard.productsTotal.active" />
                    </div>
                    <div
                      className={`col-auto ${
                        status?.activeProducts && status.activeProducts > 0 ? 'text-color-graph-positive' : ''
                      }`}
                    >
                      {numberHelper.formatNumber(status?.activeProducts)}
                    </div>
                  </div>
                  <div className="row card-line px-3 card-line-padding" data-test-id="dashboard-productsTotal-inactive">
                    <div className="col">
                      <FormattedMessage id="dashboard.productsTotal.inactive" />
                    </div>
                    <div className="col-auto">{numberHelper.formatNumber(status?.inactiveProducts)}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row gx-4 mb-4">
          <div className="col-6">
            <ProductAlertsMini companyId={companyId} />
          </div>
          <div className="col-6">
            <ProductUpdatesMini companyId={companyId} />
          </div>
        </div>
        {isLoadingProducts ? (
          <ProductRowSkeleton cards={1} />
        ) : (
          !!productsModel?.pages?.[0]?.products?.length && (
            <InfiniteScroll
              className="overflow-visible"
              next={fetchNextPage}
              hasMore={hasNextPage || false}
              loader={null}
              dataLength={productsModel?.pages.length || 0}
              scrollableTarget="scrollContainer"
            >
              <div>
                {productsModel?.pages
                  ?.flatMap((x) => x.products)
                  .map((product) => (
                    <ProductRow key={product.id} product={product} />
                  ))}
              </div>
            </InfiniteScroll>
          )
        )}
      </div>
    </>
  );
};

export default Dashboard;
