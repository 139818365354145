import UsersFiltersViewState from '../../../models/User/UsersFiltersViewState';
import useQueryStringState from '../../useQueryStringState';

const useUsersFiltersViewState = () => {
  const [viewState, setViewState] = useQueryStringState<UsersFiltersViewState>();

  const handleFilterChange = (newFilterState: UsersFiltersViewState) => {
    setViewState({
      ...viewState,
      search: {
        ...viewState.search,
        ...newFilterState,
      },
      hash: '',
    });
  };

  return { handleFilterChange, viewState };
};
export default useUsersFiltersViewState;
