import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import Header from '../../components/Header/Header';
import useNotifications from '../../hooks/useNotifications';
import GenericFormattedDate from '../../components/GenericFormattedDate/GenericFormattedDate';
import BatchList from '../../components/BatchList/BatchList';
import BackButton from '../../components/BackButton/BackButton';
import productService from '../../services/productService';
import useQueryStringState from '../../hooks/useQueryStringState';
import BatchListViewState from '../../models/Batch/BatchListViewState';
import productsHelper from '../../helpers/productsHelper';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

interface Props {
  companyId: string;
}

const BatchesOverview: FC<Props> = ({ companyId }) => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const [viewState] = useQueryStringState<BatchListViewState>();
  const { data: status } = useQuery(['productBaseInfo', productId], () => productService.getBaseInfo(productId!), {
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'batchesOverview.notification.title' }),
        intl.formatMessage({ id: 'batchesOverview.notification.unableLoadProductStatus' }),
      ),
  });

  return (
    <>
      <Header
        title={
          <>
            <FormattedMessage id="batchesOverview.title" />
            <div className="py-2">
              {status && status.companyId === companyId && (
                <Breadcrumbs
                  values={[
                    {
                      url: productsHelper.getProductUrl(productId!),
                      label: status?.name || '',
                    },
                    ...(viewState.search.synthesisStepName
                      ? [
                          {
                            url: productsHelper.getSynthesisStepUrl(productId!, viewState.search.synthesisStepId!),
                            label: viewState.search.synthesisStepName || '',
                          },
                          ...(viewState.search.campaignName
                            ? [
                                {
                                  url: productsHelper.getCampaignsUrl({
                                    productId: productId!,
                                    stepId: viewState.search.synthesisStepId!,
                                    campaignId: viewState.search.campaignId!,
                                  }),
                                  label: viewState.search.campaignName || '',
                                },
                              ]
                            : []),
                        ]
                      : []),
                  ]}
                />
              )}
              {status && status.companyId !== companyId && (
                <Breadcrumbs
                  values={[
                    {
                      label: status?.name || '',
                    },
                    ...(viewState.search.synthesisStepName
                      ? [
                          {
                            url: productsHelper.getSynthesisStepOverviewUrl(
                              productId!,
                              viewState.search.synthesisStepId!,
                            ),
                            label: viewState.search.synthesisStepName || '',
                          },
                          ...(viewState.search.campaignName
                            ? [
                                {
                                  url: productsHelper.getSynthesisStepOverviewCampaingsUrl(
                                    productId!,
                                    viewState.search.synthesisStepId!,
                                    viewState.search.campaignId!,
                                  ),
                                  label: viewState.search.campaignName || '',
                                },
                              ]
                            : []),
                        ]
                      : []),
                  ]}
                />
              )}
            </div>
          </>
        }
      >
        <div className="d-flex gap-4 align-items-baseline">
          <span className="size-caption">
            <FormattedMessage id="batchesOverview.header.updatedAt" />{' '}
            <GenericFormattedDate value={status?.updatedAt} />
          </span>
          <BackButton />
        </div>
      </Header>
      <div className="page-content pb-4 px-5 ">
        <BatchList productId={productId!} companyId={companyId} hasAccessToProduct={companyId === status?.companyId} />
      </div>
    </>
  );
};

export default BatchesOverview;
