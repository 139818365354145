import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import ProgressBar from '../ProgressBar/ProgressBar';

import './ProgressMini.scss';

const ProgressMini: FC<Props> = ({ value }) => (
  <div className="progress-mini-component d-flex gap-3 align-items-center w-100" data-test-id="progressMini-container">
    <ProgressBar value={value} size="sm" />
    <div className="fixed-length size-caption d-flex justify-content-end flex-1" data-test-id="progressMini-value">
      {value !== null ? (
        `${Math.trunc(value)}%`
      ) : (
        <span className="text-color-gray-l6">
          <FormattedMessage id="tracker.noData" />
        </span>
      )}
    </div>
  </div>
);

interface Props {
  value: number | null;
}

export default ProgressMini;
