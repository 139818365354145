import React, { FC, useMemo } from 'react';
import './ProgressBar.scss';

const ProgressBar: FC<Props> = ({ value, size }) => {
  const values = useMemo(() => {
    const array = [...Array(10).keys()];
    return array.map((item) => ({
      id: item,
      value: (value || 0) / 10 - (item + 1) >= 0,
    }));
  }, [value]);
  return (
    <div className="progress-bar-component d-flex w-100">
      {values.map((item) => (
        <div
          key={item.id}
          className={`progress-item ${size === 'sm' ? 'progress-item--sm' : ''} ${
            item.value ? 'progress-item--active' : ''
          } ${value === null || value === undefined ? 'progress-item--disabled' : ''}`}
          data-test-id={`progressBar-${item.id}`}
        />
      ))}
    </div>
  );
};

interface Props {
  value?: number | null;
  size?: 'lg' | 'sm';
}

export default ProgressBar;
