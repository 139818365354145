import qs from 'qs';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import usersHelper from '../../helpers/usersHelper';
import useUsersFiltersViewState from '../../hooks/admin/users/useUsersFiltersViewState';
import useNotifications from '../../hooks/useNotifications';

interface Props {
  companyId: string;
  fallback: string;
}

const AdminToSeniorManagerUsersRoute: FC<Props> = ({ companyId, fallback }) => {
  const { viewState } = useUsersFiltersViewState();
  const navigate = useNavigate();
  const { notify } = useNotifications();
  const { formatMessage: fm } = useIntl();

  useEffect(() => {
    const { companyId: urlCompany, ...searchWithoutCompany } = viewState.search;
    if (urlCompany !== companyId) {
      notify(
        'error',
        fm({ id: 'adaptRoutes.notification.notFound.title' }),
        fm({ id: 'adaptRoutes.notification.notFound.description' }),
      );
      navigate(fallback, { replace: true });
      return;
    }

    navigate({
      pathname: usersHelper.getSeniorManagerUsersUrl(companyId),
      search: qs.stringify(searchWithoutCompany),
    });
  }, []);

  return null;
};

export default AdminToSeniorManagerUsersRoute;
