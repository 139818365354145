import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faHouse,
  faIndustryWindows,
  faFolder,
  faUsers,
  faBell,
  faArrowsRotate,
  faUpload,
  faFiles,
  faBrowser,
  faQuestion,
  faConveyorBelt,
} from '@fortawesome/pro-solid-svg-icons';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppContext } from '../context/useAppContext';
import NavigationItem from '../models/AppGallery/Module/NavigationItem';
import moduleService from '../services/AppGallery/moduleService';

type Navigation = { icon: IconDefinition; item: NavigationItem };
const icons: Map<string, IconDefinition> = new Map([
  ['Dashboard', faHouse],
  ['Companies', faIndustryWindows],
  ['Products', faFolder],
  ['Users', faUsers],
  ['Alerts', faBell],
  ['Updates', faArrowsRotate],
  ['FileManagement', faFiles],
  ['AppManagement', faBrowser],
  ['Upload', faUpload],
  ['Manufacturer', faConveyorBelt],
]);

const useUserNavList = (): Navigation[] => {
  const {
    state: { user },
  } = useAppContext();
  const { data: navs } = useQuery(['navigation'], () => moduleService.getNavigation());

  const [navCache, setNavCache] = useState<Navigation[]>([]);
  useEffect(() => {
    if (navs) {
      setNavCache(
        navs.navigations.map((item) => {
          const icon = icons.get(item.name) || faQuestion;

          return {
            icon,
            item: {
              ...item,
              path: item.path.replace('{companyId}', user?.companyId || ''),
            },
          };
        }),
      );
    }
  }, [navs]);

  return navCache;
};

export default useUserNavList;
