import { useSearchParams } from 'react-router-dom';

const useQueryParams = <T extends string, U = { [K in T]?: string | null }>(keys: T[]): U => {
  const [searchParams] = useSearchParams();

  return keys.reduce((map, key) => {
    return {
      ...map,
      [key]: searchParams.get(key),
    };
  }, {} as U);
};

export default useQueryParams;
