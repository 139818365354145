import AuthToken from '../models/AuthToken';

const localStorageKey = 'Dudechem_Auth';

export default {
  getState(): AuthToken | null {
    const value = localStorage.getItem(localStorageKey);
    return value ? JSON.parse(value) : null;
  },
  setState(state: AuthToken | null) {
    if (state == null) {
      localStorage.removeItem(localStorageKey);
    } else {
      localStorage.setItem(localStorageKey, JSON.stringify(state));
    }
  },
};
