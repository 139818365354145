import React from 'react';

import { BrowserRouter } from 'react-router-dom';
import AppProvider from './components/AppProvider/AppProvider';
import AppTemplate from './AppTemplate';

const App = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <AppTemplate />
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
