import UpdateItem from '../models/Update/UpdateItem';

const updatesBaseUrl = '/updates';
const adminUpdatesUrl = updatesBaseUrl;
const getUserUpdatesUrl = (companyId: string) => `/companies/${companyId}${updatesBaseUrl}`;
const getAdminClientUpdatesUrl = (companyId: string) => {
  return `${adminUpdatesUrl}/?companyId=${companyId}`;
};

const getItemUrl = (updateItem: UpdateItem) => {
  switch (updateItem.typeName) {
    case 'NewCampaign':
    case 'CampaignFinished':
    case 'NewMBR':
      return `/products/${updateItem.productId}/campaigns/#campaign-${updateItem.data.CampaignId}`;
    default:
      return `/products/${updateItem.productId}`;
  }
};

export default {
  adminUpdatesUrl,
  getItemUrl,
  getUserUpdatesUrl,
  getAdminClientUpdatesUrl,
};
