import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import { Button } from 'react-bootstrap';
import ManufacturerSynthesisStepListSortColumn from '../../models/SynthesisStep/ManufacturerSynthesisStepSortColumn';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';

const columns: { name: ManufacturerSynthesisStepListSortColumn | ''; key: string }[] = [
  { name: 'companyName', key: 'manufacturerStepTable.table.header.companyName' },
  { name: 'productName', key: 'manufacturerStepTable.table.header.productName' },
  { name: 'synthesisStepName', key: 'manufacturerStepTable.table.header.synthesisStepName' },
  { name: 'phaseName', key: 'manufacturerStepTable.table.header.phaseName' },
  { name: '', key: '' }, // actions
];

interface Props {
  cards: number;
}

const ManufacturerStepTableSkeleton: React.FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <div className="shadow-2" data-test-id="loader">
      <div className="d-flex align-items-center justify-content-between bg-color-gray-l3 p-3 rounded-top">
        <div className="font-bold text-uppercase">
          <FormattedMessage id="manufacturerStepTable.title" />
        </div>
        <div className="size-caption text-color-gray-l6">
          <ContentLoader
            height={18}
            speed={SkeletonSpeed}
            backgroundColor={SkeletonColors.lightGrey2}
            foregroundColor={SkeletonColors.lightGrey1}
            viewBox="0 0 210 18"
          >
            <rect x="0" y="0" rx="4" ry="4" width="210" height="17" />
          </ContentLoader>
        </div>
      </div>
      <table className="w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              <td style={{ width: '320px' }}>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 170 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="150" height="25" />
                </ContentLoader>
              </td>
              <td style={{ width: '313px' }}>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 150 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="120" height="25" />
                </ContentLoader>
              </td>

              <td style={{ width: '320px' }}>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 180 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="150" height="25" />
                </ContentLoader>
              </td>
              <td style={{ width: '313px' }}>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 220 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="200" height="25" />
                </ContentLoader>
              </td>
              <td align="left">
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 280 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="280" height="25" />
                </ContentLoader>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManufacturerStepTableSkeleton;
