import React from 'react';
import { useQuery } from 'react-query';
import { useIntl } from 'react-intl';

import applicationService from '../../../../services/AppGallery/applicationService';
import useNotifications from '../../../../hooks/useNotifications';
import ProcessCard from '../ProcessCard/ProcessCard';
import ProcessCardSkeleton from '../ProcessCard/ProcessCardSkeleton';

const ProcessColumn: React.FC<Props> = ({ processId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: applicationsData, isLoading: isLoadingApplications } = useQuery(
    ['applicationList', 'process', processId],
    () => applicationService.list({ processId, orderBy: 'processPriority' }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.processTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.processTab.notification.unableToLoadApplicationList' }),
        ),
    },
  );

  return (
    <div>
      {isLoadingApplications && <ProcessCardSkeleton cards={1} />}
      {applicationsData &&
        applicationsData?.applications.map((app) => (
          <div key={app.id} className="animated-component-item">
            <ProcessCard applicationItem={app} />
          </div>
        ))}
    </div>
  );
};

interface Props {
  processId: string;
}

export default ProcessColumn;
