import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import dataPlaceholders from '../../constants/dataPlaceholders';
import IconTooltip from '../Tooltips/IconTooltip/IconTooltip';

dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const GenericFormattedDate: React.FC<Props> = ({ value, withTime, className }) => {
  const intl = useIntl();
  const dateString = useMemo(() => {
    if (!value) {
      return dataPlaceholders.missingData;
    }
    const dayObj = dayjs(value);
    return (
      <>
        {withTime ? dayObj.format('DD.MM.YYYY - HH:mm') : dayObj.format('DD.MM.YYYY')}{' '}
        <span className="text-color-gray-l6">{`(${dayObj.format('z')})`}</span>
      </>
    );
  }, [value, withTime]);

  return (
    <span className={className}>
      {dateString}
      {value && (
        <IconTooltip
          tooltip={intl.formatMessage(
            { id: 'genericFormattedDate.tooltip' },
            { value: !withTime ? 'dd.mm.yyyy\xa0Z' : 'dd.mm.yyyy\xa0-\xa0hh:mm\xa0Z' },
          )}
          className="ms-1"
        />
      )}
    </span>
  );
};

interface Props {
  value?: string | null;
  withTime?: boolean;
  className?: string;
}

export default GenericFormattedDate;
