import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';

const BatchRowSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="animated-component-skeleton row py-3 ps-3 card-divider" data-test-id="loader">
          <div>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 131 32"
            >
              <rect x="27" y="5" rx="8" ry="8" width="77" height="21" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.blue}
              foregroundColor={SkeletonColors.darkGrey1}
              viewBox="0 0 165 32"
            >
              <rect x="35" y="4" rx="4" ry="4" width="112" height="23" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 99 32"
            >
              <rect x="14" y="6" rx="8" ry="8" width="44" height="18" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.green}
              foregroundColor={SkeletonColors.lightGrey1}
              viewBox="0 0 213 32"
            >
              <rect x="0" y="0" rx="4" ry="4" width="198" height="32" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.green}
              foregroundColor={SkeletonColors.lightGrey1}
              viewBox="0 0 218 32"
            >
              <rect x="0" y="0" rx="4" ry="4" width="201" height="32" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.green}
              foregroundColor={SkeletonColors.lightGrey1}
              viewBox="0 0 214 32"
            >
              <rect x="0" y="0" rx="4" ry="4" width="198" height="32" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 245 32"
            >
              <rect x="58" y="5" rx="4" ry="4" width="127" height="21" />
            </ContentLoader>
            <ContentLoader
              height={32}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey1}
              foregroundColor={SkeletonColors.lightGrey2}
              viewBox="0 0 257 32"
            >
              <rect x="87" y="0" rx="4" ry="4" width="170" height="32" />
            </ContentLoader>
          </div>
        </div>
      ))}
    </>
  );
};
interface Props {
  cards: number;
}
export default BatchRowSkeleton;
