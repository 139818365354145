import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './Spinner.scss';
import { useIntl } from 'react-intl';
import spinner from '../../assets/icons/spinner.svg';

type Props = {
  timeout?: number;
};
const Spinner = (props: Props) => {
  const [isVisible, setVisible] = useState(false);
  const { timeout } = props;
  const intl = useIntl();

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, timeout || 300);
    return () => {
      clearTimeout(timer);
    };
  });
  if (!isVisible) {
    return null;
  }

  return (
    <Modal className="spinner-component" show centered backdrop="static" animation={false} data-test-id="loader">
      <Modal.Body className="p-5">
        <img src={spinner} alt={intl.formatMessage({ id: 'spinner' })} />
      </Modal.Body>
    </Modal>
  );
};

export default Spinner;
