import qs from 'qs';
import BatchDetails from '../models/Batch/BatchDetails';
import BatchList from '../models/Batch/BatchList';
import BatchOperation from '../models/Batch/BatchOperation';
import BatchPart from '../models/Batch/BatchPart';
import BatchSearch from '../models/Batch/BatchSearch';
import BatchStagesList from '../models/Batch/BatchStagesList';
import BatchTrackers from '../models/Batch/BatchTrackers';
import PagingInfo from '../models/PagingInfo';
import request from './requestService';
import BatchQualityTestList from '../models/Batch/BatchQualityTestList';

class BatchService {
  private readonly BaseUrl = `/Batch`;

  public async list(params: {
    companyId?: string;
    productId: string;
    paging?: PagingInfo;
    ids?: string[];
    problemTrackers?: string[];
    synthesisStepId?: string;
    campaignId?: string;
    fromDate?: Date;
  }): Promise<BatchList> {
    const response = await request.get(`${this.BaseUrl}/List/`, {
      params: {
        companyId: params.companyId,
        productId: params.productId,
        ...params.paging,
        ids: params.ids,
        problemTrackers: params.problemTrackers,
        synthesisStepId: params.synthesisStepId,
        campaignId: params.campaignId,
        fromDate: params.fromDate,
      },
      paramsSerializer: {
        serialize: (p) => {
          return qs.stringify(p);
        },
      },
    });
    return response.data;
  }

  public async get(batchId: string): Promise<BatchDetails> {
    const response = await request.get(`${this.BaseUrl}/Details/?batchId=${batchId}`);
    return response.data;
  }

  public async listParts(batchId: string): Promise<BatchPart[]> {
    const response = await request.get(`${this.BaseUrl}/ListParts/?batchId=${batchId}`);
    return response.data.parts;
  }

  public async listFailedStages(batchId: string, partId: string): Promise<BatchStagesList> {
    const response = await request.get(`${this.BaseUrl}/ListFailedStages/?batchId=${batchId}&partId=${partId}`);
    return response.data;
  }

  public async listFailedActions(batchId: string, stageId: string): Promise<BatchOperation[]> {
    const response = await request.get(`${this.BaseUrl}/ListFailedActions/?batchId=${batchId}&stageId=${stageId}`);
    return response.data.operations;
  }

  public async search(params: { companyId?: string; productId: string; nameTerm: string }): Promise<BatchSearch[]> {
    const response = await request.get(`${this.BaseUrl}/Search`, {
      params: {
        companyId: params.companyId,
        productId: params.productId,
        name: encodeURI(params.nameTerm),
      },
    });
    return response.data.batches;
  }

  public async trackers(batchId: string): Promise<BatchTrackers> {
    const response = await request.get(`${this.BaseUrl}/Trackers/?batchId=${batchId}`);
    return response.data;
  }

  public async listQualityTests(batchId: string): Promise<BatchQualityTestList> {
    const response = await request.get(`${this.BaseUrl}/QualityTests/?batchId=${batchId}`);
    return response.data;
  }
}

export default new BatchService();
