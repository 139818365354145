import React, { FC, useMemo } from 'react';

import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import ActionDescriptor from '../../../components/ActionDescriptor/ActionDescriptor';
import { transformString } from '../../../helpers/yupExtentions';
import Shape from '../../../models/Shape';

type FormData = { code: string };

interface Props {
  onSubmit: (code: string) => void;
  onCancel: () => void;
}

const RecoveryCode: FC<Props> = ({ onSubmit, onCancel }) => {
  const intl = useIntl();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      code: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' })),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmitFormHandler = async (data: FormData) => {
    onSubmit(data.code);
  };

  return (
    <ActionDescriptor
      header={intl.formatMessage({ id: 'login.recoveryCode.header' })}
      subHeader={intl.formatMessage({ id: 'login.recoveryCode.subHeader' })}
    >
      <Form className="d-flex flex-column mt-4" noValidate onSubmit={handleSubmit(onSubmitFormHandler)}>
        <Form.Group className="mb-3">
          <Form.Label>
            <FormattedMessage id="login.recoveryCode.form.code.label" />
          </Form.Label>
          <Form.Control
            autoFocus
            type="text"
            size="lg"
            placeholder={intl.formatMessage({ id: 'login.recoveryCode.form.code.placeholder' })}
            {...register('code')}
            isInvalid={!!errors?.code}
            data-test-id="recoveryCode-code"
          />
          {!!errors?.code && (
            <Form.Control.Feedback type="invalid" data-test-id="recoveryCode-code-error">
              {errors.code.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="btn btn-primary btn-rounded mb-3"
          data-test-id="recoveryCode-submit"
        >
          <FormattedMessage id="login.recoveryCode.form.btn.label" />
        </Button>
        <Button
          variant="ghost"
          type="button"
          className="btn-rounded"
          onClick={onCancel}
          data-test-id="recoveryCode-cancel"
        >
          <FormattedMessage id="login.recoveryCode.cancel.btn.label" />
        </Button>
      </Form>
    </ActionDescriptor>
  );
};

export default RecoveryCode;
