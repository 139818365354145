import { useMemo } from 'react';
import { useQuery } from 'react-query';
import productService from '../../services/productService';

const useProductOptions = (onError: () => void, placeholder: string) => {
  const { isLoading, data: productsList } = useQuery(
    ['manufacturerProducts'],
    () => productService.manufacturerList(),
    {
      onError,
    },
  );

  const options = useMemo(() => {
    const productOptions = productsList?.products.map((product) => ({ label: product.name, value: product.id })) ?? [];
    return [{ label: placeholder, value: '' }, ...productOptions];
  }, [productsList?.products, placeholder]);

  return {
    isLoading,
    options,
  };
};

export default useProductOptions;
