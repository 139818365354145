import React, { useMemo } from 'react';

import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faLockKeyhole } from '@fortawesome/pro-solid-svg-icons';

import { transformString } from '../../../helpers/yupExtentions';
import Shape from '../../../models/Shape';

const LoginForm = ({ onSubmit }: ILoginFormProps) => {
  const intl = useIntl();

  const validationSchema = useMemo(() => {
    return object<Shape<FormData>>({
      userName: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' }))
        .email(intl.formatMessage({ id: 'validation.email' })),
      password: string()
        .transform(transformString)
        .required(intl.formatMessage({ id: 'validation.required' })),
    });
  }, [intl.locale]);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });

  const onSubmitFormHandler = async (data: FormData) => {
    onSubmit(data.userName, data.password);
  };

  return (
    <Form className="d-flex flex-column px-6 mt-auto mb-auto" onSubmit={handleSubmit(onSubmitFormHandler)}>
      <h3 className="text-center font-bold">
        <FormattedMessage id="login.form.title" />
      </h3>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>
          <FormattedMessage id="login.form.email.label" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            autoFocus
            type="text"
            size="lg"
            {...register('userName')}
            isInvalid={!!errors?.userName}
            data-test-id="loginForm-userName"
          />
          <InputGroup.Text>
            <FontAwesomeIcon icon={faEnvelope} />
          </InputGroup.Text>
        </InputGroup>
        {errors?.userName && (
          <Form.Control.Feedback type="invalid" data-test-id="loginForm-userName-error">
            {errors?.userName.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>

      <Form.Group className="mb-5" controlId="formBasicPassword">
        <Form.Label>
          <FormattedMessage id="login.form.password.label" />
        </Form.Label>
        <InputGroup>
          <Form.Control
            type="password"
            size="lg"
            {...register('password')}
            isInvalid={!!errors?.password}
            data-test-id="loginForm-password"
          />
          <InputGroup.Text>
            <FontAwesomeIcon icon={faLockKeyhole} />
          </InputGroup.Text>
        </InputGroup>
        {errors?.password && (
          <Form.Control.Feedback type="invalid" data-test-id="loginForm-password-error">
            {errors?.password.message}
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Button variant="primary" type="submit" className="btn btn-primary btn-rounded" data-test-id="loginForm-submit">
        <FormattedMessage id="login.form.btn.label" />
      </Button>
    </Form>
  );
};

type FormData = { userName: string; password: string };

interface ILoginFormProps {
  onSubmit: (userName: string, password: string) => void;
}

export default LoginForm;
