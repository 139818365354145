import React, { FC, HTMLAttributes } from 'react';
import { ReactComponent as DuDeChemLogo } from './logo.svg';

const Logo: FC<HTMLAttributes<HTMLDivElement>> = ({ className }) => {
  return (
    <div className={className}>
      <DuDeChemLogo />
    </div>
  );
};

export default Logo;
