import React, { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';

import './TermsModal.scss';
import { FormattedMessage } from 'react-intl';

interface Props {
  onClose: () => void;
}

const TermsModal: FC<Props> = ({ onClose }) => {
  return (
    <Modal className="terms-modal" size="lg" show centered backdrop="static" onHide={onClose} scrollable>
      <Modal.Header className="py-3 px-4">
        <Modal.Title>
          <FormattedMessage id="activation.terms.title" />
        </Modal.Title>
        <Button className="btn-rounded" onClick={onClose}>
          <FormattedMessage id="activation.terms.btn.label" />
        </Button>
      </Modal.Header>
      <Modal.Body className="px-4 me-4 mt-4 mb-4">
        <p className="pe-4">
          <FormattedMessage id="activation.terms.body" />
        </p>
      </Modal.Body>
    </Modal>
  );
};

export default TermsModal;
