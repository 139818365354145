import { useQuery } from 'react-query';
import applicationService from '../../services/AppGallery/applicationService';

const queryKey = 'categoryList';

const useCategoryList = (options: { onError?: () => void }) =>
  useQuery([queryKey], () => applicationService.listCategories(), {
    onError: options.onError,
  });

export default useCategoryList;
