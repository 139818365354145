import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useUserType from '../../hooks/useUserType';
import UserType from '../../models/Account/UserType';

const ProtectedAdminRoute: FC = () => {
  const userType = useUserType();

  if (userType !== UserType.Admin) {
    return <Navigate to="/login" replace />;
  }

  return <Outlet />;
};

export default ProtectedAdminRoute;
