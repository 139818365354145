import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useCategoryList from '../../../hooks/appGallery/useCategoryList';
import useNotifications from '../../../hooks/useNotifications';
import CategoryCard from './CategoryCard/CategoryCard';
import './CategoryTab.scss';
import CategoryCardSkeleton from './CategoryCard/CategoryCardSkeleton';

const CategoryTab: FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: categoryList, isLoading: isLoadingCategories } = useCategoryList({
    onError: () =>
      notify(
        'error',
        intl.formatMessage({ id: 'appGallery.categoryTab.notification.title' }),
        intl.formatMessage({ id: 'appGallery.categoryTab.notification.unableToLoadCategoryList' }),
      ),
  });

  return (
    <div className="category-tab-component">
      <div className="px-3 col-3 d-flex pb-3">
        <span className="me-4">
          <FormattedMessage id="appGallery.categoryTab.colorLegend.title" />
        </span>
        <span className="requestable me-3">
          <FormattedMessage id="appGallery.categoryTab.colorLegend.requestable" />
        </span>
        <span className="installed">
          <FormattedMessage id="appGallery.categoryTab.colorLegend.installed" />
        </span>
      </div>
      <div className="form-2-col gap-4">
        {isLoadingCategories && <CategoryCardSkeleton cards={4} />}
        {categoryList &&
          categoryList?.map((categoryItem) => (
            <div key={categoryItem.id}>
              <CategoryCard categoryItem={categoryItem} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryTab;
