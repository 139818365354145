import React, { FC } from 'react';
import './Login.scss';

import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppContext } from '../../context/useAppContext';
import supportService from '../../services/supportService';
import LoginForm from './LoginForm/LoginForm';
import useLoginReducer from './useLoginReducer';
import useAuth from '../../hooks/useAuth';

import Spinner from '../../components/Spinner/Spinner';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Support from '../../components/Support/Support';
import BackupRecoveryCode from '../../components/BackupRecoveryCode/BackupRecoveryCode';
import RecoveryCode from './RecoveryCode/RecoveryCode';
import LoginLayout from './LoginLayout/LoginLayout';
import ActionDescriptor from '../../components/ActionDescriptor/ActionDescriptor';
import CodeForm from '../../components/CodeForm/CodeForm';
import AuthQrCode from '../../components/AuthQrCode/AuthQrCode';
import Logo from '../../components/Logo/Logo';

const Login: FC = () => {
  const intl = useIntl();
  const { state, dispatch } = useLoginReducer();
  const { dispatch: appDispatch } = useAppContext();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    isLoading,
    api: { login, verifyOtpCode, resetPassword, generateRecoveryCode, verifyRecoveryCode },
  } = useAuth();

  const navigateToHomePage = (): void => {
    appDispatch({ type: 'AUTHORIZE' });
    navigate((location.state as { from: string } | undefined)?.from || '');
  };

  const onSubmitLoginHandler = async (userName: string, password: string) => {
    const data = await login(userName, password);

    if (data) {
      dispatch({ type: 'SHOW_TWO_FA', payload: { ...data, email: userName } });
    } else {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'login.title' }),
            message: intl.formatMessage({ id: 'login.form.response.error' }),
          },
        },
      });
    }
  };

  const onSubmitTwoFaHandler = async (code: string) => {
    const firstValidation = !!state.data?.barcodeUri;

    try {
      await verifyOtpCode(state.data!.twoFaToken!, code);

      if (firstValidation) {
        const recCode = await generateRecoveryCode();
        dispatch({ type: 'BACKUP_RECOVERY_CODE', payload: { recoveryCode: recCode } });
      } else {
        navigateToHomePage();
      }
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'login.title' }),
            message: intl.formatMessage({ id: '2fa.code.response.error' }),
          },
        },
      });
    }
  };

  const onClickForgotPasswordHandler = () => {
    dispatch({ type: 'RECOVER_PASSWORD' });
  };

  const onCancelRecoverPasswordHandler = () => {
    dispatch({ type: 'SHOW_LOGIN' });
  };

  const onSubmitRecoverPasswordHandler = async (email: string) => {
    await resetPassword(email);

    appDispatch({
      type: 'SHOW_NOTIFICATION',
      payload: {
        notification: {
          type: 'success',
          title: intl.formatMessage({ id: 'login.title' }),
          message: intl.formatMessage({ id: 'notification.emailSent' }),
          hint: intl.formatMessage({ id: 'notification.clickTheLink' }),
          callback: onCancelRecoverPasswordHandler,
        },
      },
    });
  };

  const onRecoveryCodeRequestedHandler = () => {
    dispatch({ type: 'RECOVERY_CODE' });
  };

  const onCancelRecoveryCodeHandler = () => {
    dispatch({
      type: 'SHOW_TWO_FA',
      payload: { twoFaToken: state.data!.twoFaToken!, barcodeUri: state.data!.barcodeUri! },
    });
  };

  const onSubmitRecoveryCodeHandler = async (code: string) => {
    try {
      const { recoveryCode } = await verifyRecoveryCode(state.data!.twoFaToken!, code);

      dispatch({ type: 'BACKUP_RECOVERY_CODE', payload: { recoveryCode } });
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'login.title' }),
            message: intl.formatMessage({ id: 'login.invalidRecoveryCode.error' }),
          },
        },
      });
    }
  };

  const onSupportRequestedHandler = () => {
    dispatch({ type: 'SHOW_SUPPORT' });
  };

  const onCancelSupportHandler = () => {
    dispatch({
      type: 'SHOW_TWO_FA',
      payload: { twoFaToken: state.data!.twoFaToken!, barcodeUri: state.data!.barcodeUri! },
    });
  };

  const onSubmitSupportHandler = async (email: string, message: string) => {
    try {
      await supportService.sendEmail(email, message, state.data?.twoFaToken || '');
      onCancelSupportHandler();
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'success',
            title: intl.formatMessage({ id: 'login.title' }),
            message: intl.formatMessage({ id: 'notification.emailSent' }),
            hint: intl.formatMessage({ id: 'notification.willGetInTouch' }),
          },
        },
      });
    } catch (err) {
      appDispatch({
        type: 'SHOW_NOTIFICATION',
        payload: {
          notification: {
            type: 'error',
            title: intl.formatMessage({ id: 'login.title' }),
            message: intl.formatMessage({ id: 'notification.unableSendEmail' }),
          },
        },
      });
    }
  };

  const onRegenerateRCHandler = async (): Promise<void> => {
    const code = await generateRecoveryCode();
    dispatch({ type: 'BACKUP_RECOVERY_CODE', payload: { recoveryCode: code } });
  };
  const renderStep = React.useCallback(() => {
    switch (state.mode) {
      case 'LOGIN':
        return (
          <LoginLayout>
            <div className="d-flex flex-column my-auto">
              <LoginForm onSubmit={onSubmitLoginHandler} />{' '}
              <Button
                variant="ghost"
                type="button"
                className="btn mt-5 mx-6 btn-rounded"
                onClick={onClickForgotPasswordHandler}
                data-test-id="loginForm-forgotPassword"
              >
                <FormattedMessage id="login.forgotPassword.label" />
              </Button>
            </div>
          </LoginLayout>
        );
      case 'SETUP_2FA':
        return (
          <div className="d-flex flex-column w-100">
            <div className="w-100 text-center pt-6">
              <Logo />
            </div>

            <div className="d-flex m-auto">
              <AuthQrCode barcodeUri={state.data!.barcodeUri!} />
              <ActionDescriptor
                className="code-form bg-color-gray-l3 ms-4"
                title={intl.formatMessage({ id: 'activation.title' })}
                actionPoint={intl.formatMessage({ id: 'activation.2fa.code.actionPoint' })}
                header={intl.formatMessage({ id: 'activation.2fa.code.header' })}
                subHeader={intl.formatMessage({ id: 'activation.2fa.code.subHeader' })}
              >
                <CodeForm
                  onSubmit={onSubmitTwoFaHandler}
                  onSupportRequested={onSupportRequestedHandler}
                  buttonClassName="btn-rounded"
                />
              </ActionDescriptor>
            </div>
          </div>
        );
      case 'VERIFY_2FA':
        return (
          <LoginLayout>
            <div className="d-flex flex-column m-auto">
              <ActionDescriptor
                className="code-form"
                header={intl.formatMessage({ id: 'login.2fa.code.header' })}
                subHeader={intl.formatMessage({ id: 'login.2fa.code.subHeader' })}
              >
                <CodeForm
                  onSubmit={onSubmitTwoFaHandler}
                  onRecoveryCodeRequested={onRecoveryCodeRequestedHandler}
                  onSupportRequested={onSupportRequestedHandler}
                  buttonClassName="btn-rounded"
                />
              </ActionDescriptor>
            </div>
          </LoginLayout>
        );
      case 'BACKUP_RECOVERY_CODE':
        if (state.data?.barcodeUri) {
          return (
            <div className="d-flex flex-column w-100">
              <div className="w-100 text-center pt-6">
                <Logo />
              </div>
              <ActionDescriptor
                className="bg-color-gray-l3 m-auto align-items-center"
                title={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.title' })}
                header={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.header' })}
                subHeader={intl.formatMessage({ id: 'activation.2fa.backupRecoveryCode.subHeader' })}
              >
                <BackupRecoveryCode
                  codes={state.data!.recoveryCode!}
                  slimBtn
                  onRegenerate={onRegenerateRCHandler}
                  onBackup={navigateToHomePage}
                />
              </ActionDescriptor>
            </div>
          );
        }

        return (
          <LoginLayout>
            <div className="m-auto">
              <ActionDescriptor
                header={intl.formatMessage({ id: 'login.2fa.backupRecoveryCode.header' })}
                subHeader={intl.formatMessage({ id: 'login.2fa.backupRecoveryCode.subHeader' })}
                data-test-id="new-recovery-code"
              >
                <BackupRecoveryCode
                  codes={state.data!.recoveryCode!}
                  onRegenerate={onRegenerateRCHandler}
                  onBackup={navigateToHomePage}
                  data-test-id="backup-recovery-code"
                />
              </ActionDescriptor>
            </div>
          </LoginLayout>
        );
      case 'RECOVER_PASSWORD':
        return (
          <LoginLayout>
            <ForgotPassword
              onGetRecoveryLink={onSubmitRecoverPasswordHandler}
              onCancel={onCancelRecoverPasswordHandler}
            />
          </LoginLayout>
        );
      case 'RECOVERY_CODE':
        return (
          <LoginLayout>
            <div className="m-auto">
              <RecoveryCode onSubmit={onSubmitRecoveryCodeHandler} onCancel={onCancelRecoveryCodeHandler} />
            </div>
          </LoginLayout>
        );
      case 'SUPPORT': {
        if (state.data?.barcodeUri) {
          return (
            <div className="m-auto">
              <ActionDescriptor
                className="bg-color-gray-l3"
                title={intl.formatMessage({ id: 'activation.title' })}
                header={intl.formatMessage({ id: 'support.needHelp.header' })}
                subHeader={intl.formatMessage({ id: 'support.getInTouch.subHeader' })}
              >
                <Support
                  onSubmit={onSubmitSupportHandler}
                  onCancel={onCancelSupportHandler}
                  emailFrom={state.data?.email}
                  roundButtons
                />
              </ActionDescriptor>
            </div>
          );
        }

        return (
          <LoginLayout>
            <div className="my-auto">
              <ActionDescriptor
                className="p-6"
                header={intl.formatMessage({ id: 'support.needHelp.header' })}
                subHeader={intl.formatMessage({ id: 'support.getInTouch.subHeader' })}
              >
                <Support
                  onSubmit={onSubmitSupportHandler}
                  onCancel={onCancelSupportHandler}
                  emailFrom={state.data?.email}
                  roundButtons
                />
              </ActionDescriptor>
            </div>
          </LoginLayout>
        );
      }
      default:
        return null;
    }
  }, [state.mode, state.data?.barcodeUri, state.data?.recoveryCode]);

  return (
    <>
      {renderStep()}
      {isLoading && <Spinner />}
    </>
  );
};

export default Login;
