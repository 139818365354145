import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';

import useNotifications from '../../../hooks/useNotifications';
import ApplicationStatus from '../../../models/AppGallery/Application/ApplicationStatus';
import applicationService from '../../../services/AppGallery/applicationService';
import AppsTabCard from './AppsTabCard.tsx/AppsTabCard';
import AppsTabCardSkeleton from './AppsTabCard.tsx/AppsTabCardSkeleton';

const AppsTab: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();

  const { data: userApps, isLoading: isLoadingUserApps } = useQuery(
    ['userApps'],
    () => applicationService.list({ status: ApplicationStatus.Installed, orderBy: 'name' }),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'settings.tab.apps.notification.title' }),
          intl.formatMessage({ id: 'settings.tab.apps.notification.unableLoadApps' }),
        ),
    },
  );

  return (
    <>
      {userApps?.applications.length === 0 && (
        <div className="row p-7 w-100">
          <h4 className="col d-flex justify-content-center">
            <FormattedMessage id="settings.tab.apps.noData" />
          </h4>
        </div>
      )}
      <div className="form-2-col gap-4">
        {userApps &&
          userApps?.applications.map((application) => (
            <div key={application.id} className="bg-color-gray-l3 rounded-4">
              <AppsTabCard application={application} />
            </div>
          ))}
        {isLoadingUserApps && <AppsTabCardSkeleton cards={4} />}
      </div>
    </>
  );
};

export default AppsTab;
