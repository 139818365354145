import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Nav from '../Nav/Nav';

const Layout: FC = () => {
  return (
    <div className="la-scroll d-flex">
      <div className="d-flex border-end">
        <Nav />
      </div>
      <div className="d-flex flex-column flex-fill bg-dark">
        <div className="smart-scroll" id="scrollContainer">
          <div className="smart-scroll-inner">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
