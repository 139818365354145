import { faChartPie, faFolderGrid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const SynthesisStepSkeleton: FC<Props> = ({ showGetReportBtn = true }) => {
  return (
    <div className="animated-component-skeleton card mb-4" data-test-id="loader">
      <div className="row px-3 py-3 card-divider align-items-center">
        <div className="col">
          <ContentLoader
            height={32}
            speed={SkeletonSpeed}
            backgroundColor={SkeletonColors.lightGrey1}
            foregroundColor={SkeletonColors.lightGrey2}
            viewBox="0 0 244 32"
          >
            <rect x="0" y="0" rx="4" ry="5" width="244" height="32" />
          </ContentLoader>
        </div>
        <div className="col-5">
          <div className="row gx-2">
            <div className="col">
              <ContentLoader
                height={32}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.darkGrey1}
                foregroundColor={SkeletonColors.mediumGrey}
                viewBox={showGetReportBtn ? '0 0 210 32' : '0 0 319 32'}
              >
                <rect x="0" y="0" rx="4" ry="5" width={showGetReportBtn ? '210' : '319'} height="32" />
              </ContentLoader>
            </div>
            <div className="col">
              <ContentLoader
                height={32}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.darkGrey1}
                foregroundColor={SkeletonColors.mediumGrey}
                viewBox={showGetReportBtn ? '0 0 210 32' : '0 0 319 32'}
              >
                <rect x="0" y="0" rx="4" ry="5" width={showGetReportBtn ? '210' : '319'} height="32" />
              </ContentLoader>
            </div>
            {showGetReportBtn && (
              <div className="col">
                <ContentLoader
                  height={32}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 210 32"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="210" height="32" />
                </ContentLoader>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row p-3 card-divider" style={{ height: '54px' }}>
        <div className="col size-label">
          <ContentLoader
            height={21}
            speed={SkeletonSpeed}
            backgroundColor={SkeletonColors.lightGrey1}
            foregroundColor={SkeletonColors.lightGrey2}
            viewBox="0 0 244 21"
          >
            <rect x="0" y="0" rx="4" ry="5" width="244" height="21" />
          </ContentLoader>
        </div>
        <div className="col-5">
          <div className="row text-end">
            <div className="col">
              <ContentLoader
                height={21}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.lightGrey2}
                viewBox="0 0 130 21"
              >
                <rect x="0" y="0" rx="4" ry="5" width="130" height="21" />
              </ContentLoader>
            </div>
            <div className="col">
              <ContentLoader
                height={21}
                speed={SkeletonSpeed}
                backgroundColor={SkeletonColors.lightGrey1}
                foregroundColor={SkeletonColors.lightGrey2}
                viewBox="0 0 370 21"
              >
                <rect x="127" y="0" rx="4" ry="5" width="243" height="21" />
              </ContentLoader>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex gap-3 px-3 py-3 gx-3 card-divider">
        <ContentLoader
          height={133}
          speed={SkeletonSpeed}
          backgroundColor={SkeletonColors.darkGrey2}
          foregroundColor={SkeletonColors.mediumGrey}
          viewBox="0 0 1551 133"
        >
          <rect x="0" y="0" rx="8" ry="8" width="501" height="133" />
          <rect x="517" y="0" rx="8" ry="8" width="501" height="133" />
          <rect x="1032" y="0" rx="8" ry="8" width="250" height="133" />
          <rect x="1300" y="0" rx="8" ry="8" width="250" height="133" />
        </ContentLoader>
      </div>
      <div className="row">
        <div className="row header card-divider px-4 py-3" style={{ height: '65px' }}>
          <div className="col d-flex align-self-center">
            <FontAwesomeIcon icon={faChartPie} className="pe-3 d-flex align-self-center" />
            <span className="text-uppercase font-bold accordion-item-title">
              <FormattedMessage id="synthesisStepTrackers.title" />
            </span>
          </div>
        </div>
        <div className="row header px-4 py-3" style={{ height: '64px' }}>
          <div className="col d-flex align-self-center">
            <FontAwesomeIcon icon={faFolderGrid} className="pe-3 d-flex align-self-center" />
            <span className="text-uppercase font-bold accordion-item-title">
              <FormattedMessage id="campaigns.title" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

interface Props {
  showGetReportBtn?: boolean;
}
export default SynthesisStepSkeleton;
