import React, { FC, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import './TableTracker.scss';
import numberHelper from '../../../helpers/numberHelper';
import KeyValue from '../../../models/KeyValue';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';

const TableTracker: FC<Props> = ({ title, value, tooltip, unit, method }) => {
  const ref = useRef(null);
  const getTooltip = (tooltipMethod?: string | null) => {
    return tooltipMethod ? (
      <>
        {tooltip}
        <br />
        <FormattedMessage id="tracker.method" values={{ method: tooltipMethod }} />
      </>
    ) : (
      tooltip
    );
  };
  return (
    <div className="table-tracker-component bg-color-gray-l1 p-2" ref={ref}>
      <div className="mb-2 size-label d-flex">
        <span className="align-middle">{title}</span>
        {tooltip && (
          <div className="ms-1 d-flex">
            <IconTooltip tooltip={getTooltip(method)} className="align-middle" />
          </div>
        )}
      </div>
      {!!value?.length && (
        <div className="scrollable-table">
          <table className="w-100">
            <tbody>
              {value?.map((item) => (
                <tr key={item.key}>
                  <td>
                    <div className="table-key">{item.key}</div>
                  </td>
                  <td>
                    {numberHelper.formatNumber(item.value)}
                    {unit ?? ''}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!value?.length && (
        <div className="row h-100 d-flex align-items-center hint">
          <FormattedMessage id="tracker.noData" />
        </div>
      )}
    </div>
  );
};

type Props = {
  title: string;
  tooltip?: string;
  value?: KeyValue[];
  unit?: string;
  method?: string | null;
};

export default TableTracker;
