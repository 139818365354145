// eslint-disable-next-line @typescript-eslint/no-unused-vars,max-classes-per-file
export type ErrorCode =
  | 'EmailAlreadyVerified'
  | 'InvalidConfiguration'
  | 'InvalidCredentials'
  | 'InvalidOtpCode'
  | 'InvalidToken'
  | 'InvalidVerificationToken'
  | 'TwoFaSetupRequired';

class InvalidVerificationTokenError extends Error {
  constructor() {
    super('InvalidVerificationToken');
    this.name = this.constructor.name;
  }
}

class TwoFaSetupRequiredError extends Error {
  constructor(public data: { twoFaToken: string }) {
    super('TwoFaSetupRequired');
    this.name = this.constructor.name;
  }
}

export { TwoFaSetupRequiredError, InvalidVerificationTokenError };
