import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';

import './ProductAlerts.scss';

import SkeletonColors from '../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../models/Skeletons/SkeletonSpeed';
import ListAlertsSortColumn from '../../models/Alert/ListAlertSortColumn';

const ProductAlertsSkeleton: FC<Props> = ({ cards, columns }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <div className="product-updates-component" data-test-id="loader">
      <table className="w-100">
        <thead className="text-color-gray-l6">
          <tr className="size-caption">
            {columns.map((x) => (
              <th key={x.name} className="font-medium">
                <Button variant="link" className="text-color-gray-l6 btn-sort">
                  {x.key && <FormattedMessage id={x.key} />}{' '}
                </Button>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="animated-component-skeleton">
          {items.map((key) => (
            <tr key={key}>
              {columns.find((x) => x.name === 'productName') && (
                <td>
                  <ContentLoader
                    height={30}
                    speed={SkeletonSpeed}
                    backgroundColor={SkeletonColors.lightGrey2}
                    foregroundColor={SkeletonColors.darkGrey2}
                    viewBox="0 0 170 30"
                  >
                    <rect x="0" y="3" rx="4" ry="4" width="150" height="25" />
                  </ContentLoader>
                </td>
              )}
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 150 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="120" height="25" />
                </ContentLoader>
              </td>
              {columns.find((x) => x.name === 'companyName') && (
                <td>
                  <ContentLoader
                    height={30}
                    speed={SkeletonSpeed}
                    backgroundColor={SkeletonColors.lightGrey2}
                    foregroundColor={SkeletonColors.darkGrey2}
                    viewBox="0 0 180 30"
                  >
                    <rect x="0" y="3" rx="4" ry="4" width="150" height="25" />
                  </ContentLoader>
                </td>
              )}
              <td>
                <ContentLoader
                  height={30}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey2}
                  foregroundColor={SkeletonColors.darkGrey2}
                  viewBox="0 0 220 30"
                >
                  <rect x="0" y="3" rx="4" ry="4" width="200" height="25" />
                </ContentLoader>
              </td>
              <td align="right">
                <Button className="btn-toggle btn-secondary">
                  <FontAwesomeIcon icon={faChevronRight} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

interface Props {
  cards: number;
  columns: { name: ListAlertsSortColumn | ''; key: string }[];
}
export default ProductAlertsSkeleton;
