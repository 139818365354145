import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { FormattedMessage } from 'react-intl';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const DetailsSkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton row mb-4" data-test-id="loader">
      <div className="card">
        <div className="card-divider font-bold text-uppercase p-3">
          <FormattedMessage id="productsOverview.detailTab.productDetails.title" />
        </div>
        <div className="px-3 py-4">
          <div className="row">
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.tradeName" />
              </div>
              <div className="size-label">
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.commercialName" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.casNumber" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.productType" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.molecularWeight" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.originator" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.approvedFDA" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
            <div className="col-3 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.approvedEMA" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={20}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 180 20"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="180" height="20" />
                </ContentLoader>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-8 pb-3">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.iupacName" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={19}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 600 19"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="600" height="19" />
                </ContentLoader>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <div className="size-caption text-color-gray-l6 pb-2">
                <FormattedMessage id="productsOverview.detailTab.productDetails.smiles" />
              </div>
              <div className="size-label">
                {' '}
                <ContentLoader
                  height={19}
                  speed={SkeletonSpeed}
                  backgroundColor={SkeletonColors.lightGrey1}
                  foregroundColor={SkeletonColors.lightGrey2}
                  viewBox="0 0 600 19"
                >
                  <rect x="0" y="0" rx="4" ry="5" width="600" height="19" />
                </ContentLoader>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSkeleton;
