import axios from 'axios';
import { OrderBy } from '../models/SortOrder';
import ListUsersSortColumn from '../models/User/ListUsersSortColumn';
import request from './requestService';
import PagingInfo from '../models/PagingInfo';
import UserList from '../models/User/UserList';
import UserDetails from '../models/User/UserDetails';
import InvalidEmailAddressError from '../errors/InvalidEmailAddressError';
import DuplicateEmailError from '../errors/DuplicateEmailError';
import InvalidPhoneNumberError from '../errors/InvalidPhoneNumberError';
import CreateUser from '../models/User/CreateUser';
import UpdateUser from '../models/User/UpdateUser';
import { isBusinessError } from '../models/BusinessError';
import UserRolesList from '../models/User/UserRolesList';

class UserService {
  private readonly BaseUrl = `/user`;

  public async list(requestParams: {
    companyId?: string;
    paging?: PagingInfo;
    orderBy?: OrderBy<ListUsersSortColumn>;
    searchTerm?: string;
    roleId?: string;
  }): Promise<UserList> {
    const response = await request.post<UserList>(`${this.BaseUrl}/list`, {
      companyId: requestParams.companyId,
      ...requestParams.paging,
      orderBy: requestParams.orderBy,
      searchTerm: requestParams.searchTerm,
      roleId: requestParams.roleId,
    });
    return response.data;
  }

  public async listRoles(): Promise<UserRolesList> {
    const response = await request.get(`${this.BaseUrl}/ListRoles`);
    return response.data;
  }

  public async get(id: string): Promise<UserDetails> {
    const response = await request.get(`${this.BaseUrl}/Get/?id=${id}`);
    return response.data;
  }

  public async create(user: CreateUser): Promise<void> {
    try {
      await request.post(`${this.BaseUrl}/Create`, user);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidEmailAddress') throw new InvalidEmailAddressError();
        if (error.response?.data.code === 'EmailAlreadyUsed') throw new DuplicateEmailError();
        if (error.response?.data.code === 'InvalidPhoneNumber') throw new InvalidPhoneNumberError();
      }
      throw error;
    }
  }

  public async update(user: UpdateUser): Promise<void> {
    try {
      await request.put(`${this.BaseUrl}/Update`, user);
    } catch (error: unknown) {
      if (axios.isAxiosError(error) && error.response?.status === 422 && isBusinessError(error.response.data)) {
        if (error.response?.data.code === 'InvalidEmailAddress') throw new InvalidEmailAddressError();
        if (error.response?.data.code === 'EmailAlreadyUsed') throw new DuplicateEmailError();
        if (error.response?.data.code === 'InvalidPhoneNumber') throw new InvalidPhoneNumberError();
      }
      throw error;
    }
  }

  public delete(userId: string): Promise<void> {
    return request.delete(`${this.BaseUrl}/Delete/?id=${userId}`);
  }

  public resendActivationLink(userId: string): Promise<void> {
    return request.post(`${this.BaseUrl}/ResendActivationEmail`, { userId });
  }

  public deleteAuthenticators(userId: string): Promise<void> {
    return request.delete(`${this.BaseUrl}/DeleteAuthenticators?UserId=${userId}`);
  }
}

const userService = new UserService();

export default userService;
