import React, { FC, useEffect, useMemo } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { string, object } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Button, Form, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import companyService from '../../../../services/companyService';
import { transformString } from '../../../../helpers/yupExtentions';
import Shape from '../../../../models/Shape';
import InvalidPhoneNumberError from '../../../../errors/InvalidPhoneNumberError';
import DuplicateCompanyNameError from '../../../../errors/DuplicateCompanyNameError';
import useNotifications from '../../../../hooks/useNotifications';
import Regex from '../../../../constants/regex';
import Spinner from '../../../../components/Spinner/Spinner';

import './AddEditCompany.scss';

const AddEditCompany: FC<Props> = ({ onClose, companyId }) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const validationSchema = useMemo(() => {
    const requiredMessage = intl.formatMessage({ id: 'validation.required' });

    return object<Shape<FormData>>({
      name: string()
        .transform(transformString)
        .required(requiredMessage)
        .min(2, intl.formatMessage({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      email: string()
        .transform(transformString)
        .required(requiredMessage)
        .email(intl.formatMessage({ id: 'validation.email' })),
      contactPerson: string()
        .transform(transformString)
        .required(requiredMessage)
        .min(2, intl.formatMessage({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.Name, intl.formatMessage({ id: 'validation.nameRegex' })),
      phoneNumber: string().transform(transformString),
      street: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      zipCode: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.ZipCode, intl.formatMessage({ id: 'validation.zipCodeRegex' })),
      country: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.City, intl.formatMessage({ id: 'validation.cityRegex' })),
      city: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.City, intl.formatMessage({ id: 'validation.cityRegex' })),
    });
  }, [intl.locale]);

  const notificationTitle = intl.formatMessage({
    id: companyId ? 'admin.companies.notification.title.edit' : 'admin.companies.notification.title.add',
  });

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    setError,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient();
  const { data: company, isLoading: isLoadingItem } = useQuery(
    ['company', companyId],
    () => companyService.get(companyId!),
    {
      onError: () =>
        notify('error', notificationTitle, intl.formatMessage({ id: 'admin.companies.notification.unableLoadItem' })),
      enabled: !!companyId,
    },
  );

  useEffect(() => {
    if (company) {
      setValue('name', company.name);
      setValue('email', company.email);
      setValue('phoneNumber', company.phoneNumber || undefined);
      setValue('contactPerson', company.contactPerson);
      setValue('street', company.street || undefined);
      setValue('zipCode', company.zipCode || undefined);
      setValue('country', company.country || undefined);
      setValue('city', company.city || undefined);
      setValue('hasManufacturingFacilities', company.hasManufacturingFacilities);
    }
  }, [company]);

  const { mutate: saveCompany, isLoading: isSaving } = useMutation(
    async (formData: FormData) => {
      if (companyId) {
        await companyService.update({ id: companyId, ...formData });
      } else {
        await companyService.create(formData);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['companies']);
        queryClient.invalidateQueries(['companyStatus', companyId]);
        queryClient.invalidateQueries(['company', companyId]);
        queryClient.invalidateQueries('search');
        notify(
          'success',
          notificationTitle,
          intl.formatMessage({
            id: companyId
              ? 'admin.companies.notification.updatedItem.message'
              : 'admin.companies.notification.addedItem.message',
          }),
          !companyId
            ? intl.formatMessage({
                id: 'admin.companies.notification.addedItem.description',
              })
            : undefined,
        );
        onClose();
      },
      onError: (error: unknown) => {
        if (error instanceof DuplicateCompanyNameError) {
          setError('name', { message: intl.formatMessage({ id: 'validation.nameUsed' }) }, { shouldFocus: true });
        } else if (error instanceof InvalidPhoneNumberError) {
          setError(
            'phoneNumber',
            { message: intl.formatMessage({ id: 'validation.phoneNumber' }) },
            { shouldFocus: true },
          );
        } else {
          notify('error', notificationTitle, intl.formatMessage({ id: 'admin.companies.notification.unableSaveItem' }));
        }
      },
    },
  );
  const isLoading = isSubmitting || isLoadingItem || isSaving;

  return (
    <>
      <Modal show centered className="modal-2-col modal-size-medium add-edit-company-component">
        <span
          className="text-uppercase text-color-primary-green size-label px-4 pt-4 font-normal"
          data-test-id="addEditCompany-extraTitle"
        >
          <FormattedMessage id="admin.companies.title" />
        </span>
        <Modal.Header className="px-4">
          <h3 data-test-id="addEditCompany-title" className="font-bold">
            {companyId ? (
              <FormattedMessage id="admin.companies.form.title.edit" />
            ) : (
              <FormattedMessage id="admin.companies.form.title.add" />
            )}
          </h3>
        </Modal.Header>
        <Modal.Body className="px-4 pb-4">
          <Form noValidate onSubmit={handleSubmit((data) => saveCompany(data))}>
            <div className="pb-4 form-2-col">
              <Form.Group className="mb-1">
                <Form.Label className="font-bold">
                  <FormattedMessage id="admin.companies.form.name.label" />
                </Form.Label>
                <Form.Control
                  autoFocus
                  type="text"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.name.placeholder' })}
                  {...register('name')}
                  isInvalid={!!errors?.name}
                  data-test-id="addEditCompany-name"
                />
                {!!errors?.name && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-name-error">
                    {errors.name.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>
                  <FormattedMessage id="admin.companies.form.street.label" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.street.placeholder' })}
                  {...register('street')}
                  isInvalid={!!errors?.street}
                  data-test-id="addEditCompany-street"
                />
                {!!errors?.street && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-street-error">
                    {errors.street.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>
                  <FormattedMessage id="admin.companies.form.contactPerson.label" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.contactPerson.placeholder' })}
                  {...register('contactPerson')}
                  isInvalid={!!errors?.contactPerson}
                  data-test-id="addEditCompany-contactPerson"
                />
                {!!errors?.contactPerson && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-contactPerson-error">
                    {errors.contactPerson.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-2 zip-city">
                <div>
                  <Form.Label>
                    <FormattedMessage id="admin.companies.form.zipCode.label" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({ id: 'admin.companies.form.zipCode.placeholder' })}
                    {...register('zipCode')}
                    isInvalid={!!errors?.zipCode}
                    data-test-id="addEditCompany-zipCode"
                  />
                  {!!errors?.zipCode && (
                    <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-zipCode-error">
                      {errors.zipCode.message}
                    </Form.Control.Feedback>
                  )}
                </div>
                <div>
                  <Form.Label>
                    <FormattedMessage id="admin.companies.form.city.label" />
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={intl.formatMessage({ id: 'admin.companies.form.city.placeholder' })}
                    {...register('city')}
                    isInvalid={!!errors?.city}
                    data-test-id="addEditCompany-city-error"
                  />
                  {!!errors?.city && (
                    <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-city-error">
                      {errors.city.message}
                    </Form.Control.Feedback>
                  )}
                </div>
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>
                  <FormattedMessage id="admin.companies.form.email.label" />
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.email.placeholder' })}
                  {...register('email')}
                  isInvalid={!!errors?.email}
                  data-test-id="addEditCompany-email"
                />
                {!!errors?.email && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-email-error">
                    {errors.email.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>
                  <FormattedMessage id="admin.companies.form.country.label" />
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.country.placeholder' })}
                  {...register('country')}
                  isInvalid={!!errors?.country}
                  data-test-id="addEditCompany-country"
                />
                {!!errors?.country && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-country-error">
                    {errors.country.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="mb-1">
                <Form.Label>
                  <FormattedMessage id="admin.companies.form.phoneNumber.label" />
                </Form.Label>

                <Form.Control
                  type="tel"
                  placeholder={intl.formatMessage({ id: 'admin.companies.form.phoneNumber.placeholder' })}
                  {...register('phoneNumber')}
                  isInvalid={!!errors?.phoneNumber}
                  data-test-id="addEditCompany-phoneNumber"
                />
                {!!errors?.phoneNumber && (
                  <Form.Control.Feedback type="invalid" data-test-id="addEditCompany-phoneNumber-error">
                    {errors.phoneNumber.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>

              <Form.Group className="ps-3">
                <Form.Label>&nbsp;</Form.Label>
                <Form.Check
                  id="hasManufacturingFacilitiesCheckbox"
                  type="checkbox"
                  {...register('hasManufacturingFacilities')}
                  label={intl.formatMessage({ id: 'admin.companies.form.hasManufacturingFacilities.label' })}
                  className="form-check pt-2"
                />
              </Form.Group>
            </div>
            <div>
              <Button
                variant="primary"
                type="submit"
                className="w-100 my-3"
                disabled={isLoading}
                data-test-id="addEditCompany-submit"
              >
                {companyId ? (
                  <FormattedMessage id="admin.companies.form.action.update" />
                ) : (
                  <FormattedMessage id="admin.companies.form.action.add" />
                )}
              </Button>
              <Button
                variant="ghost"
                type="button"
                className="w-100"
                onClick={onClose}
                disabled={isLoading}
                data-test-id="addEditCompany-cancel"
              >
                <FormattedMessage id="admin.companies.form.action.cancel" />
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

interface Props {
  companyId: string | null;
  onClose: () => void;
}

type FormData = {
  name: string;
  email: string;
  phoneNumber?: string;
  contactPerson: string;
  street?: string;
  zipCode?: string;
  city?: string;
  country?: string;
  hasManufacturingFacilities: boolean;
};

export default AddEditCompany;
