import { OrderBy } from '../models/SortOrder';
import request from './requestService';
import PagingInfo from '../models/PagingInfo';
import AdminAlertStatList from '../models/Alert/AdminAlertStatList';
import ListAlertsSortColumn from '../models/Alert/ListAlertSortColumn';
import AlertList from '../models/Alert/AlertList';
import AdminAlertStatGroupBy from '../models/Alert/AdminAlertStatGroupBy';

class AlertService {
  private readonly BaseUrl = `/alert`;

  public async list(
    productId?: string,
    companyId?: string,
    paging?: PagingInfo,
    orderBy?: OrderBy<ListAlertsSortColumn>,
  ): Promise<AlertList> {
    const params = {
      productId,
      companyId,
      ...paging,
      orderBy,
    };

    const response = await request.get(`${this.BaseUrl}/list`, {
      params,
    });
    return response.data;
  }

  public async getStatistics(groupBy: AdminAlertStatGroupBy, companyId?: string): Promise<AdminAlertStatList> {
    const params = {
      GroupBy: groupBy,
      Take: 20,
      companyId,
    };

    const response = await request.get<IAlertStatResponse>(`${this.BaseUrl}/GetStatistics`, {
      params,
    });

    return (
      response?.data?.frequenciesByGroup?.map((item) => {
        return {
          Count: item.count,
          UnitDate: new Date(item.fromDate),
        };
      }) || []
    );
  }
}

interface IAlertStatItem {
  count: number;
  fromDate: string;
  toDate: string;
}

interface IAlertStatResponse {
  frequenciesByGroup: IAlertStatItem[];
}

const alertService = new AlertService();

export default alertService;
