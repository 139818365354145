import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

const DetailsAdminNoteSkeleton: FC = () => {
  return (
    <div className="animated-component-skeleton description" data-test-id="loader">
      <ContentLoader
        height={100}
        speed={SkeletonSpeed}
        backgroundColor={SkeletonColors.lightGrey2}
        foregroundColor={SkeletonColors.lightGrey1}
        viewBox="0 0 346 100"
      >
        <rect x="0" y="0" rx="4" ry="4" width="346" height="14" />
        <rect x="0" y="24" rx="4" ry="4" width="346" height="14" />
        <rect x="0" y="48" rx="4" ry="4" width="346" height="14" />
        <rect x="0" y="72" rx="4" ry="4" width="346" height="14" />
      </ContentLoader>
    </div>
  );
};
export default DetailsAdminNoteSkeleton;
