import React, { FC, PropsWithChildren, useRef, useState } from 'react';

import { Button, Overlay, Tooltip } from 'react-bootstrap';

const CopyToClipboardButton: FC<PropsWithChildren<Props>> = ({ targetText, className, tooltip, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef<HTMLButtonElement>(null);
  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(targetText).then(() => {
      setShowTooltip(true);
      tooltipTarget.current?.blur();

      setTimeout(() => setShowTooltip(false), 3000);
    });
  };
  return (
    <>
      <Button variant="secondary" className={className} ref={tooltipTarget} onClick={onCopyToClipboard}>
        {children}
      </Button>
      <Overlay target={tooltipTarget.current} show={showTooltip} placement="top">
        {(props) => <Tooltip {...props}>{tooltip}</Tooltip>}
      </Overlay>
    </>
  );
};

interface Props {
  targetText: string;
  tooltip: string;
  className?: string;
}

export default CopyToClipboardButton;
