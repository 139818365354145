import React, { Dispatch, FC, useMemo } from 'react';
import TabsContext from './TabsContext';

interface Props {
  activeTab: string;
  setActiveTab: Dispatch<string>;
  children: React.ReactNode;
}

const TabsContextProvider: FC<Props> = ({ activeTab, setActiveTab, children }) => {
  const contextValue = useMemo(() => ({ activeTab, setActiveTab }), [activeTab, setActiveTab]);

  return <TabsContext.Provider value={contextValue}>{children}</TabsContext.Provider>;
};

export default TabsContextProvider;
