enum TrackerVariant {
  noData = 'no-data',
  regular = 'regular',
  positive = 'positive',
  negative = 'negative',
  warning = 'warning',
  progress = 'progress',
}

export default TrackerVariant;
