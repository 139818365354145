import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutation } from 'react-query';
import CopyToClipboardButton from '../../../components/CopyToClipboardButton/CopyToClipboardButton';
import Spinner from '../../../components/Spinner/Spinner';
import InvalidTwoFactorStatusError from '../../../errors/InvalidTwoFactorStatusError';
import useNotifications from '../../../hooks/useNotifications';
import accountService from '../../../services/accountService';
import ChangeMfa from './ChangeMfa/ChangeMfa';
import ChangePassword from './ChangePassword/ChangePassword';

const SecurityTab: React.FC = () => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const notificationTitle = intl.formatMessage({ id: 'settings.tab.security.notification.title' });
  const {
    mutate: regenerateRecoveryCode,
    isLoading: isRegeneratingRecoveryCode,
    data: recoveryCode,
  } = useMutation(() => accountService.regenerateRecoveryCode(), {
    onSuccess: () => {
      notify(
        'success',
        notificationTitle,
        intl.formatMessage({
          id: 'settings.tab.security.notification.regeneratedRecoveryCode',
        }),
      );
    },
    onError: (error) => {
      if (error instanceof InvalidTwoFactorStatusError) {
        notify(
          'error',
          notificationTitle,
          intl.formatMessage({ id: 'settings.tab.security.notification.invalidTwoFactorState' }),
        );
      } else {
        notify(
          'error',
          notificationTitle,
          intl.formatMessage({ id: 'settings.tab.security.notification.unableRegenerateRecoveryCode' }),
        );
      }
    },
  });

  const [isChangePassword, setChangePassword] = useState(false);
  const [isChangeMfa, setIsChangeMfa] = useState(false);

  const handleOnChangeMfa = () => setIsChangeMfa(true);

  return (
    <>
      <div className="card row p-3 mb-4 shadow-2">
        <div className="col-10">
          <span className="text-uppercase font-bold mb-2 d-block">
            <FormattedMessage id="settings.tab.security.passwordSettings.title" />
          </span>
          <span className="text-color-gray-light1">
            <FormattedMessage id="settings.tab.security.passwordSettings.description" />
          </span>
        </div>
        <div className="col-2 d-flex align-items-center justify-content-end">
          <Button variant="secondary" onClick={() => setChangePassword(true)}>
            <FormattedMessage id="settings.tab.security.passwordSettings.action.changePassword" />
          </Button>
        </div>
      </div>
      <div className="card">
        <div className="row p-3 card-divider">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <div className="text-uppercase font-bold mb-2">
                <FormattedMessage id="settings.tab.security.twoFactor.title" />
              </div>
              <div className="text-color-gray-light1">
                <FormattedMessage id="settings.tab.security.twoFactor.description" />
              </div>
            </div>
            <Button variant="secondary" onClick={() => handleOnChangeMfa()}>
              <FormattedMessage id="settings.tab.security.twoFactor.mfa.action.changeOtpDevice" />
            </Button>
          </div>
        </div>

        <div className="d-flex flex-column p-3">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div>
              <div className="text-uppercase font-bold mb-2">
                <FormattedMessage id="settings.tab.security.twoFactor.backupCode.title" />
              </div>
              <div className="text-color-gray-light1">
                <FormattedMessage id="settings.tab.security.twoFactor.backupCode.description" />
              </div>
            </div>
            <div>
              {recoveryCode && (
                <CopyToClipboardButton
                  className="me-4"
                  targetText={recoveryCode}
                  tooltip={intl.formatMessage({ id: 'settings.tab.security.twoFactor.backupCode.copied' })}
                >
                  <FormattedMessage id="settings.tab.security.twoFactor.backupCode.action.copy" />
                </CopyToClipboardButton>
              )}
              <Button variant="secondary" onClick={() => regenerateRecoveryCode()}>
                <FormattedMessage id="settings.tab.security.twoFactor.backupCode.action.regenerate" />
              </Button>
            </div>
          </div>
          {recoveryCode && (
            <div className="mt-4 bg-color-gray-l1 p-3 d-flex justify-content-center rounded size-label">
              {recoveryCode}
            </div>
          )}
        </div>
      </div>
      {isChangePassword && <ChangePassword onClose={() => setChangePassword(false)} />}
      {isChangeMfa && <ChangeMfa onClose={() => setIsChangeMfa(false)} />}
      {isRegeneratingRecoveryCode && <Spinner />}
    </>
  );
};
export default SecurityTab;
