import { useQuery } from 'react-query';
import companyService from '../../../services/companyService';

const queryKey = 'companies';

const useCompanies = (options: { onError?: () => void }) =>
  useQuery([queryKey], async () => companyService.list(), {
    onError: options.onError,
  });

export default useCompanies;
