import React, { FC, useMemo } from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { NavLink, Outlet, useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/BackButton/BackButton';
import GenericFormattedDate from '../../components/GenericFormattedDate/GenericFormattedDate';
import Header from '../../components/Header/Header';
import ReportButton from '../../components/Report/ReportButton';
import StatusBadge from '../../components/StatusBadge/StatusBadge';
import dataPlaceholders from '../../constants/dataPlaceholders';
import useNotifications from '../../hooks/useNotifications';
import EntityTypes from '../../models/Report/EntityTypes';
import ReportData from '../../models/Report/ReportData';
import synthesisStepService from '../../services/synthesisStepService';
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

const SynthesisStepOverview: FC = () => {
  const { stepId: synthesisStepId, id: productId } = useParams();
  const intl = useIntl();
  const { notify } = useNotifications();

  const navigate = useNavigate();
  const onLoadingError = () => {
    notify(
      'error',
      intl.formatMessage({ id: 'synthesisStepOverview.notification.title' }),
      intl.formatMessage({ id: 'synthesisStepOverview.notification.unableLoadStep' }),
    );
    navigate('/');
  };

  const { data: step } = useQuery(
    ['synthesisStep', synthesisStepId],
    () => synthesisStepService.getDetails(synthesisStepId!),
    {
      onSuccess: (details) => {
        if (details.productId !== productId) {
          onLoadingError();
        }
      },
      onError: onLoadingError,
    },
  );

  const reportData: ReportData = useMemo(() => {
    if (step) {
      return {
        productName: step.productName,
        synthesisStepName: step?.name,
        entityType: EntityTypes.synthesisStep,
        entityId: step.id,
      };
    }
    return {
      productName: '',
      entityType: EntityTypes.product,
      entityId: '',
    };
  }, [step]);

  return (
    <>
      <Header
        title={
          <>
            {step?.name}
            <div className="py-2">
              <Breadcrumbs
                values={[
                  {
                    label: step?.productName || '',
                  },
                ]}
              />
            </div>
          </>
        }
      >
        <div className="d-flex gap-4 align-items-baseline">
          <StatusBadge isActive={step?.productIsActive || false} className="size-caption" />
          <span className="size-caption">
            <FormattedMessage id="synthesisStepOverview.header.phase" />{' '}
            {`${step?.phaseName || dataPlaceholders.missingData}`}
          </span>
          <span className="size-caption">
            <FormattedMessage id="synthesisStepOverview.header.updatedAt" />{' '}
            <GenericFormattedDate value={step?.updatedAt} />
          </span>
          <ReportButton reportData={reportData} fullSize />
          <BackButton />
        </div>
      </Header>
      <div className="page-content px-5 mb-4">
        <Nav variant="tabs" className="mb-4">
          <Nav.Item>
            <Nav.Link as={NavLink} to="details">
              <FormattedMessage id="synthesisStepOverview.tab.detail" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="campaigns">
              <FormattedMessage id="synthesisStepOverview.tab.campaigns" values={{ value: step?.totalCampaigns }} />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Outlet />
      </div>
    </>
  );
};

export default SynthesisStepOverview;
