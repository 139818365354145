import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import useNotifications from '../../hooks/useNotifications';
import useUserInfo from '../../hooks/useUserInfo';

const ProtectedUserRoute: FC = () => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();

  const { companyId } = useParams<'companyId'>();
  const user = useUserInfo();

  if (user!.companyId !== companyId) {
    notify(
      'error',
      fm({ id: 'protectedRoute.pageNotFound.title' }),
      fm({ id: 'protectedRoute.pageNotFound.description' }),
    );
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedUserRoute;
