import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQueryClient } from 'react-query';
import { FormattedMessage, useIntl } from 'react-intl';

import './ProcessCard.scss';
import { Link } from 'react-router-dom';

import ApplicationItem from '../../../../models/AppGallery/Application/ApplicationItem';
import imageFileService from '../../../../services/AppGallery/imageFileService';
import useNotifications from '../../../../hooks/useNotifications';
import applicationRequestService from '../../../../services/AppGallery/applicationRequestService';
import ProcessCardSkeleton from './ProcessCardSkeleton';

const ProcessCard: FC<Props> = ({ applicationItem }) => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { notify } = useNotifications();
  const { mutate: requestApplication, isLoading: isRequesting } = useMutation<unknown, unknown, string>(
    (id) => applicationRequestService.requestAccess(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('applicationList');
        notify(
          'success',
          intl.formatMessage({ id: 'appGallery.processTab.notification.title' }),
          intl.formatMessage({
            id: 'appGallery.processTab.notification.requestedApplication',
          }),
        );
      },
      onError: () => {
        notify(
          'error',
          intl.formatMessage({ id: 'appGallery.processTab.notification.title' }),
          intl.formatMessage({ id: 'appGallery.processTab.notification.unableRequestApplication' }),
        );
      },
    },
  );

  return (
    <>
      {isRequesting && <ProcessCardSkeleton cards={1} />}

      {!isRequesting && requestApplication && (
        <div
          className="process-app-card-component card p-2 mb-2 me-1 bg-color-gray-l2"
          data-test-id="processTab-appCard"
        >
          <Link to={`?id=${applicationItem.id}`} className="text-color-white">
            <div className="font-bold text-uppercase mb-2">{applicationItem.name}</div>
            <div className="d-flex">
              <div>
                <img
                  src={imageFileService.getImageUrl(applicationItem.iconImageId)}
                  alt="app icon"
                  className="process-app-card-component-img mx-3"
                />
              </div>
              <div className="process-app-card-component-description">
                <div className="size-caption text-color-gray-l6">{applicationItem.shortDescription}</div>
              </div>
            </div>
          </Link>
          <div>
            <div className="process-app-card-component-status d-flex justify-content-between mb-1 mt-2">
              {applicationItem.status === 'Available' ? (
                <Button
                  variant="primary"
                  className="process-app-card-component-btn"
                  onClick={() => requestApplication(applicationItem.id)}
                >
                  <FormattedMessage id="appGallery.processTab.application.button.request" />
                </Button>
              ) : (
                <div className="py-1">
                  {applicationItem.status === 'Installed' && (
                    <span className="installed">
                      <FormattedMessage id="appGallery.processTab.application.status.installed" />
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface Props {
  applicationItem: ApplicationItem;
}

export default ProcessCard;
