import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';
import './CategoryCard.scss';

const CategoryCardSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="category-app-card-component card" data-test-id="loader">
          <div className="d-flex card-divider p-3 animated-component-skeleton">
            <ContentLoader
              height={40}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.lightGrey2}
              foregroundColor={SkeletonColors.lightGrey1}
              viewBox="0 0 360 40"
            >
              <circle cx="20" cy="20" r="20" />
              <rect x="67" y="0" rx="4" ry="4" width="280" height="40" />
            </ContentLoader>
          </div>
          <div className="p-3 animated-component-skeleton">
            <ContentLoader
              height={215}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 748 215"
            >
              <rect x="0" y="0" rx="4" ry="4" width="748" height="215" />
            </ContentLoader>
          </div>
        </div>
      ))}
    </>
  );
};
interface Props {
  cards: number;
}
export default CategoryCardSkeleton;
