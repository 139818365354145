import React, { FC } from 'react';

import './GroupTracker.scss';
import numberHelper from '../../../helpers/numberHelper';
import IconTooltip from '../../Tooltips/IconTooltip/IconTooltip';

interface GroupItem {
  title: string;
  tooltip: string;
  value?: number | null;
  unit: string;
}

type Props = {
  items: GroupItem[];
};

const GroupTracker: FC<Props> = ({ items }) => {
  return (
    <div className="group-tracker-component bg-color-gray-l1 p-2 d-flex justify-content-between">
      {items.map((item) => (
        <div className="d-flex justify-content-between w-100 py-1">
          <div>
            {item.title} <IconTooltip className="ms-1 size-label" tooltip={item.tooltip} />
          </div>
          <div>
            {numberHelper.formatNumber(item.value)}
            {item.unit}
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupTracker;
