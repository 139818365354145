import React, { FC, useEffect, useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { string, number, mixed, object, lazy } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import { useQuery, useMutation, useQueryClient } from 'react-query';
import { Button, Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { transformString } from '../../../../helpers/yupExtentions';
import Shape from '../../../../models/Shape';
import useNotifications from '../../../../hooks/useNotifications';
import Regex from '../../../../constants/regex';
import productService from '../../../../services/productService';
import companyService from '../../../../services/companyService';
import productImageService from '../../../../services/productImageService';
import ImageUpload from '../../../../components/ImageUpload/ImageUpload';
import Spinner from '../../../../components/Spinner/Spinner';
import Header from '../../../../components/Header/Header';
import Select from '../../../../components/Select/Select';
import useQueryParams from '../../../../hooks/useQueryParams';

const AddEditProduct: FC = () => {
  const { id: productId } = useParams<'id'>();
  const { defaultCompanyId } = useQueryParams(['defaultCompanyId']);
  const intl = useIntl();
  const navigate = useNavigate();
  const { notify } = useNotifications();

  const title = intl.formatMessage({
    id: productId ? 'admin.products.addEdit.title.edit' : 'admin.products.addEdit.title.add',
  });
  const { data: product, isLoading: isLoadingItem } = useQuery(
    ['product', productId],
    () => productService.get(productId!),
    {
      onError: () =>
        notify('error', title, intl.formatMessage({ id: 'admin.products.addEdit.notification.unableLoadItem' })),
      enabled: !!productId,
    },
  );
  const [imageUrl, setImageUrl] = useState<string>();
  useEffect(() => setImageUrl(productImageService.getImageUrl(product?.imageId)), [product]);

  const validationSchema = useMemo(() => {
    const requiredMessage = intl.formatMessage({ id: 'validation.required' });

    return object<Shape<FormData>>({
      companyId: string().transform(transformString).required(requiredMessage),
      name: string()
        .transform(transformString)
        .required(requiredMessage)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      commercialName: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.CommercialName, intl.formatMessage({ id: 'validation.commercialNameRegex' })),
      casNumber: string()
        .transform(transformString)
        .max(14, intl.formatMessage({ id: 'validation.maxLength' }, { value: 14 }))
        .matches(Regex.CasNumber, intl.formatMessage({ id: 'validation.casNumberRegex' })),
      productType: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.ProductType, intl.formatMessage({ id: 'validation.productTypeRegex' })),
      molecularWeight: lazy((value) =>
        value === ''
          ? string().transform(transformString)
          : number()
              .positive()
              .nullable()
              .typeError(intl.formatMessage({ id: 'validation.number' })),
      ),
      originator: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.CommercialName, intl.formatMessage({ id: 'validation.commercialNameRegex' })),
      yearApprovedFda: lazy((value) =>
        value === '' || value === undefined
          ? string().transform(transformString)
          : number()
              .positive()
              .integer()
              .nullable()
              .typeError(intl.formatMessage({ id: 'validation.number' })),
      ),
      yearApprovedEma: lazy((value) =>
        value === ''
          ? string().transform(transformString)
          : number()
              .nullable()
              .positive()
              .integer()
              .typeError(intl.formatMessage({ id: 'validation.number' })),
      ),
      iupacName: string()
        .required(requiredMessage)
        .transform(transformString)
        .min(2, intl.formatMessage({ id: 'validation.minLength' }, { value: 2 }))
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      smiles: string()
        .transform(transformString)
        .max(256, intl.formatMessage({ id: 'validation.maxLength' }, { value: 256 }))
        .matches(Regex.Smiles, intl.formatMessage({ id: 'validation.smilesRegex' })),
      description: string()
        .transform(transformString)
        .max(500, intl.formatMessage({ id: 'validation.maxLength' }, { value: 500 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      note: string()
        .transform(transformString)
        .max(500, intl.formatMessage({ id: 'validation.maxLength' }, { value: 500 }))
        .matches(Regex.NameExtended, intl.formatMessage({ id: 'validation.nameExtendedRegex' })),
      image: mixed<FileList>()
        .test('required', requiredMessage, (files) => (files && files.length > 0) || !!imageUrl)
        .test(
          'imageSize',
          intl.formatMessage({ id: 'validation.fileSize' }, { value: 1 }),
          (files) => !files || !files.length || files[0].size < 1024 * 1024,
        ),
    });
  }, [intl.locale, product?.imageId, imageUrl]);

  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
    setValue,
    control,
  } = useForm<FormData>({
    mode: 'onSubmit',
    resolver: yupResolver(validationSchema),
  });
  const queryClient = useQueryClient();
  const { data: companiesModel, isLoading: isLoadingCompanies } = useQuery(['companies'], () => companyService.list(), {
    onError: () =>
      notify('error', title, intl.formatMessage({ id: 'admin.products.addEdit.notification.unableToLoadCompanies' })),
  });

  useEffect(() => {
    if (defaultCompanyId) {
      setValue('companyId', defaultCompanyId);
    }
  }, []);

  useEffect(() => {
    if (product) {
      setValue('companyId', product.companyId);
      setValue('name', product.name);
      setValue('commercialName', product.commercialName || undefined);
      setValue('casNumber', product.casNumber || undefined);
      setValue('productType', product.productType || undefined);
      setValue('molecularWeight', product.molecularWeight || undefined);
      setValue('originator', product.originator || undefined);
      setValue('yearApprovedFda', product.yearApprovedFda || undefined);
      setValue('yearApprovedEma', product.yearApprovedEma || undefined);
      setValue('iupacName', product.iupacName);
      setValue('smiles', product.smiles || undefined);
      setValue('description', product.description || undefined);
      setValue('note', product.note || undefined);
    }
  }, [product]);

  const [newImage, setNewImage] = useState<{ imageId: string; file: File }>();
  const { mutate: saveProduct, isLoading: isSaving } = useMutation(
    async (formData: FormData) => {
      const { image, ...data } = formData;
      let targetImageId = (image && newImage?.imageId) || product?.imageId;
      if (
        image &&
        (!newImage ||
          !(
            image[0].name === newImage.file.name &&
            image[0].size === newImage.file.size &&
            image[0].type === newImage.file.type
          ))
      ) {
        targetImageId = await productImageService.upload(image[0]);
        setNewImage({ imageId: targetImageId, file: image[0] });
      }

      if (productId) {
        await productService.update({ id: productId, imageId: targetImageId!, ...data });
      } else {
        await productService.create({ imageId: targetImageId!, ...data });
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['companies']);
        notify(
          'success',
          title,
          intl.formatMessage({
            id: productId
              ? 'admin.products.addEdit.notification.updatedItem.message'
              : 'admin.products.addEdit.notification.addedItem.message',
          }),
        );
        navigate('../');
      },
      onError: () => {
        notify('error', title, intl.formatMessage({ id: 'admin.products.addEdit.notification.unableSaveItem' }));
      },
    },
  );

  const companyOptions = useMemo(() => {
    return companiesModel
      ? companiesModel.companies.map((company) => ({ label: company.name, value: company.id }))
      : [];
  }, [companiesModel?.companies]);

  const isLoading = isSubmitting || isLoadingItem || isLoadingCompanies || isSaving;

  return (
    <Form className="d-flex flex-column overflow-auto" noValidate onSubmit={handleSubmit((data) => saveProduct(data))}>
      <Header title={title}>
        <div className="col-auto">
          <Button
            variant="secondary"
            type="button"
            className="mx-4"
            onClick={() => navigate(-1)}
            disabled={isLoading}
            data-test-id="addEditProduct-cancel"
          >
            <FormattedMessage id="admin.products.addEdit.form.action.cancel" />
          </Button>
          <Button variant="primary" type="submit" disabled={isLoading} data-test-id="addEditProduct-submit">
            {productId ? (
              <FormattedMessage id="admin.products.addEdit.form.action.update" />
            ) : (
              <FormattedMessage id="admin.products.addEdit.form.action.add" />
            )}
          </Button>
        </div>
      </Header>

      <div className="page-content d-flex flex-column px-5">
        <div className="row pt-5 form-3-col">
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.company.label" />
            </Form.Label>
            <Controller
              control={control}
              name="companyId"
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  value={value}
                  onChange={onChange}
                  options={companyOptions}
                  dataTestId="addEditProduct-company"
                  isError={!!error}
                  placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.company.placeholder' })}
                />
              )}
            />
            {!!errors?.companyId && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-company-error">
                {errors.companyId.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.name.label" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.name.placeholder' })}
              {...register('name')}
              isInvalid={!!errors?.name}
              data-test-id="addEditProduct-name"
            />
            {!!errors?.name && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-name-error">
                {errors.name.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.commercialName.label" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.commercialName.placeholder' })}
              {...register('commercialName')}
              isInvalid={!!errors?.commercialName}
              data-test-id="addEditProduct-commercialName"
            />
            {!!errors?.commercialName && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-commercialName-error">
                {errors.commercialName.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.casNumber.label" />
            </Form.Label>
            <Form.Control
              autoFocus
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.casNumber.placeholder' })}
              {...register('casNumber')}
              isInvalid={!!errors?.casNumber}
              data-test-id="addEditProduct-casNumber"
            />
            {!!errors?.casNumber && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-casNumber-error">
                {errors.casNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.productType.label" />
            </Form.Label>
            <Form.Control
              type="tel"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.productType.placeholder' })}
              {...register('productType')}
              isInvalid={!!errors?.productType}
              data-test-id="addEditProduct-productType"
            />
            {!!errors?.productType && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-productType-error">
                {errors.productType.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.molecularWeight.label" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.molecularWeight.placeholder' })}
              {...register('molecularWeight')}
              isInvalid={!!errors?.molecularWeight}
              data-test-id="addEditProduct-molecularWeight"
            />
            {!!errors?.molecularWeight && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-molecularWeight-error">
                {errors.molecularWeight.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>

          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.originator.label" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.originator.placeholder' })}
              {...register('originator')}
              isInvalid={!!errors?.originator}
              data-test-id="addEditProduct-originator"
            />
            {!!errors?.originator && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-originator-error">
                {errors.originator.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.yearApprovedFda.label" />
            </Form.Label>
            <Form.Control
              type="text"
              min="0"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.yearApprovedFda.placeholder' })}
              {...register('yearApprovedFda')}
              isInvalid={!!errors?.yearApprovedFda}
              data-test-id="addEditProduct-yearApprovedFda"
            />
            {!!errors?.yearApprovedFda && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-yearApprovedFda-error">
                {errors.yearApprovedFda.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>
              <FormattedMessage id="admin.products.addEdit.form.yearApprovedEma.label" />
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.yearApprovedEma.placeholder' })}
              {...register('yearApprovedEma')}
              isInvalid={!!errors?.yearApprovedEma}
              data-test-id="addEditProduct-yearApprovedEma"
            />
            {!!errors?.yearApprovedEma && (
              <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-yearApprovedEma-error">
                {errors.yearApprovedEma.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        </div>

        <div className="row gx-4 py-4">
          <div className="col-8">
            <Form.Group className="mb-3">
              <Form.Label>
                <FormattedMessage id="admin.products.addEdit.form.iupacName.label" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.iupacName.placeholder' })}
                {...register('iupacName')}
                isInvalid={!!errors?.iupacName}
                data-test-id="addEditProduct-iupacName"
              />
              {!!errors?.iupacName && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-iupacName-error">
                  {errors.iupacName.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <FormattedMessage id="admin.products.addEdit.form.smiles.label" />
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.smiles.placeholder' })}
                {...register('smiles')}
                isInvalid={!!errors?.smiles}
                data-test-id="addEditProduct-smiles"
              />
              {!!errors?.smiles && (
                <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-smiles-error">
                  {errors.smiles.message}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        </div>
        <Controller
          control={control}
          name="image"
          render={({ field: { onChange }, fieldState: { error } }) => (
            <ImageUpload
              className="mb-5"
              onChange={onChange}
              imageUrl={imageUrl}
              clearImageUrl={() => setImageUrl(undefined)}
              error={error?.message}
            />
          )}
        />
        <Form.Group className="mb-3">
          <Form.Label>
            <FormattedMessage id="admin.products.addEdit.form.description.label" />
          </Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.description.placeholder' })}
            {...register('description')}
            isInvalid={!!errors?.description}
            data-test-id="addEditProduct-description"
          />
          {!!errors?.description && (
            <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-description-error">
              {errors.description.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>
            <FormattedMessage id="admin.products.addEdit.form.note.label" />
          </Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            placeholder={intl.formatMessage({ id: 'admin.products.addEdit.form.note.placeholder' })}
            {...register('note')}
            isInvalid={!!errors?.note}
            data-test-id="addEditProduct-note"
          />
          {!!errors?.note && (
            <Form.Control.Feedback type="invalid" data-test-id="addEditProduct-note-error">
              {errors.note.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </div>
      {isLoading && <Spinner />}
    </Form>
  );
};

type FormData = {
  companyId: string;
  name: string;
  commercialName?: string;
  casNumber?: string;
  productType?: string;
  molecularWeight?: number;
  originator?: string;
  yearApprovedFda?: number;
  yearApprovedEma?: number;
  iupacName: string;
  smiles?: string;
  description?: string;
  note?: string;
  image: FileList | null;
};

export default AddEditProduct;
