import React, { FC } from 'react';
import './ActionsDropdown.scss';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { PropsWithChildrenOnly } from '../../types/propsWithChildrenOnly';

const ActionsDropdown: FC<PropsWithChildrenOnly> = ({ children }) => {
  return (
    <div className="actions-dropdown-component text-center" data-test-id="actionsDropdown-container">
      <Dropdown>
        <Dropdown.Toggle variant="link" className="no-drop p-0 btn-toggle" data-test-id="actionsDropdown-toggle">
          <FontAwesomeIcon icon={faEllipsis} />
        </Dropdown.Toggle>
        <Dropdown.Menu className="bg-color-gray-l3">{children}</Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default ActionsDropdown;
