import React, { FC, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';
import { useMutation } from 'react-query';
import './SetUpNewDeviceModal.scss';
import CodeForm from '../../../../components/CodeForm/CodeForm';
import Spinner from '../../../../components/Spinner/Spinner';
import InvalidOtpCodeError from '../../../../errors/InvalidOtpCodeError';
import useNotifications from '../../../../hooks/useNotifications';
import AuthToken from '../../../../models/AuthToken';
import accountService from '../../../../services/accountService';
import Image from '../../../../constants/Authenticator/Image';
import Link from '../../../../constants/Authenticator/Link';

const BaseKey = 'settings.tab.security.changeMfa.setUpNewDeviceModal';

const SetUpNewDeviceModal: FC<Props> = ({ barcodeUri, mfaToken, onCodeVerified, onClose }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();

  const [otpCodeError, setOtpCodeError] = useState<string | undefined>();

  const { mutate: verifyOtpCode, isLoading } = useMutation<AuthToken, unknown, string>(
    (code) => accountService.verifyOtpCode(mfaToken, code),
    {
      onSuccess: (data) => {
        onCodeVerified(data);
      },
      onError: (error) => {
        if (error instanceof InvalidOtpCodeError) {
          setOtpCodeError(fm({ id: `${BaseKey}.form.otpCode.validation.invalid` }));
        } else {
          notify(
            'error',
            fm({ id: 'settings.tab.security.notification.title' }),
            fm({ id: `${BaseKey}.notification.unableToVerifyOtpCode` }),
          );
          onClose();
        }
      },
    },
  );

  const onSubmit = (code: string) => verifyOtpCode(code);

  return (
    <>
      <Modal show centered className="set-up-new-device-modal-component">
        <span className="text-uppercase text-color-primary-green size-label px-4 pt-4 font-normal">
          <FormattedMessage id="settings.tab.security.title" />
        </span>
        <Modal.Header className="px-4">
          <h3 className="mb-0 font-bold">
            <FormattedMessage id={`${BaseKey}.title`} />
          </h3>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0 size-label font-medium text-color-gray-light1">
          <p className="mb-3">
            <FormattedMessage id={`${BaseKey}.description`} />
          </p>

          <div className="d-flex">
            <div className="bg-white p-2 my-4 mx-auto">
              <QRCode value={barcodeUri} size={183} />
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-baseline mx-auto">
            <a target="_blank" rel="noreferrer" className="app-store" href={Link.appStore}>
              <img src={Image.appStore} alt={fm({ id: '2fa.qrCode.appStore.gaImgAlt' })} />
            </a>
            <a target="_blank" rel="noreferrer" className="play-market" href={Link.playMarket}>
              <img alt={fm({ id: '2fa.qrCode.playMarket.gaImgAlt' })} src={Image.playMarket} />
            </a>
          </div>
          <CodeForm onSubmit={onSubmit}>
            {!!otpCodeError && <Form.Control.Feedback type="invalid">{otpCodeError}</Form.Control.Feedback>}
          </CodeForm>
        </Modal.Body>
      </Modal>
      {isLoading && <Spinner />}
    </>
  );
};

interface Props {
  barcodeUri: string;
  mfaToken: string;
  onCodeVerified: (authToken: AuthToken) => void;
  onClose(): void;
}

export default SetUpNewDeviceModal;
