import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useNotifications from '../../../../hooks/useNotifications';
import useSynthesisStepsViewState from '../../../../hooks/useSynthesisStepsViewState';
import synthesisStepService from '../../../../services/synthesisStepService';
import numberHelper from '../../../../helpers/numberHelper';
import SynthesisStepItem from '../../../../components/SynthesisStepItem/SynthesisStepItem';
import SynthesisStepSkeleton from '../../../../components/Skeletons/ProductOverview/SynthesisSteps/SynthesisStepSkeleton';
import StepShortInfoSkeleton from '../../../../components/Skeletons/ProductOverview/SynthesisSteps/StepShortInfoSkeleton';
import SummaryInfoSkeleton from '../../../../components/Skeletons/ProductOverview/SynthesisSteps/SummaryInfoSkeleton';

const StepsTab: FC = () => {
  const { id: productId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: steps, isLoading: isLoadingSteps } = useQuery(
    ['synthesisSteps', productId],
    () => synthesisStepService.list(productId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.productOverview.stepsTab.notification.title' }),
          intl.formatMessage({ id: 'admin.productOverview.stepsTab.notification.unableLoadSteps' }),
        ),
    },
  );

  const { viewState, getHandleStepViewStateChange } = useSynthesisStepsViewState();

  return (
    <div className="pb-2">
      <div className="row gx-4 mb-4">
        <div className="col-6">
          <div className="card p-3">
            {isLoadingSteps ? (
              <StepShortInfoSkeleton />
            ) : (
              <div className="row gx-2 align-items-center">
                <div className="col text-uppercase font-bold">
                  <FormattedMessage id="admin.productOverview.stepsTab.stepsTotal.title" />
                </div>
                <div className="col">
                  <div className="row card-line py-2 px-3">
                    <div className="col">
                      <FormattedMessage id="admin.productOverview.stepsTab.stepsTotal.total" />
                    </div>
                    <div className="col-auto">{numberHelper.formatNumber(steps?.total)}</div>
                  </div>
                </div>
                <div className="col">
                  <div className="row card-line py-2 px-3">
                    <div className="col">
                      <FormattedMessage id="admin.productOverview.stepsTab.stepsTotal.active" />
                    </div>
                    <div className={`col-auto ${steps?.active && steps.active > 0 ? 'text-color-graph-positive' : ''}`}>
                      {numberHelper.formatNumber(steps?.active)}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row card-line py-2 px-3">
                    <div className="col">
                      <FormattedMessage id="admin.productOverview.stepsTab.stepsTotal.finished" />
                    </div>
                    <div className="col-auto">{numberHelper.formatNumber(steps?.finished)}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-6">
          <div className="card p-3">
            {isLoadingSteps ? (
              <SummaryInfoSkeleton />
            ) : (
              <div className="row gx-2 align-items-center">
                <div className="col text-uppercase font-bold">
                  <FormattedMessage id="admin.productOverview.stepsTab.summary.title" />
                </div>
                <div className="col">
                  <div className="row card-line py-2 px-3">
                    <div className="col">
                      <FormattedMessage id="admin.productOverview.stepsTab.summary.totalCampaigns" />
                    </div>
                    <div
                      className={`col-auto ${
                        steps?.totalCampaigns && steps.totalCampaigns > 0 ? 'text-color-graph-positive' : ''
                      }`}
                    >
                      {numberHelper.formatNumber(steps?.totalCampaigns)}
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="row card-line py-2 px-3">
                    <div className="col">
                      <FormattedMessage id="admin.productOverview.stepsTab.summary.totalBatches" />
                    </div>
                    <div
                      className={`col-auto ${
                        steps?.totalBatches && steps.totalBatches > 0 ? 'text-color-graph-positive' : ''
                      }`}
                    >
                      {numberHelper.formatNumber(steps?.totalBatches)}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {isLoadingSteps && <SynthesisStepSkeleton />}
      {steps?.steps.map((step, idx) => (
        <SynthesisStepItem
          key={step.id}
          step={step}
          viewState={viewState.search[step.id]}
          onViewStateChange={getHandleStepViewStateChange(step.id)}
          isScrollIntoView={viewState.hash === step.id}
          highlighted={idx === 0}
        />
      ))}
    </div>
  );
};

export default StepsTab;
