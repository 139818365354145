import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, PropsWithChildren } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';

const IconTooltip: FC<PropsWithChildren<Props>> = ({ tooltip, icon, className }) => {
  return (
    <OverlayTrigger overlay={<Tooltip data-test-id="iconTooltip-tooltip">{tooltip}</Tooltip>}>
      <span className={className}>
        {icon ? (
          <FontAwesomeIcon icon={icon} className="text-color-gray-l3" data-test-id="iconTooltip-icon" />
        ) : (
          <FontAwesomeIcon icon={faCircleInfo} className="text-color-gray-l6" data-test-id="iconTooltip-icon" />
        )}
      </span>
    </OverlayTrigger>
  );
};
interface Props {
  tooltip: React.ReactNode;
  icon?: IconProp;
  className?: string;
}
export default IconTooltip;
