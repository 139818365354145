import React, { FC } from 'react';
import './Maintenance.scss';
import { FormattedMessage } from 'react-intl';

const Maintenance: FC = () => {
  return (
    <div className="maintenance-component w-100">
      <div>
        <div className="row justify-content-end">
          <div className="col-4 justify-content-center">
            <div className="d-flex flex-column my-auto login-container">
              <div className="d-flex flex-column m-auto login-container p-5 text-center">
                <div className="m-auto">
                  <h3 className="text-center font-bold">
                    <FormattedMessage id="maintenance.exclamation" />
                    <br />
                    <FormattedMessage id="maintenance.title" />
                    <br />
                    <FormattedMessage id="maintenance.hint" />
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Maintenance;
