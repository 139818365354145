import React, { FC, MouseEventHandler } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';

type Props = {
  icon: IconDefinition;
  path: string;
  labelKey: string;
  dataTestId?: string;
  onClick?: MouseEventHandler<HTMLAnchorElement> | undefined;
  children?: React.ReactNode[];
};

const NavItem: FC<Props> = ({ path, labelKey, icon, dataTestId, onClick, children }) => {
  const { pathname } = useLocation();
  const active = pathname.startsWith(path) || pathname.startsWith(path.replace(/^\/companies\/([\da-f]+)/gi, ''));

  return (
    <Link className={cn('ps-4 menu-item', { active })} to={path} data-test-id={dataTestId} onClick={onClick}>
      <FontAwesomeIcon className="pe-3" icon={icon} />
      <span className="nav-link-label size-label">
        <FormattedMessage id={`nav.items.${labelKey}`} defaultMessage={labelKey} />
        {children}
      </span>
    </Link>
  );
};

export default NavItem;
