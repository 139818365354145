import classnames from 'classnames';
import React, { FC } from 'react';
import { Accordion, Button } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import useProblemTrackerFilter from '../../hooks/useProblemTrackerFilter';
import TimePeriod from '../../models/TimePeriod';
import NoData from '../NoData/NoData';

import BatchIdSelect from './BatchIdSelect/BatchIdSelect';
import MultiSelect from '../Select/MultiSelect/MultiSelect';
import Select from '../Select/Select';
import BatchPartDetails from './BatchParts/BatchPartDetails/BatchPartDetails';
import BatchRow from './BatchRow/BatchRow';
import BatchRowSkeleton from './BatchRowSkeleton';
import useBatchList from './useBatchListViewState';
import BatchTests from './BatchTests/BatchTests';

interface Props {
  productId: string;
  companyId?: string;
  hasAccessToProduct: boolean;
}

const BatchList: FC<Props> = ({ productId, companyId, hasAccessToProduct }) => {
  const intl = useIntl();
  const { selectedTrackers, selectTrackers, options: problemTrackerOptions } = useProblemTrackerFilter();
  const {
    hash,
    selectBatches,
    selectedBatches,
    searchBatches,
    batchId,
    handleBatchToggle,
    oorBatchId,
    handleShowOorDetails,
    oosBatchId,
    handleShowOosDetails,
    handleSelectPart,
    totalPages,
    totalBatches,
    totalCampaings,
    totalSynthesisSteps,
    fetchNextPage,
    hasNextPage,
    isLoadingBatches,
    groupedBatches,
    steps,
    selectedSynthesisStep,
    handleSelectSynthesisStep,
    campaings,
    selectedCampaign,
    handleSelectCampaign,
    timePeriod,
    setTimePeriod,
  } = useBatchList(productId, companyId, selectedTrackers, hasAccessToProduct);

  return (
    <>
      <div className="d-flex mb-3">
        <BatchIdSelect
          loadOptions={searchBatches}
          onChange={selectBatches}
          value={selectedBatches}
          size="lg"
          placeholder={intl.formatMessage({ id: 'batchList.filter.ids.placeholder' })}
          noOptionsMessage={intl.formatMessage({ id: 'batchList.filter.ids.noOptions' })}
        />
      </div>
      <div className="d-flex gap-3">
        <Select
          value={selectedSynthesisStep}
          size="lg"
          onChange={handleSelectSynthesisStep}
          options={steps}
          placeholder={intl.formatMessage({ id: 'batchList.filter.synthesisStep.placeholder' })}
        />
        <Select
          value={selectedCampaign}
          size="lg"
          onChange={handleSelectCampaign}
          options={campaings}
          placeholder={intl.formatMessage({ id: 'batchList.filter.campaign.placeholder' })}
          isDisabled={!selectedSynthesisStep}
        />
        <MultiSelect
          value={selectedTrackers}
          options={problemTrackerOptions}
          onChange={selectTrackers}
          size="lg"
          placeholder={intl.formatMessage({ id: 'batchList.filter.problemTrackers.empty' })}
        />
        <div className="d-flex flex-column justify-content-between">
          <div className="text-color-gray-l6 size-caption">
            <FormattedMessage id="batchList.filter.period.title" />
          </div>
          <div className="d-flex gap-3">
            <Button
              variant="link"
              onClick={() => setTimePeriod(TimePeriod.all)}
              className={classnames({ 'btn-link-selected': timePeriod === TimePeriod.all })}
            >
              <FormattedMessage id="batchList.filter.period.all" />
            </Button>
            <Button
              variant="link"
              onClick={() => setTimePeriod(TimePeriod.day)}
              className={classnames({ 'btn-link-selected': timePeriod === TimePeriod.day })}
            >
              <FormattedMessage id="batchList.filter.period.day" />
            </Button>
            <Button
              variant="link"
              onClick={() => setTimePeriod(TimePeriod.week)}
              className={classnames({ 'btn-link-selected': timePeriod === TimePeriod.week })}
            >
              <FormattedMessage id="batchList.filter.period.week" />
            </Button>
            <Button
              variant="link"
              onClick={() => setTimePeriod(TimePeriod.month)}
              className={classnames({ 'btn-link-selected': timePeriod === TimePeriod.month })}
            >
              <FormattedMessage id="batchList.filter.period.month" />
            </Button>
          </div>
        </div>
        <div className="ms-auto d-flex flex-column justify-content-between align-items-end">
          <div className="text-color-gray-l6 size-caption">
            <span className="me-2">
              <FormattedMessage id="batchList.filter.total.synthesisSteps" values={{ value: totalSynthesisSteps }} />
            </span>
            <FormattedMessage id="batchList.filter.total.campaigns" values={{ value: totalCampaings }} />
          </div>
          <FormattedMessage id="batchList.filter.total.batches" values={{ value: totalBatches }} />
        </div>
      </div>
      <InfiniteScroll
        className="mt-3 overflow-visible"
        next={fetchNextPage}
        hasMore={hasNextPage || false}
        loader={null}
        dataLength={totalPages}
        scrollableTarget="scrollContainer"
      >
        {!isLoadingBatches && (!groupedBatches || (groupedBatches.length === 0 && <NoData />))}
        {groupedBatches && groupedBatches.length > 0 && (
          <div className="card overflow-visible pb-2">
            <Accordion activeKey={batchId ?? null}>
              {isLoadingBatches && <BatchRowSkeleton cards={5} />}
              {!isLoadingBatches &&
                groupedBatches?.map((groupedBatch) => (
                  <div key={groupedBatch.campaign.id}>
                    <div className="p-3">
                      <Link to={groupedBatch.campaign.stepLink} className="base-link">
                        {groupedBatch.campaign.synthesisStepName}
                      </Link>{' '}
                      -{' '}
                      <Link to={groupedBatch.campaign.campaignLink} className="base-link">
                        {groupedBatch.campaign.name}
                      </Link>
                    </div>
                    <div className="px-2">
                      {groupedBatch.batches?.map((batch) => (
                        <BatchRow
                          key={batch.id}
                          batch={batch}
                          onToggle={handleBatchToggle}
                          onSelectPart={handleSelectPart}
                          isScrollIntoView={hash === batch.id}
                          showOor={handleShowOorDetails}
                          showOos={handleShowOosDetails}
                        />
                      ))}
                    </div>
                  </div>
                ))}
            </Accordion>
          </div>
        )}
      </InfiniteScroll>
      {oorBatchId && <BatchPartDetails batchId={oorBatchId} onClose={() => handleSelectPart(undefined)} />}
      {oosBatchId && <BatchTests batchId={oosBatchId} onClose={() => handleShowOosDetails(undefined)} />}
    </>
  );
};

export default BatchList;
