import React, { FC, PropsWithChildren } from 'react';
import { useIntl } from 'react-intl';
import { useAppContext } from '../../context/useAppContext';
import Header from '../Header/Header';

const DashboardHeader: FC<PropsWithChildren<Props>> = ({ children, showSearch = true }) => {
  const {
    state: { user },
  } = useAppContext();

  const intl = useIntl();

  return (
    <Header
      data-test-id="dashboard-header"
      title={intl.formatMessage({ id: 'header.welcomeBack' }, { name: user?.fullName })}
      showSearch={showSearch}
    >
      {children}
    </Header>
  );
};
interface Props {
  showSearch?: boolean;
}
export default DashboardHeader;
