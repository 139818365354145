import { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';

import useNotifications from './useNotifications';
import productService from '../services/productService';
import userService from '../services/userService';
import synthesisStepService from '../services/synthesisStepService';
import useSortingViewState from './useSortingViewState';
import ListUsersSortColumn from '../models/User/ListUsersSortColumn';
import companyService from '../services/companyService';

const pageSize = 20;

export const tabs = {
  products: 'Products',
  users: 'Users',
  steps: 'Steps',
  companies: 'Companies',
};

const userSearch = (
  searchTerm: string,
  companyId?: string,
  setting?: { users?: boolean; steps?: boolean; companies?: boolean },
) => {
  const intl = useIntl();
  const { notify } = useNotifications();
  const [selectedTab, selectTab] = useState<string>(tabs.products);

  const {
    data: productsResult,
    isLoading: isLoadingProducts,
    fetchNextPage: fetchProductsNextPage,
    hasNextPage: hasProductsNextPage,
  } = useInfiniteQuery(
    ['search', 'products', companyId, searchTerm],
    async ({ pageParam = 1 }) => {
      const data = await productService.list({
        companyId,
        searchTerm,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'hooks.search.notification.title' }),
          intl.formatMessage({ id: 'hooks.search.notification.unableLoadProducts' }),
        ),
    },
  );

  const { getSortOrder } = useSortingViewState();
  const usersSortOrder = getSortOrder<ListUsersSortColumn>('fullName', 'Users').toString();
  const {
    data: usersResult,
    isLoading: isLoadingUsers,
    fetchNextPage: fetchUsersNextPage,
    hasNextPage: hasUsersNextPage,
  } = useInfiniteQuery(
    ['search', 'users', companyId, searchTerm, usersSortOrder],
    async ({ pageParam = 1 }) => {
      const data = await userService.list({
        companyId,
        searchTerm,
        orderBy: usersSortOrder,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'hooks.search.notification.title' }),
          intl.formatMessage({ id: 'hooks.search.notification.unableLoadUsers' }),
        ),
      enabled: !!setting?.users,
    },
  );

  const {
    data: stepsResult,
    isLoading: isLoadingSteps,
    fetchNextPage: fetchStepsNextPage,
    hasNextPage: hasStepsNextPage,
  } = useInfiniteQuery(
    ['search', 'steps', searchTerm],
    async ({ pageParam = 1 }) => {
      const data = await synthesisStepService.manufacturerList({
        searchTerm,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'hooks.search.notification.title' }),
          intl.formatMessage({ id: 'hooks.search.notification.unableLoadSteps' }),
        ),
      enabled: !!setting?.steps,
    },
  );

  const {
    data: companiesResult,
    isLoading: isLoadingCompanies,
    fetchNextPage: fetchCompaniesNextPage,
    hasNextPage: hasCompaniesNextPage,
  } = useInfiniteQuery(
    ['search', 'companies', searchTerm],
    async ({ pageParam = 1 }) => {
      const data = await companyService.list({
        searchTerm,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'hooks.search.notification.title' }),
          intl.formatMessage({ id: 'hooks.search.notification.unableLoadCompanies' }),
        ),
      enabled: !!setting?.companies,
    },
  );

  useEffect(() => {
    if (!isLoadingProducts && !isLoadingCompanies && !isLoadingSteps && !isLoadingUsers) {
      if (productsResult?.pages[0]?.total) {
        selectTab(tabs.products);
      } else if (companiesResult?.pages[0]?.total) {
        selectTab(tabs.companies);
      } else if (usersResult?.pages[0]?.total) {
        selectTab(tabs.users);
      } else if (stepsResult?.pages[0]?.total) {
        selectTab(tabs.steps);
      }
    }
  }, [isLoadingProducts, isLoadingCompanies, isLoadingSteps, isLoadingUsers]);

  return {
    productsResult,
    isLoadingProducts,
    fetchProductsNextPage,
    hasProductsNextPage,
    usersResult,
    isLoadingUsers,
    fetchUsersNextPage,
    hasUsersNextPage,
    stepsResult,
    isLoadingSteps,
    fetchStepsNextPage,
    hasStepsNextPage,
    companiesResult,
    isLoadingCompanies,
    fetchCompaniesNextPage,
    hasCompaniesNextPage,
    selectedTab,
  };
};

export default userSearch;
