import React, { FC } from 'react';
import { Nav } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { NavLink, Outlet, useParams } from 'react-router-dom';
import BackButton from '../../../components/BackButton/BackButton';
import GenericFormattedDate from '../../../components/GenericFormattedDate/GenericFormattedDate';

import Header from '../../../components/Header/Header';
import Spinner from '../../../components/Spinner/Spinner';
import StatusBadge from '../../../components/StatusBadge/StatusBadge';
import numberHelper from '../../../helpers/numberHelper';
import useNotifications from '../../../hooks/useNotifications';
import companyService from '../../../services/companyService';

const companyOverview: FC = () => {
  const { id: companyId } = useParams<'id'>();
  const intl = useIntl();
  const { notify } = useNotifications();
  const { data: status, isLoading: isLoadingStatus } = useQuery(
    ['companyStatus', companyId],
    () => companyService.getStatus(companyId!),
    {
      onError: () =>
        notify(
          'error',
          intl.formatMessage({ id: 'admin.companyOverview.notification.title' }),
          intl.formatMessage({ id: 'admin.companyOverview.notification.unableLoadDetails' }),
        ),
    },
  );

  return (
    <>
      <Header
        title={
          <>
            {status?.name || ''}
            <div className="py-2">
              <span className="text-color-gray-l6 size-body">
                <FormattedMessage id="admin.companyOverview.subtitle" />
              </span>
            </div>
          </>
        }
      >
        <div className="d-flex gap-4 align-items-baseline">
          <StatusBadge isActive={status?.isActive || false} bg="gray-l4" className="size-caption" />
          <span className="size-caption">
            <FormattedMessage id="admin.companyOverview.header.createdAt" />{' '}
            <GenericFormattedDate value={status?.createdAt} />
          </span>
          <BackButton />
        </div>
      </Header>
      <div className="page-content px-5" id="companyOverviewContainer">
        <Nav variant="tabs" className="mb-4">
          <Nav.Item>
            <Nav.Link as={NavLink} to="details">
              <FormattedMessage id="admin.companyOverview.tab.detail.title" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="dashboard">
              <FormattedMessage id="admin.companyOverview.tab.status.title" />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="products">
              <FormattedMessage
                id="admin.companyOverview.tab.products.title"
                values={{ value: numberHelper.formatNumber(status?.totalProducts) }}
              />
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="users">
              <FormattedMessage
                id="admin.companyOverview.tab.users.title"
                values={{ value: numberHelper.formatNumber(status?.totalUsers) }}
              />
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Outlet />
        {isLoadingStatus && <Spinner />}
      </div>
    </>
  );
};

export default companyOverview;
