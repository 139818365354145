import React from 'react';
import IframeResizer from 'iframe-resizer-react';
import Header from '../../components/Header/Header';

interface Props {
  title: string;
  src: string;
}

const ExternalFrame: React.FC<Props> = ({ title, src }) => {
  return (
    <>
      <Header title={title} />
      <div className="page-content px-5">
        <IframeResizer heightCalculationMethod="bodyOffset" minHeight={750} scrolling src={src} />
      </div>
    </>
  );
};

export default ExternalFrame;
