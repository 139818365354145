import React, { FC, useMemo } from 'react';
import ContentLoader from 'react-content-loader';
import SkeletonColors from '../../../../models/Skeletons/SkeletonColors';
import SkeletonSpeed from '../../../../models/Skeletons/SkeletonSpeed';

import '../ProcessTab.scss';

const ProcessCardSkeleton: FC<Props> = ({ cards }) => {
  const items = useMemo(() => {
    return [...Array(cards).keys()].map((i) => i + 1);
  }, [cards]);

  return (
    <>
      {items.map((key) => (
        <div key={key} className="animated-component-skeleton" data-test-id="loader">
          <div className="mb-2">
            <ContentLoader
              height={122}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 260 122"
            >
              <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
            </ContentLoader>
          </div>
          <div className="mb-2">
            <ContentLoader
              height={122}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 260 122"
            >
              <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
            </ContentLoader>
          </div>
          <div className="mb-2">
            <ContentLoader
              height={122}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 260 122"
            >
              <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
            </ContentLoader>
          </div>
          <div className="mb-2">
            <ContentLoader
              height={122}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 260 122"
            >
              <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
            </ContentLoader>
          </div>
          <div className="mb-2">
            <ContentLoader
              height={122}
              speed={SkeletonSpeed}
              backgroundColor={SkeletonColors.darkGrey1}
              foregroundColor={SkeletonColors.darkGrey2}
              viewBox="0 0 260 122"
            >
              <rect x="0" y="0" rx="4" ry="4" width="260" height="122" />
            </ContentLoader>
          </div>
        </div>
      ))}
    </>
  );
};
interface Props {
  cards: number;
}
export default ProcessCardSkeleton;
