import React, { FC } from 'react';

import { useIntl } from 'react-intl';

import numberHelper from '../../../helpers/numberHelper';
import trackerHelper from '../../../helpers/trackerHelper';
import TrackerVariant from '../../../models/TrackerState';
import TrackerStatus from '../../../models/TrackerStatus';
import ValueTrackerWithDetails from '../ValueTrackerWithDetails/ValueTrackerWithDetails';

const OutOfTime: FC<Props> = ({ delay, tooltip, status, link }) => {
  const intl = useIntl();

  return (
    <ValueTrackerWithDetails
      title={intl.formatMessage({ id: 'tracker.outOfTime.shortTitle' })}
      titleTooltip={intl.formatMessage({ id: 'tracker.outOfTime.title' })}
      value={numberHelper.formatNumber(delay)?.toString()}
      variant={trackerHelper.getTypeForTrackerStatus(status, TrackerVariant.positive)}
      tooltip={tooltip}
      units={`\u0020${intl.formatMessage({ id: 'tracker.outOfTime.unitDay' }, { days: delay })}`}
      dataTestId="outOfTime-component"
      link={status && status !== TrackerStatus.Normal ? link : undefined}
    />
  );
};

type Props = {
  delay?: number | null;
  status?: TrackerStatus | null;
  tooltip?: string;
  link: string;
};

export default OutOfTime;
