import { useQuery } from 'react-query';
import applicationService from '../../services/AppGallery/applicationService';

const queryKey = 'processList';

const useProcessList = (options: { onError?: () => void }) =>
  useQuery([queryKey], async () => applicationService.listProcesses(), {
    onError: options.onError,
  });

export default useProcessList;
