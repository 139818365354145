import React, { FC } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { useQuery } from 'react-query';
import messageService from '../../services/InternalMessaging/messageService';
import MessageStatus from '../../models/Messages/MessageStatus';
import NavItem from './NavItem';

type Props = {
  icon: IconDefinition;
  path: string;
  labelKey: string;
  dataTestId?: string;
};

const MessagesNavItem: FC<Props> = ({ path, labelKey, icon, dataTestId }) => {
  const { data: messages, refetch } = useQuery(
    ['messages', 'unread'],
    () => messageService.list({ paging: { pageSize: 1, pageNumber: 1 }, status: MessageStatus.unread }),
    {
      refetchOnWindowFocus: true,
    },
  );

  return (
    <NavItem path={path} labelKey={labelKey} icon={icon} dataTestId={dataTestId} onClick={() => refetch()}>
      {' '}
      ({messages?.total ?? 0})
    </NavItem>
  );
};

export default MessagesNavItem;
