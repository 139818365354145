import React, { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';

import './ProductAlerts.scss';

import useNotifications from '../../hooks/useNotifications';
import alertService from '../../services/alertService';
import GenericFormattedDate from '../GenericFormattedDate/GenericFormattedDate';
import TableHeader from '../Table/TableHeader/TableHeader';
import alertsHelper from '../../helpers/alertsHelper';
import TitleTooltip from '../Tooltips/TitleTooltip/TitleTooltip';
import IconTooltip from '../Tooltips/IconTooltip/IconTooltip';
import UpdatedAtSkeleton from '../Skeletons/UpdatedAtSkeleton';
import ProductAlertsSkeleton from './ProductAlertsSkeleton';
import useSortingViewState from '../../hooks/useSortingViewState';
import ListAlertsSortColumn from '../../models/Alert/ListAlertSortColumn';
import productsHelper from '../../helpers/productsHelper';
import companiesHelper from '../../helpers/companiesHelper';

const pageSize = 20;

const ProductAlerts: React.FC<Props> = ({ productId, companyId }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const columns: { name: ListAlertsSortColumn | ''; key: string }[] = useMemo(() => {
    let productColumns: { name: ListAlertsSortColumn | ''; key: string }[] =
      !productId && !companyId ? [{ name: 'companyName', key: 'productAlerts.table.header.companyName' }] : [];
    productColumns = !productId
      ? [...productColumns, { name: 'productName', key: 'productAlerts.table.header.productName' }]
      : productColumns;

    return [
      ...productColumns,
      { name: 'typeName', key: 'productAlerts.table.header.typeName' },
      { name: 'createdAt', key: 'productAlerts.table.header.createdAt' },
      { name: '', key: '' }, // action
    ];
  }, [productId, companyId]);

  const {
    data: alertsData,
    isLoading: isAlertsLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['productAlerts', productId, companyId, getSortOrder<ListAlertsSortColumn>('createdAt', 'Alerts', false)],
    async ({ pageParam = 1 }) => {
      const data = await alertService.list(
        productId,
        companyId,
        { pageSize, pageNumber: pageParam },
        getSortOrder<ListAlertsSortColumn>('createdAt', 'Alerts', false).toString(),
      );
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify(
          'error',
          fm({ id: 'productAlerts.notification.title' }),
          fm({ id: 'productAlerts.notification.unableToLoadAlerts' }),
        ),
    },
  );

  return (
    <div className="product-alerts-component mb-4 shadow-2">
      <div className="bg-color-gray-l3 p-4 rounded-top row">
        <div className="col text-uppercase font-bold">
          <FormattedMessage id="productAlerts.table.title" />
        </div>
        <div className="col-6 d-flex justify-content-end text-color-gray-l6 size-caption">
          {isAlertsLoading && <UpdatedAtSkeleton />}
          {alertsData?.pages[0].updatedAt && (
            <span>
              <FormattedMessage id="productAlerts.updatedAt" />{' '}
              <GenericFormattedDate value={alertsData.pages[0].updatedAt} />
            </span>
          )}
        </div>
      </div>
      {alertsData?.pages?.[0]?.alerts?.length === 0 && (
        <div className="row p-3 text-color-gray-l6">
          <div className="col d-flex justify-content-center">
            <FormattedMessage id="productAlerts.noData" />
          </div>
        </div>
      )}

      {isAlertsLoading && <ProductAlertsSkeleton cards={11} columns={columns} />}
      {!!alertsData?.pages?.[0]?.alerts?.length && (
        <InfiniteScroll
          className="rounded-bottom"
          next={fetchNextPage}
          hasMore={hasNextPage || false}
          loader={null}
          dataLength={alertsData.pages.length}
          scrollableTarget="scrollContainer"
        >
          <table className="w-100 col-25-wide">
            <TableHeader<ListAlertsSortColumn> columns={columns} tableName="Alerts" />
            <tbody className="animated-component-item">
              {alertsData.pages
                .flatMap((x) => x.alerts)
                .map((alert) => (
                  <tr key={alert.id} data-test-id="alerts-row">
                    {!companyId && !productId && (
                      <td data-test-id="alerts-row-companyName" className="font-semi-bold">
                        <TitleTooltip
                          titleTooltip={alert.companyName}
                          title={alert.companyName}
                          titleClassName="inline-crop"
                          link={companiesHelper.getCompanyUrl(alert.companyId)}
                        />
                      </td>
                    )}
                    {!productId && (
                      <td data-test-id="alerts-row-productName" className="font-semi-bold">
                        <TitleTooltip
                          titleTooltip={alert.productName}
                          title={alert.productName}
                          titleClassName="inline-crop"
                          link={productsHelper.getProductUrl(alert.productId)}
                        />
                      </td>
                    )}
                    <td data-test-id="alerts-row-typeName">
                      <Link to={alertsHelper.getItemUrl(alert)} className="base-link">
                        <FormattedMessage id={`productAlerts.type.${alert.typeName}.title`} />
                      </Link>
                      <IconTooltip
                        tooltip={fm({ id: `productAlerts.type.${alert.typeName}.description` })}
                        className="ms-1"
                      />
                    </td>
                    <td data-test-id="alerts-row-createdAt">
                      <GenericFormattedDate value={alert.createdAt} withTime />
                    </td>
                    <td align="right">
                      <Button
                        variant="secondary"
                        className="btn-toggle d-flex align-items-center justify-content-center"
                        href={alertsHelper.getItemUrl(alert)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </InfiniteScroll>
      )}
    </div>
  );
};

interface Props {
  productId?: string;
  companyId?: string;
}

export default ProductAlerts;
