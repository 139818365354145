import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import productsHelper from '../../../helpers/productsHelper';
import useNotifications from '../../../hooks/useNotifications';
import TableHeader from '../../Table/TableHeader/TableHeader';
import ListUpdateSortColumn from '../../../models/Update/ListUpdateSortColumn';
import updateService from '../../../services/updateService';
import GenericFormattedDate from '../../GenericFormattedDate/GenericFormattedDate';
import './ProductUpdatesMini.scss';
import updatesHelper from '../../../helpers/updatesHelper';
import TitleTooltip from '../../Tooltips/TitleTooltip/TitleTooltip';
import UpdatedAtSkeleton from '../../Skeletons/UpdatedAtSkeleton';
import ProductUpdatesMiniSkeleton from './ProductUpdatesMiniSkeleton';
import useSortingViewState from '../../../hooks/useSortingViewState';
import companiesHelper from '../../../helpers/companiesHelper';

const pageSize = 3;

const ProductUpdatesMini: React.FC<Props> = ({ productId, companyId }) => {
  const { formatMessage: fm } = useIntl();
  const { notify } = useNotifications();
  const { getSortOrder } = useSortingViewState();
  const columns: { name: ListUpdateSortColumn | ''; key: string }[] = useMemo(() => {
    let productColumns: { name: ListUpdateSortColumn | ''; key: string }[] =
      !productId && !companyId ? [{ name: 'companyName', key: 'productUpdates.table.header.companyName' }] : [];
    productColumns = !productId
      ? [...productColumns, { name: 'productName', key: 'productUpdates.table.header.productName' }]
      : productColumns;

    return [
      ...productColumns,
      { name: 'typeName', key: 'productUpdates.table.header.typeName' },
      { name: 'createdAt', key: 'productUpdates.table.header.createdAt' },
      { name: '', key: '' }, // action
    ];
  }, [productId, companyId]);

  const { data: updatesData, isLoading: isUpdatesLoading } = useQuery(
    ['productUpdatesMini', companyId, productId, getSortOrder<ListUpdateSortColumn>('createdAt', 'Updates', false)],
    () =>
      updateService.list(
        companyId,
        productId,
        { pageSize, pageNumber: 1 },
        getSortOrder<ListUpdateSortColumn>('createdAt', 'Updates', false).toString(),
      ),
    {
      onError: () =>
        notify(
          'error',
          fm({ id: 'productUpdates.notification.title' }),
          fm({ id: 'productUpdates.notification.unableToLoadUpdates' }),
        ),
    },
  );

  const getShowAllLink = () => {
    if (productId) return productsHelper.getProductUpdatesUrl(productId);
    if (companyId) return updatesHelper.getAdminClientUpdatesUrl(companyId);
    return updatesHelper.adminUpdatesUrl;
  };

  return (
    <div className="product-updates-mini-component narrow-row-table shadow-2 w-100 mb-4">
      <div className="bg-color-gray-l3 p-4 rounded-top row">
        <div className="col text-uppercase font-bold">
          <FormattedMessage id="productUpdates.table.title" />
        </div>
        <div className="col-6 d-flex justify-content-end text-color-gray-l6 size-caption">
          {isUpdatesLoading && <UpdatedAtSkeleton />}
          {updatesData?.updatedAt && (
            <span>
              <FormattedMessage id="productUpdates.updatedAt" /> <GenericFormattedDate value={updatesData.updatedAt} />
            </span>
          )}
        </div>
      </div>
      {updatesData?.updates?.length === 0 && (
        <div className="row p-3 text-color-gray-l6">
          <div className="col d-flex justify-content-center">
            <FormattedMessage id="productUpdates.noData" />
          </div>
        </div>
      )}
      {isUpdatesLoading && <ProductUpdatesMiniSkeleton cards={3} columns={columns} />}
      {!!updatesData?.updates?.length && (
        <table className="w-100">
          <TableHeader<ListUpdateSortColumn> columns={columns} tableName="Updates" />
          <tbody className="animated-component-item">
            {updatesData?.updates.map((update) => (
              <tr key={update.id} data-test-id="updates-row">
                {!companyId && !productId && (
                  <td data-test-id="updates-row-companyName" className="td-width">
                    <TitleTooltip
                      titleTooltip={update.companyName}
                      title={update.companyName}
                      titleClassName="inline-crop"
                      link={companiesHelper.getCompanyUrl(update.companyId)}
                    />
                  </td>
                )}
                {!productId && (
                  <td data-test-id="updates-row-productName" className="td-width">
                    <TitleTooltip
                      titleTooltip={update.productName}
                      title={update.productName}
                      titleClassName="inline-crop"
                      link={productsHelper.getProductUrl(update.productId)}
                    />
                  </td>
                )}
                <td data-test-id="updates-row-typeName">
                  <TitleTooltip
                    titleTooltip={fm({ id: `productUpdates.type.${update.typeName}.title` }, update.extendedData)}
                    title={fm({ id: `productUpdates.type.${update.typeName}.title` }, update.extendedData)}
                    titleClassName="inline-crop"
                    link={updatesHelper.getItemUrl(update)}
                  />
                </td>
                <td data-test-id="updates-row-createdAt">
                  <GenericFormattedDate value={update.createdAt} withTime />
                </td>
                <td align="right">
                  <Button
                    variant="link"
                    className="btn-toggle d-flex align-items-center justify-content-center"
                    href={updatesHelper.getItemUrl(update)}
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      {!isUpdatesLoading && (
        <div className="bg-color-gray-l3 px-4 py-2 rounded-bottom row">
          <div className="col d-flex justify-content-end">
            {pageSize < (updatesData?.total || 0) && (
              <Link to={getShowAllLink()} className="link link--underlined link--secondary size-caption">
                <FormattedMessage id="productUpdates.action.details" />
              </Link>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

interface Props {
  productId?: string;
  companyId?: string;
}

export default ProductUpdatesMini;
