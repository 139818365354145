import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import Header from '../../../components/Header/Header';
import ProductAlerts from '../../../components/ProductAlerts/ProductAlerts';
import useNotifications from '../../../hooks/useNotifications';
import useCompanies from '../../../hooks/admin/companies/useCompanies';
import Select from '../../../components/Select/Select';
import AlertsStatistics from '../../../components/AlertsStatistics/AlertsStatistics';

const AlertsPage: React.FC = () => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();

  const [searchParams, setSearchParams] = useSearchParams();
  const companyId = searchParams.get('companyId');
  const onCompanyIdChange = (newCompanyId: string | undefined) => {
    if (!newCompanyId) {
      searchParams.delete('companyId');
    } else {
      searchParams.set('companyId', newCompanyId);
    }
    setSearchParams(searchParams);
  };

  const { data: companyList } = useCompanies({
    onError: () =>
      notify(
        'error',
        fm({ id: 'admin.alerts.notification.title' }),
        fm({ id: 'admin.alerts.notification.unableToLoadCompanies' }),
      ),
  });

  const companyOptions = useMemo(() => {
    return [
      { label: fm({ id: 'admin.alerts.filter.company.empty' }), value: '' },
      ...(companyList ? companyList.companies.map((company) => ({ label: company.name, value: company.id })) : []),
    ];
  }, [companyList?.companies, locale]);

  return (
    <>
      <Header title={fm({ id: 'admin.alerts.title' })} showSearch />
      <div className="smart-scroll">
        <div className="page-content px-5">
          <div className="filters-container mb-4">
            <Form.Group>
              <Select
                value={companyId || ''}
                onChange={onCompanyIdChange}
                options={companyOptions}
                size="lg"
                dataTestId="alerts-companyFilter"
              />
            </Form.Group>
          </div>
          <AlertsStatistics className="mb-4 shadow-2" size={20} companyId={companyId ?? undefined} />
          <ProductAlerts companyId={companyId ?? undefined} />
        </div>
      </div>
    </>
  );
};

export default AlertsPage;
