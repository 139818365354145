import React, { useMemo, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useInfiniteQuery } from 'react-query';
import { Button, Form } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';

import useNotifications from '../../../hooks/useNotifications';
import Header from '../../../components/Header/Header';
import companyService from '../../../services/companyService';
import StatusSelect from '../../../components/StatusSelect/StatusSelect';
import CompanyItem from './CompanyItem/CompanyItem';
import AddEditCompany from './AddEditCompany/AddEditCompany';
import CompaniesSkeleton from './CompaniesSkeleton';
import CompanyType from '../../../models/Company/CompanyType';
import Select from '../../../components/Select/Select';
import NoData from '../../../components/NoData/NoData';

const pageSize = 20;

const Companies = () => {
  const { formatMessage: fm, locale } = useIntl();
  const { notify } = useNotifications();
  const [isActiveFilter, setIsActiveFilter] = useState<boolean | undefined>();
  const [companyTypeFilter, setCompanyTypeFilter] = useState<string | undefined>();
  const [isAdd, setIsAdd] = useState<boolean>(false);

  const {
    data: companiesModel,
    isLoading: isCompaniesLoading,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(
    ['companies', isActiveFilter, companyTypeFilter],
    async ({ pageParam = 1 }) => {
      const data = await companyService.list({
        isActive: isActiveFilter,
        companyType: companyTypeFilter,
        paging: {
          pageNumber: pageParam,
          pageSize,
        },
      });
      return {
        ...data,
        nextPage: pageParam + 1,
      };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.nextPage <= Math.ceil(allPages[0].total / pageSize)) {
          return lastPage.nextPage;
        }
        return undefined;
      },
      onError: () =>
        notify('error', fm({ id: 'admin.companies.title' }), fm({ id: 'admin.companies.notification.unableLoadList' })),
    },
  );

  const companyTypeOptions = useMemo(() => {
    return [
      { label: fm({ id: 'admin.companies.filter.type.empty' }), value: '' },
      { label: fm({ id: 'admin.companies.filter.type.hybrid' }), value: CompanyType.Hybrid },
      { label: fm({ id: 'admin.companies.filter.type.client' }), value: CompanyType.Client },
      { label: fm({ id: 'admin.companies.filter.type.manufacturer' }), value: CompanyType.Manufacturer },
    ];
  }, [locale]);

  return (
    <>
      <Header title={fm({ id: 'header.admin.companiesTitle' })} showSearch />
      <div className="page-content px-5 pb-4">
        <div className="filters-container pb-4">
          <div className="d-flex gap-3">
            <Form.Group>
              <StatusSelect
                onChange={(value) => setIsActiveFilter(value)}
                value={isActiveFilter}
                dataTestId="companies-statusFilter"
                labels={{
                  empty: fm({ id: 'admin.companies.filter.enabled.empty' }),
                  active: fm({ id: 'admin.companies.filter.enabled.onlyEnabled' }),
                  inactive: fm({ id: 'admin.companies.filter.enabled.onlyDisabled' }),
                }}
              />
            </Form.Group>
            <Form.Group>
              <Select
                value={companyTypeFilter || ''}
                onChange={(value) => setCompanyTypeFilter(value !== '' ? value : undefined)}
                options={companyTypeOptions}
                size="lg"
                dataTestId="companies-typeFilter"
              />
            </Form.Group>
            <div className="d-flex align-items-center">
              {isActiveFilter !== undefined || companyTypeFilter !== undefined
                ? fm({ id: 'admin.companies.filter.total.label' }, { value: companiesModel?.pages[0].total || 0 })
                : null}
            </div>
          </div>
          <Button
            variant="link"
            className="btn-link-underline btn-link-underline-create"
            onClick={() => setIsAdd(true)}
            data-test-id="companies-add"
          >
            <FormattedMessage id="admin.companies.form.title.add" />
          </Button>
        </div>
        {companiesModel?.pages[0].companies?.length === 0 && <NoData />}
        {isCompaniesLoading && (
          <div className="form-2-col gap-4">
            <CompaniesSkeleton cards={6} />
          </div>
        )}

        {!!companiesModel?.pages?.[0]?.companies?.length && (
          <InfiniteScroll
            className="animated-component-item overflow-visible"
            next={fetchNextPage}
            hasMore={hasNextPage || false}
            loader={null}
            dataLength={companiesModel?.pages.length || 0}
            scrollableTarget="scrollContainer"
          >
            <div className="form-2-col gap-4">
              {companiesModel?.pages
                ?.flatMap((x) => x.companies)
                .map((company) => (
                  <CompanyItem key={company.id} company={company} />
                ))}
            </div>
          </InfiniteScroll>
        )}
        {isAdd && <AddEditCompany companyId={null} onClose={() => setIsAdd(false)} />}
      </div>
    </>
  );
};

export default Companies;
