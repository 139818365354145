import React, { FC } from 'react';

import './Confirm.scss';

import { Modal, Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';

const Confirm: FC<Props> = ({
  title,
  targetName,
  confirmTitle,
  message,
  onClose,
  onConfirm,
  showWarningIcon = false,
}) => {
  const onCloseHandler = () => {
    onClose();
  };

  const onConfirmHandler = () => {
    onConfirm();
  };

  return (
    <Modal className="confirm-component" show centered backdrop="static" keyboard={false} data-test-id="confirm">
      <Modal.Body className="text-center">
        <div className="text-color-gray-l6 size-label text-uppercase mb-3" data-test-id="confirm-title">
          {title}
        </div>
        {showWarningIcon && (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="confirm-component-icon text-color-warn pb-3"
            size="lg"
          />
        )}
        <div className="message pb-4" data-test-id="confirm-message">
          {message}
        </div>
        {targetName && <h3 className="my-3">{targetName}</h3>}
      </Modal.Body>
      <Button variant="danger" className="text-uppercase mb-3" onClick={onConfirmHandler}>
        {confirmTitle}
      </Button>
      <Button variant="ghost" onClick={onCloseHandler}>
        <FormattedMessage id="confirm.cancelBtn.label" />
      </Button>
    </Modal>
  );
};

type Props = {
  title: string;
  targetName?: string;
  message: string;
  confirmTitle: string;
  onClose: () => void;
  onConfirm: () => void;
  showWarningIcon?: boolean;
};

export default Confirm;
