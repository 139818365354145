import appConfig from '../../appConfig';
import AppDetails from '../../models/AppGallery/Application/AppDetails';
import ApplicationsList from '../../models/AppGallery/Application/ApplicationsList';
import ApplicationStatus from '../../models/AppGallery/Application/ApplicationStatus';
import CategoryItem from '../../models/AppGallery/Application/CategoryItem';
import ProcessItem from '../../models/AppGallery/Application/ProcessItem';
import request from '../requestService';

class ApplicationService {
  private readonly BaseUrl = `/Application`;

  public async list(params: {
    processId?: string;
    categoryId?: string;
    orderBy?: string;
    status?: ApplicationStatus;
  }): Promise<ApplicationsList> {
    const response = await request.get<ApplicationsList>(`${this.BaseUrl}/List`, {
      params,
      baseURL: appConfig.appGalleryBackendUrl,
    });

    return response.data;
  }

  public async details(applicationId: string): Promise<AppDetails> {
    const response = await request.get(`${this.BaseUrl}/Details?id=${applicationId}`, {
      baseURL: appConfig.appGalleryBackendUrl,
    });
    return response.data;
  }

  public async listProcesses(): Promise<ProcessItem[]> {
    const response = await request.get<{ processes: ProcessItem[] }>(`${this.BaseUrl}/ListProcesses`, {
      baseURL: appConfig.appGalleryBackendUrl,
    });

    return response.data.processes;
  }

  public async listCategories(): Promise<CategoryItem[]> {
    const response = await request.get<{ categories: CategoryItem[] }>(`${this.BaseUrl}/ListCategories`, {
      baseURL: appConfig.appGalleryBackendUrl,
    });

    return response.data.categories;
  }

  public removeApplication(id: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/Remove`,
      {
        id,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }

  public activateApplication(id: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/Activate`,
      {
        id,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }

  public deactivateApplication(id: string): Promise<void> {
    return request.post(
      `${this.BaseUrl}/Deactivate`,
      {
        id,
      },
      { baseURL: appConfig.appGalleryBackendUrl },
    );
  }
}
export default new ApplicationService();
