import React, { FC, useEffect, useState } from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import ChangeMfaResult from '../../../../models/Account/ChangeMfaResult';
import AuthToken from '../../../../models/AuthToken';
import accountService from '../../../../services/accountService';
import authStorageService from '../../../../services/authStorageService';
import ConfirmationModal from './ConfirmationModal';
import RecoveryCodeModal from './RecoveryCodeModal';
import SetUpNewDevice from './SetUpNewDeviceModal';

const ChangeMfa: FC<Props> = ({ onClose }) => {
  const [changeMfaResult, setChangeMfaResult] = useState<ChangeMfaResult | undefined>();
  const [authToken, setAuthToken] = useState<AuthToken | undefined>();
  const [recoveryCode, setRecoveryCode] = useState<string | undefined>();

  useEffect(() => {
    if (!authToken) {
      return;
    }

    const regenerateRecoveryWithNewToken = async () => {
      authStorageService.setState(authToken);
      const newRecoveryCode = await accountService.regenerateRecoveryCode();
      setRecoveryCode(newRecoveryCode);
    };

    regenerateRecoveryWithNewToken();
  }, [authToken]);

  const handleMfaReset = (data: ChangeMfaResult) => setChangeMfaResult(data);
  const handleDeviceConfirmed = (token: AuthToken) => setAuthToken(token);
  const handleOnClose = () => {
    setChangeMfaResult(undefined);
    setAuthToken(undefined);
    setRecoveryCode(undefined);

    onClose();
  };

  return (
    <>
      {!changeMfaResult && <ConfirmationModal onConfirmed={handleMfaReset} onClose={handleOnClose} />}
      {changeMfaResult && !authToken && (
        <SetUpNewDevice
          barcodeUri={changeMfaResult.barcodeUri}
          mfaToken={changeMfaResult.mfaToken}
          onCodeVerified={handleDeviceConfirmed}
          onClose={handleOnClose}
        />
      )}
      {authToken && !recoveryCode && <Spinner />}
      {recoveryCode && <RecoveryCodeModal recoveryCode={recoveryCode} onClose={handleOnClose} />}
    </>
  );
};

interface Props {
  onClose: () => void;
}

export default ChangeMfa;
